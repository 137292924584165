const Switcher = ({ state, setState, switchData }) => {
    return (
        <div className="bg-[#EAEEF4] shadow-inner w-fit h-fit rounded-lg p-[1px] min-w-fit flex items-center">
            {switchData.map((currSwitch,i) => (
                <button
                    key={i+"swicherDataComp"}
                    onClick={() => setState(currSwitch.state)}
                    type='button'
                    className={` ${
                        state === currSwitch.state
                            ? "bg-white text-[#5500E7]"
                            : "bg-transparent text-[#292931]"
                    } flex gap-2 items-center rounded-lg p-2 px-3 !w-fit min-w-[100px] justify-center`}
                >
                    
                    <img src={currSwitch.img} alt="" />
                    <span className="whitespace-nowrap font-medium min-w-fit">{currSwitch.name}</span>
                </button>
            ))}
        </div>
    )
}

export default Switcher
