import React, { useEffect, useState } from "react";
import "./ManageBooking.css";
import MyBooking from "../../components/Loader/MyBooking";
import BookingCard from "../../components/BookingCard/BookingCard";
import { Helmet } from "react-helmet";
import {
    HotelUserServices,
    setAuthToken,
    getUserDetails,
} from "../../services/supplier";

function ManageBooking() { 
    const [currentTab, setCurrentTab] = useState("upcoming");
    const [bookingsData, setBookingsData] = useState("");
    const [loading, setLoading] = useState(true);
    const [isTransaction, setIsTransaction] = useState(false)
    const getBookings = async () => {
        try {
            setLoading(true);
            setAuthToken(getUserDetails()?.token);
            const hotelData = await HotelUserServices.getUserBookings(
                getUserDetails()?.userId,
                currentTab
            );
            setBookingsData(hotelData.data?.booking);
            setLoading(false);
        } catch (err) {
            // console.log(err.response)
        }
    };

    useEffect(() => {
        getBookings();
    }, [currentTab]);
    useEffect(() => {
        getBookings()
    },[])
    return (
        <div className="app">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Buk | Manage Booking</title>
                <link rel="canonical"/>
            </Helmet>
            <div className="m-auto tabbar 2xl:min-h-[100vh]">
                <p className="text-center heading1 text-4xl font-bold mt-8 fontdesc">
                    My Bookings
                </p>
                <ul
                    className="tabnav nav nav-tabs flex flex-row gap-8 justify-center list-none border-b-0 pl-0 mb-4 items !p-0 mt-16"
                    id="tabs-tab"
                    role="tablist"
                >
                    
                    <li className="nav-item" role="presentation">
                        <a
                            href="#tabs-profile"
                            onClick={() => setCurrentTab("upcoming")}
                            className="active fontdesc nav-link block font-normal text-lg leading-tight border-x-0 border-t-0 border-b-2 border-transparent py-2 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent text-[#292931]"
                            id="tabs-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#tabs-profile"
                            role="tab"
                            aria-controls="tabs-profile"
                            aria-selected="false"
                        >
                            Upcoming
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a
                            href="#tabs-profile"
                            onClick={() => setCurrentTab("sale")}
                            className="fontdesc nav-link block font-normal text-lg leading-tight border-x-0 border-t-0 border-b-2 border-transparent py-2 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent text-[#292931]"
                            id="tabs-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#tabs-profile"
                            role="tab"
                            aria-controls="tabs-profile"
                            aria-selected="false"
                        >
                            Listed For Sale
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a
                            href="#tabs-messages"
                            onClick={() => setCurrentTab("checkin")}
                            className="fontdesc nav-link block font-normal text-lg leading-tight border-x-0 border-t-0 border-b-2 border-transparent py-2 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent text-[#292931]"
                            id="tabs-messages-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#tabs-messages"
                            role="tab"
                            aria-controls="tabs-messages"
                            aria-selected="false"
                        >
                            Checked-in
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a
                            href="#tabs-messages"
                            onClick={() => setCurrentTab("sold")}
                            className="fontdesc nav-link block font-normal text-lg leading-tight border-x-0 border-t-0 border-b-2 border-transparent py-2 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent text-[#292931]"
                            id="tabs-sold-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#tabs-sold"
                            role="tab"
                            aria-controls="tabs-sold"
                            aria-selected="false"
                        >
                            Sold
                        </a>
                    </li>
                    {/* Past bookings */}
                    <li className="nav-item" role="presentation">
                        <a
                            href="#tabs-messages"
                            onClick={() => setCurrentTab("past")}
                            className="fontdesc nav-link block font-normal text-lg leading-tight border-x-0 border-t-0 border-b-2 border-transparent py-2 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent text-[#292931]"
                            id="tabs-cancelled-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#tabs-cancelled"
                            role="tab"
                            aria-controls="tabs-cancelled"
                            aria-selected="false"
                        >
                            Past Bookings
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a
                            href="#tabs-messages"
                            onClick={() => setCurrentTab("cancelled")}
                            className="fontdesc nav-link block font-normal text-lg leading-tight border-x-0 border-t-0 border-b-2 border-transparent py-2 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent text-[#292931]"
                            id="tabs-cancelled-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#tabs-cancelled"
                            role="tab"
                            aria-controls="tabs-cancelled"
                            aria-selected="false"
                        >
                            Cancelled
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a
                            href="#tabs-home"
                            onClick={() => setCurrentTab("all")}
                            className="fontdesc nav-link block font-normal text-lg leading-tight border-x-0 border-t-0 border-b-2 border-transparent py-2 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent"
                            id="tabs-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#tabs-home"
                            role="tab"
                            aria-controls="tabs-home"
                            aria-selected="true"
                        >
                            All Bookings
                        </a>
                    </li>
                </ul>
                <div className="tab-content" id="tabs-tabContent">
                    {currentTab === "all" && (
                        <div className="" aria-labelledby="tabs-home-tab">
                            {loading ? (
                                <MyBooking isTransaction={isTransaction} />
                            ) : bookingsData && bookingsData.length > 0 ? (
                                bookingsData.map((item) => {
                                    return item.checkInStatus == "CHECKED_IN" ? (
                                        <BookingCard
                                            key={item._id}
                                            uniqueKey={item._id}
                                            item={item}
                                            type={item.checkInStatus}
                                            setLoading={setLoading}
                                            setIsTransaction={setIsTransaction}
                                            getBookings={getBookings}
                                            city={item.hotelId?.city}
                                            date={item.fromDate}
                                        />
                                    ) : item.checkInStatus == "CANCELLED" ? (
                                        <BookingCard
                                            key={item._id}
                                            uniqueKey={item._id}
                                            item={item}
                                            type={item.checkInStatus}
                                            setLoading={setLoading}
                                            setIsTransaction={setIsTransaction}
                                            getBookings={getBookings}
                                            city={item.hotelId?.city}
                                            date={item.fromDate}
                                        />
                                    ) : (
                                        <BookingCard
                                            key={item._id}
                                            uniqueKey={item._id}
                                            item={item}
                                            type={item.saleStatus}
                                            setLoading={setLoading}
                                            setIsTransaction={setIsTransaction}
                                            getBookings={getBookings}
                                            city={item.hotelId?.city}
                                            date={item.fromDate}
                                        />
                                    );
                                })
                            ) : (
                                <div className="min-h-[340px] flex items-center justify-center font-bold text-2xl">
                                    No Bookings Found...
                                </div>
                            )}
                        </div>
                    )}
                    {currentTab === "sale" && (
                        <div className="" aria-labelledby="tabs-profile-tab">
                            {loading ? 
                                <MyBooking isTransaction={isTransaction} /> : 
                                bookingsData && bookingsData.length > 0 ? (
                                bookingsData.map((item) => {
                                    return (
                                        <BookingCard
                                            key={item._id}
                                            uniqueKey={item._id}
                                            item={item}
                                            type={item.saleStatus}
                                            setLoading={setLoading}
                                            setIsTransaction={setIsTransaction}
                                            getBookings={getBookings}
                                        />
                                    );
                                })
                            ) : (
                                <div className="min-h-[340px] flex items-center justify-center font-bold text-2xl">
                                    No Bookings Found...
                                </div>
                            )}
                        </div>
                    )}
                    {currentTab === "checkin" && (
                        <div className="" aria-labelledby="tabs-profile-tab">
                            {
                                loading ? 
                                    <MyBooking isTransaction={isTransaction} /> :
                            bookingsData && bookingsData.length > 0 ? (
                                bookingsData.map((item) => {
                                    return (
                                        <BookingCard
                                            key={item._id}
                                            uniqueKey={item._id}
                                            item={item}
                                            type={item.checkInStatus}
                                            setLoading={setLoading}
                                            setIsTransaction={setIsTransaction}
                                            getBookings={getBookings}
                                        />
                                    );
                                })
                            ) : (
                                <div className="min-h-[340px] flex items-center justify-center font-bold text-2xl">
                                    No Bookings Found...
                                </div>
                            )}
                        </div>
                    )}
                    {currentTab === "sold" && (
                        <div className="" aria-labelledby="tabs-sold-tab">
                            {loading ? 
                                <MyBooking isTransaction={isTransaction} /> : bookingsData && bookingsData.length > 0 ? (
                                bookingsData.map((item) => {
                                    return (
                                        <BookingCard
                                            key={item._id}
                                            uniqueKey={item._id}
                                            item={item}
                                            type={item.saleStatus}
                                            setLoading={setLoading}
                                            setIsTransaction={setIsTransaction}
                                            getBookings={getBookings}
                                        />
                                    );
                                })
                            ) : (
                                <div className="min-h-[340px] flex items-center justify-center font-bold text-2xl">
                                    No Bookings Found...
                                </div>
                            )}
                        </div>
                    )}
                    {currentTab === "cancelled" && (
                        <div className="" aria-labelledby="tabs-cancelled-tab">
                            {loading ? 
                                <MyBooking isTransaction={isTransaction} /> :bookingsData && bookingsData.length > 0 ? (
                                bookingsData.map((item) => {
                                    return (
                                        <BookingCard
                                            key={item._id}
                                            uniqueKey={item._id}
                                            item={item}
                                            type={item.checkInStatus}
                                            setLoading={setLoading}
                                            setIsTransaction={setIsTransaction}
                                            getBookings={getBookings}
                                        />
                                    );
                                })
                            ) : (
                                <div className="min-h-[340px] flex items-center justify-center font-bold text-2xl">
                                    No Bookings Found...
                                </div>
                            )}
                        </div>
                    )}
                    {currentTab === "past" && (
                        <div className="" aria-labelledby="tabs-cancelled-tab">
                            {loading ? 
                                <MyBooking isTransaction={isTransaction} /> : bookingsData && bookingsData.length > 0 ? (
                                bookingsData.map((item) => {
                                    return (
                                        <BookingCard
                                            key={item._id}
                                            uniqueKey={item._id}
                                            item={item}
                                            type={item.checkInStatus}
                                            setLoading={setLoading}
                                            setIsTransaction={setIsTransaction}
                                            getBookings={getBookings}
                                        />
                                    );
                                })
                            ) : (
                                <div className="min-h-[340px] flex items-center justify-center font-bold text-2xl">
                                    No Bookings Found...
                                </div>
                            )}
                        </div>
                    )}
                    {currentTab === "upcoming" && (
                        <div className="" aria-labelledby="tabs-home-tab">
                        {loading ? (
                            <MyBooking isTransaction={isTransaction} />
                        ) : bookingsData && bookingsData.length > 0 ? (
                            bookingsData.map((item) => {
                                return item.checkInStatus ==
                                    "CHECKED_IN" ? (
                                    <BookingCard
                                        key={item._id}
                                        uniqueKey={item._id}
                                        item={item}
                                        type={item.checkInStatus}
                                        setLoading={setLoading}
                                        setIsTransaction={setIsTransaction}
                                        getBookings={getBookings}
                                    />
                                ) : item.checkInStatus == "CANCELLED" ? (
                                    <BookingCard
                                        key={item._id}
                                        uniqueKey={item._id}
                                        item={item}
                                        type={item.checkInStatus}
                                        setLoading={setLoading}
                                        setIsTransaction={setIsTransaction}
                                        getBookings={getBookings}
                                    />
                                ) : (
                                    <BookingCard
                                        key={item._id}
                                        uniqueKey={item._id}
                                        item={item}
                                        type={item.saleStatus}
                                        setLoading={setLoading}
                                        setIsTransaction={setIsTransaction}
                                        getBookings={getBookings}
                                    />
                                );
                            })
                        ) : (
                            <div className="min-h-[340px] flex items-center justify-center font-bold text-2xl">
                                No Bookings Found...
                            </div>
                        )}
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ManageBooking;
