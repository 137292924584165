import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useState } from "react";
import { useEffect } from "react";
import bookings from "../../images/bookings.svg";
import location from "../../images/location.svg";
import calender from "../../images/calender.svg";
import iimg from "../../images/iimg.svg";
import down from "../../images/down.svg";
import "./Sales.css";
import { getQuery } from "../../utils/queryString";
import RatingComponent from "../../components/RatingComponent/Rating";
import HotelAmenities from "../../components/Amenities/HotelAmenities";
import TransactionData from "../../components/TransactionData/TransactionData"
import {
    HotelUserServices,
    getUserDetails,
    setAuthToken,
    SaleServices,
} from "../../services/supplier";
import PageLoader from "../../components/PageLoader/PageLoader";
import { getDate, getYear } from "../../utils/date";
import { toast } from "react-toastify";
import { createSell, endSale, editSale,getTokenUrl,getContractUrl } from "../../services/web3Services";
import { SimpleDialog } from "../HotelPage/HotelPage";

const Sales = () => {
    const [margin, setMargin] = useState(0);
    const [allData, setAllData] = useState("");
    const [hotelData, setHotelData] = useState("");
    const [saleData, setSaleData] = useState("");
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const bookingId = getQuery("sale-room");
    const editListing = getQuery("edit-listing");
    const [open, setOpen] = useState(false);
    const [viewMore, setViewMore] = useState(false);
    const [transactionInfo, setTransactionInfo] = useState(false);
    const [minResale, setMinResale] = useState(0);
    const [isTransaction, setIsTransaction] = useState(false)
    const [transactionData, setTransactionData] = useState([
        {
            eventType: "Sale",
            previouseOwner: "0x420b595d8b648971b3bfcf46e66544c384860536",
            currentOwner: "0x420b595d8b648971b3bfcf46e66544c384860536",
            price: 1650,
            date: "4 Days ago",
        },
    ]);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };
    const getRoomInfo = async () => {
        try {
            setLoading(true);
            setAuthToken(getUserDetails()?.token);
            const getRoom = await HotelUserServices.getBookingDetails(
                bookingId
            );
            setHotelData(getRoom.data?.detail);
            setMinResale(
                getRoom.data?.detail.roomId.roomPricing["roomPricing"][0]
                    .minResale
            );
            // setTotalProfit(getRoom.data?.detail.buyPrice);
            setSaleData(getRoom.data?.saleData);
            if (getRoom.data?.hasOwnProperty("saleData")) {
                setMargin(((getRoom.data?.saleData.priceOnSale / 102) * 100).toFixed(2));
            }
            setTransactionData(getRoom.data?.detail.nftRef.ownerTrail);
            setAllData(getRoom.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const createSale = async () => {
        try {
            if (minResale > Number(margin) || Number(margin) < 2) {
                return toast.error(
                    `Resale price cannot be less than ${minResale}`
                );
            }
            setLoading(true);
            setTransactionInfo(true);
            setIsTransaction(true)
            setAuthToken(getUserDetails()?.token);
            await createSell(
                hotelData.web3BookingId,
                (1.02 * Number(margin)).toFixed(2).toString()
            ).then(async (res) => {
                let saleData = {
                    bookingId: bookingId,
                    salePrice: (1.02 * Number(margin)).toFixed(2),
                    saleId: res?.events.saleCreated.returnValues.itemId,
                    margin: margin,
                    fees: Number(margin) * 0.02,
                    transactionHash: res.transactionHash
                };
                if (!res.hasOwnProperty("events")) {
                    return;
                }
                await SaleServices.createRoomSale(saleData)
                    .then((res) => {
                        toast.success("Sale Created Successfully!");
                        navigate("/manageBooking");
                        setLoading(false);
                        setIsTransaction(false)
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error(
                            "Oops, while creating sale, error occurred!"
                        );
                        setLoading(false);
                        setIsTransaction(false)
                    });
            });
            setTransactionInfo(false);
        } catch (err) {
            toast.error(
                "Oops, there was an error and we could not list your booking for sale!"
            );
            setLoading(false);
            console.log(err);
            setIsTransaction(false)
            setTransactionInfo(false);
        }
    };

    const cancelSale = async (saleId) => {
        try {
            setLoading(true);
            setIsTransaction(true)
            setAuthToken(getUserDetails()?.token);
            const cancel = await endSale(saleId);
            const transactionHash = await cancel?.transactionHash
            await SaleServices.cancelSale(saleId, bookingId,transactionHash);

            setIsTransaction(false)
            setLoading(false);
            toast.success("Sale De-Listed successfully!");
            navigate("/manageBooking");
        } catch (err) {
            console.log(err);
            toast.error("Oops, while cancel sale, error occurred!");
            setLoading(false);
            setIsTransaction(false)
        }
    };

    const editListingSale = async (saleId) => {
        try {
            if (minResale > Number(margin) || Number(margin) < 2) {
                return toast.error(
                    `Resale price cannot be less than ${minResale}`
                )
            }
            setLoading(true)
            setIsTransaction(true)
            let saleData = {
                priceOnSale: (1.02 * Number(margin)).toFixed(2),
                totalPrice: (1.02 * Number(margin)).toFixed(2),
                fees: Number(0.02 * margin).toFixed(2),
                margin: Number(margin),
                saleId: saleId,
                bookingId:bookingId
            }
            const edit = await editSale(saleId, saleData.priceOnSale)
            saleData.transactionHash = await edit?.transactionHash
            await SaleServices.editListing(saleData)
            setLoading(false)
            setIsTransaction(false)
            toast.success("Sale updated successfully!")
            await getRoomInfo()
        } catch (err) {
            toast.error("Oops, while edit sale, error occurred!")
            console.log(err)
            setLoading(false)
            setIsTransaction(false)
        }
    };

    useEffect(() => {
        getRoomInfo();
    }, []);
    return (
        <>
            {loading ? (
                <PageLoader showTransactionMessage={transactionInfo} isTransaction={isTransaction} />
            ) : (
                <div className="app  min-h-[400px]">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Sales</title>
                        <link
                            rel="canonical"
                        />
                    </Helmet>
                    <div className="sale m-auto mt-3">
                        <SimpleDialog
                            open={open}
                            onClose={handleClose}
                            hotelImages={hotelData.hotelId?.images}
                        />
                        <div className="cursor-pointer" onClick={() => navigate("/manageBooking")}>
                            <img src={bookings} className="pl-2" alt="" />
                        </div>
                        <p className="text-center sell py-4 saleshead">
                            {editListing ? "Edit" : "Sell"} your booking on the
                            marketplace
                        </p>
                        <div className="flex mt-5 mb-20 saling">
                            <div className="part1 w-auto shadow-lg p-4 bg-white">
                                <p className="fontdesc text-2xl font-bold text-gray-900 hotelhead">
                                    {hotelData?.hotelId.name} -{" "}
                                    <span className="text-gray-500">
                                        {hotelData?.roomId.roomType}
                                    </span>
                                </p>
                                <div className="flex mt-6 mb-4">
                                    <img
                                        src={location}
                                        width={10}
                                        height={12}
                                        alt="location"
                                    />
                                    <label className="text-sm font-normal text-gray-600 pl-2 pr-4 textsm">
                                        {hotelData?.hotelId.city},{" "}
                                        {hotelData?.hotelId.country}
                                    </label>

                                    <img
                                        src={calender}
                                        width={10}
                                        height={12}
                                        alt="location1"
                                    />
                                    <label className="text-sm font-normal text-gray-600 pl-2 pr-4 textsm">
                                        {getDate(hotelData?.fromDate)} -{" "}
                                        {getDate(hotelData?.toDate)}
                                    </label>
                                    <label
                                        className="text-sm font-normal text-gray-600 pl-2 pr-4 textsm cursor-pointer"
                                        onClick={() =>
                                            getContractUrl(hotelData?.nftRef.contractAddress)
                                            
                                        }
                                    >
                                        Contract Address :{" "}
                                        <span className=" bg-[#2f69ff30] py-1 px-3 rounded-full textsm text-[#2F69FF]">
                                            {hotelData?.contractAddress
                                                ? (hotelData?.contractAddress).slice(
                                                      0,
                                                      6
                                                  ) +
                                                  "..." +
                                                  (hotelData?.contractAddress).slice(
                                                      -4
                                                  )
                                                : "null"}
                                        </span>
                                    </label>
                                    <label
                                        className="text-sm font-normal text-gray-600 pl-2 pr-4 textsm cursor-pointer"
                                        onClick={() =>
                                            getTokenUrl(hotelData?.nftRef.contractAddress,hotelData?.nftRef.tokenId)
                                            
                                        }
                                    >
                                        Token ID :{" "}
                                        <span className=" bg-[#2f69ff30] py-1 px-3 rounded-full textsm text-[#2F69FF]">
                                            {hotelData?.web3BookingId}
                                        </span>
                                    </label>
                                </div>
                                <div className="hotel-grid flex mobile:h-[480px]">
                                    {hotelData.hotelId?.images.length >=1 && <img
                                        onClick={handleClickOpen}
                                        className="div1 h-[240px] mobile:h-[480px] w-full object-cover"
                                        src={hotelData.hotelId?.images[0].url}
                                        alt="location2"
                                    />}
                                    {hotelData.hotelId?.images.length >=2 && <img
                                        onClick={handleClickOpen}
                                        src={hotelData.hotelId?.images[1].url}
                                        className="div2 w-full h-[90px] mobile:h-full object-cover"
                                        alt="location3"
                                    />}
                                    {hotelData.hotelId?.images.length >=3 && <img
                                        onClick={handleClickOpen}
                                        src={hotelData.hotelId?.images[2].url}
                                        className="div3 w-full h-[90px] mobile:h-full object-cover"
                                        alt="location4"
                                    />}

                                    <div
                                        className="div4  h-[90px] mobile:h-full object-cover w-full  relative"
                                        onClick={handleClickOpen}
                                    >
                                        {hotelData.hotelId?.images.length >=4 && <img
                                            onClick={handleClickOpen}
                                            src={
                                                hotelData.hotelId?.images[3].url
                                            }
                                            className=" w-full h-[90px] mobile:h-full object-cover"
                                            alt="location5"
                                        />}
                                        {hotelData.hotelId?.images.length >4 && <p className="absolute bg-stone-700/50 h-full w-full flex justify-center items-center text-white cursor-pointer top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                            +
                                            {hotelData.hotelId?.images &&
                                            hotelData.hotelId?.images.length >
                                                4
                                                ? hotelData.hotelId?.images
                                                      .length - 4
                                                : 0}{" "}
                                            more
                                        </p>}
                                    </div>
                                </div>

                                {hotelData?.hotelId.guestRatings ? (
                                    <div className="flex mt-4 content-center">
                                        <RatingComponent
                                            rating={
                                                hotelData?.hotelId.starRatings
                                            }
                                            readOnly
                                        />

                                        {hotelData?.hotelId.guestRatings && (
                                            <p className="fontdesc text-sm font-normal text-gray-800 ml-4 fontsmin">
                                                {
                                                    hotelData?.hotelId
                                                        .guestRatings
                                                }{" "}
                                                Users reviews
                                            </p>
                                        )}
                                    </div>
                                ) : (
                                    <div className="justify-center items-center mt-2 text-xs text-gray-500">
                                        No guest reviews available yet
                                    </div>
                                )}
                                <p className="fontdesc text-lg font-semibold text-gray-900 mt-2">
                                    Amenities
                                </p>
                                <div className="flex flex-wrap gap-x-8 gap-y-4 grid-cols-4 justify-items-start mt-3">
                                    <HotelAmenities
                                        attributes={
                                            hotelData.hotelId.attributes
                                        }
                                    />

                                    {transactionData &&
                                        transactionData.length > 0 && (
                                            <div
                                                onClick={() =>
                                                    setViewMore(!viewMore)
                                                }
                                                className="flex cursor-pointer gap-x-4 text-cyan-600 font-medium mb-4 dispnone justify-self-end"
                                            >
                                                <span className="text-sm">
                                                    {!viewMore && "View Details"}
                                                </span>
                                            </div>
                                        )}
                                </div>

                                {viewMore && (
                                    <div>
                                        <TransactionData transactionData={transactionData}/>
                                        <div
                                                onClick={() =>
                                                    setViewMore(!viewMore)
                                                }
                                                className="flex cursor-pointer gap-x-4 text-cyan-600 font-medium mb-4 dispnone justify-self-end"
                                            >
                                                <span className="text-sm">
                                                    View Less
                                                </span>
                                            </div>
                                    </div>
                                )}
                            </div>
                            <div className="w-auto shadow-lg pt-4 pb-4 ml-4 part1 bg-white gradientBorder mobile:!h-fit mobile:min-w-[360px]">
                                <p className="fontdesc text-2xl font-bold text-gray-800 ml-2 pl-4 pr-4 textsell">
                                    Listing details
                                </p>
                                <div className="border-b-2 border-gray-300 pb-6">
                                    <table className="table-auto">
                                        <tbody>
                                            {!allData.hasOwnProperty(
                                                "saleData"
                                            ) ? (
                                                <tr>
                                                    <td className="w-full">
                                                        <p className="fontdesc text-lg font-medium text-gray-800 ml-2 pl-4 pr-4 pt-8 textsell">
                                                        Purchase Price
                                                        </p>
                                                        <p className="fontdesc text-xs font-normal text-gray-800 ml-2 pl-4 pr-4">
                                                            As of{" "}
                                                            {getDate(
                                                                hotelData?.createdAt
                                                            )}{" "}
                                                            {getYear(
                                                                hotelData?.createdAt
                                                            )}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p className="fontdesc text-4xl font-medium text-gray-800 ml-2 pt-8 pr-4 textinsm">
                                                            $
                                                            {hotelData?.marketplace_buy_price ? hotelData.marketplace_buy_price.toFixed(
                                                                2
                                                            ) :
                                                            (hotelData?.buyPrice).toFixed(
                                                                2
                                                            )}
                                                        </p>
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td className="w-full">
                                                        <p className="fontdesc text-lg font-medium text-gray-800 ml-2 pl-4 pr-4 pt-8 textsell">
                                                            Purchase Price
                                                        </p>
                                                        <p className="fontdesc text-xs font-normal text-gray-800 ml-2 pl-4 pr-4">
                                                            As of{" "}
                                                            {getDate(
                                                                hotelData?.createdAt
                                                            )}{" "}
                                                            {getYear(
                                                                hotelData?.createdAt
                                                            )}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p className="fontdesc text-4xl font-medium text-gray-800 ml-2 pt-8 pr-4 textinsm">
                                                            $
                                                            {hotelData?.marketplace_buy_price ? hotelData.marketplace_buy_price.toFixed(
                                                                2
                                                            ) :
                                                            (hotelData?.buyPrice).toFixed(
                                                                2
                                                            )}
                                                        </p>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="flex">
                                    <p className="fontdesc text-lg font-semibold text-gray-900 ml-2 pt-4 pl-4 pr-2">
                                        Your Price
                                    </p>
                                    <img
                                        src={iimg}
                                        width={14}
                                        height={14}
                                        className="pt-4"
                                        alt="portrait"
                                    />
                                </div>
                                <div className="flex items-center justify-between pr-4">
                                    <p className="fontdesc text-sm font-normal text-[#646465] ml-2 pt-4 pl-4 pr-2">
                                        Selling price
                                    </p>
                                    <div className="flex gap-4 items-center">
                                        <p className="fontdesc text-sm font-medium text-[#646465] usd1">
                                            USD
                                        </p>
                                        <img
                                            src={down}
                                            width={8}
                                            height={4}
                                            className=""
                                            alt="down"
                                        />
                                        <input
                                            type={"text"}
                                            value={margin}
                                            onChange={(e) => {
                                                if (e.target.value < 0) {
                                                    return;
                                                }
                                                setMargin(e.target.value);
                                            }}
                                            min={0}
                                            className="w-[80px] border-2 border-[#ADADAD] rounded-lg p-1"
                                        />
                                    </div>
                                </div>
                                <div className=" border-b-2 border-gray-300 pb-6">
                                    <table className="table-auto">
                                        <tbody>
                                            <tr>
                                                <td className="w-full">
                                                    <p className=" place-self-start fontdesc text-sm font-normal text-[#646465] ml-2 pt-4 pl-4 pr-2">
                                                        Fees 2%
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fontdesc place-self-end text-sm font-normal text-[#646465] ml-2 pt-4 pr-4">
                                                        $
                                                        {Number(
                                                            0.02 * margin
                                                        ).toFixed(2)}
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <table className="table-auto">
                                    <tbody>
                                        <tr>
                                            <td className="w-full">
                                                <p className="fontdesc text-lg font-semibold text-gray-900 pt-4 pl-4 pr-2">
                                                    Total
                                                </p>
                                            </td>
                                            <td>
                                                <p className="fontdesc text-4xl font-medium text-gray-900 pt-4 pl-16 pr-2 textinsm">
                                                    $
                                                    {(
                                                        1.02 * Number(margin)
                                                    ).toFixed(2)}
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="p-4">
                                    {Boolean(editListing) ? (
                                        <div>
                                            <button
                                                className="mt-4 w-full overflow-hidden hover:bg-blue-700 hover:border-1 text-white font-bold py-2 px-4 rounded-full  buttonClr"
                                                height={60}
                                                onClick={() => {
                                                    editListingSale(
                                                        saleData?.saleId
                                                    );
                                                }} //edit listing
                                            >
                                                <p className="fontdesc text-lg font-medium p-2 textsell1">
                                                    Update Listing
                                                </p>
                                            </button>
                                            <button
                                                className="mt-4 w-full overflow-hidden bg-gray-500 hover:bg-gray-800 hover:border-1  text-white font-bold py-2 px-4 rounded-full "
                                                height={60}
                                                onClick={() =>
                                                    cancelSale(saleData?.saleId)
                                                } // cancel sale
                                            >
                                                <p className="fontdesc text-lg font-medium p-2 textsell1">
                                                    Delist from Sale
                                                </p>
                                            </button>
                                        </div>
                                    ) : (
                                        <button
                                            className="mt-4 w-full overflow-hidden hover:bg-blue-700 hover:border-1 text-white font-bold py-2 px-4 rounded-full  buttonClr"
                                            height={60}
                                            onClick={createSale}
                                        >
                                            <p className="fontdesc text-lg font-medium p-2 textsell1">
                                                List For Sale
                                            </p>
                                        </button>
                                    )}
                                    <p className="fontdesc text-sm font-medium text-[#7E7E7E] pt-4 ml-4 mr-4 text-center pr-2">
                                        You will be asked to confirm the
                                        transaction in your wallet and pay a
                                        small fee for the blockchain transaction
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Sales;
const ManageCard = ({ data }) => {
    return (
        <div className="p-6 flex flex-col gap-2 bg-white shadow-lg shadow-[#D8E3F1A6] w-[340px]">
            <div className="flex flex-col">
                <span className="font-medium text-sm text-[#7E7E7E]">
                    Event
                </span>
                <span className="font-bold">{data.eventType}</span>
            </div>
            <div className="flex gap-2 items-center">
                <div className="flex gap-2 flex-col">
                    <span className="font-medium text-sm text-[#7E7E7E]">
                        From
                    </span>
                    <span className="bg-[#2F69FF1A] text-[#2F69FF] px-4 py-1 rounded-full">
                        {data?.previousOwner
                            ? data.previousOwner.substring(0, 5) + "..."
                            : "Buk"}
                    </span>
                </div>
                <div className="flex gap-2 flex-col">
                    <span className="font-medium text-sm text-[#7E7E7E]">
                        To
                    </span>
                    <span className="bg-[#2F69FF1A] text-[#2F69FF] px-4 py-1 rounded-full">
                        {data.currentOwner.substring(0, 5)}...
                    </span>
                </div>
            </div>
            <div className="flex flex-col justify-between">
                <span className="font-medium text-sm text-[#7E7E7E]">Date</span>
                <span className="text-[#130A44]">{data.date}</span>
            </div>
        </div>
    );
};
