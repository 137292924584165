import axios from "axios";
import { toast } from "react-toastify";
import Web3 from "web3";
import { backendUrl, coingecko, contractAddress, marketAddress, network, polygonGasStation, polygonScanAddress, polygonScanToken, usdcAddress } from "../config/config";
import { BUK_ABI, MARKET_ABI, USDC_ABI } from "../config/contractABI";
import { getTimeStamp } from "../utils/date";
import { blobUrlToFile } from "../utils/imageConvert";
import {
    getUserDetails,
    HotelUserServices, setAuthToken, uploadPinata
} from "./supplier";
import { switchChain } from "./web3token";


const web3 = new Web3(Web3.givenProvider || process.env.REACT_APP_RPC_ENDPOINT);

const getContractInstance = () => { return new web3.eth.Contract(BUK_ABI, contractAddress); };

const getUSDC_ContractInstance = () => { return new web3.eth.Contract(USDC_ABI, usdcAddress); };

const getMarketInstance = () => { return new web3.eth.Contract(MARKET_ABI, marketAddress); };

const getUserAddress = async () => {
    const address = await web3.eth.getAccounts();

    if (
        JSON.parse(
            sessionStorage.getItem("userDetails")
        ).address.toLowerCase() !== address[0].toLowerCase()
    ) {
        throw new Error("Please switch to current login address in Metamask.");
    }
    return address[0];
};


const isApproved = async () => {
    const contract = getContractInstance();
    const userAddress = await getUserAddress();

    return contract.methods.isApprovedForAll(userAddress, marketAddress).call();
};

const price = async () => {
    const estimateGas = await fetch(polygonGasStation).then((response) => response.json());
    const maxPriorityFeePerGas = web3.utils.toWei(Math.ceil(estimateGas.fast.maxPriorityFee).toString(), 'gwei');

    return maxPriorityFeePerGas;
};

const approve_USDC = async (totalPrice) => {
    let approveContract = getUSDC_ContractInstance();
    const address = await getUserAddress();

    console.info("Approve USDC:", totalPrice);

    const maxPriorityFeePerGas = await price();
    const estimatedGas = await approveContract.methods.approve(contractAddress, totalPrice).estimateGas({ from: address });

    return approveContract.methods.approve(contractAddress, totalPrice).send({ from: address, gas: estimatedGas, maxPriorityFeePerGas });
};

const approve_marketplace = async (totalPrice) => {
    let approveContract = getUSDC_ContractInstance();
    const address = await getUserAddress();

    console.info("Marketplace Approval:", totalPrice);

    const maxPriorityFeePerGas = await price();
    const estimatedGas = await approveContract.methods.approve(marketAddress, totalPrice).estimateGas({ from: address });

    return approveContract.methods.approve(marketAddress, totalPrice).send({ from: address, gas: estimatedGas, maxPriorityFeePerGas });
};

const getApproveAll = async () => {
    const contract = getContractInstance();
    const userAddress = await getUserAddress();

    console.info("Get Approval:", userAddress);

    const maxPriorityFeePerGas = await price();
    const estimatedGas = await contract.methods.setApprovalForAll(marketAddress, true).estimateGas({ from: userAddress });

    await contract.methods.setApprovalForAll(marketAddress, true).send({ from: userAddress, gas: estimatedGas, maxPriorityFeePerGas });
};

export const registerHotel = async (hotelId, hotelData, index, attributes, treasuryAddress) => {
    await switchChain();

    const contract = getContractInstance();
    const userAddress = await getUserAddress();

    setAuthToken(getUserDetails()?.token);


    let fileUrl = await blobUrlToFile(hotelData.images[0], hotelData.name);
    let pinataFormData = new FormData();
    pinataFormData.append("name", hotelData.name);
    pinataFormData.append("description", hotelData.description.replace(/<\/?[^>]+(>|$)/g, ""));
    pinataFormData.append("collection", "Registrations-BUK");
    pinataFormData.append("nftType", "image");
    pinataFormData.append("image", fileUrl);
    pinataFormData.append("attributes", JSON.stringify(attributes));

    const uploadPinataRes = await uploadPinata(pinataFormData);
    const data = await HotelUserServices.registerHotels(hotelId);

    const hotelDetails = { hotelId, hotelUri: uploadPinataRes?.data, hotelManager: userAddress, hotelTreasury: treasuryAddress, index };

    console.info("Register Hotel:", hotelDetails);

    const maxPriorityFeePerGas = await price();
    const estimated = await contract.methods.registerHotel(hotelDetails, data.data.nonce, data.data.sign).estimateGas({ from: userAddress });
    return contract.methods.registerHotel(hotelDetails, data.data.nonce, data.data.sign).send({ from: userAddress, gas: estimated, maxPriorityFeePerGas });
};

export const bookRooms = async (nftData, room, attributes, forDays, booking_id_arr, totalPrice, hotelId) => {
    await switchChain();
    const contract = getContractInstance();
    const userAddress = await getUserAddress();

    if (!userAddress) return toast.error("Session Timed out!");
    setAuthToken(getUserDetails()?.token);

    const fileURL = await blobUrlToFile(attributes[0].nftImage, nftData.roomType);

    let pinArr = [];
    for (let pin = 0; pin < forDays; pin++) {
        let NFTFormData = new FormData();
        NFTFormData.append("name", nftData.roomType);
        NFTFormData.append("description", nftData.source);
        NFTFormData.append("collection", "BUK");
        NFTFormData.append("nftType", "image");
        NFTFormData.append("image", fileURL);
        NFTFormData.append("attributes", JSON.stringify(attributes[pin]));

        const uploadPinata = await axios.post(
            `${backendUrl}/nft-storage`,
            NFTFormData,
            {
                headers: {
                    "Content-Type":
                        "multipart/form-data;boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
                },
            }
        );

        pinArr.push(uploadPinata.data);
    }

    const data = await HotelUserServices.bookRoomSign(userAddress);
    let bookingStructureArr = [];

    for (let k = 0; k < room; k++) {
        for (let i = 0; i < forDays; i++) {
            const bookingStructure = {
                nftUri: pinArr[i], // nft Uri
                bookingId: booking_id_arr[i], // database booking id
                price: web3.utils.toWei(attributes[i].totalSalePriceWithTax.toString(), "Mwei"),// booking cost
                _baseprice: web3.utils.toWei(attributes[i].salePriceWithoutTax.toString(), "Mwei"),
                discount: web3.utils.toWei(attributes[i].discount_amount.toString(), "Mwei"),
                hotelId: Number(hotelId),
                buk_cancellationFee: web3.utils.toWei((attributes[i].buk_cancellationFee).toString(), "Mwei"),
                Hotel_cancellationfee: web3.utils.toWei((attributes[i].hotel_cancellation).toString(), "Mwei"),
                time: (getTimeStamp(attributes[i].fromDate, attributes[i].timezone) / 1000), //Booking Time(start time of the stay)
            };
            bookingStructureArr.push(bookingStructure);
        }
    }

    await approve_USDC(web3.utils.toWei(totalPrice.toString(), "Mwei"));

    console.info("Buy Room:", bookingStructureArr, "Total Price", totalPrice);

    const maxPriorityFeePerGas = await price();
    const estimated = await contract.methods.bookRoom(bookingStructureArr, userAddress, data.data.sign, data.data.nonce).estimateGas({ from: userAddress });

    return contract.methods.bookRoom(bookingStructureArr, userAddress, data.data.sign, data.data.nonce).send({ from: userAddress, gas: estimated, maxPriorityFeePerGas });
};

export const cancelBooking = async (nftId, userAddress) => {
    if (!userAddress) return toast.error("Session timed out!");
    if (userAddress === "userCancel") userAddress = await getUserAddress();

    const contract = getContractInstance();
    const managerAddress = await getUserAddress();

    setAuthToken(getUserDetails()?.token);

    const booking_struct = await contract.methods.bookingDetails(nftId).call();
    const amountInWei = booking_struct.price - (((booking_struct._baseprice * 5) / 100) - (booking_struct.discount));

    console.info("Cancel Booking:", userAddress, nftId, amountInWei);

    const data = await HotelUserServices.cancelBookingSign(nftId, amountInWei, managerAddress);
    const maxPriorityFeePerGas = await price();
    const estimated = await contract.methods.cancelBookingUser(nftId, userAddress, data.data.nonce, data.data.sign).estimateGas({ from: managerAddress });

    return contract.methods.cancelBookingUser(nftId, userAddress, data.data.nonce, data.data.sign).send({ from: managerAddress, gas: estimated, maxPriorityFeePerGas });

};

export const createSell = async (nftId, amount) => {
    await switchChain();
    const contract = getMarketInstance();
    const userAddress = await getUserAddress();

    setAuthToken(getUserDetails()?.token);

    const isApprovedAlready = await isApproved();
    if (isApprovedAlready === false) { await getApproveAll() }
    const amountInWei = web3.utils.toWei(amount.toString(), "Mwei");

    console.info("Create Sell:", nftId, amountInWei)

    const data = await HotelUserServices.creatSellSign(nftId, amountInWei);
    const maxPriorityFeePerGas = await price();
    const estimated = await contract.methods.createSale(nftId, amountInWei, data.data.sign, data.data.nonce).estimateGas({ from: userAddress });

    return contract.methods.createSale(nftId, amountInWei, data.data.sign, data.data.nonce).send({ from: userAddress, gas: estimated, maxPriorityFeePerGas });
};

export const endSale = async (itemId) => {
    await switchChain();
    const contract = getMarketInstance();
    const userAddress = await getUserAddress();

    console.info("End Sale:", userAddress, itemId);

    const maxPriorityFeePerGas = await price();
    const estimated = await contract.methods.endSale(itemId).estimateGas({ from: userAddress });
    return contract.methods.endSale(itemId).send({ from: userAddress, gas: estimated, maxPriorityFeePerGas });
};

export const editSale = async (saleId, amount) => {
    await switchChain();
    const contract = getMarketInstance();
    const userAddress = await getUserAddress();

    setAuthToken(getUserDetails()?.token);
    let amountInWei = web3.utils.toWei(amount.toString(), "Mwei");

    console.info("Edit Sale:", saleId, amountInWei)

    const data = await HotelUserServices.createEditSign(saleId, amountInWei);
    const maxPriorityFeePerGas = await price();
    const estimated = await contract.methods.editListing(saleId, data.data.nonce, data.data.sign, amountInWei).estimateGas({ from: userAddress });

    return contract.methods.editListing(saleId, data.data.nonce, data.data.sign, amountInWei).send({ from: userAddress, gas: estimated, maxPriorityFeePerGas });
};

export const buyItem = async (itemId) => {
    await switchChain();
    const contract = getMarketInstance();
    const userAddress = await getUserAddress();

    setAuthToken(getUserDetails()?.token);

    const idToMarketItem = await contract.methods.idToMarketItem(itemId).call();
    await approve_marketplace(idToMarketItem.price);

    console.info("Buy Item:", itemId, idToMarketItem);

    const data = await HotelUserServices.buyItem(itemId, idToMarketItem.price);
    const maxPriorityFeePerGas = await price();
    const estimated = await contract.methods.buyItem(itemId, data.data.nonce, data.data.sign).estimateGas({ from: userAddress });

    return contract.methods.buyItem(itemId, data.data.nonce, data.data.sign).send({ from: userAddress, gas: estimated, maxPriorityFeePerGas });
};

export const validate_address = (address) => { return Web3.utils.isAddress(address) }

export const checkIn = async (nftId) => {
    await switchChain();
    const contract = getContractInstance();
    const userAddress = await getUserAddress();

    setAuthToken(getUserDetails()?.token);

    console.info("Check In:", userAddress, nftId);

    const data = await HotelUserServices.preCheckInSign(userAddress, nftId);
    const maxPriorityFeePerGas = await price();
    const estimated = await contract.methods.preCheckIn(nftId, data.data.nonce, data.data.sign).estimateGas({ from: userAddress });

    return contract.methods.preCheckIn(nftId, data.data.nonce, data.data.sign).send({ from: userAddress, gas: estimated, maxPriorityFeePerGas });
};
// Change the URL for Mainnet.
export const convertPriceToMatic = async (dollarPrice) => {
    let price = await axios.get(coingecko);
    price = dollarPrice / price.data[network].usd;

    return web3.utils.toWei(price.toString());
};

export const convert_usdc = async (amount) => {
    const usdc_contract = getUSDC_ContractInstance();
    const decimal = await usdc_contract.methods.decimals().call();

    return (Number(amount.toFixed(decimal)) * 10 ** decimal).toString();
};
// Change the URL for Mainnet.
export const getTokenUrl = (contractAddress, tokenId) => {
    return window.open(`${polygonScanToken}${contractAddress}?a=${tokenId}`, "_blank");
};
// Change the URL for Mainnet.
export const getContractUrl = (contractAddress) => {
    return window.open(`${polygonScanAddress}${contractAddress}`, "_blank");
};
