import HotelIcon from "./HotelIcon"
import RoomIcon from "./RoomIcon"
import VerifyIcon from "./VerifyIcon"
import stepCompleteImg from "../../images/stepComplete.svg"

const CustomizedSteppers = ({ state }) => {
    return (
        <div className="flex justify-center items-center mt-8">
            <div className="flex flex-col gap-4 relative">
                <div
                    className={`${
                        state >= 0
                            ? "bg-[#FFFFFF] shadow shadow-[#D8E3F1A6]"
                            : "bg-[#EAEEF4] shadow-inner shadow-[#130A4426]"
                    } w-16 h-16 flex justify-center items-center rounded-full`}
                >
                    <HotelIcon active={state >= 0 ? true : false} />
                </div>
                {state > 0 && (
                    <div className="absolute z-100 top-[-8px] left-1">
                        <img src={stepCompleteImg} alt="" className="w-6 h-6"/>
                    </div>
                )}
                <div
                    className={`font-extrabold text-sm text-center ${
                        state >= 0
                            ? "text-transparent bg-clip-text bg-gradient-to-r from-[#2F69FF] to-[#C856FF]"
                            : ""
                    }`}
                >
                    Hotel
                </div>
            </div>
            <div
                className={`h-[3px] w-[60px] md:w-[100px] mobile:w-[250px] top-[-1rem] relative ${
                    state >= 1
                        ? "bg-gradient-to-r from-[#2F69FF] to-[#C856FF]"
                        : "bg-[#E7ECF2]"
                }`}
            ></div>
            <div className="flex flex-col gap-4 relative">
                <div
                    className={`${
                        state >= 1
                            ? "bg-[#FFFFFF] shadow shadow-[#D8E3F1A6]"
                            : "bg-[#EAEEF4] shadow-inner shadow-[#130A4426]"
                    } w-16 h-16 flex justify-center items-center rounded-full`}
                >
                    <RoomIcon active={state >= 1 ? true : false} />
                </div>
                {state > 1 && (
                    <div className="absolute z-100 top-[-8px] left-1">
                        <img src={stepCompleteImg} alt="" className="w-6 h-6"/>
                    </div>
                )}
                <div
                    className={`font-extrabold text-sm text-center ${
                        state >= 2
                            ? "text-transparent bg-clip-text bg-gradient-to-r from-[#2F69FF] to-[#C856FF]"
                            : ""
                    }`}
                >
                    Rooms
                </div>
            </div>
            <div
                className={`h-[3px] w-[60px] md:w-[100px] mobile:w-[250px] top-[-1rem] relative ${
                    state >= 2
                        ? "bg-gradient-to-r from-[#2F69FF] to-[#C856FF]"
                        : "bg-[#E7ECF2]"
                }`}
            ></div>
            <div className="flex flex-col gap-4">
                <div
                    className={`${
                        state >= 2
                            ? "bg-[#FFFFFF] shadow shadow-[#D8E3F1A6]"
                            : "bg-[#EAEEF4] shadow-inner shadow-[#130A4426]"
                    } w-16 h-16 flex justify-center items-center rounded-full`}
                >
                    <VerifyIcon active={state >= 2 ? true : false} />
                </div>
                <div
                    className={`font-extrabold text-sm text-center relative left-[-8px] ${
                        state >= 2
                            ? "text-transparent bg-clip-text bg-gradient-to-r from-[#2F69FF] to-[#C856FF]"
                            : ""
                    }`}
                >
                    Verification
                </div>
            </div>
        </div>
    )
}

export default CustomizedSteppers
