import React from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import bookings from "../../images/bookings.svg";

function AboutUs() {
  const navigate = useNavigate();
  React.useEffect(() => {
    window.scrollTo(0, 0)
  },[])
  return (
    <div className="app bg-[#F7F9FB] !pb-28">
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us</title>
      </Helmet>

      {/* <div onClick={() => navigate("/manageBooking")}>
        <img src={bookings} className="p-4" alt='' />
      </div> */}
      <p className="text-center sell py-4 ">About Us</p>
      <p className="p-4 mobile:mt-4 text-sm text-gray-500">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p>
      <p className="p-4 text-sm text-gray-500">
        It is a long established fact that a reader will be distracted by the
        readable content of a page when looking at its layout. The point of
        using Lorem Ipsum is that it has a more-or-less normal distribution of
        letters, as opposed to using 'Content here, content here', making it
        look like readable English. Many desktop publishing packages and web
        page editors now use Lorem Ipsum as their default model text, and a
        search for 'lorem ipsum' will uncover many web sites still in their
        infancy.
      </p>
      <p className="p-4 text-sm text-gray-500">
        Contrary to popular belief, Lorem Ipsum is not simply random text. It
        has roots in a piece of classical Latin literature from 45 BC, making it
        over 2000 years old. Richard McClintock, a Latin professor at
        Hampden-Sydney College in Virginia, looked up one of the more obscure
        Latin words, consectetur,
      </p>
      <div className="px-4 my-11 w-full">
        <div className="flex justify-between mobile:flex-row flex-col gap-4">
          <div className="p-4 shadow-xl">
            <div className="flex flex-row place-items-center">
              <svg
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="27"
                  cy="27"
                  r="27"
                  fill="#2F69FF"
                  fill-opacity="0.2"
                />
                <path
                  d="M40.8535 16.3556L22.2254 34.9664L13.1492 25.8965C12.6684 25.4209 11.8418 25.4209 11.3658 25.8916C11.1254 26.1318 11 26.4422 11 26.7827C11 27.1231 11.1251 27.4384 11.3658 27.6737L21.3338 37.6401C21.5693 37.8755 21.8848 38.0005 22.2254 38.0005C22.556 38.0005 22.8767 37.8755 23.1119 37.6401L42.6318 18.1337C43.1227 17.6431 43.1227 16.8473 42.6318 16.3617C42.151 15.8809 41.3196 15.88 40.8535 16.3556Z"
                  fill="#2F69FF"
                />
              </svg>

              <h1 className="title-font text-lg ml-3 font-medium text-gray-900">
                Incredible Choices
              </h1>
            </div>
            <p className="text-sm text-gray-500 ml-16">
              Whether you want to stay in a chic city apartment, a luxury beach
              resort or a cosy B&B in the countryside, Buk provides you with
              amazing diversity and breadth of choice - all in one place.
            </p>
          </div>
          <div className="p-4 shadow-xl">
            <div className="flex flex-row place-items-center">
              <svg
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M42.2862 19.7657C42.2596 19.3176 41.9353 18.9438 41.4955 18.8529C36.2319 17.7669 36.6977 12.8937 36.7201 12.6906C36.7653 12.3001 36.5766 11.92 36.2382 11.7201C33.1798 9.91612 30.9103 9 26.9934 9C23.0767 8.99972 20.8075 9.91583 17.7492 11.7204C17.4108 11.9202 17.2221 12.3004 17.2673 12.6909C17.2914 12.8973 17.796 17.7584 12.4919 18.8532C12.0194 18.9505 11.6855 19.3736 11.6996 19.8555C11.7224 20.5973 12.4077 38.1211 26.4771 46.6949C26.4874 46.7013 26.4999 46.7032 26.5101 46.7093C26.568 46.7414 26.6284 46.7678 26.6924 46.7883C26.7127 46.7949 26.7321 46.8019 26.7523 46.8068C26.8299 46.8262 26.9097 46.8398 26.9934 46.8398C27.0713 46.8398 27.1463 46.8287 27.219 46.8121C27.2392 46.8074 27.2583 46.7999 27.2777 46.7935C27.3326 46.7772 27.3849 46.757 27.4351 46.7323C27.4462 46.7262 27.4587 46.7243 27.4692 46.7187C43.2015 38.1236 42.2977 19.9489 42.2864 19.7658L42.2862 19.7657ZM13.7407 20.5867C18.1977 19.27 19.3122 15.4935 19.2817 13.1267C21.5493 11.8321 23.3234 11.144 26.0002 11.0119L25.9999 44.0003C15.83 36.7403 14.0366 23.891 13.7408 20.5866L13.7407 20.5867ZM27.9861 44.1054V11.0113C30.6629 11.1435 32.437 11.8315 34.7046 13.1261C34.6741 15.5045 35.8006 19.3068 40.3132 20.6053C40.2902 23.8059 39.4603 36.8449 27.986 44.1056L27.9861 44.1054Z"
                  fill="#2F69FF"
                />
                <circle
                  cx="27"
                  cy="27"
                  r="27"
                  fill="#2F69FF"
                  fill-opacity="0.2"
                />
              </svg>

              <h1 className="title-font text-lg ml-3 font-medium text-gray-900">
                Secure Booking
              </h1>
            </div>
            <p className="text-sm text-gray-500 ml-16">
              Whether you want to stay in a chic city apartment, a luxury beach
              resort or a cosy B&B in the countryside, Buk provides you with
              amazing diversity and breadth of choice - all in one place.
            </p>
          </div>
          <div className="p-4 shadow-xl">
            <div className="flex flex-row place-items-center">
              <svg
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="27"
                  cy="27"
                  r="27"
                  fill="#2F69FF"
                  fill-opacity="0.2"
                />
                <path
                  d="M32.9621 14.0001C30.5083 13.9896 28.1791 15.0791 26.6141 16.969C25.1534 15.2216 23.0365 14.154 20.7628 14.018C18.4893 13.8823 16.2603 14.6901 14.6017 16.2512C12.9433 17.8121 12.002 19.9882 12 22.2659C12 30.6173 21.9198 37.5462 26.1824 40.1342C26.4474 40.2953 26.7801 40.2953 27.0451 40.1342C31.3082 37.5464 41.2275 30.6173 41.2275 22.2659C41.2245 20.0744 40.3529 17.9737 38.8034 16.424C37.2539 14.8746 35.1529 14.0029 32.9616 14.0001L32.9621 14.0001ZM35.525 31.1479C33.3151 33.6822 30.2391 36.202 26.6141 38.451C22.989 36.2019 19.913 33.682 17.7031 31.1479C15.0227 28.079 13.6646 25.0885 13.6646 22.2656C13.6669 20.3342 14.5142 18.5006 15.9839 17.2472C17.4536 15.9941 19.3981 15.4471 21.3053 15.75C23.2128 16.0528 24.8921 17.1756 25.9012 18.8224C26.0512 19.0732 26.3219 19.2269 26.6141 19.2269C26.9062 19.2269 27.177 19.0732 27.3269 18.8224C28.3328 17.1689 30.0136 16.0402 31.9245 15.7339C33.8355 15.4278 35.7845 15.9751 37.2566 17.2314C38.7288 18.4877 39.5759 20.3266 39.5741 22.2619C39.5669 25.0886 38.2089 28.0791 35.5249 31.148L35.525 31.1479Z"
                  fill="#2F69FF"
                />
              </svg>

              <h1 className="title-font text-lg font-medium ml-3 text-gray-900">
                Trust Booking
              </h1>
            </div>
            <p className="text-sm text-gray-500 ml-16">
              Whether you want to stay in a chic city apartment, a luxury beach
              resort or a cosy B&B in the countryside, Buk provides you with
              amazing diversity and breadth of choice - all in one place.
            </p>
          </div>
        </div>
      </div>

      <p className="p-4 mt-7 mb-10  text-sm text-gray-500">
        It is a long established fact that a reader will be distracted by the
        readable content of a page when looking at its layout. The point of
        using Lorem Ipsum is that it has a more-or-less normal distribution of
        letters, as opposed to using 'Content here, content here', making it
        look like readable English. Many desktop publishing packages and web
        page editors now use Lorem Ipsum as their default model text, and a
        search for 'lorem ipsum' will uncover many web sites still in their
        infancy.
      </p>
    </div>
  );
}

export default AboutUs;
