/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState, useCallback } from "react";
import logo from "../../images/images/Group-3210.svg";
import menu from "../../images/menu.svg";
import user from "../../images/user.svg";
import "./Navbar.css";
import styles from "./TradingPlatformHome.module.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { WalletContext } from "../../context/wallet/WalletContext";
import Drawer from "@mui/material/Drawer";
import NavMenu from "../NavMenu/NavMenu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { LanguageContext } from "../../context/language/LanguageContext";
import { ManagerContext } from "../../context/managerContext/ManagerContext";
import {
  getUserDetails,
  storeInformation,
  uploadUserDetail,
} from "../../services/supplier";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Box, FormControlLabel, Switch, Modal } from "@mui/material";
import FrameComponent1 from "../Search/FrameComponent1";
import PortalPopup from "../Search/PortalPopup";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import "../../css/normalize.css";
import "../../css/webflow.css";
import "../../css/dashboard-b98d0b.webflow.css";
import ReactPlayer from "react-player";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const Navbar = ({ ishomepage }) => {
  const navigate = useNavigate();
  const { connectWallet, walletLogin, setWalletLogin } =
    useContext(WalletContext);
  const [connecting, setConnecting] = useState(false);
  const { hotels, setHotels, hotelId, setHotelId } = useContext(ManagerContext);
  const [drawer, setDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open2 = Boolean(anchorEl2);
  const location = useLocation();
  const { language, changeLanguage } = useContext(LanguageContext);
  const [isFramePopupOpen, setFramePopupOpen] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isFormOpen, setFormOpen] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [terms, setTerms] = useState(false);

  const handleLanguage = (e) => {
    const { value } = e.target.dataset;
    changeLanguage(value);
    handleClose();
  };

  const handleHotelClick = (hotel) => {
    setHotelId(hotel);
    handleClose();
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl1(null);
    setAnchorEl2(null);
  };
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(open);
  };

  const userLogin = async () => {
    setConnecting(true);
    const userLogin = await connectWallet();
    setWalletLogin(userLogin?.address);
    setConnecting(false);
  };

  const logOut = async () => {
    sessionStorage.clear();
    navigate("/");
    window.location.reload();
  };

  const openFramePopup = useCallback(() => {
    setFramePopupOpen(true);
  }, []);

  const closeFramePopup = useCallback(() => {
    setFramePopupOpen(false);
  }, []);

  useEffect(() => {
    const userDetail = JSON.parse(sessionStorage.getItem("userDetails"));
    setWalletLogin(userDetail?.address);
    if (userDetail?.address && !userDetail?.email) {
      setFormOpen(true);
    } else {
      setFormOpen(false);
    }
  }, [sessionStorage.getItem("userDetails")]);

  const submitUserProfile = () => {
    const userData = JSON.parse(sessionStorage.getItem("userDetails"));
    const userProfile = uploadUserDetail({
      email: email,
      fullname: fullName,
      address: walletLogin,
    });

    userProfile.then((response) => {
      let data = response.data.userUpdatedData;
      const { walletAddress, fullName, email, isKYCVerified, userType, _id } =
        data;
      const aa = {
        address: walletAddress,
        fullName: fullName,
        email: email,
        kycVerified: isKYCVerified,
        user: userType,
        userid: _id,
        token: userData.token,
      };
      storeInformation(aa);
      window.location.reload();
    });
  };

  return (
    <div>
      <div>
        <Modal
          open={isOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100vh",
              minWidth: "100%",
            }}
          >
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <HighlightOffIcon
                fontSize="large"
                style={{
                  marginBottom: -19,
                  marginRight: -17,
                  zIndex: 99,
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={() => setOpen(false)}
              />
            </div>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=WLYxeZ10ExY"
              controls={true}
              width="90%"
              height={"calc(100vh - 50px)"}
            />
          </Box>
        </Modal>
      </div>

      <div className="navbar-logo-left wf-section">
        <div
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="navbar-logo-left-container shadow-three w-nav"
        >
          <div>
            <div className="navbar-wrapper">
              <a href="/" className="navbar-brand w-nav-brand">
                <img src={logo} loading="lazy" alt="" />
              </a>
              <nav
                role="navigation"
                className="nav-menu-wrapper w-nav-menu w--open"
              >
                <ul role="list" className="nav-menu-two w-list-unstyled">
                  <li>
                    <a
                      href="#"
                      onClick={() => setOpen(true)}
                      className="nav-link-text"
                    >
                      How it works
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://t.me/buktrips"
                      target="_blank"
                      className="nav-link-text"
                    >
                      Support
                    </a>
                  </li>
                  {location.pathname !== "/" && (
                    <div className="flex gap-x-4 respnav">
                      <div className="flex justify-center">
                        <div className=" flex items-center">
                          <div className="dropdown relative">
                            <Menu
                              anchorEl={anchorEl1}
                              open={open1}
                              onClose={handleClose}
                              id="language-menu"
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                            >
                              <MenuItem
                                onClick={handleLanguage}
                                data-value="english"
                                className="cursor-pointer"
                              >
                                English
                              </MenuItem>
                              <MenuItem
                                onClick={handleLanguage}
                                data-value="french"
                                className="cursor-pointer"
                              >
                                French
                              </MenuItem>
                              <MenuItem
                                onClick={handleLanguage}
                                data-value="spanish"
                                className="cursor-pointer"
                              >
                                Spanish
                              </MenuItem>
                              <MenuItem
                                onClick={handleLanguage}
                                data-value="suomalian"
                                className="cursor-pointer"
                              >
                                Suomalian
                              </MenuItem>
                              <MenuItem
                                onClick={handleLanguage}
                                data-value="filipino"
                                className="cursor-pointer"
                              >
                                Filipino
                              </MenuItem>
                              <MenuItem
                                onClick={handleLanguage}
                                data-value="francais"
                                className="cursor-pointer"
                              >
                                Francais
                              </MenuItem>
                            </Menu>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <li className="mobile-margin-top-10">
                    {walletLogin ? (
                      <>
                        <div
                          className="flex gap-x-2 cursor-pointer "
                          onClick={handleClick}
                        >
                          <p className="fontdesc text-base font-medium text-gray-900 token m-0">{`${walletLogin.substr(
                            0,
                            7
                          )}...${walletLogin.substr(-4, 100)}`}</p>
                          <div className="flex justify-center">
                            <div>
                              <div className="dropdown relative">
                                <div
                                  className="dropdown-toggle leading-tight transition duration-150 ease-in-out flex items-center whitespace-nowrap"
                                  type="button"
                                  id="dropdownMenuButton2"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <img
                                    src={user}
                                    width={20}
                                    height={20}
                                    className="cursor-pointer"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              navigate("/manageBooking");
                              handleClose();
                            }}
                            className="cursor-pointer"
                          >
                            My Bookings
                          </MenuItem>
                          {(getUserDetails()?.user == 1 ||
                            getUserDetails()?.user == 2) && (
                            <MenuItem
                              onClick={() => {
                                navigate("/registration");
                                handleClose();
                              }}
                              className="cursor-pointer"
                            >
                              Hotel Registration{" "}
                            </MenuItem>
                          )}
                          {(getUserDetails()?.user == 1 ||
                            getUserDetails()?.user == 2) && (
                            <MenuItem
                              onClick={() => {
                                navigate("/dashboard");
                                handleClose();
                              }}
                              className="cursor-pointer"
                            >
                              Hotel Dashboard
                            </MenuItem>
                          )}
                          {/* <MenuItem>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <p style={{ fontSize: "12px" }}>Standard</p>
                              <FormControlLabel
                                className="ml-2"
                                label=""
                                control={
                                  <Switch color="primary" size="medium" />
                                }
                                onClick={openFramePopup}
                              />
                              <p style={{ fontSize: "12px" }}>Professional</p>
                            </div>
                          </MenuItem> */}
                          <MenuItem onClick={logOut}>Logout</MenuItem>
                        </Menu>
                      </>
                    ) : connecting ? (
                      <button className={styles.frameButton} disabled={true}>
                        <div className={styles.connectWalletDiv}>
                          Connect Wallet
                        </div>
                      </button>
                    ) : (
                      <button
                        className={styles.frameButton}
                        onClick={userLogin}
                      >
                        <div className={styles.connectWalletDiv}>
                          Connect Wallet
                        </div>
                      </button>
                    )}
                  </li>
                  {walletLogin && (
                    <button
                      style={{ marginLeft: "15px" }}
                      onClick={handleClick}
                    >
                      <img
                        src={menu}
                        width={20}
                        height={20}
                        className="cursor-pointer"
                      />
                    </button>
                  )}
                </ul>
              </nav>
              <div className="menu-button w-nav-button">
                <div className="w-icon-nav-menu"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={isFormOpen} className="flex justify-center items-center">
        <div className="userDetail-form xs:p-0 sm:p-0 p-5 flex flex-col " style={{borderRadius:"15px"}}>
          <p className="pers flex justify-center mt-2">Personal Data</p>
          <p className="specify flex justify-center mt-2">
            Specify exactly as in your passport
          </p>
          <div className="lg:mt-10 flex flex-col items-center gap-y-5">
            <input
              className="inp pl-5 sm:w-[300px] placeholder-gray-500::placeholder"
              placeholder="Full Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />

            <input
              className="inp sm:w-[300px] pl-5 placeholder-gray-500::placeholder"
              type="email"
              required
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          {/* <div className="flex items-start mx-auto topspace mt-8 gap-x-5">
            <input
              type="checkbox"
              value={terms}
              onChange={() => setTerms(!terms)}
              className="check"
            />
            <p className="text-xs font-medium fontWidth w-[314px] text-[#646465] fontdesc">
              I understand that check-in information cannot be modified once
              sent to the hotel. Booking cancellations or any modifications will
              have to be carried out directly with the hotel and might incur
              additional fees
            </p>
          </div>
          <p className="ht fontWidth w-[348px] mx-auto topspace mt-4">
            Completing the online check-in will make your booking
            non-transferrable (and it will remove it from the marketplace in
            case it was currently for sale)
          </p> */}
          <div style={{display:"flex", justifyContent:"center"}}>
          <button
            loading={true}
            disabled={fullName === "" || email === ""}
            onClick={submitUserProfile}
            className="btn  box-border p-4 mb-4 mt-4"
          >
            Submit
          </button>

          </div>
          
        </div>
      </Modal>
    </div>
  );
};

export default Navbar;
