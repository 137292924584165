/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./Footer.css";
import linkedin from "../../images/images/linkedin-3-1.svg";
import github from "../../images/images/github-logo.svg";
import twitter from "../../images/images/twitter-3-1.svg";
import logo from "../../images/images/Full_logo_white-text-1.svg";

const Footer = () => {
  return (
    <section className="footer-dark-2 wf-section">
      <div className="container-6">
        <div className="footer-wrapper">
          <div className="div-block-5">
            <a href="#" className="footer-brand w-inline-block">
              <img src={logo} loading="lazy" alt="" />
            </a>
            <p className="body-text footer small-text">
              Buk is building a blockchain solution to bring transparency and
              flexibility into the ecosystem. We&#x27;re helping create a
              win-win solution for travelers, hotels, and everyone in between.
            </p>
          </div>
          <div className="footer-content-2">
            <div
              id="w-node-_86ede1d1-d4eb-2d7e-d8c1-f9815b7a6f59-3fc478c7"
              className="footer-block"
            >
              <div className="title-small-2">ABOUT US</div>
              <a
                href="https://buk.technology/#what-is-buk"
                target="_blank"
                className="footer-link-2"
              >
                What is Buk
              </a>
              <a
                href="https://buk.technology/#team"
                target="_blank"
                className="footer-link-2"
              >
                Team
              </a>
              <a
                href="https://github.com/BUK-protocol/assets"
                target="_blank"
                className="footer-link-2"
              >
                Media-kit
              </a>
              <a
                href="https://buk.technology/blog"
                target="_blank"
                className="footer-link-2"
              >
                Blog
              </a>
              <a
                href="https://t.me/buk_tech"
                target="_blank"
                className="footer-link-2"
              >
                Contact us
              </a>
            </div>
            <div
              id="w-node-_86ede1d1-d4eb-2d7e-d8c1-f9815b7a6f66-3fc478c7"
              className="footer-block"
            >
              <div className="title-small-2">Buk products</div>
              <a
                href="https://buk.technology/for-hotels"
                target="_blank"
                className="footer-link-2"
              >
                For Hotels
              </a>
              <a
                href="https://buk.technology/traveller"
                target="_blank"
                className="footer-link-2"
              >
                For Travelers
              </a>
              <a
                href="https://buk.technology/early-access"
                target="_blank"
                className="footer-link-2"
              >
                For Trader &amp; investor
              </a>
            </div>
            <div
              id="w-node-_86ede1d1-d4eb-2d7e-d8c1-f9815b7a6f6f-3fc478c7"
              className="footer-block"
            >
              <div className="title-small-2">Stay in touch</div>
              <div className="footer-social-block-2">
                <a
                  href="https://twitter.com/buk_tech"
                  target="_blank"
                  className="footer-social-link w-inline-block"
                >
                  <img
                    src={twitter}
                    loading="lazy"
                    alt=""
                    className="social-image"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/buktech/"
                  target="_blank"
                  className="footer-social-link w-inline-block"
                >
                  <img
                    src={linkedin}
                    loading="lazy"
                    alt=""
                    className="social-image"
                  />
                </a>
                <a
                  href="https://github.com/BUK-protocol/assets"
                  target="_blank"
                  className="footer-social-link w-inline-block"
                >
                  <img
                    src={github}
                    loading="lazy"
                    alt=""
                    className="social-image"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-divider-2"></div>
      <div className="footer-copyright-center">
        Copyright © BUK Technology Inc 2022
      </div>
    </section>
  );
};

export default Footer;
