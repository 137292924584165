import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function BasicPagination({page,setPage,metaData}) {
  const handleChange = (e,v) => {
    console.log(e,v)
    setPage(v)
  }
  return (
    <Stack mt={2} spacing={2}  justifyContent="center" alignItems="center">
      <Pagination defaultValue={page} value={page} onChange={handleChange} count={Math.ceil(parseInt(metaData?.total)/parseInt(metaData?.limit))} />
    </Stack>
  );
}