/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState, useEffect } from "react";
import back from "../../images/back.svg";
import view from "../../images/view.svg";
import loc from "../../images/loc.svg";
import pool from "../../images/am-pool.svg";
import airConditioning from "../../images/am-air.svg";
import wifi from "../../images/am-wifi.svg";
import spa from "../../images/am-spa.svg";
import parking from "../../images/am-parking.svg";
import restaurant from "../../images/am-restaurant.svg";
import bed from "../../images/am-bed.svg";
import { Helmet } from "react-helmet";
import ChooseRoom from "../../components/ChooseRoom/ChooseRoom";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import "./HotelPage.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import Enlarge from "../../components/Enlarge/Enlarge";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
import wallet from "../../images/wallet.svg";
import { getHotelRoom, HotelUserServices } from "../../services/supplier.js";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import PageLoader from "../../components/PageLoader/PageLoader";
import RatingComponent from "../../components/RatingComponent/Rating";
import { toast } from "react-toastify";
import { bookRooms } from "../../services/web3Services";
import { SearchContext } from "../../context/search/SearchContext";
import { getDateFormat, countDays } from "../../utils/date";
import GMap from "../../services/GoogleMap";
import { getQuery } from "../../utils/queryString";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import roomppl from "../../images/roomppl.svg";
import cal from "../../images/cal.svg";
import down from "../../images/down.svg";
import Description from "../../components/Description/Description";

const amenties = {
  hasSwimmingPool: {
    name: "Pool",
    img: pool,
  },
  hasAirCondition: {
    name: "Air Conditioning",
    img: airConditioning,
  },
  hasFreeWifi: {
    name: "Free Wifi",
    img: wifi,
  },
  hasSpa: {
    name: "Spa",
    img: spa,
  },
  hasKingBed: {
    name: "King Bed",
    img: bed,
  },
  hasFreeParking: {
    name: "Parking",
    img: parking,
  },
  hasRestaurant: {
    name: "Restaurant",
    img: restaurant,
  },
  hasHalfBoard: {
    name: "Half Board",
    img: bed,
  },
  hasFullBoard: {
    name: "Full Board",
    img: bed,
  },
  isWalletFriendly: {
    name: "Wallet Friendly",
    img: wallet,
  },
};

const mapStyle = {
  minHeight: "250px",
  height: "100%",
};

const HotelPage = () => {
  const [seeMoreAminities, setSeeMoreAminities] = useState(false);
  const [hotelData, setHotelData] = useState("");
  const [roomData, setRoomData] = useState("");
  const [reviews, setReviews] = useState([]);
  const [enlarge, setEnlarge] = useState(false);
  const [enlarge1, setEnlarge1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [lessAttributes, setLessAttributes] = useState([]);
  const [addPrice, setAddPrice] = useState(0);
  const [coordinates, setCordinates] = useState({
    lat: parseFloat(38.12),
    lng: parseFloat(-9.12),
  });
  const [vat, setVat] = useState(10);
  const [readMore, setReadMore] = useState(false);
  const [open, setOpen] = useState(false);
  const searchId = getQuery("search");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };
  let navigate = useNavigate();
  let { id } = useParams();
  const lessAttr = (arr) => {
    const tempArr = arr.splice(0, 5);
    setLessAttributes(tempArr);
  };
  const mapAttributes = (items) => {
    const tempArr = [];
    for (const [key, value] of Object.entries(items)) {
      if (value === true) tempArr.push(key.toString());
    }
    setAttributes([...tempArr]);
    lessAttr(tempArr);
  };

  const getHotelById = async (id) => {
    try {
      const res = await HotelUserServices.getHotelById(id);
      setHotelData(res?.data.data);
      setReviews(res?.data.reviews);
      setLoading(false);
      mapAttributes(res?.data.data.attributes);
      setVat(res?.data.data.vat);
    } catch (err) {
      setLoading(false);
    }
  };

  const {
    startDate,
    endDate,
    room,
    setRoom,
    guest,
    setGuest,
    dateValue,
    handleDateRangeSelect,
  } = useContext(SearchContext);
  const handleSubmitForm = async (e) => {
    if (e) {
      e.preventDefault();
    }
    getHotelRoom(
      id,
      getDateFormat(dateValue[0]),
      getDateFormat(dateValue[1]),
      room,
      guest
    )
      .then((res) => {
        setRoomData(res?.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    getHotelById(id);
    handleSubmitForm(false);
  }, []);

  useEffect(() => {
    if (hotelData && hotelData?.locationCordinates) {
      setCordinates({
        lat: parseFloat(hotelData.locationCordinates.latitude),
        lng: parseFloat(hotelData.locationCordinates.longitude),
      });
    }
  }, [hotelData]);
  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <div className="app  min-h-[400px]">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Buk | {(hotelData && hotelData?.name) || "Hotel"}</title>
            <link rel="canonical" />
          </Helmet>
          {/* <Bar /> */}
          {hotelData && (
            <div className="m-auto hpage">
              <Link to={`/results/${searchId}`} className="cursor-pointer">
                <img src={back} className="back pl-2" />
              </Link>
              <SimpleDialog
                open={open}
                onClose={handleClose}
                hotelImages={hotelData && hotelData?.images}
              />
              <div className="imgfull flex justify-center my-6">
                <div className="parent1 h-[480px] w-full">
                  {hotelData?.images.length >= 1 && (
                    <img
                      onClick={handleClickOpen}
                      className="div1 w-full  mobile:h-[480px] h-[320px] overflow-hidden object-cover"
                      src={hotelData && hotelData?.images[0].url}
                    />
                  )}
                  {hotelData?.images.length > 1 && (
                    <img
                      onClick={handleClickOpen}
                      src={hotelData?.images[1].url}
                      className="div2 w-full h-[160px] mobile:h-[232px] object-cover"
                    />
                  )}
                  {hotelData?.images.length > 2 && (
                    <div
                      className="h-full w-full  relative"
                      onClick={handleClickOpen}
                    >
                      <img
                        src={hotelData?.images[2].url}
                        className="absolute  div3 w-full h-[160px] mobile:h-[232px] object-cover"
                      />
                      {hotelData?.images.length > 3 && (
                        <p className="absolute bg-stone-700/50 h-full w-full flex justify-center items-center text-white cursor-pointer top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                          +
                          {hotelData?.images.length >= 3
                            ? hotelData?.images.length - 3
                            : ""}{" "}
                          more
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="hp mb-4">
                <div className="w-full div1">
                  <div className="flex gap-x-2 mt-5 items-center justify-between mb-4">
                    <p className="hhead">{hotelData?.name}</p>
                    {reviews?.length > 0 ? (
                      <div className="flex items-center gap-4">
                        <RatingComponent
                          rating={hotelData?.starRatings}
                          readOnly
                        />

                        {reviews?.length > 0 && (
                          <span className="rat-text">
                            {reviews?.length} Users Reviewed
                          </span>
                        )}
                      </div>
                    ) : (
                      <div className="justify-center items-center ml-2 mb-1 text-xs text-gray-500">
                        No guest reviews available yet
                      </div>
                    )}
                  </div>
                  <p className="descr whitespace-pre-wrap">
                    <Description
                      description={hotelData?.description}
                      viewMore={readMore}
                      setViewMore={setReadMore}
                    />
                  </p>
                  <hr />
                  <div className="shadow-lg mt-4 gap-x-16 p-8 hel bg-white">
                    <p className="amtText  pb-4">Amenities</p>
                    <div className="grid gap-x-4 gap-y-8 grid-cols-2 md:grid-cols-5 mb-8 justify-items-start w-full max-w-[800px]">
                      {seeMoreAminities
                        ? attributes.map((attribute) => (
                            <div
                              key={attribute}
                              className="flex gap-x-2 amchng "
                            >
                              <span>
                                {" "}
                                <img
                                  src={amenties[attribute]?.img}
                                  className="w-6 h-6 min-h-6 min-w-6"
                                />
                              </span>
                              <span className="text-sm text-[#292931]  flex justify-center items-center">
                                {amenties[attribute]?.name}
                              </span>
                            </div>
                          ))
                        : attributes.map((attribute) => (
                            <div
                              key={attribute}
                              className="flex gap-x-2 amchng"
                            >
                              <span>
                                {" "}
                                <img
                                  src={amenties[attribute]?.img}
                                  className="w-6 h-6 min-h-6 min-w-6"
                                />
                              </span>
                              <span className="text-sm text-[#292931] flex justify-center items-center">
                                {amenties[attribute]?.name}
                              </span>
                            </div>
                          ))}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col mt-7 gap-y-2 mp div2">
                  <Enlarge
                    open={enlarge1}
                    setOpen={setEnlarge1}
                    element={
                      <GMap coordinates={coordinates} mapStyle={mapStyle} />
                    }
                  />
                  <p>
                    {hotelData?.address}, {hotelData?.city},{" "}
                    {hotelData?.country}
                  </p>
                  <img
                    className="cursor-pointer"
                    src={view}
                    width={111}
                    height={18}
                    onClick={() => setEnlarge1(true)}
                  />
                </div>
              </div>
              <hr />
              <div className="mt-7 mb-16">
                <div>
                  <p className="ch my-2">Choose your Room</p>
                </div>
                <form
                  onSubmit={handleSubmitForm}
                  className="flex border-left border-right bg-white relative items-center gap-x-4 w-full justify-center  border-gray-200 pl-8"
                >
                  <img src={loc} width={15.6} height={19.5} />
                  <input
                    placeholder="Enter your destination or hotel name"
                    className="w-full pb-2 pt-4 bg-white text-[#888] placeholder:text-[#888] focus:placeholder:!text-white"
                    name="search"
                    disabled={true}
                    value={hotelData?.name}
                  />
                  <div className="flex flex-col">
                    <div className="flex items-center justify-start w-3/4 ">
                      <div className="w-1/2">
                        <p className="fontdesc text-xs font-normal text-gray-500">
                          Check-in
                        </p>
                      </div>
                      <div>
                        <p className="fontdesc text-xs font-normal text-gray-500 ">
                          Check-out
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-row">
                      <img
                        className="mr-[2px]"
                        src={cal}
                        width={13}
                        height={13}
                      />
                      <DateRangePicker
                        onChange={handleDateRangeSelect}
                        value={dateValue}
                        minDate={new Date()}
                        format="dd/MM/y"
                        onFocus={(e) => e.target.blur()}
                      />
                    </div>
                  </div>
                  <div className="borderToCheck pl-4">
                    <div>
                      <p className="fontdesc text-xs font-normal text-gray-500 ml-1">
                        Rooms and Guests
                        <span className="text-[8px]">(Per Room)</span>
                      </p>
                    </div>
                    <div className="flex items-center w-[150px]">
                      <img src={roomppl} width={13} height={13} />

                      <div className="flex justify-center">
                        <div>
                          <div className="dropdown relative cursor-pointer">
                            <a
                              className="dropdown-toggle leading-tight transition duration-150 ease-in-out flex items-center whitespace-nowrap"
                              type="button"
                              id="dropdownMenuButton2"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <p
                                className="fontdesc text-sm font-medium text-gray-800 ml-1"
                                id="roomTxtChng"
                              >
                                <span className="font-bold text-base">
                                  {room}
                                </span>
                                {room > 1 ? "Rooms" : "Room"}
                              </p>

                              <img
                                src={down}
                                width={7}
                                height={7}
                                className="cursor-pointer ml-1"
                              />
                            </a>
                            <ul
                              className="dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-sm shadow-lg mt-1 hidden m-0 bg-clip-padding border-none"
                              aria-labelledby="dropdownMenuButton2"
                            >
                              <li className="w-32 cursor-pointer">
                                <span
                                  className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                                  onClick={() => {
                                    setRoom(1);
                                  }}
                                >
                                  1 Room
                                </span>
                              </li>
                              <li className="w-32 cursor-pointer">
                                <span
                                  className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                                  onClick={() => {
                                    setRoom(2);
                                  }}
                                >
                                  2 Rooms
                                </span>
                              </li>
                              <li className="w-32 cursor-pointer">
                                <span
                                  className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                                  onClick={() => {
                                    setRoom(3);
                                  }}
                                >
                                  3 Rooms
                                </span>
                              </li>
                              <li className="w-32 cursor-pointer">
                                <span
                                  className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                                  onClick={() => {
                                    setRoom(4);
                                  }}
                                >
                                  4 Rooms
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-center">
                        <div>
                          <div className="dropdown relative cursor-pointer">
                            <a
                              className="dropdown-toggle leading-tight transition duration-150 ease-in-out flex items-center whitespace-nowrap"
                              type="button"
                              id="dropdownMenuButton2"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <p
                                className="fontdesc text-sm font-medium text-gray-800 ml-1"
                                id="adultTxtChng"
                              >
                                <span className="font-bold text-base">
                                  {guest}
                                </span>
                                {guest > 1 ? "Adults" : "Adult"}
                              </p>

                              <img
                                src={down}
                                width={7}
                                height={7}
                                className="cursor-pointer ml-1"
                              />
                            </a>
                            <ul
                              className="dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-sm shadow-lg mt-1 hidden m-0 bg-clip-padding border-none"
                              aria-labelledby="dropdownMenuButton2"
                            >
                              <li className="w-32 cursor-pointer">
                                <span
                                  className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                                  onClick={() => setGuest(1)}
                                >
                                  1 Adult
                                </span>
                              </li>
                              <li className="w-32 cursor-pointer">
                                <span
                                  className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                                  onClick={() => setGuest(2)}
                                >
                                  2 Adults
                                </span>
                              </li>
                              <li className="w-32 cursor-pointer">
                                <span
                                  className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                                  onClick={() => setGuest(3)}
                                >
                                  3 Adults
                                </span>
                              </li>
                              <li className="w-32 cursor-pointer">
                                <span
                                  className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                                  onClick={() => setGuest(4)}
                                >
                                  4 Adults
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="sbtn pr-4">
                    Search
                  </button>
                </form>
                <div className="flex gap-x-1 mt-5 room">
                  <div
                    className="flex flex-wrap gap-2 flex-col xl:flex-row items-start w-full "
                    style={{ display: "contents" }}
                  >
                    {roomData && roomData.length > 0 ? (
                      roomData.map((item, i) => {
                        return (
                          <ChooseRoom
                            key={i}
                            item={item}
                            countDays={countDays}
                            room={room}
                            endDate={dateValue[1]}
                            startDate={dateValue[0]}
                            setAddPrice={setAddPrice}
                            addPrice={addPrice}
                            hotelId={id}
                            hotelAttributes={hotelData?.attributes}
                            vat={vat}
                            guest={guest}
                          />
                        );
                      })
                    ) : (
                      <div className="flex justify-center items-center w-full my-4 font-bold text-lg">
                        No Rooms Found
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export function SimpleDialog(props) {
  const { onClose, open, hotelImages } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: { background: "none", boxShadow: "none" },
      }}
    >
      <Swiper
        cssMode={true}
        navigation={{
          prevEl: ".slidePrev-btn",
          nextEl: ".slideNext-btn",
        }}
        loop={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mobile:w-[600px] w-[300px]"
      >
        <button className="slidePrev-btn absolute top-[40%] left-4 z-[1000] bg-[#00000066] rounded-full">
          <KeyboardArrowLeftRoundedIcon
            className="slidePrev-btn text-white"
            fontSize="large"
          />
        </button>
        {hotelImages.map((image, index) => (
          <SwiperSlide key={index + "img"} className="bg-transparent">
            <img
              className="mobile:h-[480px] h-[620px] overflow-hidden mb-8 object-cover"
              src={image?.url ? image.url : image}
            />
          </SwiperSlide>
        ))}
        <button className="slideNext-btn absolute top-[40%] right-4 z-[1000] bg-[#00000066] rounded-full">
          <ChevronRightRoundedIcon
            className="slidePrev-btn text-white"
            fontSize="large"
          />
        </button>
      </Swiper>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  // selectedValue: PropTypes.string.isRequired,
};

export default HotelPage;
