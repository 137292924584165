import moment from "moment";
const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const getDate = (newDate) => {
    return (
        new Date(newDate).getDate() +
        " " +
        monthNames[new Date(newDate).getMonth()]
    );
};
export const getDay = (newDate) => {
    return (new Date(newDate)).getDate();
};
export const getMonth = (newDate) => {
    return (new Date(newDate)).getMonth();
};
export const getYear = (newDate) => {
    return (new Date(newDate)).getFullYear();
};
export const getDateFormat = (newDate) => {
    let month = (getMonth(newDate) + 1)
    month = month<10 ? '0' + month.toString() : month.toString()
    let day = getDay(newDate)
    day = day<10 ? '0' + day.toString(): day.toString()
    return (
        getYear(newDate) + "-" + (month) + "-" + day
    );
};


export const getCompleteDate = (newDate) => {
    return moment(new Date(newDate.substr(0, 16))).format('llll');
};
export const getDateFormatWithWeekday = (newDate) => {
    return (
        weekday[moment(getCompleteDate(newDate)).day()] +", " + getDay(getCompleteDate(newDate)) + " " + monthNames[getMonth(getCompleteDate(newDate))] + " " + getYear(getCompleteDate(newDate))
    );
}
export const getDateFormatDay = (newDate) => {
    return (
          getDay(getCompleteDate(newDate)) + " " + monthNames[getMonth(getCompleteDate(newDate))] + " " + getYear(getCompleteDate(newDate))
    );
}
export const getTimeStamp = (newDate,timezone) => {
    const curr_timezone_offset = Number(new Date().getTimezoneOffset() * 60) // in seconds
    const hotel_timezone_offset = Number(timezone.rawOffset) * 60 * 60 // in seconds
    let adujusted_timezone_offset = (curr_timezone_offset + hotel_timezone_offset) * 1000 // in ms
    if(adujusted_timezone_offset > 0) {
        adujusted_timezone_offset = -adujusted_timezone_offset
    }
    else {
        adujusted_timezone_offset = Math.abs(adujusted_timezone_offset)
    }
    const unix_time = (new Date(newDate)).getTime() + (adujusted_timezone_offset)
    return unix_time
}

export function parseDate(date) {
    if(!date) return
    return moment(new Date(date.toString()?.substr(0, 16))).format("MM/DD/YYYY");
}
export function parseDate2(date) {
    if(!date) return
    return moment(new Date(date.toString()?.substr(0, 16))).format("YYYY-MM-DD");
}
export const countDays = (date_1, date_2) => {
    date_1 = new Date(parseDate(date_1)).getTime();
    date_2 = new Date(parseDate(date_2)).getTime();
    const days = (date_1, date_2) => {
        let difference = date_1 - date_2;
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return TotalDays;
    };
    let counted_days = days(date_1, date_2);
    if (
        new Date(date_1).getDate() == new Date(date_2).getDate() &&
        new Date(date_1).getMonth() == new Date(date_2).getMonth()
    ) {
        counted_days = 1;
    }
    return counted_days;
};

export const decreaseDays = (fromR) => {
    let date = new Date(parseDate(fromR)).getTime();
    let twoDay = date - 1000 * 60 * 60 * 24 * 2; // current date's milliseconds - 1,000 ms * 60 s * 60 mins * 24 hrs * (# of days beyond one to go back)
    twoDay = new Date(twoDay);
    return getDateFormat(twoDay).toString();
};
export const decrease_x_days = (x,from) => {
    let date = new Date(parseDate(from)).getTime();
    // var xDay = date - (1000 * 60 * 60 * 24 * Number(x)); // current date's milliseconds - 1,000 ms * 60 s * 60 mins * 24 hrs * (# of days beyond one to go back)
    // xDay = new Date(xDay);
    return getDateFormat(date).toString();
}
export const decreaseHours = (date) => {
    let ndate = new Date(parseDate(date)).getTime();
    let decHours = date - 1000 * 60 * 60 * 24;
    ndate = new Date(decHours)
    return getDateFormat(decHours).toString()
}
export const addDaysTo =  (add,fromDate) => {
    // console.log(parseDate(fromDate), "parsed adte")
    // console.log(moment(fromDate,"DD/MM/YYYY").toString(), "Moment js")
    let d = new Date(parseDate(fromDate));
    // console.log(d,"abc") // from Date!
    d.setDate(d.getDate() +  add)
    // console.log(d,"d")
    return d;
  }
export const inLast48Hours = (fromR) => {
    const hour = 1000 * 60 * 60;
    const FourtyEigthHourago = Math.floor((new Date(fromR) - hour * 48) / 1000);
    const currDate = Math.floor(Date.now() / 1000); // in seconds
    return Math.abs(currDate - FourtyEigthHourago) < 172800;
};
export const inLast24Hours = (fromR) => {
    const hour = 1000 * 60 * 60;
    const FourtyEigthHourago = Math.floor((new Date(fromR) - hour * 24) / 1000);
    const currDate = Math.floor(Date.now() / 1000); // in seconds
    return Math.abs(currDate - FourtyEigthHourago) < 86400;
};

export function convert(str) {
    if (!str) return;
    let mnths = {
            Jan: "01",
            Feb: "02",
            Mar: "03",
            Apr: "04",
            May: "05",
            Jun: "06",
            Jul: "07",
            Aug: "08",
            Sep: "09",
            Oct: "10",
            Nov: "11",
            Dec: "12",
        },
        date = str?.split(" ");
    return [date[3], mnths[date[1]], date[2]].join("-");
}


//expects an date in format YYYY-MM-DD
export const configure_check_in_date = (date) => {
    const newDate = getDateFormat(date)
    const dateTime = newDate.toString() +" " + "15:00:00"
    return dateTime
}
export const configure_check_out_date = (date) => {
    const newDate = getDateFormat(date)
    const dateTime = newDate.toString() +" " + "12:00:00"
    return dateTime
}

export const check_past = (date) => {
    date = (new Date(date)).getTime()
    const currDate = (new Date()).getTime()
    return date < currDate
}

export const checkDateTimeOverlap = (dateTimes)=>{
    let isOverlap = false;
    dateTimes.forEach((time,i) => {
        let  st1 = time.dateFrom;
        let  et1 = time.dateTo;

        dateTimes.forEach((time2,j) => {
            if(i != j){
                let st2 = time2.dateFrom;
                let et2 = time2.dateTo;
                if (st1 >= st2 && st1 <= et2 || et1 >= st2 && et1 <= et2 || st2 >= st1 && st2 <= et1 || et2 >= st1 && et2 <= et1) {
                    isOverlap =  true;
                }else{
                    isOverlap =  false;
                }
            }
        })
    });

    return isOverlap;
}

//This function takes a date &  returns a UTC string representation i.e ISO 8601 format
export function getUTCDateString(date) {
  date = new Date(date)

  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  ).toUTCString();
}
