import React, { useEffect, useState } from "react";
import {
    HotelUserServices,
    setAuthToken,
    getUserDetails,
} from "../../services/supplier";
import Loader from "../../components/Loader/Loader";
import { getQuery } from "../../utils/queryString";
import { useNavigate } from "react-router-dom";
import location from "../../images/location.svg";
import calender from "../../images/calender.svg";
import cancel from "../../images/cancel.svg";
import { getDateFormatWithWeekday } from "../../utils/date";
import HotelAmenities from "../../components/Amenities/HotelAmenities";
import TransactionData from "../../components/TransactionData/TransactionData";
import star5 from "../../images/5star.svg";
import GMap from "../../services/GoogleMap";
import { Helmet } from "react-helmet";
import Enlarge from "../../components/Enlarge/Enlarge";
import { getTokenUrl } from "../../services/web3Services";
import Description from "../../components/Description/Description";
import bookings from "../../images/bookings.svg";
import {SimpleDialog} from "../HotelPage/HotelPage"

const mapStyle = {
    minWidth: "300px",
    minHeight: "300px",
    height: "100%",
};

const BookingInfo = () => {
    const [loading, setLoading] = useState(true);
    const [hotelData, setHotelData] = useState("");
    const [viewMore, setViewMore] = useState(false);
    const [coordinates, setCordinates] = useState({
        lat: 38.7223,
        lng: 9.1393,
    });
    const bookingId = getQuery("booking-id");
    const getDetails = async () => {
        try {
            setLoading(true);
            setAuthToken(getUserDetails()?.token);
            const getData = await HotelUserServices.getBookingDetails(
                bookingId
            );
            setHotelData(getData.data);
            setCordinates({
                lat: getData.data?.detail.hotelId.locationCordinates.latitude,
                lng: getData.data?.detail.hotelId.locationCordinates.longitude,
            });
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };
    useEffect(() => {
        getDetails();
    }, []);
    return (
        <div className="h-full min-h-[100vh]">
            {loading ? (
                <Loader />
            ) : (
                <Booking
                    data={hotelData}
                    setLoading={setLoading}
                    loading={loading}
                    setViewMore={setViewMore}
                    viewMore={viewMore}
                    setCordinates={setCordinates}
                    coordinates={coordinates}
                />
            )}
        </div>
    );
};

export const Booking = ({
    data,
    loading,
    setLoading,
    setViewMore,
    viewMore,
    setCordinates,
    coordinates,
}) => {
    let navigate = useNavigate();
    const [enlarge1, setEnlarge1] = useState(false);
    const [open,setOpen] = useState(false)
    const [moreDescription, setDescription] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };
    return (
        <div className="info-card shadow-lg mx-auto">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Buk | Booking Info</title>
            </Helmet>
            <div
                className="cursor-pointer mt-2"
                onClick={() => navigate("/manageBooking")}
            >
                <img src={bookings} className="pl-2" alt="" />
            </div>
            <Enlarge
                open={enlarge1}
                setOpen={setEnlarge1}
                noEl
                element={<GMap coordinates={coordinates} mapStyle={mapStyle} />}
            />
            <p className="text-center sell py-4">Booking Info</p>
            <div className="">
                <div className="grid gap-4 grid-cols-2 mobile:grid-cols-3">
                    <div className="flex flex-col  mx-auto col-span-2 mobile:col-span-1">
                        <div className="p-2 mobile:p-0">
                            {data?.detail.roomId?.images.length >= 1 && (
                                <img
                                    className="w-full h-[240px] object-cover mx-auto m-0"
                                    src={data.detail?.roomId?.images[0]}
                                    onClick={handleClickOpen}
                                    alt="location2"
                                />
                            )}
                        </div>
                        <SimpleDialog
                            open={open}
                            onClose={handleClose}
                            hotelImages={
                                data?.detail.roomId?.images.length >= 1 &&
                                data?.detail.roomId?.images
                            }
                        />
                        <div className="grid grid-cols-4 gap-2 p-2 mobile:p-0 mobile:mt-2">
                            {data?.detail.roomId?.images.length >= 1 && (
                                <img
                                    src={data?.detail.roomId?.images[0]}
                                    className="w-full object-cover h-[100px]"
                                    alt="location3"
                                    onClick={handleClickOpen}
                                />
                            )}
                            {data?.detail.roomId?.images.length >= 2 && (
                                <img
                                    src={data?.detail.roomId?.images[1]}
                                    className="w-full object-cover h-[100px]"
                                    alt="location4"
                                    onClick={handleClickOpen}
                                />
                            )}
                            {data?.detail.roomId?.images.length >= 3 && (
                                <img
                                    src={data?.detail.roomId?.images[2]}
                                    className="w-full object-cover h-[100px]"
                                    alt="location5"
                                    onClick={handleClickOpen}
                                />
                            )}
                            {data?.detail.roomId?.images.length >= 4 && (
                              
                                <div
                                className={`w-full object-cover h-[100px] relative`}
                                onClick={handleClickOpen}
                            >
                                <img
                                   src={data?.detail.roomId?.images[3]}
                                    className="absolute h-full  w-full"
                                />
                                {data?.detail.roomId?.images.length > 5 && (
                                    <p className="absolute bg-stone-700/50 h-full w-full flex justify-center items-center text-white cursor-pointer top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                        +
                                        {data?.detail.roomId?.images.length >
                                        5
                                            ? data?.detail.roomId?.images
                                                  .length - 5
                                            : ""}{" "}
                                        more
                                    </p>
                                )}
                            </div>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col my-2 mobile:my-6 pl-4 mobile:p-0  ">
                        <p className="fontdesc text-2xl font-bold text-gray-900 hotelhead h-auto ">
                            {data?.detail.hotelId?.name} -{" "}
                            {data?.detail.roomId.roomType}
                        </p>
                        <div className="flex  mt-2">
                            <img
                                src={location}
                                className="h-4"
                                alt="location"
                            />
                            <label className="text-sm font-normal text-gray-600 pl-2 textsm">
                                {data?.detail.hotelId.city},{" "}
                                {data?.detail.hotelId.country}
                            </label>
                        </div>
                        <div className="flex  mt-10">
                            <img
                                src={calender}
                                className="h-4"
                                alt="location1"
                            />
                            <label className="text-sm font-normal text-gray-600 pl-2 pr-4 textsm">
                                {getDateFormatWithWeekday(
                                    data?.detail.nftRef.fromDate
                                )}{" "}
                                -{" "}
                                {getDateFormatWithWeekday(
                                    data?.detail.nftRef.toDate
                                )}
                            </label>
                        </div>
                        <div className="text-gray-600 mt-5">
                            <img src={cancel} alt="Cancellation" />
                        </div>
                        <div className="flex mt-4 gap-4 place-items-center">
                            <HotelAmenities
                                small={true}
                                attributes={data?.detail.hotelId?.attributes}
                                roomArea={data?.detail.roomId.roomArea}
                                roomUnit={data?.detail.roomId.roomAreaUnit}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col items-end p-5 mt-4">
                        <p className="text-right text-2xl mobile:text-4xl">
                            {data?.detail.marketplace_buy_price ? (
                                <div className="flex justify-end items-end">
                                    <div className="mt-2 flex flex-col">
                                        <p className="text-[#292931] fontdesc cost mobile:text-right text-center">
                                            ${" "}
                                            {data?.detail.marketplace_buy_price.toFixed(
                                                2
                                            )}
                                        </p>
                                        <p className="mobile:text-right text-center text-sm font-normal fontdesc text-[#434354]">
                                            Purchase Price
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <div className="mt-2 flex flex-col gap-y-1">
                                    <p className="fontdesc cost mobile:text-right text-center">
                                        ${data?.detail.buyPrice.toFixed(2)}
                                    </p>
                                    <p className="mobile:text-right text-[#434354] text-center text-sm font-normal fontdesc">
                                        Purchase Price
                                    </p>
                                </div>
                            )}
                        </p>

                        <p
                            className="text-sm text-center mt-5 font-normal textsm cursor-pointer"
                            onClick={() =>
                                getTokenUrl(
                                    data?.detail.nftRef.contractAddress,
                                    data?.detail.nftRef.tokenId
                                )
                            }
                        >
                            Token ID :{" "}
                            <span className="bg-[#EAF0FF] text-[#2f69ff] py-1 px-4 rounded-full text-xs ml-2">
                                {data?.detail.nftRef.tokenId}
                            </span>
                        </p>
                        <p onClick={() =>
                                getTokenUrl(
                                    data?.detail.nftRef.contractAddress,
                                    data?.detail.nftRef.tokenId
                                )
                            } className="text-sm my-3 cursor-pointer mobile:4 flex  font-normal text-gray-600 textsm">
                            Booked by :{" "}
                            <span className="text-gray-400textsm ml-2">
                                {data?.detail.nftRef.ownerTrail[
                                    data?.detail.nftRef.ownerTrail?.length - 1
                                ].currentOwner.slice(0, 6)}
                                ...
                                {data?.detail.nftRef.ownerTrail[
                                    data?.detail.nftRef.ownerTrail?.length - 1
                                ].currentOwner.slice(-4)}
                            </span>
                        </p>
                        {/* {data?.detail.userId ==
                            JSON.parse(sessionStorage.getItem("userDetails"))
                                .userId ? (
                                <button
                                    className="mt-4 mb-4 w-full flex justify-center items-center
                                border-2 border-[#292931] overflow-hidden hover:bg-gray-800 hover:border-1 hover:text-gray-900 text-white font-bold py-2 px-8 rounded-full  bg-[#292931]"
                                    height={60}
                                    onClick={() => {
                                        navigate(
                                            `/sales?sale-room=${data.detail._id}&room=${data.detail.roomId._id}&edit-listing=true`
                                        );
                                    }}
                                >
                                    <img src={edit} className="w-5 h-5" />

                                    <span className="fontdesc !text-sm font-medium p-1 !m-0 text-white textsell1">
                                        Edit Listing
                                    </span>
                                </button>
                            ) : (
                                <button
                                    className="buy flex items-center  justify-center mt-4 w-[250px] overflow-hidden hover:bg-gray-100 hover:border-1 hover:text-gray-900 text-white font-bold py-1 rounded-full buttonClr"
                                    height={60}
                                    // onClick={() => buyThisItem()}
                                >
                                    <span className=" flex place-items-center fontdesc text-lg font-medium p-2 textsell1">
                                        Buy This Room
                                        <img
                                            src={arroww}
                                            alt=""
                                            className="ml-4 fill-white"
                                        />
                                    </span>
                                </button>
                            )} */}
                    </div>
                </div>

                {/* view more */}
                {viewMore && (
                    <div>
                        <div className="flex mt-7 px-4 content-center justify-between flex-wrap">
                            <div className="flex items-center my-5  mobile:w-1/2 mr-14 flex-wrap">
                                <p className="fontdesc text-2xl font-bold text-gray-900 hotelhead mr-2">
                                    {data?.detail.hotelId.name}
                                </p>
                                <span className="text-gray-500 rounded-full border-2  border-gray-500 px-2 py-[2px] text-xs">
                                    {data?.detail.hotelId.starRatings} Star
                                    Hotel
                                </span>
                            </div>
                            {data?.detail.hotelId.guestRatings && (
                                <div className="flex items-center ml-3">
                                    <img src={star5} alt="location9" />

                                    <p className="fontdesc text-sm font-normal mr-3 text-gray-800 ml-4 fontsmin">
                                        {data?.detail.hotelId.guestRatings}{" "}
                                        Users Recieved
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-wrap px-4 items-center">
                            <div className="mobile:basis-1/2">
                                <p className="w-full text-[#242426] font-medium text-sm whitespace-pre-wrap">
                                    <Description
                                        description={
                                            data?.detail.hotelId.description
                                        }
                                        viewMore={moreDescription}
                                        setViewMore={setDescription}
                                    />
                                </p>

                                <p className="fontdesc text-lg  font-semibold text-gray-900 mt-4">
                                    Amenities
                                </p>
                                <div className="flex flex-wrap justify-items-start mt-3 ml-3">
                                    <HotelAmenities
                                        attributes={
                                            data?.detail.hotelId.attributes
                                        }
                                        roomArea={data?.detail.roomId.roomArea}
                                        roomUnit={
                                            data?.detail.roomId.roomAreaUnit
                                        }
                                    />
                                </div>
                                <TransactionData
                                    transactionData={
                                        data?.detail.nftRef.ownerTrail
                                    }
                                />
                            </div>
                            <div className="  flex justify-end  p-4 mobile:basis-1/2">
                                <div className="w-full h-full">
                                    <GMap
                                        mapStyle={mapStyle}
                                        coordinates={coordinates}
                                    />
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={() => setViewMore("")}
                            className="flex px-4 text-cyan-600 font-medium  mx-auto"
                        >
                            <span className=" mx-auto text-sm my-6">
                                View Less
                            </span>
                        </button>
                        <div className="w-full relative flex pb-6 justify-center">
                            <button
                                className="buybtn box-border flex items-center w-fit px-8 mx-4 justify-center overflow-hidden hover:bg-gray-100 hover:border-1 hover:text-gray-900 text-white font-bold py-1 rounded-full  buttonClr"
                                height={60}
                            >
                                <p className=" flex place-items-center place-content-center fontdesc text-lg font-medium p-2 textsell1">
                                    Buy This Room
                                    <svg
                                        className="ml-4 hover:fill-neutral-900"
                                        width="20"
                                        height="12"
                                        viewBox="0 0 20 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M19.7719 6.48736C20.0648 6.19447 20.0648 5.71959 19.7719 5.4267L14.9989 0.65373C14.706 0.360837 14.2311 0.360837 13.9382 0.65373C13.6453 0.946624 13.6453 1.4215 13.9382 1.71439L18.1809 5.95703L13.9382 10.1997C13.6453 10.4926 13.6453 10.9674 13.9382 11.2603C14.2311 11.5532 14.706 11.5532 14.9989 11.2603L19.7719 6.48736ZM0 6.70703H19.2415V5.20703H0V6.70703Z"
                                            fill="white"
                                        />
                                    </svg>
                                </p>
                            </button>
                        </div>
                    </div>
                )}
                {!viewMore && (
                    <button
                        onClick={() => {
                            setCordinates({
                                lat: parseFloat(
                                    data.detail.hotelId.locationCordinates
                                        .latitude
                                ),
                                lon: parseFloat(
                                    data.detail.hotelId.locationCordinates
                                        .longitude
                                ),
                            });
                            setViewMore(true);
                        }}
                        className="flex mx-auto text-cyan-600 font-medium cursor-pointer text-center"
                    >
                        <span className="text-sm my-6">View Details</span>
                    </button>
                )}
            </div>
        </div>
    );
};

export default BookingInfo;
