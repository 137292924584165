/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import hotelimg from "../../images/hotelimg.png";
import cardrec from "../../images/card-rec.svg";
import wifi from "../../images/am-wifi.svg";
import sleep from "../../images/card-slp.svg";
import airconditioning from "../../images/am-air.svg";
import arrow from "../../images/arrow.svg";
import warrow from "../../images/whitearr.svg";
import "./ChooseRoom.css";
import { useNavigate } from "react-router-dom";
import sea from "../../images/seaview.svg";
import bed from "../../images/am-bed.svg";
import { getDateFormat } from "../../utils/date";
import { border } from "@chakra-ui/react";

const ChooseRoom = ({
  hotelAttributes,
  item,
  countDays,
  room,
  endDate,
  startDate,
  addPrice,
  setAddPrice,
  hotelId,
  guest,
  vat,
}) => {
  let navigate = useNavigate();
  const [attri, setAttri] = useState("");
  const [moreAttributes, setMoreAttributes] = useState("");
  const [validStartDate, setValidStartDate] = useState();

  const lessAttr = (arr) => {
    const tempArr = arr?.splice(0, 2);
    setAttri(tempArr);
  };
  const mapAttributes = (items) => {
    const tempArr = [];
    if (!items) return;
    for (const [key, value] of Object.entries(items)) {
      if (value === true) tempArr.push(key.toString());
    }
    setMoreAttributes([...tempArr]);
    lessAttr(tempArr);
  };

  useEffect(() => {
    mapAttributes(hotelAttributes);
  }, [hotelAttributes]);
  return (
    <div className="w-fit h-auto ">
      <div className="h-full border-2 border-gray-200 border-opacity-60 flex flex-col justify-between max-w-[410px] relative overflow-hidden bg-white">
        {item.images && item.images.length > 0 ? (
          <img
            className="object-cover object-center w-full max-h-[150px] min-h-[150px]"
            src={item?.images[0]}
            alt="blog"
          />
        ) : (
          <img
            className="object-cover object-center w-full max-h-[150px] min-h-[150px]"
            src={hotelimg}
            alt="blog"
          />
        )}
        <div className="p-4 pb-0 borderColor">
          <p className="title-font text-2xl font-bold mb-3">{item?.roomType}</p>
          <div className="flex items-center justify-between">
            <div>
              <div className="grid overflow-hidden grid-cols-2 grid-rows-2 gap-2">
                <div className="box">
                  <div className="flex gap-2 pt-1">
                    <span>
                      {" "}
                      <img src={cardrec} width={18} height={18} />
                    </span>
                    <span className="text-sm">
                      {item?.roomArea}{" "}
                      {item.roomAreaUnit ? item.roomAreaUnit : "sq. ft."}
                    </span>
                  </div>
                </div>
                <div className="box col-start-2 col-span-2">
                  {attri.includes("hasFreeWifi") && (
                    <div className="flex gap-2 mt-2 ml-2">
                      <span>
                        {" "}
                        <img src={wifi} width={18} height={18} />
                      </span>
                      <span className="text-sm">Free Wifi</span>
                    </div>
                  )}
                </div>
                <div className="box col-start-2 col-span-2">
                  {attri.includes("AirConditioning") && (
                    <div className="flex gap-2">
                      <span>
                        {" "}
                        <img src={airconditioning} width={18} height={18} />
                      </span>
                      <span className="text-sm">Air Conditioning</span>
                    </div>
                  )}
                </div>
                <div className="box row-start-2 col-start-1">
                  <div className="flex gap-2 ">
                    <span>
                      {" "}
                      <img src={sleep} width={18} height={18} />
                    </span>
                    <span className="text-sm">Sleeps {item?.maxOccupancy}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-auto">
              {item.hasOwnProperty("pricing") && item.pricing && (
                <span className="text-4xl cost">${item?.pricing.minPrice}</span>
              )}
            </div>
          </div>
          {/* <p className="pt-2 title-font text-sm font-medium mb-3 seeMore">
                        see more
                    </p> */}
        </div>
        {(item.attributes?.hasKingBed ||
          item.attributes?.hasSeaView ||
          item.attributes?.hasHalfBoard ||
          item.attributes?.hasFullBoardBoard) && (
          <div className="p-4 pt-1 borderColor h-full">
            <p className="pt-2 title-font not-italic text-lg font-medium">
              Additional options
            </p>
            <div className="tablecon">
              <table className="table-auto">
                <tbody>
                  {item.attributes?.hasSeaView && (
                    <tr className="flex flex-row">
                      <td className="flex flex-row">
                        <img className="mr-2" src={sea} alt="sea" />
                        <p className="text-sm form-check-label inline-block text-gray-800">
                          Sea View
                        </p>
                      </td>
                    </tr>
                  )}
                  {item.attributes?.hasHalfBoard && (
                    <tr>
                      <td className="flex flex-row">
                        <img className="mr-2" src={bed} alt="sea" />
                        <p className="text-sm form-check-label inline-block text-gray-800">
                          Half board
                        </p>
                      </td>
                    </tr>
                  )}
                  {item.attributes?.hasFullBoard && (
                    <tr>
                      <td className="flex flex-row">
                        <img className="mr-2" src={bed} alt="sea" />
                        <p className="text-sm form-check-label inline-block text-gray-800">
                          Full board
                        </p>
                      </td>
                    </tr>
                  )}
                  {item.attributes?.hasKingBed && (
                    <tr>
                      <td className="flex flex-row">
                        <img className="mr-2" src={bed} alt="sea" />
                        <p className="text-sm form-check-label inline-block text-gray-800">
                          King Bed
                        </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div className="p-4 pt-1">
          {item.pricing !== null &&
          item.pricing.hasOwnProperty("sum") &&
          item.pricing.sum ? (
            <button
              className={
                item.bookingStatus == "booked"
                  ? " mt-4 w-full flex relative pr-4 items-center choose-room-btn black justify-between text-left overflow-hidden  text-[#A5A5A5] font-bold py-2 px-8 border-2 border-[#A5A5A5]  rounded-full"
                  : " mt-4 w-full flex relative pr-4 items-center choose-room-btn black justify-between text-left overflow-hidden hover:bg-gray-100 text-gray-900 font-bold py-2 px-8 border-2 border-gray-900  rounded-full"
              }
              style={{ border: "2px solid" }}
              height={60}
                onClick={() =>
                
                navigate(
                  `/buynow/${item._id}?fromR=${getDateFormat(
                    startDate
                  )}&toDate=${getDateFormat(
                    endDate
                  )}&hotelId=${hotelId}&room=${room}&guest=${guest}`
                )
              }
              disabled={item.bookingStatus == "booked"}
            >
              <div className="price--info">
                <p className="fontdesc text-lg mobile:text-2xl font-semibold whitespace-nowrap text-inherit">
                  Total ${(room * item.pricing.sum).toFixed(2)}
                </p>
                <p className="fontdesc text-xs mobile:text-sm font-medium text-inherit">
                  {countDays(endDate, startDate)}{" "}
                  {countDays(endDate, startDate) > 1 ? "nights" : "night"}, {room}{" "}
                  {room > 1 ? "rooms" : "room"}
                </p>
              </div>
              {item.bookingStatus == "booked" ? (
                <button>
                  <p className="text-xs text-center font-thin leading-3 ">
                    Direct Buy
                  </p>
                  <p>- Sold Out -</p>
                </button>
              ) : (
                <button className="flex items-center ml-4 text-sm gap-2">
                  <p className="fontdesc text-inherit">Buy Directly</p>
                  <div>
                    <img src={arrow} className="w-6 h-6" />
                  </div>
                </button>
              )}
            </button>
          ) : (
            <div className="text-center my-6 text-[#A5A5A5]">
              No options available from hotel
            </div>
          )}
          {item.count == 0 || item.in_range_sales == 0 ? (
            <div className="text-center my-6 text-[#A5A5A5]">
              No options available from marketplace
            </div>
          ) : (
            <button
              className={
                "mt-4 mb-4 flex pr-4 w-full text-left bg-blue-500 choose-room-btn white overflow-hidden relative items-center justify-between hover:bg-blue-700 text-white font-bold py-2 px-8 rounded-full"
              }
              height={60}
              onClick={() =>
                navigate(
                  `/bookings?hotels=${hotelId}&startDate=${startDate}&endDate=${endDate}`
                )
              }
              disabled={item.count == 0 || item.in_range_sales == 0}
            >
              <div className="mr-2">
                <p className="fontdesc text-lg mobile:text-2xl font-semibold font whitespace-nowrap">
                  Total ${parseFloat(item.totalSalePriceAvailable).toFixed(2)}
                </p>
                <p className="fontdesc text-xs mobile:text-sm font-medium">
                  {item.in_range_sales}{" "}
                  {item.in_range_sales > 1 ? "Options" : "Option"} available
                </p>
              </div>

              <div className="flex items-center gap-2 ml-6">
                <p className="fontdesc text-sm">Buy From Others</p>
                <div>
                  <img src={warrow} className="w-6 h-6" />
                </div>
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChooseRoom;
