/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState, useRef } from "react";
import loc from "../../images/loc.svg";
import cal from "../../images/cal.svg";
import down from "../../images/down.svg";
import roomppl from "../../images/roomppl.svg";
import funnel11 from "../../images/filterIcon.svg";
import "react-datepicker/dist/react-datepicker.css";
import "../Bar/Bar";
import { SearchContext } from "../../context/search/SearchContext";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { SaleServices } from "../../services/supplier";
import { toast } from "react-toastify";
import { getDateFormat } from "../../utils/date";

const MarketplaceSearch = ({
  className,
  mobileClass,
  setSearchData,
  setMetadata,
  setSearched,
}) => {
  const [page, setPage] = useState(1);
  const {
    search,
    setSearch,
    startDate,
    endDate,
    room,
    setRoom,
    guest,
    setGuest,
    dateValue,
    handleDateRangeSelect,
  } = useContext(SearchContext);
  const searchSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      if (!search) {
        return toast.error("Please enter the destination.");
      }
      const data = {
        search: search,
        from: getDateFormat(startDate),
        to: getDateFormat(endDate),
        guest: guest,
        room: room,
      };
      const getData = await SaleServices.searchMarketplace(data, 10, page);
      setSearchData(getData.data?.data[0]);
      setMetadata({
        limit: getData.data?.data[0].metadata[0]?.limit || 10,
        total: getData.data?.data[0].metadata[0]?.total || 0,
      });
      setSearched(true);
    } catch (err) {
      console.log(err);
    }
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  return (
    <form onSubmit={searchSubmitHandler}>
      <div
        className={`${className} mt-4 search-box m-auto justify-between hidden md:flex items-center gap-x-2 lg:gap-x-6 w-full`}
      >
        <div className="flex relative items-center gap-x-4 w-full border-b-2 border-gray-200 ml-8">
          <img src={loc} width={15.6} height={19.5} />
          <input
            placeholder="Enter your destination or hotel name"
            className="w-full pb-2 pt-4 placeholder:text-[#888] focus:placeholder:!text-white"
            onChange={handleSearch}
            name="search"
            // onClick={()=>setShowSuggestion(true)}
            autoComplete="off"
            // ref={focusRef}
            id="inputHotelName1"
            defaultValue="Lisbon"
            readonly="readonly"
          />
          {/* {keywordValue?.length>0 && <CloseIcon onClick={handleClearText} className="cursor-pointer text-[#888] hover:text-black"/>} */}
        </div>
        <div className="flex flex-col">
          <div className="flex items-center justify-start w-3/4">
            <div className="w-1/2">
              <p className="fontdesc text-xs font-normal text-gray-500">
                Check-in
              </p>
            </div>
            <div>
              <p className="fontdesc text-xs font-normal text-gray-500 ">
                Check-out
              </p>
            </div>
          </div>

          <div className="flex flex-row">
            <img className="mr-[2px]" src={cal} width={13} height={13} />
            <DateRangePicker
              onChange={handleDateRangeSelect}
              value={dateValue}
              minDate={new Date()}
              format="dd/MM/y"
              onFocus={(e) => e.target.blur()}
            />
          </div>
        </div>
        <div className="borderToCheck pl-4">
          <div>
            <p className="fontdesc text-xs font-normal text-gray-500 ml-1">
              Rooms and Guests
              <span className="text-[8px]">(Per Room)</span>
            </p>
          </div>
          <div className="flex items-center w-[150px]">
            <img src={roomppl} width={13} height={13} />

            <div className="flex justify-center">
              <div>
                <div className="dropdown relative cursor-pointer">
                  <a
                    className="dropdown-toggle leading-tight transition duration-150 ease-in-out flex items-center whitespace-nowrap"
                    type="button"
                    id="dropdownMenuButton2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <p
                      className="fontdesc text-sm font-medium text-gray-800 ml-1"
                      id="roomTxtChng"
                    >
                      <span className="font-bold text-base">{room}</span>
                      {room > 1 ? "Rooms" : "Room"}
                    </p>

                    <img
                      src={down}
                      width={7}
                      height={7}
                      className="cursor-pointer ml-1"
                    />
                  </a>
                  <ul
                    className="dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-sm shadow-lg mt-1 hidden m-0 bg-clip-padding border-none"
                    aria-labelledby="dropdownMenuButton2"
                  >
                    <li className="w-32 cursor-pointer">
                      <span
                        className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                        onClick={() => {
                          setRoom(1);
                        }}
                      >
                        1 Room
                      </span>
                    </li>
                    <li className="w-32 cursor-pointer">
                      <span
                        className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                        onClick={() => {
                          setRoom(2);
                        }}
                      >
                        2 Rooms
                      </span>
                    </li>
                    <li className="w-32 cursor-pointer">
                      <span
                        className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                        onClick={() => {
                          setRoom(3);
                        }}
                      >
                        3 Rooms
                      </span>
                    </li>
                    <li className="w-32 cursor-pointer">
                      <span
                        className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                        onClick={() => {
                          setRoom(4);
                        }}
                      >
                        4 Rooms
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <div>
                <div className="dropdown relative cursor-pointer">
                  <a
                    className="dropdown-toggle leading-tight transition duration-150 ease-in-out flex items-center whitespace-nowrap"
                    type="button"
                    id="dropdownMenuButton2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <p
                      className="fontdesc text-sm font-medium text-gray-800 ml-1"
                      id="adultTxtChng"
                    >
                      <span className="font-bold text-base">{guest}</span>
                      {guest > 1 ? "Adults" : "Adult"}
                    </p>

                    <img
                      src={down}
                      width={7}
                      height={7}
                      className="cursor-pointer ml-1"
                    />
                  </a>
                  <ul
                    className="dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-sm shadow-lg mt-1 hidden m-0 bg-clip-padding border-none"
                    aria-labelledby="dropdownMenuButton2"
                  >
                    <li className="w-32 cursor-pointer">
                      <span
                        className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                        onClick={() => setGuest(1)}
                      >
                        1 Adult
                      </span>
                    </li>
                    <li className="w-32 cursor-pointer">
                      <span
                        className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                        onClick={() => setGuest(2)}
                      >
                        2 Adults
                      </span>
                    </li>
                    <li className="w-32 cursor-pointer">
                      <span
                        className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                        onClick={() => setGuest(3)}
                      >
                        3 Adults
                      </span>
                    </li>
                    <li className="w-32 cursor-pointer">
                      <span
                        className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                        onClick={() => setGuest(4)}
                      >
                        4 Adults
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" className="sbtn pr-4">
          Search
        </button>
      </div>
      <div
        className={`${mobileClass} barm justify-between items-center my-6 gap-x-3`}
      >
        <div className="flex gap-3 items-center">
          <img src={loc} width={12} height={15} />
          <input placeholder="Enter your destination or hotel name" />
        </div>
        <img src={funnel11} className="h-8 w-8" />
      </div>
    </form>
  );
};

export default MarketplaceSearch;
