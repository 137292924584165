import React from "react";
import parse from 'html-react-parser';

const Description = ({ description, viewMore,setViewMore }) => {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html:description?.length > 200 && !viewMore ? description.slice(0,200) : description }} className="whitespace-pre-wrap text-gray-500">
            {/* {description?.length > 200
                ? description.slice(0, 200)
                : description}
            {
              viewMore && description.slice(200)
            } */}
            
        </div>
        <div  onClick={() => setViewMore(!viewMore)} className="flex cursor-pointer text-cyan-600 font-medium text-sm mx-auto">{description?.length > 200 && !viewMore ? "View More" : "View Less"}</div>
      </div>
        
    );
};

export default Description;
