/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import bookings from "../../images/bookings.svg";
import rectangle from "../../images/card-rec.svg";
import pool from "../../images/am-pool.svg";
import airConditioning from "../../images/am-air.svg";
import wifi from "../../images/am-wifi.svg";
import spa from "../../images/am-spa.svg";
import parking from "../../images/am-parking.svg";
import restaurant from "../../images/am-restaurant.svg";
import bed from "../../images/am-bed.svg";
import takephoto from "../../images/takephoto.svg";
import location from "../../images/location.svg";
import calender from "../../images/calender.svg";
import export1 from "../../images/export.svg";
import "./Checkin.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import RatingComponent from "../../components/RatingComponent/Rating";
import { getQuery } from "../../utils/queryString";
import {
    HotelUserServices,
    setAuthToken,
    getUserDetails,
} from "../../services/supplier";
import PageLoader from "../../components/PageLoader/PageLoader";
import Loader from "../../components/Loader/Loader";
import { getDate } from "../../utils/date";
import { toast } from "react-toastify";
import { getBase64 } from "../../components/FileUpload/FileUpload";
import { SimpleDialog } from "../../pages/HotelPage/HotelPage";
import { checkIn, getTokenUrl } from "../../services/web3Services";
import wallet from "../../images/wallet.svg";
import sea from "../../images/seaview.svg";
import TransactionData from "../../components/TransactionData/TransactionData";

const amenities = {
    hasSwimmingPool: {
        name: "Pool",
        img: pool,
    },
    hasAirCondition: {
        name: "Air Conditioning",
        img: airConditioning,
    },
    hasFreeWifi: {
        name: "Free Wifi",
        img: wifi,
    },
    hasSpa: {
        name: "Spa",
        img: spa,
    },
    hasKingBed: {
        name: "King Bed",
        img: bed,
    },
    hasFreeParking: {
        name: "Parking",
        img: parking,
    },
    hasRestaurant: {
        name: "Restaurant",
        img: restaurant,
    },
    hasHalfBoard: {
        name: "Half Board",
        img: bed,
    },
    hasFullBoard: {
        name: "Full Board",
        img: bed,
    },
    isWalletFriendly: {
        name: "Wallet Friendly",
        img: wallet,
    },
    hasSeaView: {
        name: "Sea View",
        img: sea,
    },
};
const Checkin = () => {
    const [fullname, setFullname] = useState(getUserDetails()?.fullName);
    // const [lastName, setLastName] = useState("");
    const [email,setEmail] = useState(getUserDetails()?.email)
    const [terms, setTerms] = useState(false);
    const [loading, setLoading] = useState(true);
    const [files, setFiles] = useState("");
    const [hotelData, setHotelData] = useState("");
    const [open, setOpen] = useState(false);
    const [viewTransaction,setViewTransaction] = useState(false);

    const navigate = useNavigate();
    const roomId = getQuery("r-check-in");
    const bookingId = getQuery("b-check-in");
    const getDetails = async () => {
        try {
            setLoading(true);
            setAuthToken(getUserDetails()?.token);
            const getData = await HotelUserServices.getBookingDetails(
                bookingId
            );
            setHotelData(getData.data?.detail);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const userCheckin = async (bookingId, hotelId) => {
        try {
            if (!fullname ) {
                toast.error("Please enter your fullname");
                return;
            }
            if(!email) {
                toast.error("Please enter your email address")
                return true;
            }
            if (!terms) {
                toast.error("Please accept the terms and conditions");
                return;
            }
            setLoading(true);
            setAuthToken(getUserDetails()?.token);
           
            const userCheckIn = await checkIn(hotelData.web3BookingId);
            const txhash = await userCheckIn.transactionHash

            let userForm = new FormData()
            userForm.append("bookingId",bookingId)
            userForm.append("firstName",fullname)
            // userForm.append("lastName",lastName)
            userForm.append("image",files[0])
            userForm.append("hotelId",hotelId)
            userForm.append("email",email)
            userForm.append("transactionHash",txhash)

            const createCheckin = await HotelUserServices.createCheckIn(
                userForm
            );
            navigate("/manageBooking");
            toast.success("Check-in successfull!");
            setLoading(false);
        } catch (err) {
            console.log("err: ", err);
            setLoading(false);
            if (
                err
                    .toString()
                    .includes("execution reverted: CheckIn window closed")
            ) {
                return toast.error("Check-in Window closed!");
            }
            toast.error(
                err?.response.data.msg || err?.message || "Please try again!"
            );
        }
    };
    const uploadImage = async (e) => {
        console.log('e',e)
        let fileSize = e.target?.files[0].size;
        if (fileSize > 5 * 1000000) {
            // fileSize > 5MB then show popup message
            toast.error(
                `File size is too large, please upload image of size less than 5MB.\nSelected File Size: ${
                    fileSize / 1000000
                }MB only`
            );
            return;
        }
        // const f = await getBase64(e.target.files[0]);
        setFiles(e.target.files);
    };
    const handleClose = (value) => {
        setOpen(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    useEffect(() => {
        getDetails();
    }, []);

    return (
        <>
            {loading ? (
                <PageLoader isTransaction={true}/>
            ) : (
                <div className="app min-h-[800px] 2xl:min-h-[1200px]">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Buk | Check-in</title>
                    </Helmet>
                    <div className="checkin m-auto mt-3">
                        <div
                            className="cursor-pointer"
                            onClick={() => navigate("/manageBooking")}
                        >
                            <img src={bookings} />
                        </div>
                        <div className="my-4 flex flex-col gap-y-3 rowchng">
                            <p className="text-center sell">Online Check-in</p>
                            <p className="text-center hotel yashchng">
                                Your hotel requires you provide guest details up
                                to 48 hours before your stay.
                            </p>
                        </div>
                        <div className="flex mt-6 mb-20 gap-4 justify-between checkm">
                            <div className="part1 w-auto shadow-lg p-4 flex flex-col justify-between bg-white">
                                <SimpleDialog
                                    open={open}
                                    onClose={handleClose}
                                    hotelImages={
                                        hotelData && hotelData.hotelId?.images
                                    }
                                />
                                <p className="fontdesc text-2xl font-bold text-gray-900 hotelhead">
                                    {hotelData.hotelId.name} -{" "}
                                    <span className="text-gray-600">
                                        {hotelData.roomId.roomType}
                                    </span>
                                </p>

                                <div className="flex mt-6 mb-4">
                                    <img
                                        src={location}
                                        width={10}
                                        height={12}
                                        alt="location"
                                    />
                                    <label className="text-sm font-normal text-gray-600 pl-2 pr-4 textsm">
                                        {hotelData?.hotelId.city},{" "}
                                        {hotelData?.hotelId.country}
                                    </label>

                                    <img
                                        src={calender}
                                        width={10}
                                        height={12}
                                        alt="location1"
                                    />
                                    <label className="text-sm font-normal text-gray-600 pl-2 pr-4 textsm">
                                        {getDate(hotelData?.fromDate)} -{" "}
                                        {getDate(hotelData?.toDate)}
                                    </label>
                                    <label
                                        className="text-sm font-normal text-gray-600 pl-2 pr-4 textsm cursor-pointer"
                                        onClick={() =>
                                            getTokenUrl(hotelData?.nftRef.contractAddress,hotelData?.nftRef.tokenId)
                                            
                                        }
                                    >
                                        Contract Address :{" "}
                                        <span className=" bg-[#2f69ff30] py-1 px-3 rounded-full textsm text-[#2F69FF]">
                                            {hotelData?.contractAddress
                                                ? (hotelData?.contractAddress).slice(
                                                      0,
                                                      6
                                                  ) +
                                                  "..." +
                                                  (hotelData?.contractAddress).slice(
                                                      -4
                                                  )
                                                : "null"}
                                        </span>
                                    </label>
                                    <label
                                        className="text-sm font-normal text-gray-600 pl-2 pr-4 textsm cursor-pointer"
                                        onClick={() =>
                                            getTokenUrl(hotelData?.nftRef.contractAddress,hotelData?.nftRef.tokenId)
                                            
                                        }
                                    >
                                        Token ID :{" "}
                                        <span className=" bg-[#2f69ff30] py-1 px-3 rounded-full textsm text-[#2F69FF]">
                                            {hotelData?.web3BookingId}
                                        </span>
                                    </label>
                                </div>

                                <div className="hotel-grid flex mobile:h-[480px]">
                                    {hotelData.hotelId?.images.length >= 1 &&<img
                                        onClick={handleClickOpen}
                                        className="div1 h-[240px] mobile:h-[480px] w-full object-cover"
                                        src={hotelData.hotelId?.images[0].url}
                                        alt="location2"
                                    />}
                                    {hotelData.hotelId?.images.length>=2 && <img
                                        onClick={handleClickOpen}
                                        src={hotelData.hotelId?.images[1].url}
                                        className="div2 w-full h-[90px] mobile:h-full object-cover"
                                        alt="location3"
                                    />}
                                    {hotelData.hotelId?.images.length>=3 && <img
                                        onClick={handleClickOpen}
                                        src={hotelData.hotelId?.images[2].url}
                                        className="div3 w-full h-[90px] mobile:h-full object-cover"
                                        alt="location4"
                                    />}

                                    <div
                                        className="div4  h-[90px] mobile:h-full object-cover w-full  relative"
                                        onClick={handleClickOpen}
                                    >
                                        {hotelData.hotelId?.images.length>=4 && <img
                                            onClick={handleClickOpen}
                                            src={
                                                hotelData.hotelId?.images[3].url
                                            }
                                            className=" w-full h-[90px] mobile:h-full object-cover"
                                            alt="location5"
                                        />}
                                        {hotelData.hotelId?.images.length > 4 && (
                                            <p className="absolute bg-stone-700/50 h-full w-full flex justify-center items-center text-white cursor-pointer top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                +
                                                {hotelData.hotelId?.images
                                                    .length > 4
                                                    ? hotelData.hotelId?.images
                                                          .length - 4
                                                    : 0}{" "}
                                                more
                                            </p>
                                        )}
                                    </div>
                                </div>

                                {hotelData.hotelId.guestRatings ? (
                                    <div className="flex mt-4 content-center">
                                        <RatingComponent
                                            rating={
                                                hotelData.hotelId.starRatings
                                            }
                                            readOnly
                                        />

                                        <p className="fontdesc text-sm font-normal text-gray-800 ml-4 fontsmin">
                                            {hotelData.hotelId.guestRatings}{" "}
                                            Users Review
                                        </p>
                                    </div>
                                ) : (
                                    <div className="justify-center items-center mb-1 mt-4 text-xs text-gray-500">
                                        No guest reviews available yet
                                    </div>
                                )}
                                <p className="fontdesc text-lg font-semibold text-gray-900 mt-2">
                                    Amenities
                                </p>
                                <div className="flex flex-wrap gap-x-8 gap-y-4 grid-cols-4 justify-items-start my-3">
                                    <div className="flex gap-x-4 iconsm">
                                        <span>
                                            {" "}
                                            <img
                                                src={rectangle}
                                                width={18}
                                                height={16}
                                                alt="rectangle"
                                            />
                                        </span>
                                        <span className="text-sm">
                                            {hotelData?.roomId.roomArea}{" "}
                                            {hotelData?.room_id?.roomAreaUnit
                                                ? hotelData?.room_id
                                                      ?.roomAreaUnit
                                                : "sq ft"}
                                        </span>
                                    </div>

                                    {Object.keys(
                                        hotelData.hotelId.attributes
                                    ).map(
                                        (attribute) =>
                                            hotelData.hotelId.attributes[
                                                attribute
                                            ] && (
                                                <div
                                                    key={attribute}
                                                    className="flex gap-x-2 amchng"
                                                >
                                                    <span>
                                                        {" "}
                                                        <img
                                                            src={
                                                                amenities[
                                                                    attribute
                                                                ]?.img
                                                            }
                                                            className="w-6 h-6 min-h-6 min-w-6"
                                                        />
                                                    </span>
                                                    <span className="text-sm text-[#292931]  flex justify-center items-center">
                                                        {
                                                            amenities[attribute]
                                                                ?.name
                                                        }
                                                    </span>
                                                </div>
                                            )
                                    )}

                                    {Object.keys(
                                        hotelData.roomId.attributes
                                    ).map(
                                        (attribute) =>
                                            hotelData.roomId.attributes[
                                                attribute
                                            ] && (
                                                <div
                                                    key={attribute}
                                                    className="flex gap-x-2 amchng"
                                                >
                                                    <span>
                                                        {" "}
                                                        <img
                                                            src={
                                                                amenities[
                                                                    attribute
                                                                ]?.img
                                                            }
                                                            className="w-6 h-6 min-h-6 min-w-6"
                                                        />
                                                    </span>
                                                    <span className="text-sm text-[#292931]  flex justify-center items-center">
                                                        {
                                                            amenities[attribute]
                                                                ?.name
                                                        }
                                                    </span>
                                                </div>
                                            )
                                    )}
                                    {viewTransaction && <TransactionData transactionData={hotelData?.nftRef.ownerTrail} />}
                                </div>
                                <hr />
                                {!viewTransaction && <div onClick={() => setViewTransaction(true)} className="flex cursor-pointer gap-x-4 text-cyan-600 font-medium my-4 dispnone justify-self-end">View More</div>}
                                {viewTransaction && <div onClick={() => setViewTransaction(false)} className="flex cursor-pointer gap-x-4 text-cyan-600 font-medium my-4 dispnone justify-self-end">View Less</div>}

                            </div>
                            <div className="lgshow gradientBorder form p-5 flex flex-col  relative w-full">
                                <p className="pers">Personal Data</p>
                                <p className="specify mt-2">
                                    Specify exactly as in your passport
                                </p>
                                <div className="mt-10 flex flex-col items-center gap-y-5">
                                    <input
                                        className="inp pl-5 placeholder-gray-500::placeholder"
                                        placeholder="Full Name"
                                        value={fullname}
                                        onChange={(e) =>
                                            setFullname(e.target.value)
                                        }
                                    />
                                    {/* <input
                                        className="inp pl-5 placeholder-gray-500::placeholder"
                                        placeholder="Last Name"
                                        value={lastName}
                                        onChange={(e) =>
                                            setLastName(e.target.value)
                                        }
                                    /> */}
                                    <input
                                        className="inp pl-5 placeholder-gray-500::placeholder"
                                        type="email"
                                        required
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </div>
                                <div>
                                    
                                    {files && files.length > 0 ? (
                                        <div>
                                            <img
                                                src={URL.createObjectURL(files[0])}
                                                alt="fileaimge"
                                                className="fileImage"
                                            />
                                            <div className=" flex items-center justify-center">
                                                {/* <p
                                                    onClick={uploadImage}
                                                    className="cursor-pointer p-2 hover:text-green-500 mr-2 text-xs border-r-2 text-gray-700"
                                                >
                                                    Edit
                                                </p> */}
                                                <p
                                                    onClick={() => setFiles("")}
                                                    className="cursor-pointer hover:text-red-700 text-xs text-gray-700"
                                                >
                                                    Remove
                                                </p>
                                            </div>
                                        </div>
                                    ) : (<label>
                                        <input
                                        type="file"
                                        onChange={uploadImage}
                                        className="hidden"
                                        accept=".png, .jpg, .jpeg"
                                    /><img src={export1} className="mt-4" />
                                    </label>
                                        
                                    )}
                                </div>
                                <div className="flex items-start mx-auto mt-8 gap-x-5">
                                    <input
                                        type="checkbox"
                                        value={terms}
                                        onChange={() => setTerms(!terms)}
                                        className="check"
                                    />
                                    <p className="text-xs font-medium w-[314px] text-[#646465] fontdesc">
                                        I understand that check-in information
                                        cannot be modified once sent to the
                                        hotel. Booking cancellations or any
                                        modifications will have to be carried
                                        out directly with the hotel and might
                                        incur additional fees
                                    </p>
                                </div>
                                <p className="ht w-[348px] mx-auto mt-8">
                                    Completing the online check-in will make
                                    your booking non-transferrable (and it will
                                    remove it from the marketplace in case it
                                    was currently for sale)
                                </p>
                                {
                                    <button
                                        loading={true}
                                        onClick={() =>
                                            userCheckin(
                                                hotelData._id,
                                                hotelData.hotelId._id
                                            )
                                        }
                                        className="btn !w-auto box-border p-4 mt-8 mb-4"
                                    >
                                        Finalize Check-in
                                    </button>
                                }
                            </div>
                            {
                                <button
                                    onClick={() =>
                                        userCheckin(
                                            hotelData._id,
                                            hotelData.hotelId._id
                                        )
                                    }
                                    className="btn smshow mx-4 p-4 mb-4 mt-4"
                                >
                                    Finalize Check-in
                                </button>
                            }

                            <p className="smshow title-font text-xs font-normal mb-3 text-gray-400 ml-2 mr-2 mt-6">
                                Completing the online check-in will make your
                                booking non-transferrable (and it will remove it
                                from the marketplace in case it was currently
                                for sale)
                            </p>
                            <div className="smshow form p-4 mt-6">
                                <div className="flex items-start ml-2 mx-auto gap-x-5">
                                    <input type="checkbox" className="check" />
                                    <p className="formtext font-sm w-[314px] text-[#646465] fontdesc">
                                        I understand that check-in information
                                        cannot be modified once sent to the
                                        hotel. Booking cancellations or any
                                        modifications will have to be carried
                                        out directly with the hotel and might
                                        incur additional fees
                                    </p>
                                </div>
                            </div>

                            <div className="smshow form p-4 mt-6">
                                <p className="title-font text-xs font-normal mb-3 text-gray-400">
                                    Optional: provide a photo of your ID and
                                    face to gain $BUK and get a super fast
                                    check-in experience at the hotel
                                </p>
                                <div className="flex">
                                    <div>
                                        <img
                                            className="mt-4"
                                            src={takephoto}
                                            width={349}
                                            height={104}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="smshow form flex flex-col p-4">
                                <p className="pl-2 pers">Personal Data</p>
                                <p className="pl-2 specify mt-2">
                                    Specify exactly as in your passport
                                </p>
                                <div className="mt-6 flex flex-col items-center gap-y-5">
                                    <input
                                        className="inp pl-5"
                                        placeholder="First Name"
                                    />
                                    <input
                                        className="inp pl-5"
                                        placeholder="Last Name"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


        </>
    );
};

export default Checkin;
