import Rating from "@mui/material/Rating"
import { styled } from "@mui/material/styles"
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined"
import CircleIcon from "@mui/icons-material/Circle"

const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
        color: "#FFC643",
    },
    "& .MuiRating-iconHover": {
        color: "#fcb000",
    },
})

const RatingComponent = ({ rating, setRating, readOnly }) => {
    return (
        <StyledRating
            value={rating}
            onChange={(event, newValue) => {
                setRating(newValue)
            }}
            precision={0.5}
            icon={<CircleIcon fontSize="small" />}
            emptyIcon={<CircleOutlinedIcon fontSize="small" />}
            readOnly={readOnly}
        />
    )
}

export default RatingComponent
