import { createContext, useEffect, useState } from "react"

export const LanguageContext = createContext()

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState("english")

    const changeLanguage = (lang) => {
        setLanguage(lang)
        sessionStorage.setItem('language', lang)
    }

    useEffect(() => {
        (async () => { 
            const storedLanguage = sessionStorage.getItem(language)
            if(storedLanguage) setLanguage(storedLanguage)
        })()
    }, [])

    return (
        <LanguageContext.Provider value={{ language, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    )
}
