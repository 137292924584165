export const BUK_ABI = [
    {
      inputs: [
        { internalType: 'string', name: 'NAME', type: 'string' },
        { internalType: 'address', name: '_treasury', type: 'address' },
        { internalType: 'address', name: '_signer', type: 'address' },
        { internalType: 'address', name: '_currency', type: 'address' },
        { internalType: 'address', name: 'bot', type: 'address' },
      ],
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'address', name: 'account', type: 'address' },
        { indexed: true, internalType: 'address', name: 'operator', type: 'address' },
        { indexed: false, internalType: 'bool', name: 'approved', type: 'bool' },
      ],
      name: 'ApprovalForAll',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'address', name: 'user', type: 'address' },
        { indexed: true, internalType: 'uint256', name: 'hotel', type: 'uint256' },
        { indexed: true, internalType: 'uint256', name: 'nftId', type: 'uint256' },
      ],
      name: 'Booked',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: 'address', name: 'user', type: 'address' },
        { indexed: true, internalType: 'uint256', name: 'id', type: 'uint256' },
        { indexed: true, internalType: 'uint256', name: 'hotelId', type: 'uint256' },
      ],
      name: 'BookingCancelled',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'uint256', name: 'id', type: 'uint256' },
        { indexed: true, internalType: 'address', name: 'user', type: 'address' },
        { indexed: true, internalType: 'uint256', name: 'hotelId', type: 'uint256' },
      ],
      name: 'CheckedIn',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'uint256', name: 'id', type: 'uint256' },
        { indexed: true, internalType: 'address', name: 'user', type: 'address' },
        { indexed: true, internalType: 'uint256', name: 'hotelId', type: 'uint256' },
      ],
      name: 'CheckedOut',
      type: 'event',
    },
    { anonymous: false, inputs: [{ indexed: false, internalType: 'uint256', name: 'time', type: 'uint256' }], name: 'CheckinTimeUpdated', type: 'event' },
    { anonymous: false, inputs: [{ indexed: false, internalType: 'address', name: 'bot', type: 'address' }], name: 'CheckoutBotupdated', type: 'event' },
    { anonymous: false, inputs: [{ indexed: false, internalType: 'uint256', name: 'time', type: 'uint256' }], name: 'CheckoutTimeUpdated', type: 'event' },
    { anonymous: false, inputs: [{ indexed: false, internalType: 'address', name: 'currency', type: 'address' }], name: 'CurrencyAddressUpdated', type: 'event' },
    { anonymous: false, inputs: [{ indexed: true, internalType: 'uint256', name: 'hotelId', type: 'uint256' }], name: 'HotelDetailsUpdated', type: 'event' },
    {
      anonymous: false,
      inputs: [
        {
          components: [
            { internalType: 'string', name: 'hotelId', type: 'string' },
            { internalType: 'string', name: 'hotelUri', type: 'string' },
            { internalType: 'address', name: 'hotelManager', type: 'address' },
            { internalType: 'address', name: 'hotelTreasury', type: 'address' },
            { internalType: 'uint256', name: 'index', type: 'uint256' },
          ],
          indexed: false,
          internalType: 'struct Buk.Hotel',
          name: 'hotelDetails',
          type: 'tuple',
        },
      ],
      name: 'HotelRegistered',
      type: 'event',
    },
    { anonymous: false, inputs: [{ indexed: false, internalType: 'uint256', name: 'tax', type: 'uint256' }], name: 'HotelTaxesUpdated', type: 'event' },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: 'address', name: 'manager', type: 'address' },
        { indexed: false, internalType: 'bool', name: 'isWhitelisted', type: 'bool' },
      ],
      name: 'ManagerWhitelistUpdated',
      type: 'event',
    },
    { anonymous: false, inputs: [{ indexed: true, internalType: 'address', name: 'market', type: 'address' }], name: 'MarketplaceContractUpdated', type: 'event' },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'uint256', name: 'id', type: 'uint256' },
        { indexed: true, internalType: 'address', name: 'minter', type: 'address' },
      ],
      name: 'Minted',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
        { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    { anonymous: false, inputs: [{ indexed: false, internalType: 'uint256', name: 'platformFee', type: 'uint256' }], name: 'PlatformFeeUpdated', type: 'event' },
    { anonymous: false, inputs: [{ indexed: true, internalType: 'address', name: 'signer', type: 'address' }], name: 'SignerUpdated', type: 'event' },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'address', name: 'user', type: 'address' },
        { indexed: false, internalType: 'bool', name: 'isAllowed', type: 'bool' },
      ],
      name: 'TransferAllowanceUpdated',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'address', name: 'operator', type: 'address' },
        { indexed: true, internalType: 'address', name: 'from', type: 'address' },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
        { indexed: false, internalType: 'uint256[]', name: 'ids', type: 'uint256[]' },
        { indexed: false, internalType: 'uint256[]', name: 'values', type: 'uint256[]' },
      ],
      name: 'TransferBatch',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'address', name: 'operator', type: 'address' },
        { indexed: true, internalType: 'address', name: 'from', type: 'address' },
        { indexed: true, internalType: 'address', name: 'to', type: 'address' },
        { indexed: false, internalType: 'uint256', name: 'id', type: 'uint256' },
        { indexed: false, internalType: 'uint256', name: 'value', type: 'uint256' },
      ],
      name: 'TransferSingle',
      type: 'event',
    },
    { anonymous: false, inputs: [{ indexed: true, internalType: 'address', name: 'treasury', type: 'address' }], name: 'TreasuryUpdated', type: 'event' },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: 'string', name: 'value', type: 'string' },
        { indexed: true, internalType: 'uint256', name: 'id', type: 'uint256' },
      ],
      name: 'URI',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'uint256', name: 'id', type: 'uint256' },
        { indexed: false, internalType: 'string', name: 'uri', type: 'string' },
      ],
      name: 'UriUpdated',
      type: 'event',
    },
    { stateMutability: 'payable', type: 'fallback' },
    { inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: '_uri', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function' },
    { inputs: [{ internalType: 'address', name: '', type: 'address' }], name: 'allowedToTransfer', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function' },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'uint256', name: 'id', type: 'uint256' },
      ],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address[]', name: 'accounts', type: 'address[]' },
        { internalType: 'uint256[]', name: 'ids', type: 'uint256[]' },
      ],
      name: 'balanceOfBatch',
      outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          components: [
            { internalType: 'string', name: 'nftUri', type: 'string' },
            { internalType: 'string', name: 'bookingId', type: 'string' },
            { internalType: 'uint256', name: 'price', type: 'uint256' },
            { internalType: 'uint256', name: '_baseprice', type: 'uint256' },
            { internalType: 'uint256', name: 'discount', type: 'uint256' },
            { internalType: 'uint256', name: 'hotelId', type: 'uint256' },
            { internalType: 'uint256', name: 'buk_cancellationFee', type: 'uint256' },
            { internalType: 'uint256', name: 'Hotel_cancellationfee', type: 'uint256' },
            { internalType: 'uint256', name: 'time', type: 'uint256' },
          ],
          internalType: 'struct Buk.Booking[]',
          name: 'booking',
          type: 'tuple[]',
        },
        { internalType: 'address', name: 'user', type: 'address' },
        { internalType: 'bytes', name: 'signature', type: 'bytes' },
        { internalType: 'string', name: 'nonce', type: 'string' },
      ],
      name: 'bookRoom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'bookingDetails',
      outputs: [
        { internalType: 'string', name: 'nftUri', type: 'string' },
        { internalType: 'string', name: 'bookingId', type: 'string' },
        { internalType: 'uint256', name: 'price', type: 'uint256' },
        { internalType: 'uint256', name: '_baseprice', type: 'uint256' },
        { internalType: 'uint256', name: 'discount', type: 'uint256' },
        { internalType: 'uint256', name: 'hotelId', type: 'uint256' },
        { internalType: 'uint256', name: 'buk_cancellationFee', type: 'uint256' },
        { internalType: 'uint256', name: 'Hotel_cancellationfee', type: 'uint256' },
        { internalType: 'uint256', name: 'time', type: 'uint256' },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'id', type: 'uint256' },
        { internalType: 'address', name: 'user', type: 'address' },
        { internalType: 'string', name: 'nonce', type: 'string' },
        { internalType: 'bytes', name: 'signature', type: 'bytes' },
      ],
      name: 'cancelBookingUser',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    { inputs: [], name: 'checkInTime', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function' },
    {
      inputs: [
        { internalType: 'uint256', name: 'id', type: 'uint256' },
        { internalType: 'address', name: 'user', type: 'address' },
      ],
      name: 'checkOut',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    { inputs: [], name: 'checkOutBot', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function' },
    { inputs: [], name: 'checkOutTime', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function' },
    { inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'checkedOut', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function' },
    { inputs: [], name: 'currency', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function' },
    {
      inputs: [
        { internalType: 'bytes32', name: 'hash', type: 'bytes32' },
        { internalType: 'bytes', name: 'signature', type: 'bytes' },
      ],
      name: 'getAddress',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'pure',
      type: 'function',
    },
    { inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }], name: 'getHotelTreasury', outputs: [{ internalType: 'address', name: 'hotel', type: 'address' }], stateMutability: 'view', type: 'function' },
    { inputs: [], name: 'getNFTsToCheckout', outputs: [{ internalType: 'uint256[]', name: 'ids', type: 'uint256[]' }], stateMutability: 'view', type: 'function' },
    { inputs: [], name: 'getTokenCounter', outputs: [{ internalType: 'uint256', name: 'tracker', type: 'uint256' }], stateMutability: 'view', type: 'function' },
    {
      inputs: [
        { internalType: 'address', name: 'user', type: 'address' },
        { internalType: 'uint256', name: 'nft', type: 'uint256' },
        { internalType: 'string', name: 'nonce', type: 'string' },
        { internalType: 'uint256', name: 'price', type: 'uint256' },
      ],
      name: 'hashCancelTransaction',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'pure',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'user', type: 'address' },
        { internalType: 'uint256', name: 'nft', type: 'uint256' },
        { internalType: 'string', name: 'nonce', type: 'string' },
      ],
      name: 'hashCheckInTransaction',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'pure',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'string', name: 'hotelId', type: 'string' },
        { internalType: 'string', name: 'nonce', type: 'string' },
      ],
      name: 'hashHotelRegistrationTransaction',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'pure',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'user', type: 'address' },
        { internalType: 'string', name: 'nonce', type: 'string' },
      ],
      name: 'hashTransaction',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'pure',
      type: 'function',
    },
    { inputs: [], name: 'hotelTaxes', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function' },
    {
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'idToHotel',
      outputs: [
        { internalType: 'string', name: 'hotelId', type: 'string' },
        { internalType: 'string', name: 'hotelUri', type: 'string' },
        { internalType: 'address', name: 'hotelManager', type: 'address' },
        { internalType: 'address', name: 'hotelTreasury', type: 'address' },
        { internalType: 'uint256', name: 'index', type: 'uint256' },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    { inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'idToMinter', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function' },
    {
      inputs: [
        { internalType: 'address', name: 'account', type: 'address' },
        { internalType: 'address', name: 'operator', type: 'address' },
      ],
      name: 'isApprovedForAll',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    { inputs: [{ internalType: 'address', name: '', type: 'address' }], name: 'isWhitelistedManager', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function' },
    { inputs: [], name: 'marketplaceContract', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function' },
    {
      inputs: [
        { internalType: 'bytes32', name: 'hash', type: 'bytes32' },
        { internalType: 'bytes', name: 'signature', type: 'bytes' },
      ],
      name: 'matchSigner',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    { inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'nftCheckedOut', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function' },
    { inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'nftToHotel', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function' },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC1155BatchReceived',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'bytes', name: '', type: 'bytes' },
      ],
      name: 'onERC1155Received',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    { inputs: [], name: 'owner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function' },
    { inputs: [], name: 'platformFee', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function' },
    {
      inputs: [
        { internalType: 'uint256', name: 'id', type: 'uint256' },
        { internalType: 'string', name: 'nonce', type: 'string' },
        { internalType: 'bytes', name: 'signature', type: 'bytes' },
      ],
      name: 'preCheckIn',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          components: [
            { internalType: 'string', name: 'hotelId', type: 'string' },
            { internalType: 'string', name: 'hotelUri', type: 'string' },
            { internalType: 'address', name: 'hotelManager', type: 'address' },
            { internalType: 'address', name: 'hotelTreasury', type: 'address' },
            { internalType: 'uint256', name: 'index', type: 'uint256' },
          ],
          internalType: 'struct Buk.Hotel',
          name: 'hotelDetails',
          type: 'tuple',
        },
        { internalType: 'string', name: 'nonce', type: 'string' },
        { internalType: 'bytes', name: 'signature', type: 'bytes' },
      ],
      name: 'registerHotel',
     outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    { inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function' },
    {
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256[]', name: 'ids', type: 'uint256[]' },
        { internalType: 'uint256[]', name: 'amounts', type: 'uint256[]' },
        { internalType: 'bytes', name: 'data', type: 'bytes' },
      ],
      name: 'safeBatchTransferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'from', type: 'address' },
        { internalType: 'address', name: 'to', type: 'address' },
        { internalType: 'uint256', name: 'id', type: 'uint256' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
        { internalType: 'bytes', name: 'data', type: 'bytes' },
      ],
      name: 'safeTransferFrom',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'operator', type: 'address' },
        { internalType: 'bool', name: 'approved', type: 'bool' },
      ],
      name: 'setApprovalForAll',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    { inputs: [{ internalType: 'address', name: '_bukMarket', type: 'address' }], name: 'setMarketplace', outputs: [], stateMutability: 'nonpayable', type: 'function' },
    { inputs: [{ internalType: 'address', name: '_signer', type: 'address' }], name: 'setSigner', outputs: [], stateMutability: 'nonpayable', type: 'function' },
    { inputs: [{ internalType: 'address', name: '_treasury', type: 'address' }], name: 'setTreasury', outputs: [], stateMutability: 'nonpayable', type: 'function' },
    { inputs: [], name: 'signer', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function' },
    { inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }], name: 'supportsInterface', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function' },
    { inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], name: 'transferBlocked', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function' },
    { inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }], name: 'transferOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function' },
    { inputs: [], name: 'treasury', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function' },
    {
      inputs: [
        { internalType: 'address', name: 'user', type: 'address' },
        { internalType: 'bool', name: 'allowed', type: 'bool' },
      ],
      name: 'updateAllowedToTransfer',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    { inputs: [{ internalType: 'uint256', name: 'time', type: 'uint256' }], name: 'updateCheckinTime', outputs: [], stateMutability: 'nonpayable', type: 'function' },
    { inputs: [{ internalType: 'address', name: 'bot', type: 'address' }], name: 'updateCheckoutBot', outputs: [], stateMutability: 'nonpayable', type: 'function' },
    { inputs: [{ internalType: 'uint256', name: 'time', type: 'uint256' }], name: 'updateCheckoutTime', outputs: [], stateMutability: 'nonpayable', type: 'function' },
    { inputs: [{ internalType: 'address', name: '_currency', type: 'address' }], name: 'updateCurrency', outputs: [], stateMutability: 'nonpayable', type: 'function' },
    {
      inputs: [
        {
          components: [
            { internalType: 'string', name: 'hotelId', type: 'string' },
            { internalType: 'string', name: 'hotelUri', type: 'string' },
            { internalType: 'address', name: 'hotelManager', type: 'address' },
            { internalType: 'address', name: 'hotelTreasury', type: 'address' },
            { internalType: 'uint256', name: 'index', type: 'uint256' },
          ],
          internalType: 'struct Buk.Hotel',
          name: 'hotel',
          type: 'tuple',
        },
      ],
      name: 'updateHotelDetails',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'manager', type: 'address' },
        { internalType: 'bool', name: 'isWhitelisted', type: 'bool' },
      ],
      name: 'updateManagerWhitelist',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
        { internalType: 'string', name: 'tokenUri', type: 'string' },
      ],
      name: 'updateUri',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    { inputs: [{ internalType: 'uint256', name: 'fee', type: 'uint256' }], name: 'updatedPlatformFee', outputs: [], stateMutability: 'nonpayable', type: 'function' },
    { inputs: [{ internalType: 'uint256', name: 'id', type: 'uint256' }], name: 'uri', outputs: [{ internalType: 'string', name: '', type: 'string' }], stateMutability: 'view', type: 'function' },
    { inputs: [{ internalType: 'string', name: '', type: 'string' }], name: 'usedNonce', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function' },
    { inputs: [{ internalType: 'address', name: 'wallet', type: 'address' }], name: 'withdrawFunds', outputs: [], stateMutability: 'nonpayable', type: 'function' },
    {
      inputs: [
        { internalType: 'address', name: 'token', type: 'address' },
        { internalType: 'address', name: 'wallet', type: 'address' },
      ],
      name: 'withdrawTokens',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    { stateMutability: 'payable', type: 'receive' },
  ]

export const MARKET_ABI = [
  {
    inputs: [
      { internalType: 'address', name: '_treasury', type: 'address' },
      { internalType: 'address', name: '_signer', type: 'address' },
      { internalType: 'address', name: 'minting', type: 'address' },
      { internalType: 'address', name: '_currency', type: 'address' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  { anonymous: false, inputs: [{ indexed: false, internalType: 'address', name: 'currency', type: 'address' }], name: 'CurrencyAddressUpdated', type: 'event' },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'itemId', type: 'uint256' },
      { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'buyer', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'price', type: 'uint256' },
      { indexed: false, internalType: 'bool', name: 'sold', type: 'bool' },
      { indexed: false, internalType: 'bool', name: 'isActive', type: 'bool' },
    ],
    name: 'ItemBought',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'itemId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'price', type: 'uint256' },
    ],
    name: 'ListingEdited',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'platformFee', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'hotelFee', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'minterFee', type: 'uint256' },
    ],
    name: 'RoyaltyUpdated',
    type: 'event',
  },
  { anonymous: false, inputs: [{ indexed: true, internalType: 'uint256', name: 'itemId', type: 'uint256' }], name: 'SaleEnded', type: 'event' },
  { anonymous: false, inputs: [{ indexed: false, internalType: 'address', name: 'signer', type: 'address' }], name: 'SignerUpdated', type: 'event' },
  { anonymous: false, inputs: [{ indexed: false, internalType: 'address', name: 'treasury', type: 'address' }], name: 'TreasuryUpdated', type: 'event' },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'itemId', type: 'uint256' },
      { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'seller', type: 'address' },
      { indexed: false, internalType: 'address', name: 'owner', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'price', type: 'uint256' },
      { indexed: false, internalType: 'bool', name: 'sold', type: 'bool' },
      { indexed: false, internalType: 'bool', name: 'isActive', type: 'bool' },
    ],
    name: 'saleCreated',
    type: 'event',
  },
  { stateMutability: 'payable', type: 'fallback' },
  {
    inputs: [
      { internalType: 'uint256', name: 'itemId', type: 'uint256' },
      { internalType: 'string', name: 'nonce', type: 'string' },
      { internalType: 'bytes', name: 'signature', type: 'bytes' },
    ],
    name: 'buyItem',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { internalType: 'uint256', name: 'price', type: 'uint256' },
      { internalType: 'bytes', name: 'signature', type: 'bytes' },
      { internalType: 'string', name: 'nonce', type: 'string' },
    ],
    name: 'createSale',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { inputs: [], name: 'currency', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function' },
  {
    inputs: [
      { internalType: 'uint256', name: 'itemId', type: 'uint256' },
      { internalType: 'string', name: 'nonce', type: 'string' },
      { internalType: 'bytes', name: 'signature', type: 'bytes' },
      { internalType: 'uint256', name: 'newPrice', type: 'uint256' },
    ],
    name: 'editListing',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { inputs: [{ internalType: 'uint256', name: 'itemId', type: 'uint256' }], name: 'endSale', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [],
    name: 'fetchItemsCreated',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'itemId', type: 'uint256' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'address', name: 'seller', type: 'address' },
          { internalType: 'address', name: 'owner', type: 'address' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'bool', name: 'sold', type: 'bool' },
          { internalType: 'bool', name: 'isActive', type: 'bool' },
        ],
        internalType: 'struct BukMarket.MarketItem[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'fetchMarketItems',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'itemId', type: 'uint256' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'address', name: 'seller', type: 'address' },
          { internalType: 'address', name: 'owner', type: 'address' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'bool', name: 'sold', type: 'bool' },
          { internalType: 'bool', name: 'isActive', type: 'bool' },
        ],
        internalType: 'struct BukMarket.MarketItem[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'fetchMyNFTs',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'itemId', type: 'uint256' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'address', name: 'seller', type: 'address' },
          { internalType: 'address', name: 'owner', type: 'address' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'bool', name: 'sold', type: 'bool' },
          { internalType: 'bool', name: 'isActive', type: 'bool' },
        ],
        internalType: 'struct BukMarket.MarketItem[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [{ internalType: 'uint256', name: 'token', type: 'uint256' }], name: 'getTokenToItem', outputs: [{ internalType: 'uint256', name: 'itemId', type: 'uint256' }], stateMutability: 'view', type: 'function' },
  {
    inputs: [
      { internalType: 'address', name: 'user', type: 'address' },
      { internalType: 'string', name: 'nonce', type: 'string' },
    ],
    name: 'hashCheckInTransaction',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'user', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { internalType: 'uint256', name: 'price', type: 'uint256' },
      { internalType: 'string', name: 'nonce', type: 'string' },
    ],
    name: 'hashSaleTransaction',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'pure',
    type: 'function',
  },
  { inputs: [], name: 'hotelRoyalty', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function' },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'idToMarketItem',
    outputs: [
      { internalType: 'uint256', name: 'itemId', type: 'uint256' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { internalType: 'address', name: 'seller', type: 'address' },
      { internalType: 'address', name: 'owner', type: 'address' },
      { internalType: 'uint256', name: 'price', type: 'uint256' },
      { internalType: 'bool', name: 'sold', type: 'bool' },
      { internalType: 'bool', name: 'isActive', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'hash', type: 'bytes32' },
      { internalType: 'bytes', name: 'signature', type: 'bytes' },
    ],
    name: 'matchSigner',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'minterRoyalty', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function' },
  { inputs: [], name: 'mintingContract', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function' },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'bytes', name: '', type: 'bytes' },
    ],
    name: 'onERC1155BatchReceived',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'bytes', name: '', type: 'bytes' },
    ],
    name: 'onERC1155Received',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { inputs: [], name: 'owner', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function' },
  { inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  { inputs: [{ internalType: 'address', name: '_signer', type: 'address' }], name: 'setSigner', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  { inputs: [{ internalType: 'address', name: '_treasury', type: 'address' }], name: 'setTreasury', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  { inputs: [], name: 'signer', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function' },
  { inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }], name: 'transferOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  { inputs: [], name: 'treasury', outputs: [{ internalType: 'address', name: '', type: 'address' }], stateMutability: 'view', type: 'function' },
  { inputs: [], name: 'treasuryRoyalty', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function' },
  { inputs: [{ internalType: 'address', name: '_currency', type: 'address' }], name: 'updateCurrency', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [
      { internalType: 'uint256', name: '_platformFee', type: 'uint256' },
      { internalType: 'uint256', name: '_hotelFee', type: 'uint256' },
      { internalType: 'uint256', name: '_minterFee', type: 'uint256' },
    ],
    name: 'updateRoyalty',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { inputs: [{ internalType: 'string', name: '', type: 'string' }], name: 'usedNonce', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function' },
  { stateMutability: 'payable', type: 'receive' },
]

export const USDC_ABI = [
  	{
  		"inputs": [],
  		"stateMutability": "nonpayable",
  		"type": "constructor"
  	},
  	{
  		"anonymous": false,
		"inputs": [
  			{
  				"indexed": true,
  				"internalType": "address",
  				"name": "owner",
 				"type": "address"
  			},
  			{
  				"indexed": true,
  				"internalType": "address",
  				"name": "spender",
  				"type": "address"
  			},
  			{
  				"indexed": false,
  				"internalType": "uint256",
  				"name": "value",
  				"type": "uint256"
  			}
  		],
  		"name": "Approval",
  		"type": "event"
  	},
  	{
  		"inputs": [
  			{
  				"internalType": "address",
  				"name": "spender",
  				"type": "address"
  			},
  			{
  				"internalType": "uint256",
  				"name": "amount",
  				"type": "uint256"
  			}
  		],
  		"name": "approve",
  		"outputs": [
  			{
  				"internalType": "bool",
  				"name": "",
  				"type": "bool"
  			}
  		],
  		"stateMutability": "nonpayable",
  		"type": "function"
  	},
  	{
  		"inputs": [
  			{
  				"internalType": "address",
  				"name": "spender",
  				"type": "address"
  			},
  			{
  				"internalType": "uint256",
  				"name": "subtractedValue",
  				"type": "uint256"
  			}
  		],
  		"name": "decreaseAllowance",
  		"outputs": [
  			{
  				"internalType": "bool",
  				"name": "",
  				"type": "bool"
  			}
  		],
  		"stateMutability": "nonpayable",
  		"type": "function"
  	},
  	{
  		"inputs": [
  			{
  				"internalType": "address",
  				"name": "spender",
  				"type": "address"
  			},
  			{
  				"internalType": "uint256",
  				"name": "addedValue",
  				"type": "uint256"
  			}
  		],
  		"name": "increaseAllowance",
  		"outputs": [
  			{
  				"internalType": "bool",
  				"name": "",
  				"type": "bool"
  			}
  		],
  		"stateMutability": "nonpayable",
  		"type": "function"
  	},
  	{
  		"anonymous": false,
  		"inputs": [
  			{
  				"indexed": true,
  				"internalType": "address",
  				"name": "previousOwner",
  				"type": "address"
  			},
  			{
  				"indexed": true,
  				"internalType": "address",
  				"name": "newOwner",
  				"type": "address"
  			}
  		],
  		"name": "OwnershipTransferred",
  		"type": "event"
  	},
  	{
  		"inputs": [],
  		"name": "renounceOwnership",
  		"outputs": [],
  		"stateMutability": "nonpayable",
  		"type": "function"
  	},
  	{
  		"inputs": [
  			{
  				"internalType": "address",
  				"name": "to",
  				"type": "address"
  			},
  			{
  				"internalType": "uint256",
  				"name": "amount",
  				"type": "uint256"
  			}
  		],
  		"name": "transfer",
  		"outputs": [
  			{
  				"internalType": "bool",
  				"name": "",
  				"type": "bool"
  			}
  		],
  		"stateMutability": "nonpayable",
  		"type": "function"
  	},
  	{
  		"anonymous": false,
  		"inputs": [
  			{
  				"indexed": true,
  				"internalType": "address",
  				"name": "from",
  				"type": "address"
  			},
  			{
  				"indexed": true,
  				"internalType": "address",
  				"name": "to",
  				"type": "address"
  			},
  			{
  				"indexed": false,
  				"internalType": "uint256",
  				"name": "value",
  				"type": "uint256"
  			}
  		],
  		"name": "Transfer",
  		"type": "event"
  	},
  	{
  		"inputs": [
  			{
  				"internalType": "address",
  				"name": "from",
  				"type": "address"
  			},
  			{
  				"internalType": "address",
  				"name": "to",
  				"type": "address"
  			},
  			{
  				"internalType": "uint256",
  				"name": "amount",
  				"type": "uint256"
  			}
  		],
  		"name": "transferFrom",
  		"outputs": [
  			{
  				"internalType": "bool",
  				"name": "",
  				"type": "bool"
  			}
  		],
  		"stateMutability": "nonpayable",
  		"type": "function"
  	},
  	{
  		"inputs": [
  			{
  				"internalType": "address",
  				"name": "newOwner",
  				"type": "address"
  			}
  		],
  		"name": "transferOwnership",
  		"outputs": [],
  		"stateMutability": "nonpayable",
  		"type": "function"
  	},
  	{
  		"inputs": [
  			{
  				"internalType": "address",
  				"name": "owner",
  				"type": "address"
  			},
  			{
  				"internalType": "address",
  				"name": "spender",
  				"type": "address"
  			}
  		],
  		"name": "allowance",
  		"outputs": [
  			{
  				"internalType": "uint256",
  				"name": "",
  				"type": "uint256"
  			}
  		],
  		"stateMutability": "view",
  		"type": "function"
  	},
  	{
  		"inputs": [
  			{
  				"internalType": "address",
  				"name": "account",
  				"type": "address"
  			}
  		],
  		"name": "balanceOf",
  		"outputs": [
  			{
  				"internalType": "uint256",
  				"name": "",
  				"type": "uint256"
  			}
  		],
  		"stateMutability": "view",
  		"type": "function"
  	},
  	{
  		"inputs": [],
  		"name": "decimals",
  		"outputs": [
  			{
  				"internalType": "uint8",
  				"name": "",
  				"type": "uint8"
  			}
  		],
  		"stateMutability": "view",
  		"type": "function"
  	},
  	{
  		"inputs": [],
  		"name": "name",
  		"outputs": [
  			{
  				"internalType": "string",
  				"name": "",
  				"type": "string"
  			}
  		],
  		"stateMutability": "view",
  		"type": "function"
  	},
  	{
  		"inputs": [],
  		"name": "owner",
  		"outputs": [
  			{
  				"internalType": "address",
  				"name": "",
  				"type": "address"
  			}
  		],
  		"stateMutability": "view",
  		"type": "function"
  	},
  	{
  		"inputs": [],
  		"name": "symbol",
  		"outputs": [
  			{
  				"internalType": "string",
  				"name": "",
  				"type": "string"
  			}
  		],
  		"stateMutability": "view",
  		"type": "function"
  	},
  	{
  		"inputs": [],
  		"name": "totalSupply",
  		"outputs": [
  			{
  				"internalType": "uint256",
  				"name": "",
  				"type": "uint256"
  			}
  		],
  		"stateMutability": "view",
  		"type": "function"
  	}
  ]