import { useState } from "react"
import Checkbox from "@mui/material/Checkbox"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked"
import { FormControlLabel } from "@mui/material"
import Button from '@mui/material/Button';
import {registerHotel} from '../../services/web3Services'

const RegistrationStepThree = ({ stepData, setStepData, setStep, createHotel }) => {
    const [validationMethond, setValidationMethod] = useState("deposit")

    const handleSubmit = () => {
        createHotel()
    }

    return (
            <div className="flex flex-col gap-4 max-w-[1360px] w-full mt-8">
                <div className=" p-8 py-12 bg-white shadow-xl shadow-[#d8e3f1a6] w-full">
                    <div className="flex justify-center text-lg mobile:text-4xl font-bold text-[#130A44]">
                        Choose your hotel validation method
                    </div>
                    <div className="text-[#7E7E7E] text-center">
                        To ensure our platform is only used by legitimate
                        hotels, you will need to validate your account. Choose
                        one of the options below:
                    </div>
                    <div className="flex mobile:flex-row flex-col justify-between items-center gap-6 my-8">
                        <div className="w-full flex items-start">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="deposit"
                                        icon={<RadioButtonUncheckedIcon />}
                                        checkedIcon={
                                            <RadioButtonCheckedIcon className="text-[#7461FF]" />
                                        }
                                        checked={
                                            validationMethond === "deposit"
                                                ? true
                                                : false
                                        }
                                        onChange={
                                            validationMethond === "deposit"
                                                ? () => ""
                                                : (e) =>
                                                      setValidationMethod(
                                                          e.target.name
                                                      )
                                        }
                                    />
                                }
                            />
                            <div className="flex flex-col gap-1">
                                <div className="text-2xl font-bold text-[#130A44]">
                                    Security Deposit
                                </div>
                                <div className="text-[#7E7E7E]">
                                    This requires staking some funds. In case
                                    customers report issues with your
                                    reservations, Buk will charge you a penalty
                                    fee which will be deduced from this security
                                    deposit.
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex items-start">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="other"
                                        icon={<RadioButtonUncheckedIcon />}
                                        checkedIcon={
                                            <RadioButtonCheckedIcon className="text-[#7461FF]" />
                                        }
                                        checked={
                                            validationMethond === "other"
                                                ? true
                                                : false
                                        }
                                        onChange={
                                            validationMethond === "other"
                                                ? () => ""
                                                : (e) =>
                                                      setValidationMethod(
                                                          e.target.name
                                                      )
                                        }
                                    />
                                }
                            />
                            <div className="flex flex-col gap-1">
                                <div className="text-2xl font-bold text-[#130A44]">
                                    Other validation method (TBC)
                                </div>
                                <div className="text-[#7E7E7E]">
                                    This is another possible validation method
                                    with more limitations, in case hotels do not
                                    want to stake funds... still to be defined.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center items-center gap-4 my-8">
                    <button
                        onClick={() => setStep(1)}
                        className="border-2 border-[#292931] px-8 py-2 text-[#292931] rounded-full font-medium text-lg"
                    >
                        Back
                    </button>
                    
                    <button 
                        onClick={() => handleSubmit()}
                        className="border-2 border-[#2F69FF] bg-[#2F69FF] hover:hover:bg-blue-700 hover:border-[#2F69FF95] px-8 py-2 text-[#fff] rounded-full font-medium text-lg"
                    >
                        Create Inventory
                    </button>
                </div>
            </div>
    )
}

export default RegistrationStepThree
