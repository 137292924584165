const RoomIcon = ({ active }) => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.5 14C8.88071 14 10 12.8807 10 11.5C10 10.1193 8.88071 9 7.5 9C6.11929 9 5 10.1193 5 11.5C5 12.8807 6.11929 14 7.5 14Z"
                fill={active ? `url(#paint0_linear_721_19488)` : "#292931"}
            />
            <path
                d="M17.205 7H12C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11 8V15H4V6H2V20H4V17H20V20H22V11.795C21.9987 10.5237 21.4931 9.30483 20.5941 8.40588C19.6952 7.50693 18.4763 7.00132 17.205 7ZM13 15V9H17.205C17.946 9.00079 18.6565 9.29552 19.1805 9.81951C19.7045 10.3435 19.9992 11.054 20 11.795V15H13Z"
                fill={active ? `url(#paint0_linear_721_19488)` : "#292931"}
            />
            <defs>
                <linearGradient
                    id="paint0_linear_721_19488"
                    x1="21.7264"
                    y1="21"
                    x2="0.191882"
                    y2="20.0883"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#2F69FF" />
                    <stop offset="1" stop-color="#C856FF" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default RoomIcon
