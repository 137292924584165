import loc from "../../images/loc.svg";
import wifi from "../../images/am-wifi.svg";
import pool from "../../images/am-pool.svg";
import airConditioning from "../../images/am-air.svg";
import spa from "../../images/am-spa.svg";
import parking from "../../images/am-parking.svg";
import restaurant from "../../images/am-restaurant.svg";
import bed from "../../images/am-bed.svg";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import goodtoknowImg from "../../images/goodtoknow.svg";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { SaleServices } from "../../services/supplier";
import { buyItem } from "../../services/web3Services";
import {
  HotelUserServices,
  ValidateServices,
  BuyServices,
  setAuthToken,
  getUserDetails,
} from "../../services/supplier";
import { toast } from "react-toastify";
import PageLoader from "../../components/PageLoader/PageLoader";
import {
  getCompleteDate,
  decreaseDays,
  countDays,
  getDateFormat,
} from "../../utils/date";
import { bookRooms, convertPriceToMatic } from "../../services/web3Services";
const amenties = {
  hasSwimmingPool: {
    name: "Pool",
    img: pool,
  },
  hasAirCondition: {
    name: "Air Conditioning",
    img: airConditioning,
  },
  hasFreeWifi: {
    name: "Free Wifi",
    img: wifi,
  },
  hasSpa: {
    name: "Spa",
    img: spa,
  },
  hasKingBed: {
    name: "King Bed",
    img: bed,
  },
  hasFreeParking: {
    name: "Parking",
    img: parking,
  },
  hasRestaurant: {
    name: "Restaurant",
    img: restaurant,
  },
  hasHalfBoard: {
    name: "Half Board",
    img: pool,
  },
  hasFullBoard: {
    name: "Full Board",
    img: pool,
  },
};

const SaleRoom = () => {
  let navigate = useNavigate();
  const [attributes, setAttributes] = useState([
    "hasSwimmingPool",
    "hasAirCondition",
    "hasRestaurant",
    "hasFullBoard",
  ]);
  const [roomData, setRoomData] = useState("");
  const [loading, setLoading] = useState(true);
  const [price, setPrice] = useState(0);
  const { roomId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const fromR = queryParams.get("fromR");
  const toDate = queryParams.get("toDate");
  const hotelId = queryParams.get("hotelId");
  const [images, setImages] = useState("");
  const [page, setPage] = useState(1);
  const [roomSales, setRoomSales] = useState([]);
  const [meta, setMetadata] = useState([]);
  const getRoom = async () => {
    try {
      setLoading(true);
      const getRooms = await SaleServices.getRoomOnSaleDetails(roomId);
      setRoomData(getRooms.data.roomDetails[0]);
      setPrice(getRooms.data.roomDetails[0].priceOnSale);
      setImages(getRooms.data.roomDetails[0].room_id.images);
      setRoomSales(getRooms.data);
      setMetadata(getRooms.metadata);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRoom();
  }, []);

  return (
    <div className="min-h-[400px]">
      {loading ? (
        <PageLoader />
      ) : (
        <div className="app  min-h-[400px] !py-4">
          <div className="flex flex-col pb-6">
            <span className="font-bold text-3xl text-center">
              Booking recap
            </span>
            <span className="text-center text-[#646465] font-medium text-sm">
              You’re one step away from booking your room
            </span>
          </div>
          <div className="flex mobile:flex-row flex-col-reverse gap-6">
            <div>
              <div className="flex flex-col mobile:flex-row w-full gap-6 bg-white shadow">
                <div className="parent-grid h-[350px] mobile:w-[450px] mobile:min-w-[450px]">
                  {images.length > 5
                    ? images
                        ?.slice(0, 5)
                        .map((image, index) => (
                          <img
                            src={image}
                            key={index + "roomImages"}
                            alt={index + " roomImages"}
                            className={`div${index} w-full h-full object-cover`}
                          />
                        ))
                    : images?.map((image, index) => (
                        <img
                          src={image}
                          key={index + "roomImages"}
                          alt={index + " roomImages"}
                          className={`div${index} w-full h-full object-cover`}
                        />
                      ))}
                </div>
                <div className="flex flex-col my-2 mobile:my-6 px-4 mobile:p-0">
                  <p className="fontdesc text-2xl font-bold text-gray-900 hotelhead h-auto ">
                    {roomData?.room_id.roomType}
                  </p>
                  <div className="flex  mt-2">
                    <img src={loc} className="h-4" alt="location" />
                    <label className=" text-sm font-normal text-gray-600 pl-2 pr-4 textsm">
                      {roomData?.hotel_id.address}, {roomData?.hotel_id.country}
                    </label>
                  </div>
                  <div className="mt-4 whitespace-pre-wrap">
                    {roomData?.hotel_id.description}
                  </div>
                </div>
              </div>
              <div className="shadow-lg mt-4 flex flex-col gap-4 p-8 hel bg-white">
                <div className="flex items-center gap-2 text-2xl font-bold text-[#130A44]">
                  <img src={goodtoknowImg} alt="" />
                  <div>Good to know</div>
                </div>
                <div className="flex gap-2">
                  <div className="bg-[#2f69ff33] rounded-full w-6 h-6 flex justify-center items-center">
                    <ChevronRightRoundedIcon
                      fontSize="small"
                      sx={{ color: "#2F69FF" }}
                    />
                  </div>
                  <div>
                    To book you will have to confirm the transaction on your
                    wallet and your account will be charged immediately
                  </div>
                </div>
                <div className="flex gap-2">
                  <div className="bg-[#2f69ff33] rounded-full w-6 h-6 flex justify-center items-center">
                    <ChevronRightRoundedIcon
                      fontSize="small"
                      sx={{ color: "#2F69FF" }}
                    />
                  </div>
                  <div>
                    The reservation will be created as an NFT on the Polygon
                    blockchain. You will become the official owner of this
                    booking and will be able to cancel or trade it until 20th
                    October 2022 or 48 hours before the check-in date, whichever
                    is earlier.
                  </div>
                </div>
                <div className="flex gap-2">
                  <div className="bg-[#2f69ff33] rounded-full w-6 h-6 flex justify-center items-center">
                    <ChevronRightRoundedIcon
                      fontSize="small"
                      sx={{ color: "#2F69FF" }}
                    />
                  </div>
                  <div>
                    You will have to show your NFT at the hotel reception to
                    prove you’re the owner
                  </div>
                </div>
                <div className="flex gap-2">
                  <div className="bg-[#2f69ff33] rounded-full w-6 h-6 flex justify-center items-center">
                    <ChevronRightRoundedIcon
                      fontSize="small"
                      sx={{ color: "#2F69FF" }}
                    />
                  </div>
                  <div>
                    Before showing up at the hotel you can enter your details in
                    our online check-in. Please note the online check-in is
                    final and will not allow to cancel or trade the NFT
                    afterwards
                  </div>
                </div>
              </div>
            </div>
            <div className="gradient-border h-fit flex flex-col gap-4 min-w-[300px] mobile:min-w-[380px] bg-white p-4 shadow">
              <div className="text-lg text-[#130A44] font-medium">
                Your booking details
              </div>
              <div className="flex justify-between">
                <div className="flex flex-col gap-1">
                  <span className="text-[#7E7E7E] text-sm">Check in</span>
                  <span className="text-[#292931] font-medium">
                    {getCompleteDate(fromR)}
                  </span>
                  <span className="text-xs text-[#7E7E7E]">12:00 - 19:00</span>
                </div>
                <div className="min-h-full w-[1px] bg-[#E7ECF2]"></div>
                <div className="flex flex-col gap-1">
                  <span className="text-[#7E7E7E] text-sm">Check out</span>
                  <span className="text-[#292931] font-medium">
                    {getCompleteDate(toDate)}
                  </span>
                  <span className="text-xs text-[#7E7E7E]">12:00 - 19:00</span>
                </div>
              </div>
              <div className="flex flex-col gap-3 border-t-2 border-[#E7ECF2]">
                <div className="text-lg text-[#130A44] pt-4 font-medium">
                  Your price summary
                </div>
                <div className="flex justify-between">
                  <span className="font-medium">Room</span>
                  <span className="font-medium">
                    {/* ${roomData?.roomPricing.roomPricing[0].price} */}
                    roomPricing
                  </span>
                </div>
              </div>
              <div className="flex justify-between pt-4 text-[#130A44] gap-3 border-t-2 border-[#E7ECF2]">
                <div className="flex flex-col">
                  <span className="text-xl font-bold">Price</span>
                  <span className="font-medium">
                    for 2 guests and {countDays(toDate, fromR)} nights
                  </span>
                </div>
                <div className="text-4xl font-bold">${price}</div>
              </div>
              {
                <em className="flex justify-center items-center border-t-2 border-[#E7ECF2] text-sm py-8">
                  Cancellation or trading allowed until {decreaseDays(fromR)}
                </em>
              }
              <div className="flex justify-center">
                <button
                  // onClick={() => buyThisItem()}
                  className="bg-[#2F69FF] text-white rounded-full px-12 py-3 text-xl"
                >
                  Pay Now
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SaleRoom;
