import React from "react";
import {getDate} from "../../utils/date"
import Sale from "../../images/Sale.svg"
const TransactionData = ({transactionData}) => {
    return (
        <div className="w-full">
            <table className="mobile:flex flex-col gap-4 !w-full min-w-fit  overflow-x-scroll">
                <thead className="w-full bg-[#E7ECF2] p-3 border-t-2 border-[#E7ECF2]">
                    <tr className="flex gap-4 w-full">
                        <th className="text-left text-[#7E7E7E] w-full">
                            Event
                        </th>
                        <th className="text-left text-[#7E7E7E] w-full">
                            Price
                        </th>
                        <th className="text-left text-[#7E7E7E] w-full">
                            From
                        </th>
                        <th className="text-left text-[#7E7E7E] w-full">To</th>
                        <th className="text-left text-[#7E7E7E] w-full">
                            Date
                        </th>
                    </tr>
                </thead>
                <tbody className="w-full p-3 bg-white shadow-lg shadow-[#D8E3F1A6]">
                    { transactionData.length > 0 &&
                        transactionData.map((data) => (
                            <tr className="flex gap-4 py-4 border-b-2 border-[#E7ECF2]">
                                <td className="w-full  mobile:text-[16px] text-xs flex justify-start items-center font-bold">{<img src={Sale} className="mr-1" alt="Sale"/>}{data.eventType}</td>
                                <td className="w-full  mobile:text-[16px] text-xs flex justify-start items-center">
                                    ${data.price.toFixed(2)}
                                </td>
                                <td className="w-full text-[#2F69FF] flex justify-start items-center">
                                    {data && data?.previosOwner ? (
                                        <span className="bg-[#2F69FF1A] px-4 flex py-1 rounded-full  mobile:text-[16px] text-xs justify-start items-center">
                                            {data?.previosOwner.substring(
                                                0,
                                                5
                                            )}
                                            ...
                                            {data?.previosOwner.substring(
                                                data.previosOwner.length - 3,
                                                data.previosOwner.length
                                            )}
                                        </span>
                                    ) : (
                                        <span className="bg-[#2F69FF1A] px-4 py-1 rounded-full mobile:text-[16px] text-xs flex justify-start items-center">
                                            Buk
                                        </span>
                                    )}
                                </td>
                                <td className="w-full text-[#2F69FF]">
                                    <span className="bg-[#2F69FF1A] px-4 py-1 rounded-full mobile:text-[16px] text-xs">
                                        {data.currentOwner.substring(0, 5)}
                                        ...
                                        {data.currentOwner.substring(
                                            data.currentOwner.length - 3,
                                            data.currentOwner.length
                                        )}
                                    </span>
                                </td>
                                <td className="w-full font-bold flex mobile:text-[16px] text-xs justify-start items-center">
                                    {getDate(data.date)}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
};

export default TransactionData;
