import "./loader.css";

const Loader = ({ showTransactionMessage }) => {
  return (
    <div
      className="flex flex-col items-center justify-center w-[100%]"
      style={{ height: "100vh" }}
    >
      <div className="loader"></div>

      {showTransactionMessage ? (
        <div className="font-bold text-lg">
          Your transaction is being processed...
        </div>
      ) : (
        <div className="font-bold text-lg">Please Wait...</div>
      )}
    </div>
  );
};

export default Loader;
