import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {useNavigate} from 'react-router-dom';
import location from "../../images/location.svg";
import calender from "../../images/calender.svg";
import "./Moreview.css";
import PageLoader from "../../components/PageLoader/PageLoader";
import { getQuery } from "../../utils/queryString";
import GMap from "../../services/GoogleMap";
import {
    HotelUserServices,
    setAuthToken,
    getUserDetails,
} from "../../services/supplier";
import { getDate } from "../../utils/date";
import { SimpleDialog } from "../HotelPage/HotelPage";
import HotelAmenities from "../../components/Amenities/HotelAmenities";
import cancel from "../../images/cancel.svg";
import bookings from "../../images/bookings.svg";
import Description from "../../components/Description/Description"

const mapStyle = {
    minHeight: "250px",
    height: "100%",
};
const Moreview = () => {
    let navigate = useNavigate();
    const bookingId = getQuery("booking");
    const [hotelData, setHotelData] = useState("");
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [viewMore,setViewMore] = useState(false);
    const [coordinates, setCordinates] = useState({
        lat: parseFloat(44),
        lng: parseFloat(43),
    });
    const getHotel = async () => {
        try {
            setAuthToken(getUserDetails()?.token);
            setLoading(true);
            const hotel = await HotelUserServices.getBookingDetails(bookingId);
            setHotelData(hotel.data?.detail);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };
    useEffect(() => {
        getHotel();
    }, []);
    return (
        <div className="app">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Check-in Details</title>
            </Helmet>
            <div
                className="cursor-pointer"
                onClick={() => navigate("/manageBooking")}
            >
                <img src={bookings} className="pl-2" alt="" />
            </div>
            <p className="text-center sell pt-4 ">Check-in</p>
            <p className="text-center font-bold text-gray-500 text-sm mb-2">
                Everything you need to check-in at the hotel counter is on this
                page
            </p>

            {loading ? (
                <PageLoader />
            ) : (
                <div className="part1 shadow-lg mobile:w-[90vw] mx-auto pb-2 bg-white">
                    <SimpleDialog
                        open={open}
                        onClose={handleClose}
                        hotelImages={hotelData && hotelData?.roomId.images}
                    />
                    <div className="grid gap-4 grid-cols-2 mobile:grid-cols-3">
                        <div className="flex flex-col  mx-auto col-span-2 mobile:col-span-1">
                            <div className="p-2 mobile:p-0">
                                <img
                                    className="w-full h-[240px] object-cover mx-auto m-0"
                                    src={hotelData?.roomId.images[0]}
                                    alt="location2"
                                    onClick={handleClickOpen}
                                />
                            </div>
                            <div className="grid grid-cols-4 gap-2 p-2 mobile:p-0 mobile:mt-2">
                                <img
                                    src={hotelData?.roomId.images[1]}
                                    className="w-full object-cover h-[100px]"
                                    alt="location3"
                                    onClick={handleClickOpen}
                                />
                                <img
                                    src={hotelData?.roomId.images[2]}
                                    className="w-full object-cover h-[100px]"
                                    alt="location4"
                                    onClick={handleClickOpen}
                                />
                                {/* <img
                                    src={hotelData?.roomId.images[3]}
                                    className="w-full object-cover h-[100px]"
                                    alt="location5"
                                    onClick={handleClickOpen}
                                /> */}

                                <div
                                    className="h-full w-full relative"
                                    onClick={handleClickOpen}
                                >
                                    {/* <img
                                        src={hotelData?.roomId.images[3]}
                                        className="w-full object-cover h-[100px]"
                                        alt="location5"
                                    /> */}
                                    {hotelData?.roomId.images.length > 3 && (
                                        <p className="absolute bg-stone-700/50 h-full w-full flex justify-center items-center text-white cursor-pointer top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                            +
                                            {hotelData?.roomId.images.length -
                                                3}{" "}
                                            more
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col my-2 mobile:my-6 pl-4 mobile:p-0  ">
                            <p className="fontdesc text-2xl flex items-start justify-between font-bold text-gray-900 hotelhead h-auto ">
                                {hotelData?.hotelId.name} (
                                {hotelData?.roomId.roomType})
                                <div className="flex flex-col items-center justify-center">
                                    <p className="text-right text-xl mobile:text-2xl">
                                        $
                                        {Number(hotelData?.buyPrice).toFixed(2)}{" "}
                                    </p>
                                </div>
                            </p>
                            <div className="flex  mt-2">
                                <img
                                    src={location}
                                    className="h-4"
                                    alt="location"
                                />
                                <label className=" text-sm font-normal text-gray-600 pl-2 textsm">
                                    {hotelData?.hotelId.city &&
                                        hotelData?.hotelId.city + ","}{" "}
                                    {hotelData?.hotelId.country}
                                </label>
                            </div>
                            <div className="flex  mt-10">
                                <img
                                    src={calender}
                                    className="h-4"
                                    alt="location1"
                                />
                                <label className="text-sm font-normal text-gray-600 pl-2 pr-4 textsm">
                                    {getDate(hotelData?.fromDate)} -{" "}
                                    {getDate(hotelData?.toDate)}
                                </label>
                            </div>
                            <img
                                src={cancel}
                                width={117}
                                height={21}
                                className="mt-2"
                            />
                            <div className="flex mt-4 place-items-center">
                                <HotelAmenities
                                    attributes={hotelData?.hotelId.attributes}
                                />
                            </div>
                        </div>
                        {/* <div className="flex flex-col items-start p-4"> */}
                            {/*QR Code*/}
                            {/* <img
                                src={
                                    hotelData?.nftRef.hasOwnProperty("qr") &&
                                    hotelData?.nftRef.qr
                                }
                                alt="Qr-Code-Image"
                                className="w-3/4 self-center"
                            />
                            <p className="font-medium text-xs pl-4">
                                Show this QR code at the hotel counter to share
                                your reservation details and access your room.
                            </p> */}
                        {/* </div> */}
                    </div>
                    {showMore && (
                        <div>
                            <div className="flex px-4 content-center justify-between flex-wrap">
                                <div className="flex items-center xl:mt-0 mt-4 mobile:w-1/2 mr-14 flex-wrap">
                                    <p className="fontdesc text-2xl font-bold  mr-2  text-gray-900 hotelhead ">
                                        {hotelData?.hotelId.name}
                                    </p>
                                    <span className="text-gray-500 rounded-full border-2  border-gray-500 p-2 text-sm">
                                        {hotelData?.hotelId.starRatings} Star
                                        Hotel
                                    </span>
                                </div>
                                {/* <div className="flex items-center ml-3">
                            <img src={star5} alt="location9" />

                            {!hotelData?.hotelId.guestRatings ? (
                                <p className="text-sm font-normal ml-3">
                                    No user ratings...
                                </p>
                            ) : (
                                <p className="fontdesc text-sm font-normal mr-3 text-gray-800 ml-4 fontsmin">
                                    Users Reviews
                                </p>
                            )}
                        </div> */}
                            </div>
                            <div className="flex flex-wrap px-4 items-center">
                                <div className="mobile:basis-4/6">
                                    <Description description={hotelData?.hotelId.description} viewMore={viewMore} setViewMore={setViewMore}>
                                    </Description>

                                    <p className="fontdesc text-lg  font-semibold text-gray-900 my-4">
                                        Amenities
                                    </p>
                                    <HotelAmenities
                                        attributes={
                                            hotelData?.hotelId.attributes
                                        }
                                    />
                                    <HotelAmenities
                                        attributes={
                                            hotelData?.roomId.attributes
                                        }
                                        roomArea={hotelData?.roomId.roomArea}
                                        roomUnit={
                                            hotelData?.roomId.roomAreaUnit
                                        }
                                    />
                                </div>
                                <div className="flex justify-end p-4 mobile:basis-2/6">
                                    <GMap
                                        coordinates={{
                                            lat:
                                                parseFloat(
                                                    hotelData?.hotelId
                                                        .locationCordinates
                                                        .latitude
                                                ) || 22,
                                            lng:
                                                parseFloat(
                                                    hotelData?.hotelId
                                                        .locationCordinates
                                                        .longitude
                                                ) || 21,
                                        }}
                                        mapStyle={mapStyle}
                                    />
                                </div>
                            </div>
                            {/* <div className="flex px-4 text-cyan-600 font-medium ">
                        <span className=" mx-auto text-sm my-6">
                            View Less
                        </span>
                    </div> */}
                            <div className="w-full relative flex pb-6 justify-center">
                                <button
                                    className="buybtn box-border flex items-center w-fit px-8 mx-4 justify-center overflow-hidden hover:bg-gray-100 hover:border-1 hover:text-gray-900 text-white font-bold py-1 rounded-full  buttonClr"
                                    height={60}
                                >
                                    <p className=" flex place-items-center place-content-center fontdesc text-lg font-medium p-2 textsell1">
                                        Buy This Room
                                        <svg
                                            className="ml-4 hover:fill-neutral-900"
                                            width="20"
                                            height="12"
                                            viewBox="0 0 20 12"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M19.7719 6.48736C20.0648 6.19447 20.0648 5.71959 19.7719 5.4267L14.9989 0.65373C14.706 0.360837 14.2311 0.360837 13.9382 0.65373C13.6453 0.946624 13.6453 1.4215 13.9382 1.71439L18.1809 5.95703L13.9382 10.1997C13.6453 10.4926 13.6453 10.9674 13.9382 11.2603C14.2311 11.5532 14.706 11.5532 14.9989 11.2603L19.7719 6.48736ZM0 6.70703H19.2415V5.20703H0V6.70703Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </p>
                                </button>
                            </div>
                        </div>
                    )}
                    {!showMore ? (
                        <div
                            onClick={() => setShowMore(true)}
                            className="text-center font-bold cursor-pointer mb-4"
                        >
                            Show More
                        </div>
                    ) : (
                        <div
                            onClick={() => setShowMore(false)}
                            className="text-center font-bold cursor-pointer mb-4"
                        >
                            Collapse
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Moreview;
