import React from 'react'

const HowItWorks = () => {

  React.useEffect(() => {
    window.scrollTo(0, 0)
  },[])
  return (
    <div className='min-h-[400px] app'>HowItWorks</div>
  )
}

export default HowItWorks