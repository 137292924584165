import axios from "axios";
import { backendUrl, geoCode } from "../config/config";

const instance = axios.create({ baseURL: backendUrl || process.env.REACT_APP_API_URL });

export const getUserDetails = () => {
  const userDetails = sessionStorage.getItem("userDetails");
  if (userDetails) return JSON.parse(userDetails);
};

export const getUserId = () => {
  const userDetails = sessionStorage.getItem("userDetails");
  if (userDetails) return JSON.parse(userDetails).userId;
};

export const getUseraddress = () => {
  const userDetails = sessionStorage.getItem("userDetails");
  if (userDetails) return JSON.parse(userDetails).address;
};

export const storeInformation = (userData) => {
  sessionStorage.setItem("userDetails", JSON.stringify(userData));
};

export const setAuthToken = (token) => {
  if (token) instance.defaults.headers.common["Authorization"] = token;
  else delete instance.defaults.headers.common["Authorization"];
};

const responseBody = (response) => response;

const requests = {
  get: (url, body, options) =>
    instance.get(url, body, options).then(responseBody),

  post: (url, body, options) =>
    instance.post(url, body, options).then(responseBody),

  put: (url, body, options) =>
    instance.put(url, body, options).then(responseBody),

  delete: (url, options) => instance.delete(url, options).then(responseBody),
};

export const AuthServices = {
  login: (loginData, options) => requests.post(`/login`, loginData, options),
};

export const HotelUserServices = {
  getUserBookings: (userId, type) =>
    requests.get(`/getBookingsById/${userId}?type=${type}`),
  getBookingDetails: (bookingId) =>
    requests.get(`/getBookingDetails/${bookingId}`),
  createCheckIn: (checkInData) => requests.post(`/room/checkin`, checkInData),
  filterRoom: (link, dateData) => requests.get(link, dateData),
  bookRoomSign: (userAddress) =>
    requests.post(`/signbookroom`, { userAddress }),
  cancelBookingSign: (nftId, amount, userAddress) =>
    requests.post(`/signcancelbooking`, { nftId, amount, userAddress }),
  preCheckInSign: (userAddress, nftId) =>
    requests.post(`/preCheckInSign/${userAddress}/${nftId}`),
  createEditSign: (saleId, amount) =>
    requests.post(`/signeditsale`, { saleId, amount }),
  registerHotels: (hotelId) => requests.post(`/signhotelregister`, { hotelId }),
  creatSellSign: (nftId, amount) =>
    requests.post(`/signcreatesell`, { nftId, amount }),
  buyItem: (nftId, amount) => requests.post(`/signbuyitem`, { nftId, amount }),
  getHotelById: (hotelId) => requests.get(`/getHotelById/${hotelId}`),
  getHotelRoomByHotelId: (hotelId) => requests.get(`/getRooms/${hotelId}`),
  getRoomById: (roomId, fromR, toDate, room, address) =>
    requests.get(
      `/getRoomById/${roomId}?fromR=${fromR}&toDate=${toDate}&roomQty=${room}&walletaddress=${address}`
    ),
  cancelBookingUser: (bookingId, transactionHash) =>
    requests.get(`/cancelBookingUser/${bookingId}/${transactionHash}`),
  searchSuggestion: () => requests.get(`/searchSuggestion`),
  getPostalCode: (postalCode) => requests.get(`/postalcode/${postalCode}`),
};

export const BuyServices = {
  createRoomBooking: (bookingData) => requests.post(`/booking`, bookingData),
  transactionHash: (transactionHash) =>
    requests.post(`/transactionHash`, transactionHash),
};

export const SaleServices = {
  createRoomSale: (saleData) => requests.post(`/createRoomSale`, saleData),
  getRoomSales: (hotelId, resultPerPage, page, startDate, endDate) =>
    requests.post(
      `/getRoomSales/${resultPerPage}/${page}?startDate=${startDate}&endDate=${endDate}`,
      { hotelId: hotelId }
    ),
  getRoomOnSaleDetails: (roomId) =>
    requests.get(`/getRoomOnSaleDetails/${roomId}`),
  buyItem: (
    saleId,
    currenOwnerAddress,
    newOwnerAddress,
    userId,
    priceBought,
    bookingId,
    transactionHash
  ) =>
    requests.post("/buyitem", {
      saleId,
      currenOwnerAddress,
      newOwnerAddress,
      userId,
      priceBought,
      bookingId,
      transactionHash,
    }),
  cancelSale: (saleId, bookingId, transactionHash) =>
    requests.post(`/cancelSale`, { saleId, bookingId, transactionHash }),
  editListing: (saleData) => requests.post("/editListing", saleData),
  searchMarketplace: (queryData, limit, page) =>
    requests.post(`/searchMarketplace?limit=${limit}&page=${page}`, queryData),
};

export const ValidateServices = {
  validateRoom: (roomId, from, to, room) =>
    requests.get(
      `/validateRoom?roomId=${roomId}&from=${from}&to=${to}&roomQty=${room}`
    ),
};

export const ManagerServices = {
  createHotel: (hotelData) => requests.post(`/registerHotel`, hotelData),
  getHotelsByManager: () => requests.post(`/getHotelsByManager`),
  getHotelBookings: (hotelId, page, resultPerPage) =>
    requests.get(`/getRoomBookings/${hotelId}/${page}/${resultPerPage}`),
  updateRoomPrices: (roomPricingId, currRoom, pricingData) =>
    requests.put(
      `/updateRoomPricing/${roomPricingId}/${currRoom}`,
      pricingData
    ),
  updateRoomByManager: (roomId, roomData) =>
    requests.put(`/updateRoomByManager/${roomId}`, roomData),
  getDashboardSummary: (hotelId) =>
    requests.get(`/dashboardSummary/${hotelId}`),
  updateHotelByManager: (hotelId, hotelData) =>
    requests.put(`/updateHotelByManager/${hotelId}`, hotelData),
  updateHotelId: (hotelId, uniqueId) => {
    requests.post(`/updateHotelId/${hotelId}/${uniqueId}`);
  },
  updateHotel: (idData) => requests.post(`/updateHotel`, idData),
  updateRoom: (id) => requests.put(`/roomUpdate/${id}`),
  updateImage: (data) => requests.put(`/deleteImage`, data),
  addImageDB: (data) => requests.put(`/uploadImage`, data),
  updateImageHotel: (data) => requests.put(`/updateImageHotel`, data),
  addImageHotelDB: (data) => requests.put(`/addImageHotelDB`, data),
  createRoom: (data) => requests.put(`/createRoom`, data),
};

export const getHotelRoom = async (hotelId, fromDate, toDate, room, guest) => {
  return axios({
    method: "get",
    url: `${backendUrl}/getRooms?hotelId=${hotelId}&from=${fromDate}&to=${toDate}&room=${room}&guest=${guest}`,
    headers: {},
  });
};

export const LocationServices = {
  getLocation: (lat, lng) => requests.get(`${geoCode}latitude=${lat}&longitude=${lng}&localityLanguage=en`),
  getTimezone: (lat, lng) => requests.get(`/getTimezone?latitude=${lat}&longitude=${lng}`),
};

export const uploadPinata = async (NFTFormData) => {
  return axios.post(`${backendUrl}/nft-storage`, NFTFormData, {
    headers: {
      "Content-Type":
        "multipart/form-data;boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
    },
  });
};

export const uploadUserDetail = async (userData) => {
  return axios.post(`${backendUrl}/userProfile`, userData, {});
};
