import { createContext, useState } from "react";
import { connectWallet } from "../../services/web3token";

export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
    const [walletLogin, setWalletLogin] = useState("");
    const [hotels, setHotels] = useState([
        "Wellington Mews",
        "Wellington Mew",
        "Wellingtons Mews",
        "Wellingtons Mew",
    ]);
    return (
        <WalletContext.Provider value={{ connectWallet, walletLogin, setWalletLogin, hotels, setHotels }}>
            {children}
        </WalletContext.Provider>
    );
};
