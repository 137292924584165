import React from "react";
import ContentLoader from "react-content-loader";
import TransactionLoader from "../TransactionLoader/TransactionLoader";

const MyBooking = ({ isTransaction }) => (
    <>
        {isTransaction ? (
            <div className="flex justify-center">
                <TransactionLoader />
            </div>
        ) : (
            <ContentLoader
                viewBox="0 0 400 160"
                height={160}
                width={"100%"}
                backgroundColor="#333"
            >
                <circle cx="150" cy="86" r="8" />
                <circle cx="194" cy="86" r="8" />
                <circle cx="238" cy="86" r="8" />
            </ContentLoader>
        )}
    </>
);

export default MyBooking;
