import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
  responsive: true,
  tension: 0.4,
  scales: {
    xAxis: {
      display: false,
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    yAxis: {
      display: false,
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
      position: "right",
    },
    zoom: {
      zoom: {
        wheel: {
          enabled: true,
          speed: 0.1,
        },
        pinch: {
          enabled: true,
        },
        mode: "x",
      },
      pan: {
        enabled: true,
        mode: "x",
      },
    },
  },
  interaction: {
    mode: "index",
    axis: "xy",
    intersect: false,
  },
  tooltips: {
    mode: "index",
    intersect: false,
  },
  hover: {
    mode: "index",
    intersect: false,
  },
  chartArea: {
    backgroundColor: "#CCEDFF",
  },
};

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const LineChart = ({ chartData }) => {
  const data = {
    labels,
    datasets: [
      {
        label: "Amount",
        data: chartData,
        fill: true,
        backgroundColor: "#CCEDFF",
        borderColor: "#02A3FF",
        pointBackgroundColor: "#02A3FF",
      },
    ],
  };

  return <Line options={options} data={data} />;
};

export default LineChart;
