import React from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import {googleMapApiKey}   from "../config/config.js";


// const containerStyle = { minHeight: "238px", height:"100%" };

const Map = ({ coordinates,mapStyle }) => {
    console.log(coordinates , "ABC")
    return (
        <GoogleMap zoom={10} center={coordinates} mapContainerStyle={mapStyle} mapContainerClassName='w-full' >
            <Marker position={coordinates} />
        </GoogleMap>
    );
};



const GMap = ({ coordinates,mapStyle }) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: googleMapApiKey,
    });

    if (!isLoaded) return <div>Loading...</div>;

    return <Map coordinates={coordinates} mapStyle={mapStyle} />;
};

export default GMap;
