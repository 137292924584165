export const googleMapApiKey = "AIzaSyBAJo80b8UURgM576C6eRztkcITCjl-7a0";
export const polygonGasStation = "https://gasstation-mumbai.matic.today/v2";
// export const backendUrl = "http://localhost:4000/api/v1";
// export const backendUrl = "http://buk-alb-430503065.ap-south-1.elb.amazonaws.com:4000/api/v1/";
export const backendUrl = "https://dgd7ad2o7bo4h.cloudfront.net/api/v1/"
export const geoCode = "https://api.bigdatacloud.net/data/reverse-geocode-client?";
export const coingecko = "https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=USD";
export const network = "matic-network";
export const polygonScanToken = "https://mumbai.polygonscan.com/token/";
export const polygonScanAddress = "https://mumbai.polygonscan.com/address/";
export const contractAddress = '0xc0567531Af3eBA093b312B162b0DE9F517652542';
export const marketAddress = '0x6C728555E4232AAc4F420A29c541f44a1361ac6D';
export const usdcAddress = "0x8A61f57908530551c9b3FFf63CD29B7FF28253Cf"