import * as React from "react"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"

export default function FormPropsTextFields() {
    return (
        <Box
            component="form"
            sx={{
                "& .MuiTextField-root": { width: "100%" },
            }}
            noValidate
            autoComplete="off"
        >
            <div className="flex flex-col items-center shadow-lg p-4">
                <TextField
                    required
                    id="outlined-required"
                    label="Name"
                    sx={{marginBottom: '1rem'}}
                    defaultValue=""
                />
                <TextField
                    required
                    id="outlined-required"
                    label="E-mail"
                    sx={{marginBottom: '1rem'}}
                    defaultValue=""
                />
                <TextField
                    required
                    id="outlined-required"
                    label="Contact Number"
                    sx={{marginBottom: '1rem'}}
                    defaultValue=""
                />

                <TextField
                    id="outlined-basic"
                    label="Comments"
                    variant="outlined"
                    multiline
                    sx={{marginBottom: '1rem'}}
                    rows={4}
                />
                <button className="bg-blue-500  text-white font-bold py-2 px-2 mx-auto w-28 rounded-full">
                    Submit
                </button>
            </div>
        </Box>
    )
}
