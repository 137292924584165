import React from "react";
import wallet from "../../images/wallet.svg";
import sea from "../../images/seaview.svg";
import pool from "../../images/am-pool.svg";
import airConditioning from "../../images/am-air.svg";
import wifi from "../../images/am-wifi.svg";
import spa from "../../images/am-spa.svg";
import parking from "../../images/am-parking.svg";
import restaurant from "../../images/am-restaurant.svg";
import bed from "../../images/am-bed.svg";
import rectangle from "../../images/card-rec.svg";

const amenities = {
  hasSwimmingPool: {
    name: "Pool",
    img: pool,
  },
  hasAirCondition: {
    name: "AC",
    img: airConditioning,
  },
  hasFreeWifi: {
    name: "Wifi",
    img: wifi,
  },
  hasSpa: {
    name: "Spa",
    img: spa,
  },
  hasKingBed: {
    name: "King Bed",
    img: bed,
  },
  hasFreeParking: {
    name: "Parking",
    img: parking,
  },
  hasRestaurant: {
    name: "Restaurant",
    img: restaurant,
  },
  hasHalfBoard: {
    name: "Half Board",
    img: bed,
  },
  hasFullBoard: {
    name: "Full Board",
    img: bed,
  },
  isWalletFriendly: {
    name: "Wallet Friendly",
    img: wallet,
  },
  hasSeaView: {
    name: "Sea View",
    img: sea,
  },
};
const HotelAmenities = ({ attributes, roomArea, length, small, roomUnit }) => {
  return (
    <>
      {small ? (
        <div className=" gap-x-2 gap-y-4 grid-cols-2 md:grid-cols-3 mb-4 justify-items-start w-full max-w-[800px]">
          {Object.keys(attributes).map(
            (attribute) =>
              attributes[attribute] && (
                <div
                  key={attribute}
                  className="flex gap-x-2 items-center justify-center amenities-center"
                >
                  <span>
                    {" "}
                    <img
                      src={amenities[attribute]?.img}
                      className={`w-3 h-3 min-h-3 min-w-3`}
                    />
                  </span>
                  <span className="text-[14px] text-[#292931]  flex justify-center items-center">
                    {amenities[attribute]?.name}
                  </span>
                </div>
              )
          )}

          {roomArea && (
            <div className="flex items-center justify-center iconsm">
              <span>
                <img
                  src={rectangle}
                  className={`w-3 h-3 min-h-3 min-w-3`}
                  alt="rectangle"
                />
              </span>
              <span className="ml-2 text-sm">
                {roomArea} {roomUnit}
              </span>
            </div>
          )}
        </div>
      ) : (
        <div className="grid gap-x-2 gap-y-4 grid-cols-2 md:grid-cols-3 px-4 justify-items-start w-full max-w-[800px] ">
          {length
            ? Object.keys(attributes)
                .slice(0, length)
                .map(
                  (attribute) =>
                    attributes[attribute] && (
                      <div
                        key={attribute}
                        className="flex gap-x-2"
                        // style={{ flex: "flex", justifySelf: "center" }}
                      >
                        <span>
                          <img
                            src={amenities[attribute]?.img}
                            className="w-4 h-4 min-h-4 min-w-4"
                          />
                        </span>
                        <span className="text-sm text-[#292931] lg:flex justify-center items-center">
                          {amenities[attribute]?.name}
                        </span>
                      </div>
                    )
                )
            : Object.keys(attributes).map(
                (attribute) =>
                  attributes[attribute] && (
                    <div
                      key={attribute}
                      className="flex gap-x-2 amchng items-center justify-center"
                    >
                      <span>
                        {" "}
                        <img
                          src={amenities[attribute]?.img}
                          className="w-6 h-6 min-h-6 min-w-6"
                        />
                      </span>
                      <span className="text-sm text-[#292931]  flex justify-center items-center">
                        {amenities[attribute]?.name}
                      </span>
                    </div>
                  )
              )}

          {roomArea && (
            <div className="flex items-center justify-center iconsm">
              <span>
                <img src={rectangle} width={18} height={16} alt="rectangle" />
              </span>
              <span className="ml-4 text-sm">
                {roomArea} {roomUnit}
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export const SingleAmenity = ({ attributes, handleBookingInfo }) => {
  const [single, setSingle] = React.useState(0);

  return (
    <div className="grid gap-x-2 gap-y-4 grid-cols-2 md:grid-cols-3 mb-4 justify-items-start w-full max-w-[800px]">
      {Object.keys(attributes).map(
        (attribute, i) =>
          attributes[attribute] && (
            <div className="flex items-center">
              <img src={amenities[attribute]?.img} width={15} height={18} />
              <p className="fontdesc lg:my-0 my-2 text-sm font-medium text-[#A179F5] ml-1">
                {amenities[attribute]?.name}
              </p>
              <p
                onClick={handleBookingInfo}
                className="cursor-pointer fontdesc text-sm font-medium text-[#2F69FF] ml-4"
              >
                View Details
              </p>
            </div>
          )
      )}
    </div>
  );
};

export default HotelAmenities;
