import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./Filter.css";
import star5 from "../../images/5star.svg";
import star4 from "../../images/4star.svg";
import star3 from "../../images/3star.svg";
import star2 from "../../images/2star.svg";

import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import triImg from "../../images/triImg.svg";
import { clearErrors, filterRooms } from "../../Redux/actions/roomAction";

function valuetext(value) {
  return `${value}°C`;
}
const attributesList = [
  "Swimming Pool",
  "Parking",
  "Wifi",
  "Half Board",
  "Full Board",
  "Luxury",
];
const propertyList = [
  { name: "5 Star", value: "Star5" },
  { name: "4 Star", value: "Star4" },
  { name: "3 Star", value: "Star3" },
  { name: "2 Star", value: "Star2" },
  { name: "Resorts", value: "resorts" },
  { name: "Guest Houses", value: "Guest_House" },
];
const guestList = [
  { name: "5", value: "guestStar5" },
  { name: "4", value: "guestStar4" },
  { name: "3", value: "guestStar3" },
  { name: "2", value: "guestStar2" },
];
const typeList = ["Cancellation", "Special Deals", "High Appreciation"];
const Filter = ({
  page,
  sources,
  setSources,
  price,
  setPrice,
  guestRatings,
  setGuestRatings,
  attributes,
  setAttributes,
  type,
  setType,
  setPropertyType,
  propertyType,
  sourceHandle,
  marketplace,
  guest,
  startDate,
  endDate,
  room,
}) => {
  // const [checked , setChecked] = useState(false)
  const dispatch = useDispatch();
  const keyword = useParams();

  const { error } = useSelector((state) => state.rooms);
  const priceHandler = (e, newPrice) => {
    e.preventDefault();
    setPrice(newPrice);
  };
  const propertyHandler = (event) => {
    let updatedList = [...propertyType];
    if (event.target.checked) {
      updatedList = [...propertyType, event.target.value];
    } else {
      updatedList.splice(propertyType.indexOf(event.target.value), 1);
    }
    setPropertyType(updatedList);
  };

  const handleGuestRating = (event) => {
    let updatedList = [...guestRatings];
    if (event.target.checked) {
      updatedList = [...guestRatings, event.target.value];
    } else {
      updatedList.splice(guestRatings.indexOf(event.target.value), 1);
    }
    setGuestRatings(updatedList);
  };

  const attributeHandler = (event) => {
    let updatedList = [...attributes];
    if (event.target.checked) {
      updatedList = [...attributes, event.target.value];
    } else {
      updatedList.splice(attributes.indexOf(event.target.value), 1);
    }
    setAttributes(updatedList);
  };
  const typeHandler = (event) => {
    let updatedList = [...type];
    if (event.target.checked) {
      updatedList = [...type, event.target.value];
    } else {
      updatedList.splice(type.indexOf(event.target.value), 1);
    }
    setType(updatedList);
  };
  const clearAll = () => {
    setPrice([0, 2500]);
    sourceHandle();
    const aa = [];
    setPropertyType(aa);
    // console.log("propType :>> ", propertyType);
    // setChecked(!checked);
    const initial = []
    setAttributes(initial);
  };

  const ss = (dd) => {
    const yy = propertyType.filter((data) => data == dd);
    console.log("yy", yy);
    return yy.length;
  };

  const  tt = (dd) => {
    const yy = attributes.filter((data) => data == dd);
    return yy.length;
  }
  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }
    dispatch(
      filterRooms(
        keyword,
        price,
        sources,
        guestRatings,
        propertyType,
        type,
        attributes,
        page,
        startDate,
        endDate,
        guest,
        room,
      )
    );
  }, [
    dispatch,
    keyword,
    price,
    sources,
    guestRatings,
    propertyType,
    type,
    attributes,
    page,
    startDate,
    endDate,
    guest,
    room
  ]);

  return (
    <div className="flex flex-col gap-2">
      <div className="filter-heading flex flex-row items-end justify-start items-center">
        <span>Filters </span>
        <p className="ml-2 cursor-pointer mb-0" onClick={clearAll}>
          Clear all
        </p>
      </div>
      <div className="w-[295px] h-full min-h-[1150px] fil flex flex-col filter">
        <div className="flex flex-col p-5">
          <p className="fh">Sources</p>
          <div className="form-check mb-3 mt-3">
            <input
              name="hotel"
              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="radio"
              value="hotel"
              id="flexCheckDefault"
              onClick={(e) => sourceHandle(e, 0)}
              checked={!marketplace}
            />
            <span className="fsh">Hotel</span>
          </div>
          <div className="form-check mb-3">
            <input
              name="marketplace"
              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="radio"
              value="marketplace"
              id="flexCheckDefault"
              onClick={(e) => sourceHandle(e, 1)}
              checked={marketplace}
            />
            <span className="fsh">Marketplace</span>
          </div>
        </div>
        <div className="line"></div>
        {/* <div className="flex flex-col p-5">
          <p className="fh">Type</p>
          <div className=" mb-3 mt-3">
            {typeList.map((item, index) => (
              <div key={item + index} className="form-check mb-3">
                <input
                  onChange={typeHandler}
                  value={item}
                  type="checkbox"
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                />
                <span className="fsh">{item}</span>
              </div>
            ))}
          </div>
        </div> */}
        {/* <div className="line"></div> */}
        <div className="flex flex-col p-5 gap-y-3">
          <p className="fh">Price</p>
          <div>
            <AirbnbSlider
              components={{ Thumb: AirbnbThumbComponent }}
              getAriaLabel={() => "Minimum distance"}
              value={price}
              onChange={priceHandler}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              disableSwap
              min={0}
              max={5000}
            />
          </div>
          <div className="flex justify-between items-center w-full">
            <span className="border-2 rounded-lg px-2 border-[#E1E1E1]">
              ${price[0]}
            </span>
            <span className="border-2 rounded-lg px-2 border-[#E1E1E1]">
              ${price[1]}
            </span>
          </div>
          {/* <div className="line mt-8"></div> */}
          {/* <div className="flex flex-col gap-y-3">
                        <p className="fh">Guest Rating</p>
                        <div className="flex items-start flex-col gap-x-2 mb-4">
                            {guestList.map((item, index) => (
                                <div
                                    key={item.value + index}
                                    className="form-check mb-3"
                                >
                                    <input
                                        onChange={handleGuestRating}
                                        value={item.value}
                                        type="checkbox"
                                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                    />
                                    <StarRating val={Number(item.name)} />
                                </div>
                            ))}
                        </div>
                    </div> */}
          {/* Property Handler */}
          <div className="line"></div>
          <div className="flex flex-col">
            <p className="fh mb-3">Property Types</p>
            <div>
              {propertyList.map((item, index) => (
                <div key={item.value + index} className="form-check mb-3">
                  <input
                    onChange={propertyHandler}
                    value={item.value}
                    type="checkbox"
                    checked={ss(item.value) > 0 ? true : false}
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  />
                  <span className="fsh">{item.name}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="line"></div>
          {/* Amenities */}
          <div className="flex flex-col gap-y-3">
            <p className="fh">Amenities</p>
            <div>
              {attributesList.map((item, index) => (
                <div key={item + index} className="form-check mb-3">
                  <input
                    onChange={attributeHandler}
                    value={item}
                    type="checkbox"
                    checked={tt(item) > 0 ? true : false}
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  />
                  <span className="fsh">{item}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: "#640AE7",
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 27,
    width: 27,
    backgroundColor: "#fff",
    border: "1px solid #D8E7EB",
    "&:hover": {
      boxShadow: "0 0 0 8px #D8E7EB66",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "linear-gradient(to right, #640AE7,#640AE7)",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 7,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 9,
  },
}));
function AirbnbThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb
      sx={{ borderRadius: "10px", border: "1px solid #666" }}
      {...other}
    >
      {children}
      <img src={triImg} alt="slider" />
    </SliderThumb>
  );
}
export default Filter;

const StarRating = ({ val }) => {
  switch (Number(val)) {
    case 2:
      return (
        <div className="flex form-check items-center mb-2">
          <img src={star2} width={68} height={12} />
          <span className="fsh ml-2">Ok</span>
        </div>
      );
    case 3:
      return (
        <div className="flex form-check items-center  mb-2">
          <img src={star3} width={68} height={12} />
          <span className="fsh ml-2">Good</span>
        </div>
      );
    case 4:
      return (
        <div className="flex form-check items-center  mb-2">
          <img src={star4} width={68} height={12} />
          <span className="fsh ml-2">Superb</span>
        </div>
      );
    case 5:
      return (
        <div className="flex form-check items-center  mb-2">
          <img src={star5} width={68} height={12} />
          <span className="fsh ml-2">Excellent</span>
        </div>
      );
    default:
      return <div></div>;
  }
};
