import { useEffect, useState } from "react";
import roomAreaImg from "../../images/roomArea.svg";
import roomGuestsImg from "../../images/roomGuests.svg";
import roomAvailableImg from "../../images/roomAvailable.svg";
import roomEditImg from "../../images/roomEdit.svg";
import roomCloneImg from "../../images/roomClone.svg";
import roomDeleteImg from "../../images/roomDelete.svg";
import { InputAdornment, Menu, TextField } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FileUpload from "../../components/FileUpload/FileUpload";
import PricingComponent from "../../components/PricingComponent/PricingComponent";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { toast } from "react-toastify";
import { addDaysTo } from "../../utils/date";
import TextEditor from "../../components/TextEditor/TextEditor";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const cancellationList = ["Sea View", "King Bed", "Half Board", "Full Board"];

const RegistrationStepTwo = ({
    stepData,
    setStepData,
    setStep,
    noNew,
    noNext,
    noTitle,
    handleAdd,
    pricingInfo,
    setPricingInfo,
    step,
    place,
    handleRoomApi,
    deleteRoom,
    isEditing,
    setIsEditing,
    noPrice,
    updateImageDB,
    setRoomId,
    addImageDB,
    createNewRoom,
    newAdd,
    setNewAdd,
    setRoomTags,
    ...props
}) => {
    console.log("place",place)
    const handleClone = (index) => {
        const tempArr = stepData;
        tempArr.push(stepData[index]);
        setStepData([...tempArr]);
    };
    const handleDelete = async (index) => {
        try {
            if (place === "roomUpdate") {
                await deleteRoom(stepData[index].id);
            }
        } catch (e) {
            console.log("e", e);
        }
        const tempArr = stepData;
        tempArr.splice(index, 1);
        setStepData([...tempArr]);
    };
    const handleNext = () => {
        
        setStep(2);
        window.scrollTo(0, 0);
    };
    return (
        <div className="flex flex-col gap-4 mobile:max-w-[1360px] text-[#130A44] max-w-[400px] w-full mt-8">
            {!noTitle && (
                <div className="flex justify-center mobile:text-4xl font-bold text-center text-lg">
                    Room details and availability
                </div>
            )}
            {stepData &&
                stepData.map((room, index) => (
                    <RoomComponent
                        key={index}
                        room={room}
                        setStepData={setStepData}
                        index={index}
                        handleClone={handleClone}
                        handleDelete={handleDelete}
                        setPricingInfo={setPricingInfo}
                        pricingInfo={pricingInfo}
                        handleRoomApi={handleRoomApi}
                        place={place}
                        setIsEditing={setIsEditing}
                        setNewAdd={setNewAdd}
                        isEditing={isEditing}
                        noPrice={noPrice}
                        updateImageDB={updateImageDB}
                        setRoomId={setRoomId}
                        addImageDB={addImageDB}
                        createNewRoom={createNewRoom}
                        newAdd={newAdd}
                        setRoomTags={setRoomTags}
                        {...props}
                    />
                ))}
            {isEditing === "" && (
                <div className="flex justify-end my-4">
                    <button
                        onClick={handleAdd}
                        className="border-2 border-dashed w-full border-[#1C8FD0] text-[#1C8FD0] flex items-center justify-center mobile:w-fit gap-2 rounded-md font-medium px-6 py-3"
                    >
                        <AddCircleTwoToneIcon />
                        <span>Add a new room</span>
                    </button>
                </div>
            )}
            {!noNext && step == 1 && isEditing === "" && (
                <div className="flex justify-center items-center gap-4 my-8">
                    <button
                        onClick={() => {
                            setStep(0);
                            window.scrollTo(0, 0);
                        }}
                        className="border-2 border-[#292931] px-8 py-2 text-[#292931] rounded-full font-medium text-lg"
                    >
                        Back
                    </button>
                    <button
                        onClick={handleNext}
                        className="border-2 border-[#2F69FF] bg-[#2F69FF] hover:bg-blue-700 px-8 py-2 text-[#fff] rounded-full font-medium text-lg"
                    >
                        Next
                    </button>
                </div>
            )}
        </div>
    );
};

export const RoomComponent = ({
    initialState = true,
    room,
    pricingInfo,
    setPricingInfo,
    setStepData,
    index,
    handleRoomApi,
    place,
    isEditing,
    setIsEditing,
    noPrice,
    setRoomId,
    updateImageDB,
    addImageDB,
    createNewRoom,
    newAdd,
    setNewAdd,
    setRoomTags,
    ...roomProps
}) => {
    const handleEdit = () => {
        if (place !== "roomUpdate") {
            setIsEditing(index);
        } else {
            setIsEditing(index);
            setRoomId(room.id);
            setNewAdd("");
        }
    };
    const handleSave = () => {
        setIsEditing("");
    };
    return (
        <>
            {isEditing === index ? (
                <AddRoom
                    pricingInfo={pricingInfo}
                    setPricingInfo={setPricingInfo}
                    onSave={handleSave}
                    index={index}
                    setStepData={setStepData}
                    room={room}
                    handleRoomApi={handleRoomApi}
                    place={place}
                    setIsEditing={setIsEditing}
                    noPrice={noPrice}
                    updateImageDB={updateImageDB}
                    addImageDB={addImageDB}
                    createNewRoom={createNewRoom}
                    newAdd={newAdd}
                    setNewAdd={setNewAdd}
                    setRoomTags={setRoomTags}
                    {...roomProps}
                />
            ) : (
                <RoomCard
                    room={room}
                    index={index}
                    place={place}
                    handleEdit={handleEdit}
                    updateImageDB={updateImageDB}
                    {...roomProps}
                />
            )}
        </>
    );
};

export const RoomCard = ({
    room,
    handleEdit,
    handleDelete,
    handleClone,
    index,
    place,
    updateImageDB,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const roomActions = [
        {
            name: "Edit",
            img: roomEditImg,
            action: handleEdit,
        },
        {
            name: "Clone",
            img: roomCloneImg,
            action: handleClone,
        },
        {
            name: "Delete",
            img: roomDeleteImg,
            action: handleDelete,
        },
    ];

    return (
        <div
            id={index}
            className="flex justify-between bg-white shadow-xl shadow-[#d8e3f1a6] w-full overflow-hidden rounded-md mobile:h-[150px]"
        >
            <div className="flex flex-col mobile:flex-row gap-6 w-full">
                <img
                    src={room.files.length !== 0 ? room.files[0] : undefined}
                    alt="room"
                    className="mobile:w-[250px] h-[150px] mobile:h-auto w-full object-cover"
                />
                <div className="flex flex-col justify-center mobile:gap-6 gap-4 px-4 pb-4">
                    <span className="flex text-lg mobile:text-2xl w-full mobile:w-fit justify-between font-semibold text-[#292931]">
                        {room.name}
                        <MoreVertIcon
                            className="mobile:hidden"
                            onClick={handleClick}
                        />
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                "aria-labelledby": "room menu",
                            }}
                        >
                            <MenuItem
                                type="button"
                                onClick={() => {
                                    handleEdit(index);
                                }}
                                className="flex gap-2 text-sm items-center h-fit min-w-fit"
                            >
                                <img
                                    src={roomCloneImg}
                                    alt=""
                                    className="w-5 h-5 "
                                />
                                <span className="text-[#7E7E7E] min-w-fit">
                                    Edit
                                </span>
                            </MenuItem>
                            <MenuItem
                                type="button"
                                onClick={() => {
                                    handleClone(index);
                                }}
                                className="flex gap-2 text-sm items-center h-fit min-w-fit"
                            >
                                <img
                                    src={roomEditImg}
                                    alt=""
                                    className="w-5 h-5 "
                                />
                                <span className="text-[#7E7E7E] min-w-fit">
                                    Clone
                                </span>
                            </MenuItem>
                            <MenuItem
                                type="button"
                                onClick={() => {
                                    handleDelete(index);
                                }}
                                className="flex gap-2 text-sm items-center h-fit min-w-fit"
                            >
                                <img
                                    src={roomDeleteImg}
                                    alt=""
                                    className="w-5 h-5 "
                                />
                                <span className="text-[#7E7E7E] min-w-fit">
                                    Delete
                                </span>
                            </MenuItem>
                        </Menu>
                    </span>
                    <div className="flex items-center gap-6">
                        <div className="flex gap-4 items-center">
                            <img src={roomAreaImg} alt="" />
                            <span>
                                {room.details.roomSize}{" "}
                                {room.details.roomAreaUnit}
                            </span>
                        </div>
                        <div className="flex gap-4 items-center">
                            <img src={roomGuestsImg} alt="" />
                            <span>{room.details.occupancy}</span>
                        </div>
                        <div className="flex gap-4 items-center">
                            <img src={roomAvailableImg} alt="" />
                            <span>{room.details.availableRooms}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile:flex gap-4 p-6 hidden min-w-fit">
                {place
                    ? roomActions.map(
                          (action) =>
                              action.name !== "Clone" && (
                                  <button
                                      key={action.name}
                                      type="button"
                                      onClick={() => action.action(index)}
                                      className="flex gap-2 items-center h-fit min-w-fit"
                                  >
                                      <img
                                          src={action.img}
                                          alt=""
                                          className="w-5 h-5 "
                                      />
                                      <span className="text-[#7E7E7E] min-w-fit">
                                          {action.name}
                                      </span>
                                  </button>
                              )
                      )
                    : roomActions.map((action) => (
                          <button
                              key={action.name}
                              type="button"
                              onClick={() => action.action(index)}
                              className="flex gap-2 items-center h-fit min-w-fit"
                          >
                              <img
                                  src={action.img}
                                  alt=""
                                  className="w-5 h-5 "
                              />
                              <span className="text-[#7E7E7E] min-w-fit">
                                  {action.name}
                              </span>
                          </button>
                      ))}
            </div>
        </div>
    );
};

export const AddRoom = ({
    room,
    stepData,
    setStepData,
    index,
    onSave,
    noPrice,
    setPricingInfo,
    pricingInfo,
    handleRoomApi,
    place,
    setIsEditing,
    updateImageDB,
    addImageDB,
    newAdd,
    createNewRoom,
    setRoomTags,
}) => {
    console.log("room")
    const [tags, setTags] = useState(room.details.tags);
    const [files, setFiles] = useState(room.files);
    const [description,setDescription] = useState('')
    const currDate = new Date();
    currDate.setHours(0, 0, 0, 0);
    const updateRoomInfo = (e) => {
        const { name, value } = e.target;
        if (name == "availableRooms" || name == "occupancy") {
            if (value < 0) {
                return;
            }
        }
        setStepData((prevState) => {
            const newState = prevState.map((obj, objIndex) => {
                if (objIndex === index) {
                    if (name === "name") {
                        return {
                            ...obj,
                            [name]: value,
                        };
                    }
                    return {
                        ...obj,
                        details: { ...obj.details, [name]: value },
                    };
                }
                return obj;
            });

            return newState;
        });
    };
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        let tempArr = typeof value === "string" ? value.split(",") : value
        setTags(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
        sessionStorage.setItem("roomTags",JSON.stringify(tempArr))
    };

    const handleDelete = (e, value) => {
        setTags(tags.filter((cancellationList) => cancellationList != value));
    };

    const handleFilesTagsPrice = () => {
        setStepData((prevState) => {
            const newState = prevState.map((obj, objIndex) => {
                if (objIndex === index) {
                    return {
                        ...obj,
                        // files: files,
                        pricingInfo: pricingInfo,
                        details: {
                            ...obj.details,
                            tags: tags,
                        },
                    };
                }
                return obj;
            });
            return newState;
        });
    };

    const handleSave = (e) => {
        e.preventDefault();
        if (place == "roomUpdate" && !newAdd) {
            handleRoomApi(room.id, tags,description);
            setIsEditing("");
        } 
        // else if (newAdd) {
        //     console.log("stepData ", stepData);
        // } 
        else {
            if (place == "roomUpdate" && room.files.length <= 3) {
                return toast.error("Please upload at least 3 photos.");
            }
            handleFilesTagsPrice();
            onSave();
        }
    };

    const handleClear = () => {
        setStepData((prevState) => {
            const newState = prevState.map((obj, objIndex) => {
                if (objIndex === index) {
                    return {
                        name: "",
                        files: [],
                        mapFile: [],
                        details: {
                            availableRooms: 0,
                            roomAreaUnit: "sq. mt.",
                            occupancy: 0,
                            roomSize: 0,
                            description: "",
                            tags: [],
                        },
                        pricingInfo: [
                            {
                                dateFrom: currDate,
                                dateTo: addDaysTo(1, currDate),
                                minDateTo: addDaysTo(1, currDate),
                                minDateFrom: currDate,
                                price: 0,
                                minResale: 0,
                                cancellation: "",
                                percentage: 0,
                                daysBefore: 0,
                            },
                        ],
                    };
                }
                return obj;
            });
            return newState;
        });
        setFiles("");
        setPricingInfo([
            {
                dateFrom: currDate,
                dateTo: addDaysTo(1, currDate),
                minDateTo: addDaysTo(1, currDate),
                minDateFrom: currDate,
                price: 0,
                minResale: 0,
                cancellation: "",
                percentage: 0,
                daysBefore: 0,
            },
        ]);
    };

    const saveRoom = () => {
        
        console.log("description",description)
            if (
                !room?.name ||
                !room?.details.availableRooms ||
                !room?.details.occupancy ||
                !room?.details.roomAreaUnit ||
                !room?.details.roomSize
            ) {
                toast.error("Please fill all the required fields.")
                return;
            }
        // }
        setIsEditing("");
        setStepData((prevState) => {
            const newState = prevState.map((obj, objIndex) => {
                if (objIndex === index) {
                    return {
                        ...obj,
                        // files: files,
                        pricingInfo: pricingInfo,
                        details: {
                            ...obj.details,
                            tags: tags,
                            description:description
                        },
                    };
                }
                return obj;
            });
            return newState;
        });
    };
    useEffect(() => {
        if(sessionStorage.getItem("roomTags")) {
            setTags(JSON.parse(sessionStorage.getItem("roomTags")))
        }
        if(place == "roomUpdate") {
            setDescription(room.details?.description)
        }
    },[])
    useEffect(() => {
        if (newAdd) {
            setRoomTags(tags);
        }
    }, [tags]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form
                onSubmit={handleSave}
                className="flex flex-col bg-white p-6 shadow-xl shadow-[#d8e3f1a6] w-full gap-8 rounded-md"
            >
                <div className="flex flex-col mobile:flex-row justify-between gap-8 mb-4">
                    <div className="w-full">
                        <TextField
                            required
                            label="Room Name"
                            variant="standard"
                            name="name"
                            value={room.name}
                            sx={{ width: "100%", maxWidth: "300px" }}
                            InputProps={{
                                sx: {
                                    fontWeight: "700",
                                    fontSize: "24px",
                                    color: "#292931",
                                },
                            }}
                            InputLabelProps={{
                                sx: { color: "#7E7E7E" },
                            }}
                            onChange={(e) => updateRoomInfo(e)}
                        />
                    </div>
                    <div className="w-full">
                        <TextField
                            required
                            label="Available Rooms"
                            name="availableRooms"
                            variant="standard"
                            value={room.details.availableRooms}
                            sx={{ width: "100%", maxWidth: "200px" }}
                            InputProps={{
                                sx: {
                                    fontWeight: "700",
                                    fontSize: "24px",
                                    color: "#292931",
                                },
                            }}
                            InputLabelProps={{
                                sx: { color: "#7E7E7E" },
                            }}
                            type="number"
                            onChange={(e) => updateRoomInfo(e)}
                        />
                    </div>
                </div>
                <div className="flex justify-between flex-col xl:flex-row gap-8 pb-10 border-b-2 border-[#E7ECF2]">
                    <div className="w-full flex flex-col gap-6">
                        <div className="flex flex-col mobile:flex-row justify-between gap-4">
                            <div className="flex flex-row w-3/4">
                                <TextField
                                    required
                                    label="Size"
                                    name="roomSize"
                                    value={room.details.roomSize}
                                    sx={{ width: "100%" }}
                                    onChange={(e) => updateRoomInfo(e)}
                                    type="number"
                                    className="w-1/2"
                                />
                                <Select
                                    required
                                    name="roomAreaUnit"
                                    value={room.details.roomAreaUnit}
                                    onChange={(e) => updateRoomInfo(e)}
                                    // input={<OutlinedInput label="Unit" />}
                                    className="mx-2"
                                >
                                    <MenuItem default value={"sq. mt."}>
                                        Square Meters
                                    </MenuItem>
                                    <MenuItem value={"sq. ft."}>
                                        Square Feet
                                    </MenuItem>
                                </Select>
                            </div>

                            <TextField
                                required
                                value={room.details.occupancy}
                                name="occupancy"
                                sx={{
                                    width: "100%",
                                }}
                                onChange={(e) => updateRoomInfo(e)}
                                type="number"
                                label="Max Occupancy"
                            />
                        </div>
                        {/* <textarea
                            label="Room Description"
                            name="description"
                            placeholder="Room Description"
                            // multiline
                            // fullWidth
                            // maxRows={4}
                            value={room.details.description}
                            // InputProps={{
                            //     sx: { fontWeight: "500" },
                            //     // maxLength: 400,
                            // }}
                            onChange={(e) => updateRoomInfo(e)}
                        /> */}

                    <TextEditor description={description} setDescription={setDescription}/>
                    </div>
                    <div className="w-full">
                        <FormControl sx={{ width: "100%" }}>
                            <InputLabel>Amenities</InputLabel>
                            <Select
                                multiple
                                // required
                                value={tags}
                                onChange={handleChange}
                                input={<OutlinedInput label="Particulars" />}
                                IconComponent={AddCircleOutlineOutlinedIcon}
                                renderValue={(selected) => (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: 0.5,
                                        }}
                                    >
                                        {selected.map((value) => (
                                            <Chip
                                                key={value}
                                                label={value}
                                                sx={{
                                                    backgroundColor:
                                                        "#2F69FF33",
                                                    fontWeight: "500",
                                                    borderRadius: "10px",
                                                }}
                                                onDelete={(e) =>
                                                    handleDelete(e, value)
                                                }
                                                onMouseDown={(event) => {
                                                    event.stopPropagation();
                                                }}
                                                deleteIcon={
                                                    <CloseRoundedIcon className="!text-[#2F69FF]" />
                                                }
                                            />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {cancellationList.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="flex flex-col w-fit pb-8 border-b-2 border-[#E7ECF2]">
                    <div className="font-bold text-lg">Photos & Videos*</div>
                    <FileUpload
                        files={files}
                        index={index}
                        type="room"
                        setFiles={setFiles}
                        stepData={stepData}
                        setStepData={setStepData}
                        roomData
                        updateImageDB={updateImageDB}
                        addImageDB={addImageDB}
                        place={place}
                        required
                    />
                </div>
                {!noPrice && newAdd && (
                    <PricingComponent
                        data={pricingInfo}
                        setData={setPricingInfo}
                        setStepData={setStepData}
                        index={index}
                        stepData={stepData}
                    />
                )}
                
            </form>
            {place == "roomUpdate" ? (
                    newAdd ? (
                        <div className="flex justify-center mobile:justify-end items-center gap-4">
                            <button
                                type="button"
                                className="bg-white border-2 border-[#7E7E7E] text-[#7E7E7E] font-medium px-6 py-2 rounded-full"
                                onClick={() => setIsEditing("")}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => createNewRoom(index,description)}
                                className="bg-[#292931] border-2 border-[#292931] text-[#fff] font-medium px-6 py-2 rounded-full"
                            >
                                Create
                            </button>
                        </div>
                    ) : (
                        <div className="flex justify-center mobile:justify-end items-center gap-4">
                            <button
                                type="button"
                                className="bg-white border-2 border-[#7E7E7E] text-[#7E7E7E] font-medium px-6 py-2 rounded-full"
                                onClick={() => setIsEditing("")}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleSave}
                                className="bg-[#292931] border-2 border-[#292931] text-[#fff] font-medium px-6 py-2 rounded-full"
                            >
                                Save
                            </button>
                        </div>
                    )
                ) : (
                    <div className="flex justify-center mobile:justify-end items-center gap-4">
                        <button
                            type="button"
                            className="bg-white border-2 border-[#7E7E7E] text-[#7E7E7E] font-medium px-6 py-2 rounded-full"
                            onClick={handleClear}
                        >
                            Clear
                        </button>
                        <button
                            onClick={saveRoom}
                            className="bg-[#292931] border-2 border-[#292931] text-[#fff] font-medium px-6 py-2 rounded-full"
                        >
                            Save
                        </button>
                    </div>
                )}
        </LocalizationProvider>
    );
};

export default RegistrationStepTwo;
