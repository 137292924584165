import { useContext, useState, useEffect, React } from "react";
import { useParams ,useSearchParams ,useLocation } from "react-router-dom";

import Bar from "../../components/Bar/Bar";
import "./Results.css";
import Card from "../../components/Card/Card";
import Filter from "../../components/Filter/Filter";
import { SearchContext } from "../../context/search/SearchContext";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Helmet } from "react-helmet";
import Loader from "../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, filterRooms } from "../../Redux/actions/roomAction";
import Switcher from "../../components/Switcher/Switcher";
import listSwitchImg from "../../images/listSwitch.svg";
import mapSwitchimg from "../../images/loc.svg";
import ResultMap from "../ResultMap/ResultMap";
import BasicPagination from "../../components/Pagination/BasicPagination";
import { SaleServices } from "../../services/supplier";
// import { Booking } from "../BuyFromOthers/BuyFromOthers";
import { BookingData } from "../../components/Card/Card";
import { toast } from "react-toastify";
import { getDateFormat } from "../../utils/date";

const switchData = [
  {
    name: "List",
    state: 0,
    img: listSwitchImg,
  },
  {
    name: "Map",
    state: 1,
    img: mapSwitchimg,
  },
];

const Results = (props) => {
  // console.log(props,"props");
  const location = useLocation();
  console.log(location.state, "locatuion")
  const dispatch = useDispatch();
  const keyword = useParams();
  const [currSwitch, setCurrSwitch] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sources, setSources] = useState([]);
  const [price, setPrice] = useState([0, 2500]);
  const [guestRatings, setGuestRatings] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [propertyType, setPropertyType] = useState([]);
  const [type, setType] = useState([]);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [searchParam, setSearchParam] = useSearchParams();
  const [marketPlace, setMarketPlace] = useState(false);
  const [cordinates, setCordinates] = useState({
    lat: parseFloat(44),
    lng: parseFloat(43),
  });
  // const [keyword] = useSearchParams();
  // console.log("serach param" , )
  const { search, setSearch, startDate, endDate, room, dateValue, guest } =
    useContext(SearchContext); 
    // console.log("Date Start Ayush" ,startDate)
  const [card, setCard] = useState({}); 
  const [sort, setSort] = useState(1);
  const [roomSales, setRoomSales] = useState([]);
  const [viewMore, setViewMore] = useState(false);
  const [page, setPage] = useState(1);
  const [totalBookings, setTotalBookingsAvailable] = useState(0);

  const [showCity, setShowResult] = useState(location?.state.name);
  // console.log(search, "search Context");

  const handleChange = (event) => {
    setSort(event.target.value);
  };
  const { loading, error, rooms, metadata } = useSelector(
    (state) => state.rooms
  );
console.log(rooms)
  const hotel = [];
  let uniqueHotelData = [];
  let hotelCount;
  let dataPoints;
  if (rooms?.length !== 0) {
    rooms.map((value) => {
      hotel.push(value);
    });
    const key = "_id";
    // uniqueHotelData = [
    //     ...new Map(hotel.map((item) => [item[key], item])).values(),
    // ];
    uniqueHotelData = hotel;
    if (sort == 1) {
      uniqueHotelData.sort((a, b) =>
        a?.pricing.minPrice > b?.pricing.minPrice
          ? 1
          : b?.pricing.minPrice > a?.pricing.minPrice
          ? -1
          : 0
      );
    } else if (sort == 2) {
      uniqueHotelData.sort((a, b) =>
        a?.pricing.minPrice > b?.pricing.minPrice
          ? -1
          : b?.pricing.minPrice > a?.pricing.minPrice
          ? 1
          : 0
      );
    }

    hotelCount = uniqueHotelData.length;
    if (
      hotelCount > 0 &&
      uniqueHotelData[0]?.hasOwnProperty("locationCordinates")
    ) {
      dataPoints = uniqueHotelData[0]?.["locationCordinates"];
    }
  }
  // Get Current Hotels
  const indexOfLastPost = currentPage * resultPerPage;
  const indexOfFirstPost = indexOfLastPost - resultPerPage;
  let currentPosts = uniqueHotelData.slice(indexOfFirstPost, indexOfLastPost);
  useEffect(() => {
    var total = 0;
    for (let roomNo = 0; roomNo < roomSales?.rooms?.length; roomNo++) {
      if (new Date(roomSales.rooms[roomNo]?.nftRef?.checkInDate) > new Date()) {
        total++;
      }
    }
    setTotalBookingsAvailable(total);
  }, [roomSales]);

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }
    dispatch(
      filterRooms(
        keyword,
        price,
        sources,
        guestRatings,
        propertyType,
        type,
        attributes,
        page,
        startDate,
        endDate,
        guest,
        room
      )
    );
  }, [page, room, startDate, endDate,keyword,price,sources,guestRatings,propertyType,type,attributes,guest]);

  useEffect(() => {
    setSearch(location.state.name);
  }, []);
  //Testing
  const [loader, setLoading] = useState(false);
  const [meta, setMetadata] = useState({
    total: 0,
    limit: 0,
  });
  // const s =searchParam.get("keyword")
  // console.log("search" ,s);
  // const param = new URLSearchParams(window?.location.pathname);
  // console.log(param.get("keyword"), "Params");
  const searchParams = new URLSearchParams(window?.location.search);
  // console.log("Ayush Searc",searchParams)
  let hotelId = searchParams.get("hotels");
  // console.log(hotelId,"hotelId");
  const getSales = async () => {
    try {
      setLoading(true);
      const getRooms = await SaleServices.getRoomSales(
        hotelId,
        10,
        page,
        getDateFormat(startDate),
        getDateFormat(endDate)
      );
      setRoomSales(getRooms.data);
      setPage(getRooms.metadata.page);
      setMetadata(getRooms.metadata);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const handleSource = (e, num) => {
    if (num) {
      if (!sessionStorage.getItem("userDetails")) {
        return toast.error("Please login before accessing Marketplace");
      }
      setMarketPlace(true);
      getSales();
    } else {
      setMarketPlace(false);
    }
  };
  useEffect(() => {
    if (marketPlace) {
      getSales();
    }
  }, [marketPlace, startDate, endDate, dateValue]);
  console.log("startDate, endDate", startDate, endDate);
  return (
    <>
      <div className="app">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Buk | Result</title>
        </Helmet>
        <Bar
          setShowResult={setShowResult}
          marketPlace={marketPlace}
          getSales={getSales}
        />
        <div className="flex justify-center my-6">
          <Switcher
            state={currSwitch}
            setState={setCurrSwitch}
            switchData={switchData}
          />
        </div>
        {currSwitch === 0 && (
          <div className="yashcss flex justify-center mt-2">
            <div className="w-[full] hidden md:flex justify-center">
              <Filter
                page={page}
                sources={sources}
                setSources={setSources}
                price={price}
                setPrice={setPrice}
                guestRatings={guestRatings}
                setGuestRatings={setGuestRatings}
                attributes={attributes}
                setAttributes={setAttributes}
                propertyType={propertyType}
                setPropertyType={setPropertyType}
                type={type}
                setType={setType}
                sourceHandle={handleSource}
                marketplace={marketPlace}
                guest={guest}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
            <div className="rights flex-col ml-8 w-full">
              {loading || loader ? (
                <div className="w-full h-[300px] flex my-6 justify-center card333">
                  <Loader />
                </div>
              ) : marketPlace ? (
                <div className="app  min-h-[400px]">
                  <Helmet>
                    <meta charSet="utf-8" />
                    <title>Buk | Buy From Others</title>
                    <link rel="canonical" href="http://mysite.com/example" />
                  </Helmet>
                  <p className="text-center sell py-4 ">
                    {totalBookings} Property available{" "}
                  </p>
                  <div className="flex flex-col gap-4">
                    {roomSales?.rooms?.map((data, i) => (
                      <BookingData
                        key={i + "roomSales"}
                        data={data}
                        setLoading={setLoading}
                        loading={loading}
                        setViewMore={setViewMore}
                        viewMore={viewMore}
                      />
                    ))}
                  </div>
                  <div className="flex justify-center items-center mt-4 mb-10">
                  {Math.ceil(
                    parseFloat(metadata?.total) / parseFloat(metadata?.limit)
                  ) > 1 && (
                    <BasicPagination
                      metaData={metadata}
                      page={page}
                      setPage={setPage}
                    />
                  )}
                  </div>
                </div>
              ) : rooms.length !== 0 ? (
                <>
                  <div className="flex justify-between items-center">
                    <div>
                      <span className="font-semibold">
                        {uniqueHotelData.length}{" "}
                        {uniqueHotelData.length > 1 ? "Properties" : "Property"}{" "}
                      </span>
                      <span> in {location.state.name}</span>
                    </div>
                    <div className="flex items-center text-[#7E7E7E]">
                      Sort By{" "}
                      {
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 170 }}
                        >
                          <Select value={sort} onChange={handleChange}>
                            <MenuItem value={1}>Price: Low to High</MenuItem>
                            <MenuItem value={2}>Price: High to Low</MenuItem>
                          </Select>
                        </FormControl>
                      }
                    </div>
                  </div>
                  {currentPosts.map((element) => {
                    return (
                      element && 
                      (
                        
                        <Card
                          key={element?._id}
                          id={element?._id}
                          item={element}
                          images={element.images}
                          name={element.name}
                          price={element.fee}
                          roomsLeft={element.totalRooms}
                          ratings={element.guestRatings}
                          address={element.address}
                          isCancel={true}
                          attributes={element?.attributes}
                          isWf={true}
                          reviews={element.guestRatings}
                          total={element.Fee}
                          hotelCount={card?.hotelCount}
                          star={element.starRatings}
                          startDate={startDate}
                          endDate={endDate}
                          room={room}
                          dateValue={dateValue}
                          search={location.state.name}
                          isNotAvailable={element.isNotAvailable}
                        />
                      )
                    );
                  })}
                  {/* {Math.ceil(
                    parseFloat(metadata?.total) / parseFloat(metadata?.limit)
                  ) > 1 && ( */}
                    <div className="flex justify-center items-center mt-4 mb-10">
                      <BasicPagination
                        metaData={metadata}
                        page={page}
                        setPage={setPage}
                      />
                    </div>
                  {/* )} */}
                  
                  <div className="text-bold text-[12px] border-t-2 pt-4">
                    Note: all prices displayed on this page are in USDC and are
                    indicative. The exact total amount will be displayed on the
                    payment page.
                  </div>
                </>
              ) : (
                <div className="w-full h-full flex my-6 text-2xl items-start justify-center card333">
                  <p>No Search Result for "{location.state.name}"! Try Another Location</p>
                  <div className="flex justify-center items-center mt-4 mb-10">
                      <BasicPagination
                        metaData={metadata}
                        page={page}
                        setPage={setPage}
                      />
                    </div>
                </div>
              )}
            </div>
          </div>
        )}
        {currSwitch === 1 && (
          <ResultMap
            cordinates={dataPoints}
            loading={loading}
            hotelData={uniqueHotelData}
            search={location.state.name}
            sort={sort}
            handleChange={handleChange}
          />
        )}
      </div>
    </>
  );
};

export default Results;
