import loc from "../../images/loc.svg";
import wifi from "../../images/am-wifi.svg";
import pool from "../../images/am-pool.svg";
import airConditioning from "../../images/am-air.svg";
import cancel from "../../images/cancel.svg";
import spa from "../../images/am-spa.svg";
import parking from "../../images/am-parking.svg";
import restaurant from "../../images/am-restaurant.svg";
import bed from "../../images/am-bed.svg";
import { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import goodtoknowImg from "../../images/goodtoknow.svg";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import {
  HotelUserServices,
  ValidateServices,
  BuyServices,
  setAuthToken,
  getUserDetails,
  getUseraddress,
} from "../../services/supplier";
import { contractAddress } from "../../config/config";
import { toast } from "react-toastify";
import PageLoader from "../../components/PageLoader/PageLoader";
import {
  getCompleteDate,
  decreaseDays,
  countDays,
  getDateFormat,
  getDateFormatDay,
  addDaysTo,
  getDateFormatWithWeekday,
  getTimeStamp,
  configure_check_in_date,
  configure_check_out_date,
  parseDate2,
  decrease_x_days,
} from "../../utils/date";
import { bookRooms, convertPriceToMatic } from "../../services/web3Services";
import Seaview from "../../images/seaview.svg";
import { SearchContext } from "../../context/search/SearchContext";
import { v4 as uuidv4 } from "uuid";
import QRCode from "qrcode";
import Description from "../../components/Description/Description";
import { SimpleDialog } from "../HotelPage/HotelPage";
const amenties = {
  hasSwimmingPool: {
    name: "Pool",
    img: pool,
  },
  hasAirCondition: {
    name: "Air Conditioning",
    img: airConditioning,
  },
  hasFreeWifi: {
    name: "Free Wifi",
    img: wifi,
  },
  hasSpa: {
    name: "Spa",
    img: spa,
  },
  hasKingBed: {
    name: "King Bed",
    img: bed,
  },
  hasFreeParking: {
    name: "Parking",
    img: parking,
  },
  hasRestaurant: {
    name: "Restaurant",
    img: restaurant,
  },
  hasHalfBoard: {
    name: "Half Board",
    img: bed,
  },
  hasFullBoard: {
    name: "Full Board",
    img: bed,
  },
  hasSeaView: {
    name: "Sea View",
    img: Seaview,
  },
};

const BuyNow = () => {
  let currDate = new Date();
  let navigate = useNavigate();
  const [attributes, setAttributes] = useState([
    "hasSwimmingPool",
    "hasAirCondition",
    "hasRestaurant",
    "hasFullBoard",
    "hasFreeWifi",
    "hasSpa",
    "hasKingBed",
    "hasFreeParking",
    "hasHalfBoard",
  ]);
  const [roomData, setRoomData] = useState("");
  const [viewMore, setViewMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const { roomId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const fromR = queryParams.get("fromR");
  const toDate = queryParams.get("toDate");
  const hotelId = queryParams.get("hotelId");
  const room = queryParams.get("room");
  const guest = queryParams.get("guest");
  const [images, setImages] = useState("");
  const [transactionInfo, setTransactionInfo] = useState(false);
  const [open, setOpen] = useState(false);
  const [isTransaction, setIsTransaction] = useState(false);

  // pricing
  const [totalPrice, setTotalPrice] = useState(0);
  const [roomPrice, setRoomPrice] = useState(0);
  // const [calculatedTax, setCalculatedtax] = useState();
  const [discount, setDiscount] = useState(0);
  const [pricing, setPricing] = useState();
  const [totalBasePrice, setTotalBasePrice] = useState();
  const [hotelTax, setHotelTax] = useState();
  const [tax, setTax] = useState();
  const [buk_cancellationFee, setBuk_cancellationFee] = useState();
  const [buk_commission, setBuk_Commission] = useState();
  const [hotel_cancellation, setHotel_cancellation] = useState();
  const [cancellationDate, setCancellationDate] = useState();
  const [refund, setRefund] = useState();

  const getRoom = async () => {
    const address = getUserDetails()?.address;
    let forDays = countDays(toDate, fromR);

    try {
      setLoading(true);
      const res = await HotelUserServices.getRoomById(
        roomId,
        getDateFormat(fromR),
        decrease_x_days(1, toDate),
        room,
        address
      );
      let total_baseprice = 0;
      for (let k = 0; k < room; k++) {
        for (let i = 0; i < forDays; i++) {
          total_baseprice =
            total_baseprice + res.data?.pricing.prices[i].basePrice;
        }
      }
      setCancellationDate(res.data?.cancellationObject?.cancellationDate);
      setRefund(res.data?.cancellationObject?.refundAmount);
      setRoomData(res.data?.data);
      setImages(res.data?.data.images);

      setTotalPrice(res.data?.pricing.sum * room);
      setRoomPrice(res.data?.data.roomPricing.roomPricing[0].price);
      setHotelTax(res.data?.data.hotel.hotelTax);
      setTax(res.data?.tax);
      setPricing(res.data?.pricing);
      setTotalBasePrice(total_baseprice);
      setDiscount(res.data?.discount);
      setBuk_cancellationFee(res.data?.bukCancellation);
      setHotel_cancellation(res.data?.hotelCancellation);
      setBuk_Commission(res.data?.bukCommissionPrice);
      setLoading(false);
    } catch (err) {
      toast.error(err.response.data?.err || "Room not found");
      navigate(`/hotel/${hotelId}`);
      setLoading(false);
    }
  };

  const buyRoom = async (roomId, price) => {
    try {
      if (!getUserDetails()?.token) {
        return toast.error("Please login using metamask!");
      }
      let forDays = countDays(toDate, fromR);
      let attributes = [];
      let booking_id_arr = [];
      setTransactionInfo(true);

      //set timezone
      const timezone = roomData?.hotel.timezone;

      for (let k = 0; k < room; k++) {
        for (let i = 0; i < forDays; i++) {
          let bookingId = uuidv4();
          const attribute = {
            salePriceWithoutTax: pricing.prices[i].basePrice,
            totalSalePriceWithTax: pricing.prices[i].price,
            discount: Number(discount / countDays(toDate, fromR).toFixed(2)),
            discount_amount: pricing.prices[i].discount,
            buk_cancellationFee,
            hotel_cancellation,
            previousSalePrice: null,
            currentSalePrice: pricing.prices[i].price,
            bukCommission: pricing.prices[i].basePrice * 0.05,
            bukTransferFee: Number(pricing.prices[i].price) * 0.02,
            cancellationCondition: roomData?.attributes["isCancellation"],
            fromDate: configure_check_in_date(addDaysTo(i, fromR)),
            toDate: configure_check_out_date(addDaysTo(i + 1, fromR)),
            nftImage: roomData.images[0],
            hotelOwnerAddress: roomData.hotel.walletAddress[0],
            checkIn: configure_check_in_date(addDaysTo(i, fromR)),
            checkOut: configure_check_out_date(addDaysTo(i + 1, fromR)),
            isSmokingRoom: roomData.attributes["isSmoking"],
            roomType: roomData.roomType,
            roomMaxOccupancy: roomData.maxOccupancy,
            isOnResale: false,
            transferable: roomData.isTransferable,
            bundelable: roomData.isBundelable,
            checkInStatus: "NOT CHECKED IN",
            bookingId: bookingId,
            timezone: timezone,
            checkinTimeUnix:
              getTimeStamp(
                configure_check_in_date(addDaysTo(i, fromR)),
                timezone
              ) / 1000,
            description: roomData?.description.replace(/<\/?[^>]+(>|$)/g, ""),
          };
          attributes.push(attribute);
          booking_id_arr.push(bookingId);
        }
      }

      setLoading(true);
      setIsTransaction(true);
      setAuthToken(getUserDetails()?.token);
      //1 check room available
      await ValidateServices.validateRoom(
        roomId,
        parseDate2(fromR),
        parseDate2(toDate),
        room
      );

      //2 create metamask payment
      //3 create booking after verification
      let totalPrice = price;

      console.info(
        "Attributes:",
        attributes,
        "totalPrice:",
        totalPrice,
        roomData,
        room,
        forDays,
        booking_id_arr,
        roomData.hotel?.uniqueId
      );

      await bookRooms(
        roomData,
        room,
        attributes,
        forDays,
        booking_id_arr,
        totalPrice,
        roomData.hotel?.uniqueId
      )
        .then(async (web3Res) => {
          if (web3Res.code == 4001) {
            setLoading(false);
            setIsTransaction(false);
            return toast.error("Metamask error!");
          }
          let nft_ids = [];
          if (forDays == 1 && room == 1) {
            nft_ids.push(web3Res.events.Booked.returnValues.nftId);
          } else {
            for (let n = 0; n < forDays * room; n++) {
              nft_ids.push(web3Res.events.Booked[n].returnValues.nftId);
            }
          }
          let buy_price = [];
          for (const element of pricing.prices) {
            const pu = Number(element.price);
            buy_price.push(pu);
          }
          const bookingData = {
            roomId: roomId,
            hotelId: hotelId,
            fromDate: fromR,
            toDate: toDate,
            buyPrice: buy_price,
            guest: guest,
            roomQty: room,
            contractAddress: contractAddress,
            numOfDays: forDays,
            web3Transactionhash: web3Res.transactionHash, //to be an array
            web3BookingId: nft_ids,
            booking_id_arr: booking_id_arr,
            numOfRooms: room,
            // usdc_buy_price: web3Res.events.
          };

          setAuthToken(getUserDetails()?.token);
          await BuyServices.createRoomBooking(bookingData);

          setLoading(false);
          setIsTransaction(false);
          navigate("/manageBooking");
          toast.success("Room booked successfully!");
        })
        .catch((err) => {
          setLoading(false);
          setIsTransaction(false);

          if (
            (err?.message).includes("insufficient funds for transfer") ||
            err.toString().includes("transfer amount exceeds balance")
          ) {
            return toast.error("Insufficient funds!");
          }

          if (err?.message) {
            return toast.error(
              (err?.message).includes(
                "MetaMask Tx Signature: User denied transaction signature."
              )
                ? "Request Denied!"
                : err?.message
                ? err?.message
                : "Oops! Some Error Ocurred."
            );
          }
          if (err?.message) {
            return toast.error(err?.message);
          }
          toast.error(err?.message || "Oops! Some Error Ocurred.");
        });
      setTransactionInfo(false);
    } catch (err) {
      console.log("err: ", err);
      console.log("err: ", typeof err);

      if ((err?.message).includes("insufficient funds for transfer")) {
        return toast.error("Insufficient funds!");
      }
      if (err?.message) {
        console.log(err?.message);
        return toast.error(err?.message || "Please Try Again!");
      }
      toast.error(err?.response.data.err || "Oops! Some Error Ocurred.");
      setLoading(false);
      setIsTransaction(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  useEffect(() => {
    getRoom();
  }, []);

  return (
    <div className="min-h-[400px]">
      {getUserDetails() ? (
        <>
          {loading ? (
            <PageLoader
              showTransactionMessage={transactionInfo}
              isTransaction={isTransaction}
            />
          ) : (
            <div className="app  min-h-[400px] !py-4">
              <div className="flex flex-col pb-6">
                <span className="font-bold text-3xl text-center">
                  Booking recap
                </span>
                <span className="text-center text-[#646465] font-medium text-sm">
                  You’re one step away from booking your room
                </span>
              </div>
              <SimpleDialog
                open={open}
                onClose={handleClose}
                hotelImages={images && images}
              />
              <div className="flex xl:flex-row flex-col-reverse gap-6">
                <div>
                  <div className="flex flex-col mobile:flex-row w-full gap-6 bg-white shadow">
                    <div className="parent-grid h-[310px] mobile:w-[450px] mobile:min-w-[450px]">
                      {images?.length > 3
                        ? images?.slice(0, 5).map((image, index) =>
                            index !== 4 ? (
                              <img
                                onClick={handleClickOpen}
                                src={image}
                                key={index + "roomImages"}
                                alt={index + " roomImages"}
                                className={`div${index} w-full h-full object-cover`}
                              />
                            ) : (
                              <div
                                className={`h-full w-full  relative div${index} object-cover`}
                                onClick={handleClickOpen}
                              >
                                <img
                                  src={image}
                                  className="absolute h-full  w-full"
                                />
                                {images.length > 5 && (
                                  <p className="absolute bg-stone-700/50 h-full w-full flex justify-center items-center text-white cursor-pointer top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                    +
                                    {images.length > 5 ? images.length - 5 : ""}{" "}
                                    more
                                  </p>
                                )}
                              </div>
                            )
                          )
                        : images &&
                          images.map((image, index) => (
                            <img
                              onClick={handleClickOpen}
                              src={image}
                              key={index + "roomImages"}
                              alt={index + " roomImages"}
                              className={`div${index} w-full h-full object-cover`}
                            />
                          ))}
                    </div>
                    <div className="flex flex-col my-2 mobile:my-6 px-4 mobile:p-0">
                      <p className="fontdesc text-2xl font-bold text-gray-900 hotelhead h-auto ">
                        {roomData?.hotel.name} ({roomData?.roomType})
                      </p>
                      <div className="flex  mt-2">
                        <img src={loc} className="h-4" alt="location" />
                        <label className=" text-sm font-normal text-gray-600 pl-2 pr-4 textsm">
                          {roomData?.hotel.address}, {roomData?.hotel.city},{" "}
                          {roomData?.hotel.country}
                        </label>
                      </div>
                      <div className="mt-2 pr-4 text-justify whitespace-pre-wrap paragraph">
                        <Description
                          description={roomData?.description}
                          viewMore={viewMore}
                          setViewMore={setViewMore}
                        />
                      </div>
                      <p className="text-lg font-bold pt-2">Amenities</p>
                      <div className="mt-2 gap-x-16 hel">
                        <div className="grid gap-x-4 gap-y-2 grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 justify-items-start w-full max-w-[800px]">
                          {roomData.hasOwnProperty("attributes") &&
                            Object.keys(roomData?.attributes).map(
                              (attribute) =>
                                roomData?.attributes[attribute] && (
                                  <div
                                    key={attribute}
                                    className="flex gap-x-4 amchng"
                                  >
                                    <span>
                                      <img
                                        src={amenties[attribute]?.img}
                                        className="w-6 h-6 min-h-6 min-w-6"
                                        alt=""
                                      />
                                    </span>
                                    <span className="text-xs flex items-center justify-center">
                                      {amenties[attribute]?.name}
                                    </span>
                                  </div>
                                )
                            )}

                          {roomData.roomPricing &&
                          roomData.roomPricing.roomPricing[0].cancellation.includes(
                            "Late Cancellation"
                          ) ? (
                            <div className="text-gray-600 mt-2 h-[18px]">
                              <img src={cancel} alt="Cancellation" />
                            </div>
                          ) : (
                            <div className="text-gray-600 mt-2 h-[18px]">
                              <img src={cancel} alt="Cancellation" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="shadow-lg mt-4 flex flex-col gap-4 p-8 hel bg-white">
                    <div className="flex items-center gap-2 text-2xl font-bold text-[#130A44]">
                      <img src={goodtoknowImg} alt="" />
                      <div>Good to know</div>
                    </div>
                    <div className="flex gap-2">
                      <div className="bg-[#2f69ff33] rounded-full w-6 h-6 flex justify-center items-center">
                        <ChevronRightRoundedIcon
                          fontSize="small"
                          sx={{ color: "#2F69FF" }}
                        />
                      </div>
                      <div>
                        To book you will have to confirm the transaction on your
                        wallet and your account will be charged immediately
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <div className="bg-[#2f69ff33] rounded-full w-6 h-6 flex justify-center items-center">
                        <ChevronRightRoundedIcon
                          fontSize="small"
                          sx={{ color: "#2F69FF" }}
                        />
                      </div>
                      <div>
                        The reservation will be created as an NFT on the Polygon
                        blockchain. You will become the official owner of this
                        booking and will be able to cancel or trade it until
                        20th October 2022 before the check-in date, whichever is
                        earlier.
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <div className="bg-[#2f69ff33] rounded-full w-6 h-6 flex justify-center items-center">
                        <ChevronRightRoundedIcon
                          fontSize="small"
                          sx={{ color: "#2F69FF" }}
                        />
                      </div>
                      <div>
                        You will have to show your NFT at the hotel reception to
                        prove you’re the owner
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <div className="bg-[#2f69ff33] rounded-full w-6 h-6 flex justify-center items-center">
                        <ChevronRightRoundedIcon
                          fontSize="small"
                          sx={{ color: "#2F69FF" }}
                        />
                      </div>
                      <div>
                        Before showing up at the hotel you can enter your
                        details in our online check-in. Please note the online
                        check-in is final and will not allow to cancel or trade
                        the NFT afterwards.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gradientBorder h-fit flex flex-col gap-4 min-w-[300px] mobile:min-w-[380px] bg-white p-4 shadow">
                  <div className="text-[22px] text-[#130A44] font-bold">
                    Your booking details
                  </div>
                  <div className="flex justify-between">
                    <div className="flex flex-col gap-1">
                      <span className="text-[#7E7E7E] text-sm">Check-in</span>
                      <span className="text-[#292931] font-medium">
                        {getDateFormatWithWeekday(fromR)}
                      </span>
                      <span className="text-xs text-[#7E7E7E]">From 15:00</span>
                    </div>
                    <div className="min-h-full w-[1px] bg-[#E7ECF2]"></div>
                    <div className="flex flex-col gap-1 ml-2">
                      <span className="text-[#7E7E7E] text-sm">Check-out</span>
                      <span className="text-[#292931] font-medium">
                        {getDateFormatWithWeekday(toDate)}
                      </span>
                      <span className="text-xs text-[#7E7E7E]">
                        Until 12:00
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-row gap-3 justify-start items-end">
                    <p className="text-medium text-[#7E7E7E] font-medium">
                      Stay duration
                    </p>
                    <p className="text-base">
                      <span className="font-bold">
                        {countDays(toDate, fromR)}
                      </span>{" "}
                      {countDays(toDate, fromR) > 1 ? "nights" : "night"}{" "}
                    </p>
                    <p className="text-base border-l-2 pl-2">
                      <span className="font-bold">{guest * room}</span>{" "}
                      {guest * room > 1 ? "guests" : "guest"}
                    </p>
                  </div>
                  <div className="flex flex-col gap-3 border-t-2 border-[#E7ECF2]">
                    <div className="text-[22px] text-[#130A44] pt-4 font-bold">
                      Your price summary{" "}
                      <font family="sans-serif" size="3">
                        (per night)
                      </font>
                    </div>
                    <div className="flex justify-between text-[20px]">
                      <span className="font-medium ">
                        Room x <span className="font-bold text-lg">{room}</span>
                      </span>
                      <span className="font-medium">
                        $
                        {(
                          Number(totalBasePrice).toFixed(2) /
                          countDays(toDate, fromR)
                        ).toFixed(2)}{" "}
                      </span>
                    </div>
                    {hotelTax &&
                      hotelTax.map((taxes, i) => (
                        <div className="flex justify-between text-base" key={i}>
                          <span>
                            {taxes.name?.toUpperCase()} ({taxes.value}%)
                          </span>
                          {tax &&
                            tax.map((taxvalue, k) => (
                              <>
                                {i == k ? (
                                  <span>
                                    $
                                    {(Number(taxvalue.value) * room).toFixed(2)}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </>
                            ))}
                        </div>
                      ))}
                    {/* <div className="flex justify-between text-base" >
                      <span>
                        Discount
                      </span>
                      <span>${discount}</span>

                    </div>)} */}{" "}
                    <div className="flex justify-between text-base">
                      <span>Buk Commission</span>
                      <span>${(buk_commission * room).toFixed(2)}</span>
                    </div>
                    {Boolean(discount && discount > 0) && (
                      <div className="flex justify-between text-base">
                        <span>Discount</span>
                        <span>
                          ${discount / countDays(toDate, fromR).toFixed(2)}
                        </span>
                      </div>
                    )}
                    {/* <div className="flex justify-between text-base">
                                    <span>Cleaning fee 2%</span>
                                    <span>
                                        $
                                        {(Number(pricing.sum) * 0.02).toFixed(
                                            2
                                        )}
                                    </span>
                                </div> */}
                    {/* <div className="flex justify-between text-base">
                                    <span>City tax 2%</span>
                                    <span>
                                        $
                                        {(Number(pricing.sum) * 0.02).toFixed(
                                            2
                                        )}
                                    </span>
                                </div>
                                <div className="flex justify-between text-base">
                                    <span>Buk commission 5%</span>
                                    <span>
                                        $
                                        {(Number(pricing.sum) * 0.05).toFixed(
                                            2
                                        )}
                                    </span>
                                </div> */}
                  </div>
                  <div className="flex justify-between pt-4 text-[#130A44] gap-3 border-t-2 border-[#E7ECF2]">
                    <div className="flex flex-col">
                      <span className="text-[22px] font-bold">Price</span>
                      <span className="font-medium">
                        {room} room x {countDays(toDate, fromR)} nights{" "}
                      </span>
                    </div>
                    <div className="text-4xl font-bold flex justify-center items-center">
                      ${(Number(pricing.sum) * room).toFixed(2)}
                    </div>
                  </div>
                  <span className="flex fontba\sic hidden mobile:block text-xs text-gray-500">
                    $
                    {(
                      Number(pricing.sum).toFixed(2) / countDays(toDate, fromR)
                    ).toFixed(2)}{" "}
                    average per room per night
                  </span>

                  <div className="flex fontba\sic hidden mobile:block text-xs text-gray-500">
                    *All prices are inclusive of taxes
                  </div>

                  <em className="flex justify-center items-center border-t-2 border-[#E7ECF2] text-sm pt-8 pb-4">
                    <div>
                      <div>
                        Note 1 : All prices are in USDC. The exact total amount
                        to be paid in USD might vary slightly.
                      </div>
                      &nbsp;
                      <div>
                        Note 2: All bookings made on BukTrips are for single
                        occupancy, in case of additional guests in the same room
                        payment can be directly made at the hotel desk.
                      </div>
                      &nbsp;
                      <div>
                        {/* {roomData.roomPricing &&
                                roomData.roomPricing.roomPricing[0].cancellation.includes(
                                    "Late Cancellation"
                                )
                                    ? `Cancellation or trading allowed until ${getDateFormatDay(
                                          decreaseDays(fromR)
                                      )}, Cancellation charges ${
                                          100 -
                                          roomData.roomPricing.roomPricing[0]
                                              .percentage
                                      }% before ${
                                          roomData.roomPricing.roomPricing[0]
                                              .daysBefore
                                      } days.`
                                    : `Cancellation or trading allowed until ${getDateFormatDay(
                                          decreaseDays(fromR)
                                      )}`} */}
                        Note 3: You will only be able to trade or cancel this
                        booking until{" "}
                        <strong>
                          {roomData.roomPricing &&
                          roomData.roomPricing.roomPricing[0].cancellation.includes(
                            "Late Cancellation"
                          )
                            ? getDateFormatDay(cancellationDate)
                            : getDateFormatDay(fromR)}
                        </strong>
                        .In case of cancellation you will receive{" "}
                        <strong>
                          $
                          {roomData.roomPricing &&
                          roomData.roomPricing.roomPricing[0].cancellation.includes(
                            "Late Cancellation"
                          )
                            ? Number(refund).toFixed(2)
                            : (Number(pricing.sum) * room).toFixed(2)}{" "}
                        </strong>{" "}
                        as refund amount.
                      </div>
                    </div>
                  </em>

                  <div className="flex justify-center">
                    <button
                      onClick={() => buyRoom(roomId, totalPrice)}
                      className="bg-[#2F69FF] text-white rounded-full px-12 py-3 text-xl"
                    >
                      Pay Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="w-full h-full flex my-6 text-2xl items-start justify-center card333">
          Please Connect your wallet to Buy Room
        </div>
      )}
    </div>
  );
};

export default BuyNow;
