import loaderGif from "../../images/transaction-loader.webp";
import logo from "../../images/logo.svg";
const TransactionLoader = () => {
  return (
    <div className="w-[300px] h-[100vh] flex flex-col justify-center gap-4">
      <img src={loaderGif} alt="loader" />
      <div className="flex justify-center font-bold">
        Cmon Blockchain.. Work faster!
      </div>
    </div>
  );
};

export default TransactionLoader;
