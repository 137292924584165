import { createContext, useState } from "react";
import { connectWallet } from "../../services/web3token";

export const ManagerContext = createContext()

export const ManagerProvider = ({ children }) => {
  const [hotels, setHotels] = useState([
    "Wellington Mews",
    "Wellington Mew",
    "Wellingtons Mews",
    "Wellingtons Mew",
]);
const [hotelId,setHotelId] = useState('');
  return (
    <ManagerContext.Provider value={{ hotels, setHotels, hotelId, setHotelId }}>
            {children}
    </ManagerContext.Provider>
  )
}




