import {
  ALL_ROOM_SUCCESS,
  ALL_ROOM_REQUEST,
  ALL_ROOM_FAIL,
  CLEAR_ERRORS,
} from "../constants/roomConstants";

export const roomReducer = (state = { rooms: [] }, action) => {
  switch (action.type) {
    case ALL_ROOM_REQUEST:
      return {
        loading: true,
        rooms: [],
      };
    case ALL_ROOM_SUCCESS:
      return {
        loading: false,
        rooms: action.payload?.hotel,
        metadata: action.payload?.metadata
      };
    case ALL_ROOM_FAIL:
      return {
        loading: false,
        rooms: [],
        error: action?.payload,
      };
    case CLEAR_ERRORS:
      return {
        loading: false,
        rooms: [],
        error: null,
      };
    default:
      return {
        loading: false,
        rooms: [],
        error: null,
      };
  }
};
