import React, { useState, useEffect, useMemo } from "react";
import "./Details.css";
import hotel from "../../images/hotel.svg";
import loc from "../../images/loc.svg";
import editIcon from "../../images/roomEdit.svg";
import { HotelUserServices, ManagerServices } from "../../services/supplier";
import PageLoader from "../../components/PageLoader/PageLoader";
import FileUpload from "../../components/FileUpload/FileUpload";
import { toast } from "react-toastify";
import HotelAmenities from "../../components/Amenities/HotelAmenities";
import countryList from "react-select-country-list";
import RegistrationStepOne from "../Registration/RegistrationStepOne";
import { SimpleDialog } from "../../pages/HotelPage/HotelPage";
import {getTokenUrl} from "../../services/web3Services";
import Description from "../../components/Description/Description";
import { contractAddress } from "../../config/config"

const stars = ["2 Star", "3 Star", "4 Star", "5 Star"];

const amenitiesList = {
    hasAirCondition: "Air Conditioning" ,
    hasFreeParking: "Parking" ,
    hasFreeWifi: "Free Wifi" ,
    hasRestaurant:"Restaurant" ,
    hasSpa:"Spa",
    hasSwimmingPool: "Pool",
    isWalletFriendly: "Wallet Friendly"
};
const Details = ({ hotelId }) => {
    const [hotelStars, setHotelStars] = useState(stars[0]);
    const [images, setImages] = useState([hotel, hotel, hotel]);
    const [hotelData, setHotelData] = useState("");
    const [loading, setLoading] = useState(true);
    const [showEdit, setShowEdit] = useState(false);
    const [files, setFiles] = useState({});
    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("");
    const options = useMemo(() => countryList().getData(), []);
    const [treasuryWallet, setTreasuryWallet] = useState("");
    const [viewMore, setViewMore] = useState(false);
    const [vatRate, setVatRate] = useState(10);
    const [open, setOpen] = useState(false);
    const [newTags,setNewTags] = useState([])
    const [description,setDescription] = useState('')
    const [hotelDetails, setHotelDetails] = useState({
        account: "",
        email: "",
        hotel: "",
        hotelAddress: "",
        city: "",
        state: "",
        country: "",
        phone: phone,
        zip: "",
        location: "",
        latitude: "",
        longitude: "",
        description: description,
        hotelStar: "3",
        tags: [],
        tagInput: "",
        websiteUrl: "",
        files: "",
        mapFile: [],
        walletAddress: treasuryWallet,
        phone: phone,
        vat: vatRate,
    });
    const countryHandler = (value) => {
        setHotelDetails({
            ...hotelDetails,
            country: value?.label,
        });
        setCountry(value);
    };

    const handleVatRate = (evt) => {
        if (evt.target.value < 1 || evt.target.value > 50) {
            return;
        }
        setVatRate(evt.target.value);
    };
    const handleStepOneChange = (evt) => {
        let value = evt.target.value;

        setHotelDetails({
            ...hotelDetails,
            [evt.target.name]: value,
        });
    };

    const getHotelDetails = async (id) => {
        try {
            setLoading(true);
            const res = await HotelUserServices.getHotelById(id);
            const resData = await res.data?.data;
            let imgArr = [];
            resData.images.map((img) => imgArr.push(img.url));
            // resData.attributes
            let allTags = [];
            Object.keys(resData.attributes).forEach(function (key, index) {
                if (resData.attributes[key]) {
                    allTags.push(key);
                }
            });
            for (let am = 0; am < allTags.length; am++) {
                allTags[am] = amenitiesList[allTags[am]];
            }
            setHotelData(resData);
            setPhone(resData.phoneNumber);
            setTreasuryWallet(resData.walletAddress);
            setDescription(resData.description)
            setHotelDetails({
                account: resData?.accountName,
                email: resData.email,
                hotel: resData.name,
                hotelAddress: resData.address,
                city: resData.city,
                state: resData.state,
                country: resData.country,
                phone: resData.phoneNumber,
                zip: resData.zipCode,
                location: "",
                latitude: resData.locationCordinates.latitude,
                longitude: resData.locationCordinates.longitude,
                description: resData.description,
                hotelStar: resData.starRatings,
                tags: allTags,
                tagInput: "",
                websiteUrl: resData?.websiteUrl,
                files: imgArr,
                mapFile: [],
                walletAddress: treasuryWallet,
                vat: resData.vat,
                phone: resData?.phone,
            });
            setVatRate(resData.vat)
            const countryObj = options.find(
                (option) => option.label === resData.country
            );
            setCountry({
                value: countryObj.value,
                label: countryObj.label,
            });
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };
    const handleHotelDetails = (evt) => {
        let value = evt.target.value;
        setHotelDetails({
            ...hotelDetails,
            [evt.target.name]: value,
        });
    };
    const updateDetails = async (e) => {
        try {
            setLoading(true);
            e.preventDefault();
            hotelDetails.phoneNumber = phone;
            hotelDetails.walletAddress = treasuryWallet;
            hotelDetails.vat = vatRate;
            hotelDetails.description = description


            const newAttributes = {
                hasSwimmingPool: newTags.includes("Pool"),
                hasFreeWifi: newTags.includes("Free Wifi"),
                hasFreeParking: newTags.includes("Parking"),
                hasAirCondition: newTags.includes("Air Conditioning"),
                hasSpa: newTags.includes("Spa"),
                isWalletFriendly:newTags.includes("Wallet Friendly"),
                hasRestaurant: newTags.includes("Restaurant")
            }
            hotelDetails.attributes = newAttributes
            const updateHotel = await ManagerServices.updateHotelByManager(
                hotelId,
                hotelDetails
            );
            setLoading(false);
            toast.success("Hotel details updated successfully!");
            await getHotelDetails(hotelId);
            setShowEdit(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
            toast.error("Error");
        }
    };
    const updateImageHotelDB = async (image) => {
        try {
            const del = await  ManagerServices.updateImageHotel({
                image: image,
                hotelId: hotelId
            })
        }
        catch(err) {
            console.log(err);
        }
    }
    const addImageHotelDB = async (image) => {
        try {
            const cre = await ManagerServices.addImageHotelDB({
                image: image,
                hotelId: hotelId
            })
        }
        catch(err) {
            console.log(err);
        }
    }
    const showEditDetatils = async () => {
        setShowEdit(true);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (value) => {
        setOpen(false);
    };
    useEffect(() => {
        getHotelDetails(hotelId);
    }, [hotelId]);


    return (
        <>
            {loading ? (
                <PageLoader />
            ) : !showEdit ? (
                <div className="hcard m-auto mt-10 flex flex-col gap-y-4 mb-14 max-w-[1360px] w-full p-6  min-h-[400px]">
                    <SimpleDialog
                        open={open}
                        onClose={handleClose}
                        hotelImages={hotelData && hotelData?.images}
                    />
                    <div className="flex justify-between items-center">
                        <div className="flex items-center gap-2 mobile:gap-4">
                            <p className="text-lg  text-black font-bold mobile:text-2xl">
                                {hotelData?.name}
                            </p>
                            <button
                                className={`rounded-full border-2 border-[#7E7E7E] px-4 py-1 text-[#7E7E7E] cursor-default`}
                            >
                                {hotelData?.starRatings} Star
                            </button>
                        </div>
                        <button
                            onClick={showEditDetatils}
                            className="cursor-pointer"
                        >
                            <img src={editIcon} alt="" />
                        </button>
                    </div>
                    <div className=" flex items-center gap-x-2">
                        <img src={loc} alt="" />
                        <p className="text-gray-600">
                            {hotelData?.address} {hotelData?.city}{" "}
                            {hotelData?.country}
                        </p>
                    </div>
                    <div className="custom-grid !gap-2 h-[400px] mobile:h-[300px]">
                        {hotelData?.images.map((image, index) => {
                            if (index > 1) return;
                            return (
                                <img
                                    key={image?.url}
                                    src={image?.url}
                                    alt=""
                                    className="w-full h-full object-cover overflow-hidden"
                                />
                            );
                        })}
                        <div
                            className="h-full w-full  relative"
                            onClick={handleClickOpen}
                        >
                            {hotelData?.images.length>=3 && <img
                                src={ hotelData?.images[2].url}
                                className="absolute w-full h-full object-cover overflow-hidden"
                            />}
                            {hotelData?.images.length > 3 && (
                                <p className="absolute bg-stone-700/50 h-full w-full flex justify-center items-center text-white cursor-pointer top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                    +
                                    {hotelData?.images.length > 3
                                        ? hotelData?.images.length - 3
                                        : ""}{" "}
                                    more
                                </p>
                            )}
                        </div>
                    </div>
                    <p className=" text-black font-bold">Particulars</p>
                    {hotelData.hasOwnProperty("attributes") && (
                        <HotelAmenities attributes={hotelData.attributes} />
                    )}
                    <p className=" text-black font-bold">Phone Number</p>
                    <p className=" text-gray-500">+{hotelData?.phoneNumber}</p>
                    <p className=" text-black font-bold">Token ID:</p>
                    <p className=" text-gray-500" >{hotelData?.uniqueId}</p>

                    <p className=" text-black font-bold">Description</p>
                    <Description
                        viewMore={viewMore}
                        setViewMore={setViewMore}
                        description={hotelData?.description}
                    />
                </div>
            ) : (
                <div className=" m-auto flex flex-col gap-y-4 mb-14 max-w-[1360px] w-full p-6  min-h-[400px]">
                    <RegistrationStepOne
                        stepData={hotelDetails}
                        setStepData={setHotelDetails}
                        stepOneData={hotelDetails}
                        handleStepOneChange={handleHotelDetails}
                        setStepOneData={setHotelDetails}
                        setTreasuryWallet={setTreasuryWallet}
                        treasuryWallet={treasuryWallet}
                        phoneNo={phone}
                        setPhone={setPhone}
                        setCountry={setCountry}
                        country={country}
                        countryHandler={countryHandler}
                        options={options}
                        vatRate={vatRate}
                        handleVatRate={handleVatRate}
                        setNewTags={setNewTags}
                        hotelUpdate={true}
                        updateImageHotelDB={updateImageHotelDB}
                        addImageHotelDB={addImageHotelDB}
                        setDescription={setDescription}
                        description={description}
                        noNext
                    />

                    <div className="flex justify-center mobile:justify-end items-center gap-4">
                        <button
                            type="button"
                            onClick={() => setShowEdit(false)}
                            className="bg-white border-2 border-[#7E7E7E] text-[#7E7E7E] font-medium px-6 py-2 rounded-full"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={(e) => updateDetails(e)}
                            className="bg-[#292931] border-2 border-[#292931] text-[#fff] font-medium px-6 py-2 rounded-full"
                        >
                            Save
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default Details;
