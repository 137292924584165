import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import {
  FormControlLabel,
  Switch,
  TextField,
  InputAdornment,
  Icon,
  Radio,
  Checkbox,
  Grid,
} from "@mui/material";
import MobileView from '../Modals/MobileView/MobileView'
import cal from "../../images/cal.svg";
import roomppl from "../../images/roomppl.svg";
import down from "../../images/down.svg";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FrameComponent1 from "./FrameComponent1";
import PortalPopup from "./PortalPopup";
import {
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Slider as ChakraSlider,
} from "@chakra-ui/react";
import SimpleImageSlider from "react-simple-image-slider";
import { useNavigate } from "react-router-dom";
import styles from "../Navbar/TradingPlatformHome.module.css";
import "./Search.css";
import { Link, useLocation } from "react-router-dom";
import { SearchContext } from "../../context/search/SearchContext";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from "../../Redux/actions/roomAction";
import { toast } from "react-toastify";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import CloseIcon from "@mui/icons-material/Close";
import { getHotel, HotelUserServices } from "../../services/supplier";
import AmenitiesSymbol from "../../components/Amenities/AmenitiesSymbol";
import Slider from "../Swiper/Slider";
import { CheckBox } from "@mui/icons-material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import moment from "moment";

//images
import image52 from "../../images/images/image-52.png";
import Maskgroup1 from "../../images/images/Mask-group_1.png";
import Maskgroup2 from "../../images/images/Mask-group_2.png";
import Maskgroup3 from "../../images/images/Mask-group-2.png";

import image49 from "../../images/images/image-49.png";
import image50 from "../../images/images/image-50.png";
import image51 from "../../images/images/image-51.png";
import Maskgroup from "../../images/images/Mask-group.png";
import { Col, Row } from "react-bootstrap";

// requires a loader

const Search = () => {
  const [isFramePopupOpen, setFramePopupOpen] = useState(false);
  const [sizeButonDateTimePickerValue, setSizeButonDateTimePickerValue] =
    useState(null);
  const [sizeButonDateTimePicker1Value, setSizeButonDateTimePicker1Value] =
    useState(null);
  const [index, setIndex] = useState(0);
  const ref = useRef(null);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const images = [
    { url: "../group-3272@2x.png" },
    { url: "../hotel1.jpg" },
    { url: "../hotel2.jpg" },
  ];
  const dispatch = useDispatch;
  const [keyword, setKeyword] = useState("Lisbon");
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [showSuggestion2, setShowSuggestion2] = useState(false);
  const [searchRes, setSearchRes] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { error } = useSelector((value) => value.rooms);
  const inputRef = useRef(null);
  const inputRef2 = useRef(null);
  const focusRef = useRef(null);
  // const [value, onChange] = useState([new Date(), new Date()]);
  const {
    search,
    setSearch,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    guest,
    setGuest,
    room,
    setRoom,
    dateValue,
    handleDateRangeSelect,
  } = useContext(SearchContext);

  const searchSubmitHandler = (e) => {
    // e.preventDefault();
    // if (startDate > endDate) {
    //   let newDate = startDate.getDate() + 1;
    //   setEndDate(new Date(new Date().setDate(newDate)));
    // }
    if (!keyword) {
      return toast.error("Please enter your destination or hotel name.");
    }
    if (keyword.trim()) {
      navigate(`/results/${keyword}`,{state:{name:keyword}});
    } else {
      navigate("/results/");
    }
  };

  const getSearches = async () => {
    try {
      const getData = await HotelUserServices.searchSuggestion();
      setSearchRes(getData.data?.search);
    } catch (err) {
      console.log("err");
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleCitySearch = (value) => {
    setKeyword(value);
    setSearch(value);
    setShowSuggestion(false);
    setShowSuggestion2(false);
  };
  const handleClearText = () => {
    setSearch("");
    setKeyword("");
    focusRef.current && focusRef.current.focus();
  };
  const onClickHandle = async () => {
    try {
      setShowSuggestion(true);
      setShowSuggestion2(true);
      if (searchRes?.length == 0) await getSearches();
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (error && location.pathname !== "/") {
      dispatch(clearErrors());
      toast.error("Sorry, no hotels there!");
      // window.location.reload();
    }
  }, [error]);
  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setShowSuggestion(false);
      return;
    }
    if (inputRef2.current && !inputRef2.current.contains(event.target)) {
      setShowSuggestion2(false);
      return;
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showSuggestion]);
  useEffect(() => {
    getSearches();
  }, []);

  const onFrameButtonClick = useCallback(() => {
    // Please sync "Connect wallet" to the project
  }, []);

  const openFramePopup = useCallback(() => {
    setFramePopupOpen(true);
  }, []);

  const closeFramePopup = useCallback(() => {
    setFramePopupOpen(false);
  }, []);

  const onGroupContainer4Click = useCallback(() => {
    navigate("/trading-platform-search-results-by-date");
  }, [navigate]);

  const onGroupContainer1Click = useCallback(() => {
    navigate("/trading-platform-search-results-by-date");
  }, [navigate]);

  const onGroupContainer8Click = useCallback(() => {
    navigate("/trading-platform-search-results-by-date");
  }, [navigate]);

  const onGroupContainer11Click = useCallback(() => {
    navigate("/trading-platform-search-results-by-date");
  }, [navigate]);

  const onGroupContainer14Click = useCallback(() => {
    navigate("/trading-platform-search-results-by-date");
  }, [navigate]);

  const onGroupContainer17Click = useCallback(() => {
    navigate("/trading-platform-search-results-by-date");
  }, [navigate]);

  const onGroupContainerClick = useCallback(() => {
    navigate("/trading-platform-search-results-by-date");
  }, [navigate]);

  const onGroupContainer23Click = useCallback(() => {
    // Please sync "-------" to the project
  }, []);

  const onBulletIconClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='groupContainer1']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onBulletIcon1Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='groupContainer']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onBulletIcon2Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='image54']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onFashionWeekContainerClick = useCallback(() => {
    navigate("/trading-platform-search-results-by-date");
  }, [navigate]);

  const onFormula1ContainerClick = useCallback(() => {
    navigate("/trading-platform-search-results-by-date");
  }, [navigate]);

  const onWorldCupContainerClick = useCallback(() => {
    navigate("/trading-platform-search-results-by-date");
  }, [navigate]);

  const onVeniceFilmFestivalClick = useCallback(() => {
    navigate("/trading-platform-search-results-by-date");
  }, [navigate]);

  const onFashionWeekContainer1Click = useCallback(() => {
    navigate("/trading-platform-search-results-by-date");
  }, [navigate]);

  const onFormula1Container1Click = useCallback(() => {
    navigate("/trading-platform-search-results-by-date");
  }, [navigate]);

  const onWorldCupContainer1Click = useCallback(() => {
    navigate("/trading-platform-search-results-by-date");
  }, [navigate]);

  const onBulletIcon5Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='veniceFilmFestival']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const findHotel = async (date) => {
    const splitDate = date.split("-");
    const fromDate = splitDate[0];
    const toDate = splitDate[1];
    const newFromDate = new Date(fromDate);
    const newToDate = new Date(toDate);
    const gg = await handleDateRangeSelect([newFromDate, newToDate]);
    console.log("gg", gg);
    if (gg) {
      searchSubmitHandler();
    }
    // if (dateValue === [newFromDate, newToDate]) {
    //   console.log("egydgyefnguyfgfyegffuyguy");
    // }
    // console.log("dateV", dateValue === [newFromDate, newToDate], dateValue, [
    //   newFromDate,
    //   newToDate,
    // ]);
    // searchSubmitHandler();
  };

  return (
    <div>
      <div className="wf-section">
        <div className="container-2 w-container">
          <div className="w-layout-grid grid-clone">
            <div
              id="w-node-_3e37a75c-c043-8d24-66bb-f6904354c9fd-3fc478c7"
              className="div-block"
            >
              <div
                data-delay="4000"
                data-animation="slide"
                className="w-slider"
                data-autoplay="false"
                data-easing="ease"
                data-hide-arrows="false"
                data-disable-swipe="false"
                data-autoplay-limit="0"
                data-nav-spacing="3"
                data-duration="500"
                data-infinite="true"
              >
                <SimpleImageSlider
                  width="100%"
                  height="100%"
                  images={images}
                  showBullets={true}
                  showNavs={true}
                />
              </div>
            </div>
            <div
              id="w-node-_938c8522-bbe5-5ab1-bc18-410e003b9435-3fc478c7"
              className="div-block-2"
            >
              <form onSubmit={searchSubmitHandler}>
                <div>
                  <div>
                    <div ref={inputRef}>
                      <TextField
                        style={{ width: "100%" }}
                        color="primary"
                        variant="outlined"
                        defaultValue="Lisbon"
                        type="text"
                        label="City / Hotel / Event*"
                        size="medium"
                        margin="none"
                        ref={ref}
                        onChange={() => setShowSuggestion(true)}
                        autoComplete="off"
                        disabled
                      />
                    </div>
                    <div>
                      <Grid container spacing={2} mt={2}>
                        <Grid item xs={12} md={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  fontSize: "12px",
                                  paddingRight: "16px",
                                  marginRight: "20px",
                                }}
                                className="fontdesc text-xs font-normal text-gray-500"
                              >
                                Check-in
                              </p>
                            </div>
                            <div>
                              <p
                                style={{
                                  fontSize: "12px",
                                  padding: "0px 16px",
                                }}
                                className="fontdesc text-xs font-normal text-gray-500 "
                              >
                                Check-out
                              </p>
                            </div>
                          </div>
                          <div>
                            <div
                              style={{
                                justifyContent: "start",
                                display: "flex",
                              }}
                            >
                              <img
                                className="mr-[2px]"
                                src={cal}
                                width={13}
                                height={13}
                              />
                              <DateRangePicker
                                onChange={handleDateRangeSelect}
                                value={dateValue}
                                minDate={new Date()}
                                format="dd/MM/y"
                                onFocus={(e) => e.target.blur()}
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6} className="borderToCheck">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            <p className="fontdesc text-xs font-normal text-gray-500 ml-1">
                              <span className="text-[10px]">
                                Rooms and Guests(Per Room)
                              </span>
                            </p>
                          </div>
                          <div className="flex items-center justify-content-start">
                            <img src={roomppl} width={13} height={13} />
                            <div className="flex justify-center">
                              <div>
                                <div className="search-dropdown dropdown relative">
                                  <div
                                    className="dropdown-toggle cursor-pointer leading-tight transition duration-150 ease-in-out flex items-center whitespace-nowrap"
                                    type="button"
                                    id="dropdownMenuButton2"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <p
                                      className="fontdesc text-sm font-medium text-gray-800 ml-1 mb-0"
                                      id="roomTxtChng"
                                    >
                                      <span className="font-bold text-base">
                                        {room}{" "}
                                      </span>
                                      {room > 1 ? "Rooms" : "Room"}
                                    </p>
                                    <img
                                      src={down}
                                      width={10}
                                      height={10}
                                      className="cursor-pointer ml-1"
                                    />
                                  </div>
                                  <ul
                                    className="dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-sm shadow-lg mt-1 hidden m-0 bg-clip-padding border-none"
                                    aria-labelledby="dropdownMenuButton2"
                                  >
                                    <li className="w-32 cursor-pointer">
                                      <span
                                        className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                                        onClick={() => setRoom(1)}
                                      >
                                        1 Room
                                      </span>
                                    </li>
                                    <li className="w-32 cursor-pointer">
                                      <span
                                        className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                                        onClick={() => setRoom(2)}
                                      >
                                        2 Rooms
                                      </span>
                                    </li>
                                    <li className="w-32 cursor-pointer">
                                      <span
                                        className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                                        onClick={() => setRoom(3)}
                                      >
                                        3 Rooms
                                      </span>
                                    </li>
                                    <li className="w-32 cursor-pointer">
                                      <span
                                        className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                                        onClick={() => setRoom(4)}
                                      >
                                        4 Rooms
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="flex justify-center">
                              <div>
                                <div className="search-dropdown dropdown relative">
                                  <div
                                    className="dropdown-toggle cursor-pointer leading-tight transition duration-150 ease-in-out flex items-center whitespace-nowrap"
                                    type="button"
                                    id="dropdownMenuButton2"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <p
                                      className="fontdesc text-sm font-medium text-gray-800 ml-1 mb-0"
                                      id="adultTxtChng"
                                    >
                                      <span className="font-bold text-base">
                                        {guest}{" "}
                                      </span>
                                      {guest > 1 ? "Adults" : "Adult"}
                                    </p>
                                    <img
                                      src={down}
                                      width={10}
                                      height={10}
                                      className="cursor-pointer ml-1"
                                    />
                                  </div>
                                  <ul
                                    className="dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-sm shadow-lg mt-1 hidden m-0 bg-clip-padding border-none"
                                    aria-labelledby="dropdownMenuButton2"
                                  >
                                    <li className="w-32 cursor-pointer">
                                      <span
                                        className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                                        onClick={() => setGuest(1)}
                                      >
                                        1 Adult
                                      </span>
                                    </li>
                                    <li className="w-32 cursor-pointer">
                                      <span
                                        className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                                        onClick={() => setGuest(2)}
                                      >
                                        2 Adults
                                      </span>
                                    </li>
                                    <li className="w-32 cursor-pointer">
                                      <span
                                        className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                                        onClick={() => setGuest(3)}
                                      >
                                        3 Adults
                                      </span>
                                    </li>
                                    <li className="w-32 cursor-pointer">
                                      <span
                                        className="dropdown-item text-xs py-1 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-[#2f69ff1a]"
                                        onClick={() => setGuest(4)}
                                      >
                                        4 Adults
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <div className="mt-4">
                        {/* <div>
                          <div className="flex">
                            <FormControlLabel control={<Checkbox />} />
                            <p className="flex items-center m-0">
                              Show all available options and combinations within
                              this date range
                            </p>
                          </div>
                        </div> */}
                        <div>
                          <button
                            style={{
                              border: 0,
                              padding: "12px 29px",
                              backgroundColor: "#292931",
                              marginTop: "10px",
                              display: "flex",
                              flexDirection: "row",
                              boxSizing: "border-box",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            type="submit"
                            onClick={handleSearch}
                          >
                            <div className={styles.searchDiv}>Search</div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="section wf-section">
        <div className="container-3 w-container">
          <div className="text-block-2">Browse hotels for upcoming events</div>
          <div className="w-layout-grid grid-2">
            <div
              id="w-node-_6047cdf6-1b5a-31af-f4b6-c6913c38b941-3fc478c7"
              className="div-block-3"
              onClick={() => findHotel("28 Oct 2022 - 30 oct 2022")}
            >
              <div className="div-block-5">
                <img src={image50} loading="lazy" width="472" alt="" />
              </div>
              <div className="div-block-4">
                <div className="text-block-2">ETH Lisbon</div>
                <div className="text-block-3">28 Oct - 30 oct</div>
                <div className="text-block-3">Lisbon, Portugal</div>
                <div className="from">From</div>
                <div className="price">$116</div>
              </div>
            </div>
            <div
              id="w-node-_10be3560-9f62-89a9-0dfd-403497807495-3fc478c7"
              className="div-block-3"
              onClick={() => findHotel("30 Oct 2022 - 04 nov 2022")}
            >
              <div className="div-block-5">
                <img src={image51} loading="lazy" width="472" alt="" />
              </div>
              <div className="div-block-4">
                <div className="text-block-2">FIL Lisbon</div>
                <div className="text-block-3">30 Oct - 04 nov</div>
                <div className="text-block-3">Lisbon, Portugal</div>
                <div className="from">From</div>
                <div className="price">$139</div>
              </div>
            </div>
            <div
              id="w-node-_198be254-5902-2d05-2a23-887be15d156d-3fc478c7"
              className="div-block-3"
              onClick={() => findHotel("01 Nov 2022 - 04 Nov 2022")}
            >
              <div className="div-block-5">
                <img src={image49} loading="lazy" width="472" alt="" />
              </div>
              <div className="div-block-4">
                <div className="text-block-2">Websummit Lisbon</div>
                <div className="text-block-3">01 Nov - 04 Nov</div>
                <div className="text-block-3">Lisbon, Portugal</div>
                <div className="from">From</div>
                <div className="price">$139</div>
              </div>
            </div>
            <div
              id="w-node-_6c533dcf-f997-2a20-ab76-fbace487ba7c-3fc478c7"
              className="div-block-3"
              onClick={() => findHotel("04 Nov 2022 - 07 Nov 2022")}
            >
              <div className="div-block-5">
                <img src={Maskgroup} loading="lazy" width="472" alt="" />
              </div>
              <div className="div-block-4">
                <div className="text-block-2">Solana Breakpoint</div>
                <div className="text-block-3">04 Nov - 07 Nov</div>
                <div className="text-block-3">Lisbon, Portugal</div>
                <div className="from">From</div>
                <div className="price">$99</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section wf-section">
        <div className="container-3 w-container">
          <div className="text-block-2">
            Browse our hottest destinations and hotels (Coming Soon)
          </div>
          <div className="w-layout-grid grid-2 ">
            <div
              id="w-node-_94c4133d-7315-e261-8583-8b5a0d670c12-3fc478c7"
              className="div-block-4"
            >
              <div className="div-block-5">
                <img
                  src={image52}
                  loading="lazy"
                  width="472"
                  alt=""
                  className="image"
                />
              </div>
              <div className="div-block-4">
                <div className="text-block-2">Dubai</div>
                <div className="text-block-3">23 May - 26 May</div>
                <div className="text-block-3">United Arab Emirates</div>
                <div className="from">From</div>
                <div className="price">$312</div>
              </div>
            </div>
            <div
              id="w-node-_9f239c38-6873-3339-0fdf-7c3433ea8e68-3fc478c7"
              className="div-block-4"
            >
              <div className="div-block-5">
                <img
                  src={Maskgroup1}
                  loading="lazy"
                  width="472"
                  alt=""
                  className="image"
                />
              </div>
              <div className="div-block-4">
                <div className="text-block-2">Abu Dhabi</div>
                <div className="text-block-3">16 Dec - 18 Dec</div>
                <div className="text-block-3">Qatar</div>
                <div className="from">From</div>
                <div className="price">$482</div>
              </div>
            </div>
            <div
              id="w-node-_54450170-e4f1-ed7b-d8f1-a2f217c28be2-3fc478c7"
              className="div-block-4"
            >
              <div className="div-block-5">
                <img
                  src={Maskgroup2}
                  loading="lazy"
                  width="472"
                  alt=""
                  className="image"
                />
              </div>
              <div className="div-block-4">
                <div className="text-block-2">Goa</div>
                <div className="text-block-3">18 Mar - 20 Mar</div>
                <div className="text-block-3">India</div>
                <div className="from">From</div>
                <div className="price">$672</div>
              </div>
            </div>
            <div
              id="w-node-_819e799a-3c35-348c-a8cd-47cd1f3333f1-3fc478c7"
              className="div-block-4"
            >
              <div className="div-block-5">
                <img
                  src={Maskgroup3}
                  loading="lazy"
                  width="472"
                  alt=""
                  className="image"
                />
              </div>
              <div className="div-block-4">
                <div className="text-block-2">Miami Beach</div>
                <div className="text-block-3">27 Feb - 6 Mar</div>
                <div className="text-block-3">United States</div>
                <div className="from">From</div>
                <div className="price">$365</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
