import { Helmet } from "react-helmet";
import location from "../../images/location.svg";
import calender from "../../images/calender.svg";
import arroww from "../../images/arroww.svg";
import cancel from "../../images/cancel.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SaleServices, getUserDetails } from "../../services/supplier";
import {
  getDateFormatWithWeekday,
  getDateFormat,
  decrease_x_days,
} from "../../utils/date";
import { buyItem, getTokenUrl } from "../../services/web3Services";
import PageLoader from "../../components/PageLoader/PageLoader";
import { toast } from "react-toastify";
import edit from "../../images/edit.svg";
import rectangle from "../../images/card-rec.svg";
import star5 from "../../images/5star.svg";
import GMap from "../../services/GoogleMap";
import BasicPagination from "../../components/Pagination/BasicPagination";
import HotelAmenities from "../../components/Amenities/HotelAmenities";
import TransactionData from "../../components/TransactionData/TransactionData";
import MarketplaceSearch from "../../components/MarketplaceSearch/MarketplaceSearch";
import Description from "../../components/Description/Description";
const mapStyle = {
  minHeight: "300px",
  height: "100%",
};

const BuyFromOthers = () => {
  const [roomSales, setRoomSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewMore, setViewMore] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const [page, setPage] = useState(1);
  const [metadata, setMetadata] = useState({
    total: 0,
    limit: 0,
  });
  const [totalBookings, setTotalBookingsAvailable] = useState(0);
  const [searchData, setSearchData] = useState("");
  const [coordinates, setCordinates] = useState({
    lat: 38.7223,
    lng: 9.1393,
  });
  let navigate = useNavigate();
  const searchParams = new URLSearchParams(window?.location.search);
  let hotelId = searchParams.get("hotels");
  let bookingStartDate = searchParams.get("startDate");
  let bookingEndDate = searchParams.get("endDate");
  const [searched, setSearched] = useState(false);

  const getSales = async () => {
    try {
      setLoading(true);
      const getRooms = await SaleServices.getRoomSales(
        hotelId,
        10,
        page,
        getDateFormat(bookingStartDate),
        decrease_x_days(1, bookingEndDate)
      );
      setRoomSales(getRooms.data);
      setMetadata(getRooms.data.metadata);

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    var total = 0;
    for (let roomNo = 0; roomNo < roomSales?.rooms?.length; roomNo++) {
      if (new Date(roomSales.rooms[roomNo]?.nftRef?.checkInDate) > new Date()) {
        total++;
      }
    }
    setTotalBookingsAvailable(total);
  }, [roomSales]);

  useEffect(() => {
    getSales();
  }, []);
  useEffect(() => {
    getSales();
  }, [page]);

  return (
    <>
      {loading ? (
        <PageLoader isTransaction={true} />
      ) : (
        <div className="app min-h-[800px] 2xl:min-h-[1200px]">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Buk | Buy From Others</title>
            <link rel="canonical" />
          </Helmet>
          <MarketplaceSearch
            setSearched={setSearched}
            getSales={getSales}
            setMetadata={setMetadata}
            setSearchData={setSearchData}
            searchData={searchData}
            setReadMore={setReadMore}
            readMore={readMore}
          />
          {/* <hr /> */}
          <p className="text-center sell pb-6 py-8">
            {metadata?.total} {metadata?.total > 1 ? "bookings" : "booking"}{" "}
            available{" "}
          </p>
          <div className="flex flex-col gap-4">
            {searchData &&
            searchData?.data &&
            searchData?.data.length > 0 &&
            searched
              ? searchData?.data.map((data, i) => (
                  <BookingSearch
                    key={i + "roomSales"}
                    data={data}
                    setLoading={setLoading}
                    loading={loading}
                    setViewMore={setViewMore}
                    viewMore={viewMore}
                    setCordinates={setCordinates}
                    coordinates={coordinates}
                    getSales={getSales}
                    setReadMore={setReadMore}
                    readMore={readMore}
                  />
                ))
              : !searched &&
                roomSales?.rooms?.map((data, i) => (
                  <Booking
                    key={i + "roomSales"}
                    data={data}
                    setLoading={setLoading}
                    loading={loading}
                    setViewMore={setViewMore}
                    viewMore={viewMore}
                    setCordinates={setCordinates}
                    coordinates={coordinates}
                    getSales={getSales}
                    setReadMore={setReadMore}
                    readMore={readMore}
                  />
                ))}
          </div>
        </div>
      )}
      {Math.ceil(metadata.total / metadata.limit) > 1 && !loading && (
        <div className="flex justify-center items-center mt-4 mb-10">
          <BasicPagination metaData={metadata} page={page} setPage={setPage} />
        </div>
      )}
    </>
  );
};
export const BookingSearch = ({
  data,
  loading,
  setLoading,
  setViewMore,
  viewMore,
  setCordinates,
  coordinates,
  getSales,
  setReadMore,
  readMore,
}) => {
  let navigate = useNavigate();
  const buyThisItem = async () => {
    try {
      if (!getUserDetails()?.token) {
        return toast.error("Please login using metamask!");
      }
      setLoading(true);
      await buyItem(data?.saleId, data?.priceOnSale)
        .then(async (res) => {
          await SaleServices.buyItem(
            data?.saleId,
            data?.user_id,
            res.from,
            data?.user_id,
            data?.priceOnSale,
            data.bookingId,
            res?.transactionHash
          );
          setLoading(false);
          toast.success("Booking transfer successful");
          // await getSales()
          navigate("/manageBooking");
        })
        .catch((err) => {
          setLoading(false);
          if ((err?.message).includes("insufficient funds for transfer")) {
            return toast.error("Insufficient funds!");
          }
          if (err?.message) {
            toast.error(err?.message || "Error while buying this room!");
          }
        });
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <>
      {new Date(data.nft[0].checkInDate) > new Date() && (
        <div className="part1 shadow-lg mx-auto bg-white">
          <div className="grid gap-4 grid-cols-2 mobile:grid-cols-3">
            <div className="flex flex-col  mx-auto col-span-2 mobile:col-span-1">
              <div className="p-2 mobile:p-0">
                <img
                  className="w-full h-[240px] object-cover mx-auto m-0"
                  src={data?.rooms[0]?.images[0]}
                  alt="location2"
                />
              </div>
              <div className="grid grid-cols-4 gap-2 p-2 mobile:p-0 mobile:mt-2">
                <img
                  src={data?.rooms[0]?.images[0]}
                  className="w-full object-cover h-[100px]"
                  alt="location3"
                />
                <img
                  src={data?.rooms[0]?.images[1]}
                  className="w-full object-cover h-[100px]"
                  alt="location4"
                />
                <img
                  src={data?.rooms[0]?.images[2]}
                  className="w-full object-cover h-[100px]"
                  alt="location5"
                />
                <img
                  src={data?.rooms[0]?.images[3]}
                  className="w-full object-cover h-[100px]"
                  alt="location5"
                />
              </div>
            </div>
            <div className="flex flex-col my-2 mobile:my-6 pl-4 mobile:p-0  ">
              <p className="fontdesc text-2xl font-bold text-gray-900 hotelhead h-auto ">
                {data?.hotel[0]?.name} - {data?.rooms[0].roomType}
              </p>
              <div className="flex  mt-2">
                <img src={location} className="h-4" alt="location" />
                <label className="text-sm font-normal text-gray-600 pl-2 textsm">
                  {data?.hotel[0].city}, {data?.hotel[0].country}
                </label>
              </div>
              <div className="flex  mt-10">
                <img src={calender} className="h-4" alt="location1" />
                <label className="text-sm font-normal text-gray-600 pl-2 pr-4 textsm">
                  {getDateFormatWithWeekday(data?.nft[0].fromDate)} -{" "}
                  {getDateFormatWithWeekday(data?.nft[0].toDate)}
                </label>
              </div>
              <div className="text-gray-600 mt-5">
                <img src={cancel} alt="Cancellation" />
              </div>
              <div className="flex mt-4 gap-4 place-items-center">
                <HotelAmenities
                  small={true}
                  attributes={data?.hotel[0]?.attributes}
                  roomArea={data?.rooms[0].roomArea}
                  roomUnit={data?.rooms[0]?.roomAreaUnit}
                />
              </div>
            </div>
            <div className="flex flex-col items-end p-5 mt-4">
              <p className="text-right text-2xl mobile:text-4xl">
                ${data?.priceOnSale}{" "}
              </p>
              <p className="text-right text-sm mobile:text-xs text-black">
                Selling Price
              </p>
              <p
                className="text-sm text-center mt-5 font-normal textsm cursor-pointer"
                onClick={() =>
                  getTokenUrl(
                    data?.nft[0].contractAddress,
                    data?.nft[0].tokenId
                  )
                }
              >
                Token ID :{" "}
                <span className="bg-[#EAF0FF] text-[#2f69ff] py-1 px-4 rounded-full text-xs ml-2">
                  {data?.nft[0].tokenId}
                </span>
              </p>
              <p className="text-sm my-3 mobile:4 flex  font-normal text-gray-600 textsm">
                Booked by :{" "}
                <span className="text-gray-400textsm ml-2">
                  {data?.nft[0].ownerTrail[
                    data?.nft[0].ownerTrail?.length !== 0
                      ? data?.nft[0].ownerTrail?.length - 1
                      : 0
                  ].currentOwner.slice(0, 6)}
                  ...
                  {data?.nft[0].ownerTrail[
                    data?.nft[0].ownerTrail?.length !== 0
                      ? data?.nft[0].ownerTrail?.length - 1
                      : 0
                  ].currentOwner.slice(-4)}
                </span>
              </p>

              {sessionStorage.getItem("userDetails") &&
              JSON.parse(sessionStorage.getItem("userDetails")).hasOwnProperty(
                "userId"
              ) &&
              data?.user_id ==
                JSON.parse(sessionStorage.getItem("userDetails"))?.userId ? (
                <button
                  className="mt-4 mb-4 w-full flex justify-center items-center
                                border-2 border-[#292931] overflow-hidden hover:bg-gray-800 hover:border-1 hover:text-gray-900 text-white font-bold py-2 px-8 rounded-full  bg-[#292931]"
                  height={60}
                  onClick={() => {
                    navigate(
                      `/sales?sale-room=${data.bookingId}&room=${data.rooms[0]._id}&edit-listing=true`
                    );
                  }}
                >
                  <img src={edit} className="w-5 h-5" />

                  <span className="fontdesc !text-sm font-medium p-1 !m-0 text-white textsell1">
                    Edit Listing
                  </span>
                </button>
              ) : (
                <button
                  className="buy flex items-center  justify-center mt-4 w-[250px] overflow-hidden hover:bg-gray-100 hover:border-1 hover:text-gray-900 text-white font-bold py-1 rounded-full buttonClr"
                  height={60}
                  onClick={() => buyThisItem()}
                >
                  <span className=" flex place-items-center fontdesc text-lg font-medium p-2 textsell1">
                    Buy This Room
                    <img src={arroww} alt="" className="ml-4 fill-white" />
                  </span>
                </button>
              )}
            </div>
          </div>

          {/* view more */}
          {viewMore == data?._id && (
            <div>
              <div className="flex mt-7 px-4 content-center justify-between flex-wrap">
                <div className="flex items-center my-5  mobile:w-1/2 mr-14 flex-wrap">
                  <p className="fontdesc text-2xl font-bold text-gray-900 hotelhead mr-2">
                    {data?.hotel[0].name}
                  </p>
                  <span className="text-gray-500 rounded-full border-2  border-gray-500 px-2 py-[2px] text-xs">
                    {data?.hotel[0].starRatings} Star Hotel
                  </span>
                </div>
                {data?.hotel[0].guestRatings && (
                  <div className="flex items-center ml-3">
                    <img src={star5} alt="location9" />

                    <p className="fontdesc text-sm font-normal mr-3 text-gray-800 ml-4 fontsmin">
                      {data?.hotel[0].guestRatings} Users Recieved
                    </p>
                  </div>
                )}
              </div>
              <div className="flex flex-wrap px-4 items-center">
                <div className="mobile:basis-1/2">
                  <p className="w-full whitespace-pre-wrap text-[#242426] font-medium text-sm">
                    <Description
                      description={data?.hotel[0].description}
                      viewMore={readMore}
                      setViewMore={setReadMore}
                    />
                  </p>

                  <p className="fontdesc text-lg  font-semibold text-gray-900 mt-4">
                    Amenities
                  </p>
                  <div className="flex flex-wrap justify-items-start mt-3 ml-3">
                    <HotelAmenities
                      attributes={data?.hotel[0].attributes}
                      roomArea={data?.rooms[0].roomArea}
                      roomUnit={data?.rooms[0]?.roomAreaUnit}
                    />
                  </div>
                  <TransactionData transactionData={data?.nft[0].ownerTrail} />
                </div>
                <div className="  flex justify-end  p-4 mobile:basis-1/2">
                  <div className="w-full h-full">
                    <GMap
                      mapStyle={mapStyle}
                      coordinates={{
                        lat: parseFloat(
                          data.hotel[0].locationCordinates.latitude
                        ),
                        lng: parseFloat(
                          data.hotel[0].locationCordinates.longitude
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
              <button
                onClick={() => setViewMore("")}
                className="flex px-4 text-cyan-600 font-medium  mx-auto"
              >
                <span className=" mx-auto text-sm my-6">View Less</span>
              </button>
              <div className="w-full relative flex pb-6 justify-center">
                <button
                  className="buybtn box-border flex items-center w-fit px-8 mx-4 justify-center overflow-hidden hover:bg-gray-100 hover:border-1 hover:text-gray-900 text-white font-bold py-1 rounded-full  buttonClr"
                  height={60}
                >
                  <p className=" flex place-items-center place-content-center fontdesc text-lg font-medium p-2 textsell1">
                    Buy This Room
                    <svg
                      className="ml-4 hover:fill-neutral-900"
                      width="20"
                      height="12"
                      viewBox="0 0 20 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.7719 6.48736C20.0648 6.19447 20.0648 5.71959 19.7719 5.4267L14.9989 0.65373C14.706 0.360837 14.2311 0.360837 13.9382 0.65373C13.6453 0.946624 13.6453 1.4215 13.9382 1.71439L18.1809 5.95703L13.9382 10.1997C13.6453 10.4926 13.6453 10.9674 13.9382 11.2603C14.2311 11.5532 14.706 11.5532 14.9989 11.2603L19.7719 6.48736ZM0 6.70703H19.2415V5.20703H0V6.70703Z"
                        fill="white"
                      />
                    </svg>
                  </p>
                </button>
              </div>
            </div>
          )}
          {viewMore !== data?._id && (
            <button
              onClick={() => {
                setCordinates({
                  lat: parseFloat(data.hotel[0].locationCordinates.latitude),
                  lon: parseFloat(data.hotel[0].locationCordinates.longitude),
                });
                setViewMore(data?._id);
              }}
              className="flex mx-auto text-cyan-600 font-medium cursor-pointer text-center"
            >
              <span className="text-sm my-6">View Details</span>
            </button>
          )}
        </div>
      )}
    </>
  );
};
export const Booking = ({
  data,
  loading,
  setLoading,
  setViewMore,
  viewMore,
  setCordinates,
  coordinates,
  getSales,
  setReadMore,
  readMore,
}) => {
  let navigate = useNavigate();

  const buyThisItem = async () => {
    try {
      if (!getUserDetails()?.token) {
        return toast.error("Please login using metamask!");
      }
      setLoading(true);
      await buyItem(data?.saleId, data?.priceOnSale)
        .then(async (res) => {
          await SaleServices.buyItem(
            data?.saleId,
            data?.user_id,
            res.from,
            data?.user_id,
            data?.priceOnSale,
            data.bookingId,
            res?.transactionHash
          );
          setLoading(false);
          toast.success("Booking transfer successful");
          // await getSales()
          navigate("/manageBooking");
        })
        .catch((err) => {
          setLoading(false);
          if ((err?.message).includes("insufficient funds for transfer")) {
            return toast.error("Insufficient funds!");
          }
          if (err?.message) {
            toast.error(err?.message || "Error while buying this room!");
          }
        });
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <>
      {new Date(data.nftRef.checkInDate) > new Date() && (
        <div className="part1 shadow-lg mx-auto bg-white">
          <div className="grid gap-4 grid-cols-2 mobile:grid-cols-3">
            <div className="flex flex-col  mx-auto col-span-2 mobile:col-span-1">
              <div className="p-2 mobile:p-0">
                <img
                  className="w-full h-[240px] object-cover mx-auto m-0"
                  src={data?.room_id?.images[0]}
                  alt="location2"
                />
              </div>
              <div className="grid grid-cols-4 gap-2 p-2 mobile:p-0 mobile:mt-2">
                <img
                  src={data?.room_id?.images[0]}
                  className="w-full object-cover h-[100px]"
                  alt="location3"
                />
                {data?.room_id?.images.length > 1 && (
                  <img
                    src={data?.room_id?.images[1]}
                    className="w-full object-cover h-[100px]"
                    alt="location4"
                  />
                )}
                {data?.room_id?.images.length > 2 && (
                  <img
                    src={data?.room_id?.images[2]}
                    className="w-full object-cover h-[100px]"
                    alt="location5"
                  />
                )}
                {data?.room_id?.images.length > 3 && (
                  <img
                    src={data?.room_id?.images[3]}
                    className="w-full object-cover h-[100px]"
                    alt="location5"
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col my-2 mobile:my-6 pl-4 mobile:p-0  ">
              <p className="fontdesc text-2xl font-bold text-gray-900 hotelhead h-auto ">
                {data?.hotel_id?.name} - {data?.room_id.roomType}
              </p>
              <div className="flex  mt-2">
                <img src={location} className="h-4" alt="location" />
                <label className="text-sm font-normal text-gray-600 pl-2 textsm">
                  {data?.hotel_id.city}, {data?.hotel_id.country}
                </label>
              </div>
              <div className="flex  mt-10">
                <img src={calender} className="h-4" alt="location1" />
                <label className="text-sm font-normal text-gray-600 pl-2 pr-4 textsm">
                  {getDateFormatWithWeekday(data?.nftRef.fromDate)} -{" "}
                  {getDateFormatWithWeekday(data?.nftRef.toDate)}
                </label>
              </div>
              <div className="text-gray-600 mt-5">
                <img src={cancel} alt="Cancellation" />
              </div>
              <div className="flex mt-4 gap-4 place-items-center">
                <HotelAmenities
                  small={true}
                  attributes={data?.hotel_id?.attributes}
                  roomArea={data?.room_id.roomArea}
                  roomUnit={data?.room_id?.roomAreaUnit}
                />
              </div>
            </div>
            <div className="flex flex-col items-end p-5 mt-4">
              <p className="text-right text-2xl mobile:text-4xl">
                ${data?.priceOnSale}{" "}
              </p>
              <p className="text-right text-sm mobile:text-xs text-black">
                Selling Price
              </p>
              <p
                className="text-sm text-center mt-5 font-normal textsm cursor-pointer"
                onClick={() =>
                  getTokenUrl(
                    data?.nftRef.contractAddress,
                    data?.nftRef.tokenId
                  )
                }
              >
                Token ID :{" "}
                <span className="bg-[#EAF0FF] text-[#2f69ff] py-1 px-4 rounded-full text-xs ml-2">
                  {data?.nftRef.tokenId}
                </span>
              </p>
              <p
                onClick={() =>
                  getTokenUrl(
                    data?.nftRef.contractAddress,
                    data?.nftRef.tokenId
                  )
                }
                className="cursor-pointer text-sm my-3 mobile:4 flex  font-normal text-gray-600 textsm"
              >
                Booked by :{" "}
                <span className="text-gray-400textsm ml-2">
                  {data?.nftRef.ownerTrail[
                    data?.nftRef.ownerTrail?.length !== 0
                      ? data?.nftRef.ownerTrail?.length - 1
                      : 0
                  ].currentOwner.slice(0, 6)}
                  ...
                  {data?.nftRef.ownerTrail[
                    data?.nftRef.ownerTrail?.length !== 0
                      ? data?.nftRef.ownerTrail?.length - 1
                      : 0
                  ].currentOwner.slice(-4)}
                </span>
              </p>
              <div className="flex fontba\sic hidden mobile:block text-xs text-gray-500">
                Note 2: All bookings made on BukTrips are for single occupancy,
                in case of additional guests in the same room payment can be
                directly made at the hotel desk.
              </div>

              {sessionStorage.getItem("userDetails") &&
              JSON.parse(sessionStorage.getItem("userDetails")).hasOwnProperty(
                "userId"
              ) &&
              data?.user_id ==
                JSON.parse(sessionStorage.getItem("userDetails"))?.userId ? (
                <button
                  className="mt-4 mb-4 w-full flex justify-center items-center
                                border-2 border-[#292931] overflow-hidden hover:bg-gray-800 hover:border-1 hover:text-gray-900 text-white font-bold py-2 px-8 rounded-full  bg-[#292931]"
                  height={60}
                  onClick={() => {
                    navigate(
                      `/sales?sale-room=${data?.bookingId}&room=${data?.room_id?._id}&edit-listing=true`
                    );
                  }}
                >
                  <img src={edit} className="w-5 h-5" />

                  <span className="fontdesc !text-sm font-medium p-1 !m-0 text-white textsell1">
                    Edit Listing
                  </span>
                </button>
              ) : (
                <button
                  className="buy flex items-center  justify-center mt-4 w-[250px] overflow-hidden hover:bg-gray-100 hover:border-1 hover:text-gray-900 text-white font-bold py-1 rounded-full buttonClr"
                  height={60}
                  onClick={() => buyThisItem()}
                >
                  <span className=" flex place-items-center fontdesc text-lg font-medium p-2 textsell1">
                    Buy This Room
                    <img src={arroww} alt="" className="ml-4 fill-white" />
                  </span>
                </button>
              )}
            </div>
          </div>

          {/* view more */}
          {viewMore == data?._id && (
            <div>
              <div className="flex mt-7 px-4 content-center justify-between flex-wrap">
                <div className="flex items-center my-5  mobile:w-1/2 mr-14 flex-wrap">
                  <p className="fontdesc text-2xl font-bold text-gray-900 hotelhead mr-2">
                    {data?.hotel_id.name}
                  </p>
                  <span className="text-gray-500 rounded-full border-2  border-gray-500 px-2 py-[2px] text-xs">
                    {data?.hotel_id.starRatings} Star Hotel
                  </span>
                </div>
                {data?.hotel_id.guestRatings && (
                  <div className="flex items-center ml-3">
                    <img src={star5} alt="location9" />

                    <p className="fontdesc text-sm font-normal mr-3 text-gray-800 ml-4 fontsmin">
                      {data?.hotel_id.guestRatings} Users Recieved
                    </p>
                  </div>
                )}
              </div>
              <div className="flex flex-wrap px-4 items-center">
                <div className="mobile:basis-1/2">
                  <p className="w-full whitespace-pre-wrap text-[#242426] font-medium text-sm">
                    <Description
                      description={data?.hotel_id.description}
                      viewMore={readMore}
                      setViewMore={setReadMore}
                    />
                  </p>

                  <p className="fontdesc text-lg  font-semibold text-gray-900 mt-4">
                    Amenities
                  </p>
                  <div className="flex flex-wrap justify-items-start mt-3 ml-3">
                    <HotelAmenities
                      attributes={data?.hotel_id.attributes}
                      roomArea={data?.room_id.roomArea}
                      roomUnit={data?.room_id?.roomAreaUnit}
                    />
                  </div>
                  <TransactionData transactionData={data?.nftRef.ownerTrail} />
                </div>
                <div className="  flex justify-end  p-4 mobile:basis-1/2">
                  <div className="w-full h-full">
                    <GMap
                      mapStyle={mapStyle}
                      coordinates={{
                        lat: parseFloat(
                          data.hotel_id.locationCordinates.latitude
                        ),
                        lng: parseFloat(
                          data.hotel_id.locationCordinates.longitude
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
              <button
                onClick={() => setViewMore("")}
                className="flex px-4 text-cyan-600 font-medium  mx-auto"
              >
                <span className=" mx-auto text-sm my-6">View Less</span>
              </button>
              <div className="w-full relative flex pb-6 justify-center">
                <button
                  className="buybtn box-border flex items-center w-fit px-8 mx-4 justify-center overflow-hidden hover:bg-gray-100 hover:border-1 hover:text-gray-900 text-white font-bold py-1 rounded-full  buttonClr"
                  height={60}
                >
                  <p className=" flex place-items-center place-content-center fontdesc text-lg font-medium p-2 textsell1">
                    Buy This Room
                    <svg
                      className="ml-4 hover:fill-neutral-900"
                      width="20"
                      height="12"
                      viewBox="0 0 20 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.7719 6.48736C20.0648 6.19447 20.0648 5.71959 19.7719 5.4267L14.9989 0.65373C14.706 0.360837 14.2311 0.360837 13.9382 0.65373C13.6453 0.946624 13.6453 1.4215 13.9382 1.71439L18.1809 5.95703L13.9382 10.1997C13.6453 10.4926 13.6453 10.9674 13.9382 11.2603C14.2311 11.5532 14.706 11.5532 14.9989 11.2603L19.7719 6.48736ZM0 6.70703H19.2415V5.20703H0V6.70703Z"
                        fill="white"
                      />
                    </svg>
                  </p>
                </button>
              </div>
            </div>
          )}
          {viewMore !== data?._id && (
            <button
              onClick={() => {
                setCordinates({
                  lat: parseFloat(data.hotel_id.locationCordinates.latitude),
                  lon: parseFloat(data.hotel_id.locationCordinates.longitude),
                });
                setViewMore(data?._id);
              }}
              className="flex mx-auto text-cyan-600 font-medium cursor-pointer text-center"
            >
              <span className="text-sm my-6">View Details</span>
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default BuyFromOthers;
