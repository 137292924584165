/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import PageMenu from "../../components/PageMenu/PageMenu";
import hf from "../../images/hf.svg";
import cale from "../../images/cale.svg";
import g from "../../images/g.svg";
import BarChart from "../../components/BarChart/BarChart";
import LineChart from "../../components/LineChart/LineChart";
import CircleIcon from "@mui/icons-material/Circle";
import HeatMap from "../../components/HeatMap/HeatMap";
import Manage from "../Manage/Manage";
import Details from "../Details/Details";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import RegistrationStepTwo from "../Registration/RegistrationStepTwo";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./Dashboard.css";
import PricingComponent from "../../components/PricingComponent/PricingComponent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Menu from "@mui/material/Menu";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import {
    ManagerServices,
    setAuthToken,
    getUserDetails,
    HotelUserServices,
} from "../../services/supplier";
import { ManagerContext } from "../../context/managerContext/ManagerContext";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import { addDaysTo, getUTCDateString } from "../../utils/date";

const Dashboard = () => {
    const { hotels, setHotels, hotelId, setHotelId } = useContext(ManagerContext);
    const [pageState, setPageState] = useState("dashboard");
    const [roomId, setRoomId] = useState("");
    const [count,setCount] = useState(0)
    const [currHotel, setCurrHotel] = useState("");
    const [currRoom, setCurrRoom] = useState("");
    const _currDate = new Date();
    const currDate = new Date(Date.UTC(_currDate.getFullYear(),_currDate.getMonth(),_currDate.getDate()));
    // console.log(currDate.toUTCString(),'currDate');
    // currDate.setHours(0, 0, 0, 0);
    const [roomData, setRoomData] = useState([
        {
            name: "",
            files: [],
            mapFile: [],
            details: {
                availableRooms: 0,
                roomAreaUnit: "sq. mt.",
                occupancy: 0,
                roomSize: 0,
                description: "",
                tags: [],
            },
            pricingInfo: [
                {
                    dateFrom: currDate,
                    dateTo: addDaysTo(1,currDate),
                    minDateTo: addDaysTo(1,currDate),
                    minDateFrom:currDate,
                    price: 0,
                    minResale: 0,
                    cancellation: "",
                    percentage: 0,
                    daysBefore: 0,
                },
            ],
        },
    ]);
    
    const [pricingInfo, setPricingInfo] = useState([
        {
            dateFrom: currDate,
            dateTo: addDaysTo(1, currDate),
            minDateTo: addDaysTo(1, currDate),
            minDateFrom: currDate,
            price: 0,
            minResale: 0,
            cancellation: "",
            percentage: 0,
            daysBefore: 0,
        },
    ]);
    const [loading, setLoading] = useState(true);
    const [roomsData, setRoomsData] = useState([]);
    const [roomPricingId, setRoomPricingId] = useState("");
    const [dashboardData, setDashboardData] = useState("");
    const [isEditing, setIsEditing] = useState('');
    const [newAdd,setNewAdd] = useState('');
    const [roomTags,setRoomTags] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open2 = Boolean(anchorEl2);
    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
      };
      const handleHotelClick = (hotel) => {
        setHotelId(hotel);
        handleClose();
      };
      const handleClose = () => {
        setAnchorEl(null);
        setAnchorEl1(null);
        setAnchorEl2(null);
      };
    const [roomDescription,setRoomDescription] = useState('')
    const getDashboardSummary = async (id) => {
        try {
            if (!id) return;
            const res = await ManagerServices.getDashboardSummary(id);
            setDashboardData(res.data?.dashboardData);
        } catch (err) {
            console.log(err);
        }
    };
    const getHotel = async () => {
        try {
            setLoading(true);
            setAuthToken(getUserDetails()?.token);
            const getHotelManager = await ManagerServices.getHotelsByManager();
            let hotels = getHotelManager.data?.data.hotels;
            let navHotels = [];
            for (var i = 0; i < hotels?.length; i++) {
                navHotels.push({
                    hotel: hotels[i].name,
                    id: hotels[i]._id,
                });
            }
            setHotels(navHotels);
            setHotelId(hotels[0]._id);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error(err.response?.data.err);
        }
    }
    const getRooms = async (id) => {
        try {
            setLoading(true);

            setAuthToken(getUserDetails()?.token);
            const res = await HotelUserServices.getHotelRoomByHotelId(id);
            setRoomsData(res.data?.rooms);
            setRoomDescription(res.data?.rooms.description)
            setLoading(false);
        } catch (err) {
            // console.log(err);
            setLoading(false);
            toast.error(err.response?.data.err || "Error Ocurred!");
        }
    };
    const handlePricingInfo = async (rdata) => {
        let tempArr = [];
        for (let i = 0; i < rdata?.length; i++) {
            let pricing = rdata[i].roomPricing.roomPricing;
            if (rdata[i]._id == currRoom) {
                setRoomPricingId(rdata[i].roomPricing._id);
                pricing.map((p, i) => {
                    return tempArr.push({
                        dateFrom: p.dateFrom,
                        dateTo: p.dateTo,
                        price: Number(p.price),
                        minResale: Number(p.minResale),
                        cancellation: p.cancellation,
                        _id: p._id,
                        minDateFrom: p.dateFrom,
                        minDateTo: p.dateTo
                    });
                });
                
            }
        } 

        setPricingInfo(tempArr);
    };
    const handleUpdatePrice = async (e) => {
        try {
            setLoading(true);
            e.preventDefault();
            // console.log(pricingInfo,"pricingInfo")
            const pricingInfoPayload = pricingInfo.map(({minDateFrom,minDateTo,...p}) => {
              return {
                ...p,
                dateFrom: getUTCDateString(p.dateFrom),
                dateTo: getUTCDateString(p.dateTo),
              };
            });
            // console.log(pricingInfoPayload,"pricingInfoPayload")

            const pricingUpdateData = {
                pricingData: pricingInfoPayload,
            };
            setAuthToken(getUserDetails()?.token);
            const res = await ManagerServices.updateRoomPrices(
                roomPricingId,
                currRoom,
                pricingUpdateData
            );
            setLoading(false);
            toast.success(res.data.msg);
        } catch (err) {
            setLoading(false);
            toast.error(err.response.data.err || "Some error ocurred!");
        }
    };
    const handleRoomUpdate = async (roomD) => {
        try {
            const amenitiesList = {
                 hasSeaView: "Sea View" ,
                 hasKingBed: "King Bed" ,
                 hasHalfBoard: "Half Board" ,
                 hasFullBoard:"Full Board" ,
            };
            var tempArr = [];
            for (let i = 0; i < roomsData?.length; i++) {
                let allTags = [];
                if(roomsData[i].hasOwnProperty("attributes"))
                    Object.keys(roomsData[i]?.attributes).forEach(function (
                        key,
                        index
                    ) {
                        if (roomsData[i].attributes[key]) {
                            allTags.push(key);
                        }
                    });
                for(let am=0;am<allTags.length;am++) {
                  allTags[am] = amenitiesList[allTags[am]]
                }
                let roomObj = {
                    id: roomsData[i]._id,
                    name: roomsData[i].roomType,
                    files: roomsData[i].images || [],
                    details: {
                        availableRooms: roomsData[i].numberOfRooms,
                        occupancy: roomsData[i].maxOccupancy,
                        roomSize: roomsData[i].roomArea,
                        description: roomsData[i].description,
                        tags: allTags,
                        roomAreaUnit: roomsData[i].roomAreaUnit,
                    },
                    pricingInfo: roomsData[i].roomPricing.roomPricing || [],
                };
                tempArr.push(roomObj);
            }
            setRoomData(tempArr);
        } catch (err) {
            console.log(err);
            toast.error("Some error ocurred!");
        }
    };
    const createNewRoom = async (idx,description) => {
        try {
            
            let data = roomData[roomData.length-1]
            if (
                !data?.name ||
                !data?.details.availableRooms ||
                !data?.details.occupancy ||
                !data?.details.roomAreaUnit ||
                !data?.details.roomSize
            ) {
                toast.error("Please fill all the required fields.")
                return;
            }
            if(description) {
                data.details.description = description
            }
            setLoading(true)
            data.hotelId = hotelId
            let attribute = {
                hasSeaView: roomTags.includes("Sea View"),
                    hasKingBed: roomTags.includes("King Bed"),
                    hasHalfBoard: roomTags.includes("Half Board"),
                    hasFullBoard: roomTags.includes("Full Board"),
            }
            data.attribute = attribute
            // console.log(pricingInfo,"pricingInfo")
            const pricingInfoPayload = pricingInfo.map(({minDateFrom,minDateTo,...p}) => {
              return {
                ...p,
                dateFrom: getUTCDateString(p.dateFrom),
                dateTo: getUTCDateString(p.dateTo),
              };
            });
            // console.log(pricingInfoPayload,"pricingInfoPayload")
            data.pricingInfo = pricingInfoPayload
            await ManagerServices.createRoom({
                roomsObj:data,
                hotelId: hotelId
            })
            toast.success("Room Added Successfully!")
            
            
            await getRooms(roomId)
            setLoading(false)
        }
        catch(err) {
            console.log(err)
        }
    }
    const handleRoomApi = async (id,tags,description) => {
        console.log("description: ", description);
        try {
            setLoading(true);
            let roomObj = {};
            for (let i = 0; i < roomsData?.length; i++) {
                if (roomData[i].id == id) {
                    roomObj = roomData[i];
                }
            }
            roomObj.tags = tags
            roomObj.details.description = description
            await ManagerServices.updateRoomByManager(id, {
                roomData: roomObj,
            });
            setLoading(false);
            toast.success("Room data updated successfully!");
            await getRooms(hotelId)
        } catch (err) {
            setLoading(false);
            toast.error("Some error ocurred");
        }
    };
    const handleClearPricing = () => {
        setPricingInfo([
            {
                dateFrom: currDate,
                dateTo: addDaysTo(1,currDate),
                minDateTo: addDaysTo(1,currDate),
                minDateFrom:currDate,
                price: 0,
                minResale: 0,
                cancellation: "",
                percentage: 0,
                daysBefore: 0,
            }
            
        ])
    }
    const deleteRoom = async (id) => {
        try {
            if(roomsData.length <= 1) {
                window.location.reload()
                return toast.error("Cannot delete only room available!")
            }
            setLoading(true)
            const upd = await ManagerServices.updateRoom(id)
            toast.success("Room deleted successfully!")
            setLoading(false)
            await getRooms(hotelId);
        } catch (err) {
            console.log("err ", err);
            toast.error(
                err.response.data.err || "Error Ocurred while deleting!"
            );
            setLoading(false);
        }
    };
    const handleAdd = (index) => {
        const tempArr = roomData
        setNewAdd(true)
        tempArr.push({
            name: "",
            files: [],
            mapFile: [],
            details: {
                availableRooms: 0,
                roomAreaUnit: "sq. mt.",
                occupancy: 0,
                roomSize: 0,
                description: "",
                tags: [],
            },
            pricingInfo: [
                {
                    dateFrom: currDate,
                    dateTo: addDaysTo(1,currDate),
                    minDateTo: addDaysTo(1,currDate),
                    minDateFrom:currDate,
                    price: 0,
                    minResale: 0,
                    cancellation: "",
                    percentage: 0,
                    daysBefore: 0,
                },
            ],
        })
        
        setRoomData([...tempArr])
        console.log("here")
        setPricingInfo([
            {
                dateFrom: currDate,
                dateTo: addDaysTo(1,currDate),
                minDateTo: addDaysTo(1,currDate),
                minDateFrom:currDate,
                price: 0,
                minResale: 0,
                cancellation: "",
                percentage: 0,
                daysBefore: 0,
            },
        ])
        setIsEditing(tempArr?.length-1)
    };
    const updateImageDB = async (image) => {
        try {
            if(!roomId) return 
            await ManagerServices.updateImage({
                image: image,
                roomId: roomId
            })
        }
        catch(err) {
            console.log(err);
        }
    }
    const addImageDB = async (image) => {
        try {
            if(!roomId) return
            const cre = await ManagerServices.addImageDB({
                image: image,
                roomId: roomId
            })
        }
        catch(err) {
            console.log(err);
        }
    }
    useEffect(() => {
        setPageState("dashboard");
        getHotel();
    }, []);

    useEffect(() => {
        if (pageState == "rooms") {
            getRooms(hotelId);
        } else if (pageState == "dashboard") {
            getDashboardSummary(hotelId);
        }
    }, [pageState]);
 
    useEffect(() => {
        if (pageState == "price") getRooms(currHotel);
        else if (pageState == "dashboard") getDashboardSummary(currHotel);
    }, [currHotel]);

    useEffect(() => {
        handlePricingInfo(roomsData, currRoom);
    }, [currRoom]);
    useEffect(() => {
        handleRoomUpdate(roomsData);
    }, [roomsData]);

    useEffect(() => {
        setPageState("dashboard");
        if (pageState == "dashboard") getDashboardSummary(hotelId);
    }, [hotelId]);

    if (!loading && !hotelId) {
        toast.error("Authentication Invalid!");
        return <Navigate to="/" />;
    }
    return (
        <div className="bg-[#F7F9FB] flex flex-col items-center app h-full">
            <div>
              <>
                <div className="hidden mobile:flex items-center gap-4 max-w-[500px]">
                  {hotels
                    .filter((h) => h.id == hotelId)
                    .map((hotel) => (
                      <button
                        key={hotel.id}
                        onClick={() => {
                          setHotelId(hotel.id);
                        //   window.location.reload();
                        }}
                        className={`rounded-lg h-6 px-4 whitespace-nowrap text-sm ${
                          hotel.id === hotelId
                            ? "shadow text-transparent bg-clip-text bg-gradient-to-r from-[#2F69FF] to-[#C856FF]"
                            : "border-2 border-[#444]"
                        } `}
                      >
                        {hotel.hotel}
                      </button>
                    ))}
                  {hotels
                    ?.filter((h) => h.id !== hotelId)
                    .slice(0, hotels.length > 50 ? 2 : 1)
                    .map(
                      (hotel, index) =>
                        hotel.id !== hotelId && (
                          <button
                            key={index + index + "a"}
                            onClick={() => {
                              setHotelId(hotel.id);
                            }}
                            className={`rounded-lg h-6 px-4 whitespace-nowrap text-sm ${
                              hotel.id === hotelId
                                ? "shadow text-transparent bg-clip-text bg-gradient-to-r from-[#2F69FF] to-[#C856FF]"
                                : "border-2 border-[#444]"
                            } `}
                          >
                            {hotel.hotel}
                          </button>
                        )
                    )}
                     <button
                  className="w-6 h-6 border-2 border-[#444] hidden mobile:flex items-center justify-center rounded-lg"
                  onClick={handleClick2}
                >
                  <KeyboardArrowDownRoundedIcon fontSize="small" />
                </button>
                <Menu
                  anchorEl={anchorEl2}
                  open={open2}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                {hotels.map((hotel, index) => {
                    if (index >= 2)
                      return (
                        <MenuItem
                          key={index + hotel.id}
                          onClick={() => handleHotelClick(hotel.id)}
                        >
                          {hotel.hotel}
                        </MenuItem>
                      );
                  })}
                </Menu>
                </div>
               
                {/* <button
                  className="w-6 h-6 border-2 border-[#444] hidden mobile:flex items-center justify-center rounded-lg"
                  onClick={() => navigate("/registration")}
                >
                  <AddRoundedIcon fontSize="small" />
                </button> */}
            </>
            </div>
            <div className="py-6 mobile:hidden" id="styled-select">
                <Select
                    value={currHotel}
                    onChange={(e) => setCurrHotel(e.target.value)}
                    sx={{ width: "300px" }}
                    className="text-transparent font-medium text-xl bg-clip-text bg-gradient-to-r from-[#2F69FF] to-[#C856FF]"
                    IconComponent={KeyboardArrowDownRoundedIcon}
                >
                    {/* <MenuItem value=""><Put any default Value which you want to show></MenuItem> */}
                    {hotels.map((hotel, i) => (
                        <MenuItem key={i + hotel.id + i} value={hotel.id}>
                            {hotel.hotel}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <PageMenu state={pageState} setState={setPageState} />

            {loading ? (
                <div className="min-h-[300px] 2xl:min-h-[1200px] flex items-center justify-center">
                    <Loader />
                </div>
            ) : (
                <div className="min-h-[600px] 2xl:min-h-full flex justify-center w-full">
                    {pageState === "dashboard" && (
                        <DashboardComponent dashboardData={dashboardData} />
                    )}
                    {pageState === "manage" && <Manage hotelId={hotelId} />}
                    {pageState === "details" && <Details hotelId={hotelId} />}
                    {pageState === "rooms" && (
                        <RegistrationStepTwo
                            stepData={roomData}
                            setStepData={setRoomData}
                            initialState={false}
                            place={"roomUpdate"}
                            handleRoomApi={handleRoomApi}
                            deleteRoom={deleteRoom}
                            setIsEditing={setIsEditing}
                            isEditing={isEditing}
                            handleAdd={handleAdd}
                            updateImageDB={updateImageDB}
                            addImageDB={addImageDB}
                            setRoomId={setRoomId}
                            count={count}
                            setCount={setCount}
                            pricingInfo={pricingInfo}
                            setPricingInfo={setPricingInfo}
                            createNewRoom={createNewRoom}
                            newAdd={newAdd}
                            setNewAdd={setNewAdd}
                            setRoomTags={setRoomTags}
                            noNext
                            noNew
                            noTitle
                        />
                    )}
                    {pageState === "price" && (
                        // priceLoading ? <PageLoader/>:
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <form
                                onSubmit={(e) => handleUpdatePrice(e)}
                                className="max-w-[1360px] flex flex-col gap-6 mb-12"
                            >
                                <div className="bg-white shadow-xl shadow-[#d8e3f1a6] p-6 flex flex-col mobile:flex-row gap-6">
                                    <FormControl>
                                        <InputLabel>Select a hotel</InputLabel>
                                        <Select
                                            value={currHotel}
                                            onChange={(e) => {
                                                setCurrHotel(e.target.value);
                                            }}
                                            sx={{ width: "300px" }}
                                            label="Select a hotel"
                                            IconComponent={
                                                KeyboardArrowDownRoundedIcon
                                            }
                                        >
                                            {hotels.map((hotel, i) => (
                                                <MenuItem
                                                    key={i + hotel.id}
                                                    value={hotel.id}
                                                >
                                                    {hotel.hotel}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <InputLabel>Select a Room</InputLabel>
                                        <Select
                                            value={currRoom}
                                            onChange={(e) => {
                                                setCurrRoom(e.target.value);
                                            }}
                                            sx={{ width: "300px" }}
                                            label="Select a Room"
                                            IconComponent={
                                                KeyboardArrowDownRoundedIcon
                                            }
                                            disabled={currHotel?.length == 0}
                                        >
                                            {roomsData &&
                                                roomsData.length > 0 &&
                                                roomsData.map((room, i) => (
                                                    <MenuItem
                                                        key={room._id + i}
                                                        value={room._id}
                                                    >
                                                        {room.roomType}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="bg-white shadow-xl shadow-[#d8e3f1a6] p-6">
                                    <PricingComponent
                                        data={pricingInfo}
                                        setData={setPricingInfo}
                                        editPricing={true}
                                    />
                                    {currRoom && <div className="flex justify-center items-center gap-4">
                                        <button
                                            type="button"
                                            className="bg-white border-2 border-[#7E7E7E] text-[#7E7E7E] font-medium px-6 py-2 rounded-full"
                                            onClick={handleClearPricing}
                                        >
                                            Clear
                                        </button>
                                        <button className="bg-[#292931] border-2 border-[#292931] text-[#fff] font-medium px-6 py-2 rounded-full">
                                            Update
                                        </button>
                                    </div>}
                                </div>
                            </form>
                        </LocalizationProvider>
                    )}
                </div>
            )}
        </div>
    );
};

const DashboardComponent = ({ dashboardData }) => {
    const [barData1, setBarData1] = useState([]);
    const [barData2, setBarData2] = useState([]);
    const [occupancyData, setOccupancyData] = useState([]);
    const [lineData, setLineData] = useState([]);
    const [totalRevenue, setTotalRevenue] = useState(0);

    useEffect(() => {
        const data2 = Object.entries(
            dashboardData.newRoomOccupancyData || {}
        ).map((value) => value[1].available);
        const data1 = Object.entries(
            dashboardData.newRoomOccupancyData || {}
        ).map((value) => value[1].sold);
        var lineGraphData = [];
        for (let month = 1; month <= 12; month++) {
            for (
                let revenue = 0;
                revenue < dashboardData?.monthlyRevenue?.length;
                revenue++
            ) {
                if (
                    month == Number(dashboardData.monthlyRevenue[revenue]?._id)
                ) {
                    lineGraphData.push(
                        Number(dashboardData.monthlyRevenue[revenue]?.sum)
                    );
                    setTotalRevenue(
                        totalRevenue +
                            Number(dashboardData.monthlyRevenue[revenue]?.sum)
                    );
                } else {
                    lineGraphData.push(0);
                }
            }
        }
        setLineData(lineGraphData);
        setOccupancyData(dashboardData?.occupancyByDay);
        setBarData2(data2);
        setBarData1(data1);
    }, [dashboardData]);

    return (
        
        <div className="flex flex-col mt-10 gap-y-10 mb-10 max-w-[1360px] m-auto">
            <div className="mobile:grid hidden grid-cols-3 gap-4 justify-between ">
                <div className="flex items-center gap-6 p-4 shadow-lg shadow-[#D8E3F1A6] rounded-lg">
                    <img src={hf} width={116} height={116} />
                    <div className="flex flex-col">
                        <p className="text-black font-bold text-4xl">
                            {dashboardData?.occupancyRate
                                ? Number(dashboardData?.occupancyRate).toFixed(
                                      2
                                  ) * 100
                                : 0}
                            %
                        </p>
                        <p className="text-gray-500">
                            Occupancy rate this month
                        </p>
                    </div>
                </div>
                <div className="flex items-center  gap-6 p-4 shadow-lg shadow-[#D8E3F1A6] rounded-lg">
                    <img src={cale} width={116} height={116} />
                    <div className="flex flex-col">
                        <p className="text-black font-bold text-4xl">
                            {dashboardData?.newBookingsThisWeek
                                ? Number(
                                      dashboardData?.newBookingsThisWeek
                                  ).toFixed(2) * 100
                                : 0}
                            %
                        </p>
                        <p className="text-gray-500">New Booking This Week</p>
                    </div>
                </div>
                <div className="flex items-center  gap-6 p-4 shadow-lg shadow-[#D8E3F1A6] rounded-lg">
                    <img src={g} width={116} height={116} />
                    <div className="flex flex-col">
                        <p className="text-black font-bold text-4xl">
                            {dashboardData?.rateMonthlyRevenue
                                ? Number(
                                      dashboardData?.rateMonthlyRevenue
                                  ).toFixed(2) * 100
                                : 0}{" "}
                            %
                        </p>
                        <p className="text-gray-500">Revenues</p>
                    </div>
                </div>
            </div>
            <div className="flex mobile:hidden items-center gap-6 p-4 shadow-lg shadow-[#D8E3F1A6] rounded-lg">
                <div className="flex flex-col items-center gap-4">
                    <p className="m-0 p-0 w-24 h-24 rounded-full flex justify-center items-center bg-[#6C0FE740] text-[#6C0FE7] font-bold text-4xl">
                        {dashboardData?.occupancyRate
                            ? Number(dashboardData?.occupancyRate).toFixed(2) *
                              100
                            : 0}
                        %
                    </p>
                    <p className="text-[#292931] text-xl font-medium text-center">
                        Occupancy rate this month
                    </p>
                </div>
                <div className="flex flex-col items-center gap-4">
                    <p className="m-0 p-0 w-24 h-24 rounded-full flex justify-center items-center bg-[#70DC6640] text-[#70DC66] font-bold text-4xl">
                        {dashboardData?.newBookingsThisWeek
                            ? Number(
                                  dashboardData?.newBookingsThisWeek
                              ).toFixed(2) * 100
                            : 0}
                        %
                    </p>
                    <p className="text-[#292931] text-xl font-medium text-center">
                        New Booking This Week
                    </p>
                </div>
                <div className="flex flex-col items-center gap-4">
                    <p className="m-0 p-0 w-24 h-24 rounded-full flex justify-center items-center bg-[#02A3FF40] text-[#02A3FF] font-bold text-4xl">
                        {dashboardData?.occupancyRate
                            ? Number(dashboardData?.occupancyRate).toFixed(2) *
                              100
                            : 0}
                        %
                    </p>
                    <p className="text-[#292931] text-xl font-medium text-center">
                        Occupancy rate this month
                    </p>
                </div>
            </div>
            <div className="hidden mobile:grid grid-cols-3 gap-4">
                <div className="shadow-lg shadow-[#D8E3F1A6] flex flex-col p-6 gap-8 rounded-lg">
                    <div className="flex flex-col">
                        <p className="text-black font-bold text-3xl  ">
                            Occupancy by room
                        </p>
                        {/* <p className=" text-gray-500">Population</p> */}
                    </div>
                    <div className="flex flex-col gap-4">
                        <BarChart
                            barChartBookedRoom={barData1}
                            chartData2={barData2}
                        />
                        <div className="flex gap-4 items-center">
                            <div className="flex items-center gap-2">
                                <CircleIcon
                                    className="text-[#F0E7FD]"
                                    fontSize="small"
                                />
                                <span>Available</span>
                            </div>
                            <div className="flex items-center gap-2">
                                <CircleIcon
                                    className="text-[#6C0FE7]"
                                    fontSize="small"
                                />
                                <span>Sold</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shadow-lg shadow-[#D8E3F1A6] flex flex-col p-6 gap-8 rounded-lg">
                    <div className="flex flex-col">
                        <p className="text-black font-bold text-3xl  ">
                            Occupancy by day
                        </p>
                        {/* <p className=" text-gray-500">January 2022</p> */}
                    </div>
                    <div className="">
                        <HeatMap data={occupancyData} />
                    </div>
                </div>
                <div className="shadow-lg shadow-[#D8E3F1A6] flex flex-col p-6 gap-8 rounded-lg">
                    <div className="flex flex-col">
                        <p className="text-black font-bold text-3xl  ">
                            Monthly revenues
                        </p>
                        {/* <p className="text-gray-500">{totalRevenue.toFixed(2)} in k$</p> */}
                    </div>
                    <div className="">
                        <LineChart chartData={lineData} />
                    </div>
                </div>
            </div>
            <div className="mobile:hidden flex items-center justify-center">
                <div className="w-[350px]">
                    <Swiper
                        pagination={true}
                        modules={[Pagination]}
                        centeredSlides={true}
                        slidesPerView={1}
                        className="swiper-wrapper"
                    >
                        <SwiperSlide>
                            <div className="shadow-lg shadow-[#D8E3F1A6] flex flex-col p-6 gap-8 rounded-lg">
                                <div className="flex flex-col">
                                    <p className="text-black font-bold text-3xl  ">
                                        Occupancy by room
                                    </p>
                                    <p className=" text-gray-500">Population</p>
                                </div>
                                <div className="flex flex-col gap-4">
                                    <BarChart
                                        barChartBookedRoom={barData1}
                                        chartData2={barData2}
                                    />
                                    <div className="flex gap-4 items-center">
                                        <div className="flex items-center gap-2">
                                            <CircleIcon
                                                className="text-[#F0E7FD]"
                                                fontSize="small"
                                            />
                                            <span>Available</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <CircleIcon
                                                className="text-[#6C0FE7]"
                                                fontSize="small"
                                            />
                                            <span>Sold</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="shadow-lg shadow-[#D8E3F1A6] flex h-full flex-col p-6 gap-8 rounded-lg">
                                <div className="flex flex-col">
                                    <p className="text-black font-bold text-3xl  ">
                                        Occupancy by day
                                    </p>
                                </div>
                                <div className="">
                                    <HeatMap data={occupancyData} />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="shadow-lg shadow-[#D8E3F1A6] flex flex-col p-6 gap-8 rounded-lg">
                                <div className="flex flex-col">
                                    <p className="text-black font-bold text-3xl">
                                        Monthly revenues
                                    </p>
                                </div>
                                <div className="">
                                    <LineChart chartData={lineData} />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
