import moment from "moment";
import { useState, createContext, useEffect } from "react";
import { toast } from "react-toastify";
export const SearchContext = new createContext();
export const SearchProvider = ({ children }) => {
  let currDate = new Date();
  let tommDate = new Date();
  currDate.setDate(currDate.getDate());
  tommDate.setDate(tommDate.getDate() + 1);

  const [search, setSearch] = useState(currDate);
  const [startDate, setStartDate] = useState(currDate);
  const [endDate, setEndDate] = useState(tommDate);
  const [guest, setGuest] = useState(2);
  const [room, setRoom] = useState(1);

  const [dateValue, onDateChange] = useState([startDate, endDate]);

  const handleDateRangeSelect = (value) => {
    const startDate = moment(value[0]).format("L");
    const endDate = moment(value[1]).format("L");

    if (startDate !== endDate) {
      onDateChange([value[0], value[1]]);
    } else {
      toast.error("Check-in and Check-out date should not be same ");
    }
    return true;
  };
  useEffect(() => {
    setStartDate(dateValue[0]);
    setEndDate(dateValue[1]);
  }, [dateValue]);

  return (
    <SearchContext.Provider
      value={{
        dateValue,
        onDateChange,
        search,
        setSearch,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        guest,
        setGuest,
        room,
        setRoom,
        handleDateRangeSelect,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
