/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import "./CardMap.css";
import loc from "../../images/loc.svg";
import arrowm from "../../images/arrowm.svg";
import star5 from "../../images/5star.svg";
import { useNavigate } from "react-router-dom";


const CardMap = ({
    item,
    ratings,
}) => {
    let navigate = useNavigate();
    const [ratt, setRatt] = useState("Good");
    const handleRatt = (ratings) => {
        let x = Math.round(ratings);
        if (x > 1 && x <= 2) {
            setRatt("Ok");
        } else if (x > 2 && x <= 3) {
            setRatt("Good");
        } else if (x > 3 && x <= 4) {
            setRatt("Superb");
        } else if (x > 4 && x <= 5) {
            setRatt("Excellent");
        }
    };
    useEffect(() => {
        handleRatt(ratings);
    }, [ratings]);
    return (
        <div className="flex my-5 bg-white shadow-lg cardl lg:!w-[461px] min-h-[200px] !w-[344px]">
            <div className="m-4">
                <img src={item?.images[0].url} className="rounded-lg w-20 h-30 lg:w-32 lg:h-44 imgcss object-cover" />

            </div>
            <div className="mt-4 w-full relative">
                <p className="fontdesc text-base font-medium text-gray-900">{item?.name}</p>
                <div className="flex">
                    <img src={loc} width={10} height={12} />
                    <p className="fontdesc text-sm font-normal text-gray-900 ml-2">{item.city && item.city + ", "} {item.country}</p>
                </div>
                <div className="flex">
                    <p className="fontdesc text-xs lg:text-sm font-bold text-gray-600 mt-4"> {item.attributes?.hasFreeWifi ? 'Wifi' : null}</p>
                    <p className="fontdesc text-xs lg:text-sm font-bold text-gray-600 mt-4 ml-2"> {item.attributes?.hasFreeParking ? ' Parking' : null}</p>
                    <p className="fontdesc text-xs lg:text-sm font-bold text-gray-600 mt-4 ml-2"> {item.attributes?.hasAirCondition ? ' Air Conditioning' : null}</p>
                    {/* <p className="fontdesc text-xs lg:text-sm font-bold text-gray-600 mt-4"> {isBalcony ? ', Balcony' : null}</p> */}
                </div>
                <div className="flex mt-0 mobile:mt-1 items-center justify-between mb-0 mobile:mb-1">
                    <img src={star5} className='w-fit h-2' />
                    {item?.guestRatings && <div>
                        <p className="fontdesc text-xs font-bold text-gray-600 mt-1 ml-2">{item?.guestRatings} {item?.guestRatings && "Reviews"}</p>
                    </div>}
                    <div>
                        <p className="fontdesc text-xl flex items-end font-bold text-black mt-1 marginha w-[100px]">${item?.pricing.minPrice}/ <span className='text-sm'>Day</span></p>
                    </div>
                </div>

                <div className="flex mt-0 mobile:mt-2 w-full">
                        <span className="fontdesc text-sm font-bold text-gray-600">View Details</span>

                </div>
                    <div className="cursor-pointer absolute right-0 bottom-0 flex w-[180px] bg-gray-900 p-4 chooseroom cardBtnHover">
                        <button onClick={() => navigate(`/hotel/${item._id}`)} className=" text-sm font-medium text-white ml-2">Choose Room</button>
                        <img src={arrowm} width={13} height={5} className="ml-2" />

                    </div>
            </div>
        </div>
    );
};

export default CardMap;
