import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function SimpleAccordion({ data }) {
  return (
    <div className="flex flex-col gap-4">
      {data &&
        data.map((record) => (
          <Accordion
            disableGutters
            className="!shadow-xl !shadow-[#D8E3F1A6]"
            key={record.id}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p className="font-bold">{`${record.id} ${record.question}`}</p>
            </AccordionSummary>
            <AccordionDetails>
              <p className="text-gray-500 text-sm">{record.answer}</p>
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
}
