import { AuthServices, setAuthToken, storeInformation } from "./supplier";
import Web3 from "web3";
import Web3Token from "web3-token";
import { toast } from "react-toastify";

// Connection to MetaMask wallet (you can actually use any wallet)
// you can even use ethers instead of web3

export const connectWallet = async () => {
  try {
    const web3 = new Web3(window?.ethereum);
    if (!window.ethereum) toast.info("Please Install Metamask");
    // connection to metamask wallet
    await window.ethereum.request({ method: "eth_requestAccounts" });
    const chainId = await web3.eth.getChainId();
    await switchChain();
    // getting address from which we will sign message
    const address = await web3.eth.getAccounts();
    // generating a token with 1 day of expiration time
    const token = await Web3Token.sign(
      (msg) => web3.eth.personal.sign(msg, address[0]),
      "3 days"
    );
    setAuthToken(token);
    //send above token to backend
    const loginResponse = await AuthServices.login({
      address: address[0],
      token: token,
    });
    storeInformation(loginResponse?.data.user);

    let chainMessage;
    if (chainId === 80001) chainMessage = "Polygon Testnet";
    else if (chainId === 137) chainMessage = "Polygon Mainnet";

    const completeMessage = chainMessage
      ? `You are on ${chainMessage}`
      : "Please switch to Polygon";
    toast.info(completeMessage);
    return loginResponse?.data.user;
  } catch (err) {
    console.log(err);
  }
};

export const switchChain = async () => {
  const web3 = new Web3(window?.ethereum);
  const chainId = await web3.eth.getChainId();
  if (Number(chainId) !== Number(80001) || Number(137)) {
    try {
      // toast.warn("Please switch to Polygon")
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x13881' }], // chainId must be in hexadecimal numbers
      });
    } catch (err) {
      if (err.code === 4902)
        window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x13881',
              rpcUrls: ['https://matic-mumbai.chainstacklabs.com/'],
              chainName: 'mumbai',
              nativeCurrency: {
                name: 'tMATIC',
                symbol: 'tMATIC', // 2-6 characters long
                decimals: 18,
              },
              blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
            },
          ],
        });
    }
  }
};
