import React, { useState, useEffect } from "react";
import "./Manage.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import calanderIcon from "../../images/calanderIcon.svg";
import {
    ManagerServices,
    setAuthToken,
    getUserDetails,
    HotelUserServices,
} from "../../services/supplier";
import { countDays, getDateFormat } from "../../utils/date";
import PageLoader from "../../components/PageLoader/PageLoader";
import BasicPagination from "../../components/Pagination/BasicPagination";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { cancelBooking } from "../../services/web3Services";
import {toast} from 'react-toastify'
import {getTokenUrl} from "../../services/web3Services";
import { useNavigate } from "react-router-dom";

const Manage = ({ hotelId }) => {
    const navigate = useNavigate()
    const [manageData, setManageData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [metaData, setMetaData] = useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleEllipsis = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const createCancelBooking = async (bookingId, tokenId, userAddress) => {
        try {
            setLoading(true);
            setAuthToken(getUserDetails()?.token); 
            const cancellingBooking = await cancelBooking(tokenId,userAddress);
            const updateCanelBooking =
                await HotelUserServices.cancelBookingUser(bookingId, cancellingBooking?.transactionHash);
           
            toast.success("Booking Cancelled for Token Id ", tokenId)
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
            handleClose()
        }
    };
    const getBookings = async () => {
        try {
            setLoading(true);
            setAuthToken(getUserDetails()?.token);
            const getBookings = await ManagerServices.getHotelBookings(
                hotelId,
                page,
                limit
            );
            setManageData(getBookings?.data.bookings);
            setMetaData(getBookings?.data.meta);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };
    useEffect(() => {
        getBookings();
    }, []);
    useEffect(() => {
        getBookings();
    }, [hotelId,page]);

    return (
        <div className="flex flex-col w-full">
            {loading ? (
                <PageLoader />
            ) : (
                <div className="flex flex-col items-center gap-8 p-8 py-12 w-full min-w-fit  max-w-[1360px] min-h-[400px]">
                    <table className="mobile:flex flex-col gap-4 !w-full min-w-fit  overflow-x-scroll">
                        <thead className="w-full bg-[#F7F9FB] p-3 border-t-2 border-[#E7ECF2]">
                            <tr className="grid grid-cols-9 gap-4 w-full">
                                <th className="text-left text-[#7E7E7E] w-full">
                                    Guest Name
                                </th>
                                <th className="text-left text-[#7E7E7E] w-full">
                                    Room Type
                                </th>
                                <th className="text-left text-[#7E7E7E] w-full">
                                    Days
                                </th>
                                <th className="text-left text-[#7E7E7E] w-full">
                                    Check In
                                </th>
                                <th className="text-left text-[#7E7E7E] w-full">
                                    Check Out
                                </th>
                                <th className="text-left text-[#7E7E7E] w-full">
                                    Token
                                </th>
                                <th className="text-left text-[#7E7E7E] w-full">
                                    Price
                                </th>
                                <th className="text-left text-[#7E7E7E] w-full">
                                    Status
                                </th>
                                <th className="text-left text-[#7E7E7E] min-w-[50px]"></th>
                            </tr>
                        </thead>
                        <tbody className="w-full p-3 bg-white shadow-lg shadow-[#D8E3F1A6]">
                            {manageData && manageData.length > 0 ? (
                                manageData.map((manage, i) => (
                                    <tr
                                        key={i + "manageDataA"}
                                        className="grid grid-cols-9 gap-4 py-4 border-b-2 border-[#E7ECF2]"
                                    >
                                        <td className="w-full font-bold">
                                            {manage.userId?.walletAddress.substring(
                                                0,
                                                4
                                            )}
                                            ...
                                            {manage.userId?.walletAddress.substring(
                                                manage.userId?.walletAddress
                                                    .length - 4
                                            )}
                                        </td>
                                        <td className="w-full">
                                            {manage?.roomId.roomType}
                                        </td>
                                        <td className="w-full">
                                            {countDays(
                                                manage?.toDate,
                                                manage?.fromDate
                                            )}{" "}
                                            Nights
                                        </td>
                                        <td className="w-full">
                                            {getDateFormat(manage?.fromDate)}
                                        </td>
                                        <td className="w-full">
                                            {getDateFormat(manage?.toDate)}
                                        </td>
                                        <td className="w-full cursor-pointer text-[#2F69FF]" onClick={() => navigate(getTokenUrl(manage?.nftRef.smartContractAddress,manage?.nftRef.tokenId))}>
                                            <span className="bg-[#2F69FF1A] px-4 py-1 rounded-full">
                                                {manage?.nftRef.tokenId}
                                            </span>
                                        </td>

                                        <td className="w-full font-bold max-w-[130px]">
                                            ${(manage?.buyPrice).toFixed(2)}
                                        </td>
                                        <td className="w-full font-bold">
                                            {(manage?.checkInStatus)}
                                        </td>
                                        <td className="min-w-[50px] text-end">
                                            <button
                                                id="basic-button"
                                                aria-controls={
                                                    open
                                                        ? "basic-menu"
                                                        : undefined
                                                }
                                                aria-haspopup="true"
                                                aria-expanded={
                                                    open ? "true" : undefined
                                                }
                                                onClick={handleEllipsis}
                                            >
                                                <MoreVertIcon />
                                            </button>

                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                MenuListProps={{
                                                    "aria-labelledby":
                                                        "basic-button",
                                                }}
                                            >
                                                <MenuItem
                                                    onClick={() =>
                                                        createCancelBooking(
                                                            manage._id,
                                                            manage?.nftRef
                                                                .tokenId,
                                                            manage.userId.walletAddress
                                                        )
                                                    }
                                                    // disabled={manage?.checkInStatus == "CANCELLED" ? true : false}
                                                >
                                                    Cancel Booking
                                                </MenuItem>
                                            </Menu>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <p className="my-4 text-center py-6 pt-8 text-blue-500 font-bold h-[100px]">
                                    No Booking found...
                                </p>
                            )}

                            
                        </tbody>
                    </table>
                    
                    <div className="flex flex-col gap-6 mobile:hidden">
                        {manageData &&
                            manageData.length > 0 &&
                            manageData.map((data, i) => (
                                <ManageCard
                                    data={data}
                                    loading={loading}
                                    key={i + " manageCard"}
                                />
                            ))}
                    </div>
                </div>
            )}<BasicPagination
            metaData={metaData}
            page={page}
            setPage={setPage}
        />
        </div>
    );
};

const ManageCard = ({ data, loading }) => {
    return (
        <>
            {loading ? (
                <PageLoader />
            ) : (
                <div className=" p-6 flex flex-col gap-2 bg-white shadow-lg shadow-[#D8E3F1A6] w-[340px]">
                    <div className="flex items-center justify-between">
                        <span className="font-bold">{data.guestName}</span>
                        <button>
                            <MoreVertIcon />
                        </button>
                    </div>
                    <div className="flex gap-2">
                        <span className="font-medium text-[#7E7E7E]">
                            {data?.roomId.roomType}
                        </span>
                        <span className="bg-[#2F69FF1A] text-[#2F69FF] px-4 py-1 rounded-full">
                            {data.userId?.walletAddress.substring(0, 4)}...
                            {data.userId?.walletAddress.substring(
                                data.userId?.walletAddress.length - 4
                            )}
                        </span>
                    </div>
                    <div className="flex gap-2 items-center">
                        <img src={calanderIcon} alt="" />
                        <span className="text-[#7E7E7E]">
                            {data?.roomId.roomType}
                        </span>
                    </div>
                    <div className="flex items-center justify-between">
                        <span className="text-[#130A44] font-medium">
                            {countDays(data?.toDate, data?.fromDate)} Nights
                        </span>
                        <span className="text-[#292931] text-2xl font-bold">
                            ${data?.buyPrice}
                        </span>
                    </div>
                </div>
            )}
        </>
    );
};

export default Manage;
