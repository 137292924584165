import react from "react";
import { Link, useNavigate } from "react-router-dom";

function PageMenu({
  setState,
  state
}) {
  const navigate = useNavigate();
  const handleRequestParams = async (page) => {
    setState(page)
    
  }
  return (
    <div>
      <header className="text-gray-600 body-font mt-3 ">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <nav className="md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center">
            <button
              className={state=="dashboard" ? "mr-10 font-bold text-lg hover:text-violet-900 border-b-violet-900 focus:border-b-2 text-violet-900" : "mr-10 font-bold text-lg text-black hover:text-violet-900 border-b-violet-900"}
              onClick={() => {handleRequestParams("dashboard")}}
            >
              Dashboard
            </button>
            <button
              className={state=="manage" ? "mr-10 font-bold text-lg hover:text-violet-900 border-b-violet-900 focus:border-b-2 text-violet-900" : "mr-10 font-bold text-lg text-black hover:text-violet-900 border-b-violet-900"}
              onClick={() => handleRequestParams("manage")}
            >
              Manage Bookings
            </button>
            <button
              className={state=="details" ? "mr-10 font-bold text-lg hover:text-violet-900 border-b-violet-900 focus:border-b-2 text-violet-900" : "mr-10 font-bold text-lg text-black hover:text-violet-900 border-b-violet-900"}
              onClick={() => handleRequestParams("details")}
            >
              Hotel Details
            </button>
            <button onClick={() => handleRequestParams("rooms")} className={state=="rooms" ? "mr-10 font-bold text-lg hover:text-violet-900 border-b-violet-900 focus:border-b-2 text-violet-900" : "mr-10 font-bold text-lg text-black hover:text-violet-900 border-b-violet-900"}>
              Manage Rooms
            </button>
            <button onClick={() => handleRequestParams("price")} className={state=="price" ? "mr-10 font-bold text-lg hover:text-violet-900 border-b-violet-900 focus:border-b-2 text-violet-900" : "mr-10 font-bold text-lg text-black hover:text-violet-900 border-b-violet-900"}>
              Manage Price
            </button>
          </nav>
        </div>
      </header>
    </div>
  );
}

export default PageMenu;
