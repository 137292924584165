import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  scales: {
    xAxis: {
      display: false,
      stacked: true,
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    yAxis: {
      display: false,
      stacked: true,
      ticks: {
        display: false,
      },
      grid: {
        color: "rgba(0, 0, 0, 0)",
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
      position: "right",
    },
    zoom: {
      zoom: {
        wheel: {
          enabled: true,
          speed: 0.1,
        },
        pinch: {
          enabled: true,
        },
        mode: "x",
      },
      pan: {
        enabled: true,
        mode: "x",
      },
    },
  },
  interaction: {
    mode: "index",
    axis: "xy",
    intersect: false,
  },
  tooltips: {
    mode: "index",
    intersect: false,
  },
  hover: {
    mode: "index",
    intersect: false,
  },
};

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const BarChart = ({ barChartBookedRoom, chartData2 }) => {
  const data = {
    labels,
    datasets: [
      {
        label: "Sold",
        data: barChartBookedRoom,
        backgroundColor: "#6C0FE7",
      },
      {
        label: "Available",
        data: chartData2,
        backgroundColor: "#6C0FE71A",
      },
    ],
  };

  return <Bar options={options} data={data} />;
};

export default BarChart;
