import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./Store";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-loading-skeleton/dist/skeleton.css";
import "tw-elements";
import { SearchProvider } from "./context/search/SearchContext";
import { WalletProvider } from "./context/wallet/WalletContext";
import { LanguageProvider } from "./context/language/LanguageContext";
import { ManagerProvider } from "./context/managerContext/ManagerContext";
import { StyledEngineProvider } from "@mui/material/styles";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <SearchProvider>
      <WalletProvider>
        <LanguageProvider>
          <ManagerProvider>
            <StyledEngineProvider injectFirst>
              <App />
            </StyledEngineProvider>
          </ManagerProvider>
        </LanguageProvider>
      </WalletProvider>
    </SearchProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
