import React from 'react'

const UserCheckInInfo = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  },[])
  return (
    <div>UserCheckInInfo</div>
  )
}

export default UserCheckInInfo