import {
  ALL_ROOM_REQUEST,
  ALL_ROOM_SUCCESS,
  ALL_ROOM_FAIL,
  CLEAR_ERRORS,
} from "../constants/roomConstants";
import { HotelUserServices} from "../../services/supplier";
import { backendUrl } from "../../config/config";
import {getDateFormat} from "../../utils/date";

export const filterRooms =
  (
    { keyword = "" },
    price = [0, 2500],
    sources = [],
    guestRatings,
    propertyType,
    type,
    attributes,
    page,
    startDate,
    endDate,
    guest,
    room
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: ALL_ROOM_REQUEST });

      const dateData = {"checkInDate":getDateFormat(startDate),
                       "checkOutDate":getDateFormat(endDate)}

      let link = `${backendUrl}/filterRooms?keyword=${keyword}&guest=${guest}&price[gte]=${price[0]}&price[lte]=${price[1]}&page=${page}&room=${room}`;

      if (sources.length) {
        for (let i = 0; i < sources.length; i++) {
          link = link.concat(`&${sources[i]}=true`);
        }
      }
      // if (guestRatings?.length) {
      //   for(let i=0;i<guestRatings.length;i++) {
      //     link = link.concat(`&${guestRatings[i]}=true`)
      //   }
      // }
      if(propertyType.length) {
        for(let i=0;i<propertyType?.length;i++) {
          link = link.concat(`&${propertyType[i]}=true`)
        }
      }
      if (type) {
        for (let i = 0; i < type.length; i++) {
          link = link.concat(`&${type[i]}=true`);
        }
      }
      if (attributes) {
        for (let i = 0; i < attributes.length; i++) {
          link = link.concat(`&${attributes[i]}=true`);
        }
      }
      if(startDate){
        link = link.concat(`&fromDate=${getDateFormat(startDate)}&toDate=${getDateFormat(endDate)}`)
      }

      const res = await HotelUserServices.filterRoom(link,dateData);
      if(res.data) dispatch({ type: ALL_ROOM_SUCCESS, payload: res.data });
      else dispatch({ type: ALL_ROOM_FAIL, payload: "No Data Found!" });
    } catch (e) {
      console.log("e: ", e);
      dispatch({ type: ALL_ROOM_FAIL, payload: e?.message });
    }
  };

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
