import Backdrop from "@mui/material/Backdrop"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"

const Enlarge = ({ element, open, setOpen, noEl, className }) => {
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <div className={className}>
        {!noEl && element}
        <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
            className={`flex flex-col`}
        >
            <div className="flex justify-end w-4/5 mx-4 md:w-[800px]">
                <CloseRoundedIcon onClick={handleClose}/>
            </div>
            <div className="w-4/5 md:w-[800px] md:min-h-[500px] enlargeImg">
                {element}
            </div>
        </Backdrop>
        </div>
    )
}

export default Enlarge
