/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import "./BookingCard.css";
import harr from "../../images/harr.svg";
import cancel from "../../images/cancel.svg";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import loc2 from "../../images/loc2.svg";
import seaview from "../../images/seaview.svg";
import cal from "../../images/cal.svg";
import options from "../../images/options.svg";
import rightar from "../../images/rightar.svg";
import edit from "../../images/edit.svg";
import Enlarge from "../Enlarge/Enlarge";
import { getDate, check_past, inLast24Hours } from "../../utils/date";
import GMap from "../../services/GoogleMap";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  FormControlLabel,
  Switch,
  Modal,
  Typography,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { cancelBooking } from "../../services/web3Services";
import moment from "moment";
import {
  setAuthToken,
  getUserDetails,
  HotelUserServices,
} from "../../services/supplier";
import Tooltip from "@mui/material/Tooltip";
import checkInDone from "../../images/checkinDone.svg";
import forSale from "../../images/forSale.svg";
import HotelAmenities from "../Amenities/HotelAmenities";
import { SingleAmenity } from "../Amenities/HotelAmenities";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { isOneOf } from "rsuite/esm/utils";
import Button from "@mui/material/Button";
import { border } from "@chakra-ui/react";
import { Stroller } from "@mui/icons-material";

const mapStyle = {
  minHeight: "250px",
  height: "100%",
};

const BookingCard = ({
  item,
  type,
  uniqueKey,
  setLoading,
  setIsTransaction,
  getBookings,
}) => {
  const [enlarge1, setEnlarge1] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setOpen] = useState(false);
  const currDate = new Date();
  const open = Boolean(anchorEl);
  const handleEllipsis = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  const handleEnlarge1 = (e) => {
    setEnlarge1(true);
  };

  const handleNavigateCheckin = () => {
    navigate(`/checkin?r-check-in=${item.roomId._id}&b-check-in=${item._id}`);
  };
  const handleBookingInfo = () => {
    navigate(`/booking-info?booking-id=${item._id}`);
  };

  const createCancelBooking = async (bookingId) => {
    
    try {
      setLoading(true);
      setIsTransaction(true);
      setAuthToken(getUserDetails()?.token);
      const cancellingBooking = await cancelBooking(
        item.web3BookingId,
        "userCancel"
      );
      const updateCancelBooking = await HotelUserServices.cancelBookingUser(
        bookingId,
        cancellingBooking?.transactionHash
      );
      toast.success("Booking Cancelled!");
      setLoading(false);
      setIsTransaction(false);
      await getBookings();
    } catch (err) {
      console.log(err);
      setLoading(false);
      setIsTransaction(false);
      toast.error(err?.message || "Oops Some error Occured")
    }
  };

  // console.log(type)

  return (
    <div key={uniqueKey}>
      {/* <div>
        <Popup open={popup}>
          {(close) => (
            <div className="modal">
              <button className="close" onClick={close}>
                &times;
              </button>
              <div className="header"> Confirm Cancellation </div>
              {type === "ON_SALE" && (
                <div className="content">
                  {" "}
                  “This booking is currently listed for sale. Cancelling it will
                  remove the listing too from the Marketplace.”
                </div>
              )}
              <div className="content">
                {" "}
                “Are you sure you want to cancel your booking to city on
                check-in date?”
              </div>
              <div >
              <Button variant="text" onClick={
                console.log("abc")
                // console.log(type!=="SOLD")
                  }>Cancel</Button>
              </div>
            </div>
          )}
        </Popup>
      </div> */}
      <div>
        <Modal
          open={isOpen}
          // aria-labelledby="modal-modal-title"
          // aria-describedby="modal-modal-description"
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100vh",
              minWidth: "100%",
            }}
          >
            <div
              style={{
                width: "38%",
                display: "flex",
                justifyContent: "end",
                height:"1%",
                paddingTop:"10px",
                paddingRight:"15px"
              }}
            >
              <HighlightOffIcon
                fontSize="large"
                style={{
                  marginBottom: -19,
                  marginRight: -17,
                  zIndex: 99,
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={() => setOpen(false)}
              />
            </div>
            <div
              style={{ backgroundColor: "white", padding: 15, width: "38%" }}
            >
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {type === "ON_SALE" && (
                  <div>
                    {" "}
                    This booking is currently listed for sale. Cancelling it
                    will remove the listing too from the Marketplace.
                  </div>
                )}
                <div className="mt-4">
                  {" "}
                  Are you sure you want to cancel your booking to{" "}
                  <strong>{item.hotelId?.city}</strong> on
                  <br />
                  <strong>
                    {moment(item.fromDate).format("Do MMM YYYY")}
                  </strong>{" "}
                  ?
                </div>
                  <Button
                    style={{ Color: "black", paddingTop:"3%"}}
                    onClick={() => {
                      if (type !== "SOLD" && type !== "ON_SALE") {
                        createCancelBooking(item._id);
                      }
                    }}
                  >
                    Cancel
                  </Button>
              </Typography>
            </div>
          </Box>
        </Modal>
      </div>
      <Enlarge
        open={enlarge1}
        setOpen={setEnlarge1}
        noEl
        element={
          <GMap
            coordinates={{
              lat: item.hotelId.hasOwnProperty("locationCordinates")
                ? item?.hotelId.locationCordinates.latitude
                : parseFloat(44),
              lng: item.hotelId.hasOwnProperty("locationCordinates")
                ? item?.hotelId.locationCordinates.longitude
                : parseFloat(43),
            }}
            mapStyle={mapStyle}
          />
        }
      />
      <div className="mobile:h-[260px] flex justify-between my-6 !p-0 bg-white card ">
        <div className="relative ">
          {type === "CHECKED_IN" && (
            <img
              className="absolute top-[8px] left-[8px] h-6"
              src={checkInDone}
              alt="checkin"
            />
          )}
          {type === "ON_SALE" && (
            <img
              className="absolute top-[8px] left-[8px] h-6"
              src={forSale}
              alt="checkin"
            />
          )}

          <img
            src={item?.roomId?.images[0]}
            width={346}
            // height={260}
            className="mngHtlDispNone1 object-cover cursor-pointer h-full"
            onClick={handleBookingInfo}
          />
        </div>

        <img
          src={item.roomId?.images[0]}
          width={388}
          height={158}
          className="mngHtlDispNone object-cover"
        />
        <div className="flex flex-col card11 pl-3 pt-5 justify-evenly">
          <p className="hhead cursor-pointer" onClick={handleBookingInfo}>
            {item.hotelId?.name} -{" "}
            <span className="font-medium text-neutral-600">
              {item.roomId?.roomType || <Skeleton />}
            </span>
          </p>
          <div className="flex items-center gap-x-2">
            <img src={loc2} width={10} height={12} />
            <p className="hadd">
              {item?.hotelId?.city && item?.hotelId?.city + ", "}{" "}
              {item?.hotelId?.country}
            </p>
            <p onClick={handleEnlarge1} className="see cursor-pointer">
              See Map
            </p>
            <img className="seeimg" src={harr} />
          </div>
          <div className="line mt-3 mb-1"></div>
          <div className="line2 mt-3 mb-1"></div>
          <div className="flex items-center mt-1">
            {/* <SingleAmenity attributes={item.roomId.attributes} handleBookingInfo={handleBookingInfo}/> */}
            {item.roomId?.attributes.hasSeaView ? (
              <div className="flex items-center">
                <img src={seaview} width={15} height={18} />
                <p className="fontdesc lg:my-0 my-2 text-sm font-medium text-[#A179F5] ml-1">
                  Sea View
                </p>
                <p
                  onClick={handleBookingInfo}
                  className="cursor-pointer fontdesc text-sm font-medium text-[#2F69FF] ml-4"
                >
                  View Details
                </p>
              </div>
            ) : null}
          </div>

          <div className="flex items-center mt-3">
            <img src={cal} width={10} height={10} />
            <p className="fontdesc text-sm font-medium text-[#7E7E7E] ml-1">
              {getDate(item.fromDate)}
              {" - "}
              {getDate(item.toDate)}
            </p>
          </div>
          <div className="line2 mt-3 mb-1"></div>
          <div className="makeflex lg:flex lg:flex-col flex-row justify-between">
            {<img src={cancel} width={117} height={21} className="mt-1" />}
            <div className="flex items-center mt-4 lg:mt-2 gap-x-4 w-1/4 lg:w-3/4">
              <HotelAmenities length={3} attributes={item.hotelId?.attributes} />
            </div>
          </div>
        </div>

        <div className="card2 mobile:max-w-[260px] mobile:min-w-[260px] p-4 flex !flex-col pt-4 lg:pt-8 cardm flexcontrol relative">
          {type !== "CANCELLED" && new Date(item.fromDate) > new Date() && (
            <div className="w-[10px] cursor-pointer relative mobile:max-w-[260px] mobile:min-w-[260px]">
              <img
                src={options}
                width={4}
                height={19}
                className="absolute top-[-216px] right-[-306px] lg:top-[-20px] lg:right-[32px]"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleEllipsis}
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem  disabled={type == "SOLD" || type=="ON_SALE" || item?.checkInStatus == "CHECKED_IN"}>
                  <Button onClick={() => setOpen(true)} >Cancel Booking</Button>
                </MenuItem>
              </Menu>
            </div>
          )}
          {type === "SOLD" && (
            <div className="flex justify-end items-end">
              <div className="flex flex-col mr-4">
                <p className="mobile:text-right text-center font-bold">
                  ${item.buyPrice.toFixed(2) || <Skeleton />}
                </p>
                <p className="mobile:text-right text-center text-xs font-normal text-[#434354]">
                  Original Price
                </p>
              </div>
              <div className="mt-2 flex flex-col">
                <p className="text-[#292931] fontdesc cost mobile:text-right text-center">
                  ${item?.soldPrice.toFixed(2) || <Skeleton />}
                </p>
                <p className="mobile:text-right text-center text-sm font-normal fontdesc text-[#434354]">
                  Sold Price
                </p>
              </div>
            </div>
          )}
          {type !== "SOLD" &&
            (item?.marketplace_buy_price ? (
              <div className="flex justify-end items-end">
                <div className="mt-2 flex flex-col">
                  <p className="text-[#292931] fontdesc cost mobile:text-right text-center">
                    ${item.marketplace_buy_price.toFixed(2) || <Skeleton />}
                  </p>
                  <p className="mobile:text-right text-center text-sm font-normal fontdesc text-[#434354]">
                    Purchase Price
                  </p>
                </div>
              </div>
            ) : (
              <div className="mt-2 flex flex-col gap-y-1">
                <p className="fontdesc cost mobile:text-right text-center">
                  ${item.buyPrice.toFixed(2) || <Skeleton />}
                </p>
                <p className="mobile:text-right text-[#434354] text-center text-sm font-normal fontdesc">
                  Purchase Price
                </p>
              </div>
            ))}
          {!(item.fromDate < new Date()) && (
            <div className="flex flex-col gap-y-2 mt-4 items-center w-full btnalign relative">
              {(() => {
                switch (type) {
                  case "CHECKED_IN":
                    return (
                      <div className="btnchngflex w-full !mr-0 md:mr-6">
                        <button
                          className="flex justify-center mt-4 items-center w-full overflow-hidden hover:bg-blue-700 hover:border-1  text-white font-bold py-2 px-4 rounded-full mb-4 bg-[#2f69ff] "
                          height={60}
                          onClick={() =>
                            navigate(`/moreview?booking=${item?._id}`)
                          }
                        >
                          <span className="fontdesc !text-sm font-medium p-1 !m-0 textsell1 checkintext">
                            Check in at the hotel
                          </span>{" "}
                          <img
                            src={rightar}
                            width={20}
                            height={1}
                            className="ml-2"
                          />
                        </button>
                      </div>
                    );
                  case "CANCELLED":
                    return (
                      <div className="btnchngflex w-full !mr-0 md:mr-6">
                        <button
                          className="mt-4 mb-4 w-full flex justify-center items-center
                               border-2 border-[#292931] overflow-hidden text-[#292931] font-bold py-2 px-8 rounded-full  bg-white"
                          disabled
                        >
                          <span className="fontdesc !text-sm font-medium p-1 !m-0 textsell1">
                            Cancelled
                          </span>
                        </button>
                      </div>
                    );
                  case "ON_SALE":
                    return (
                      !check_past(item.fromDate) && (
                        <div className="btnchngflex w-full !mr-0 md:mr-6">
                          <button
                            className="mt-4 mb-4 w-full flex justify-center items-center
                               border-2 border-[#292931] overflow-hidden hover:bg-gray-800 hover:border-1 hover:text-gray-900 text-white font-bold py-2 px-8 rounded-full  bg-[#292931]"
                            height={60}
                            onClick={() =>
                              navigate(
                                `/sales?sale-room=${item._id}&room=${item.roomId._id}&edit-listing=true`
                              )
                            }
                          >
                            <img src={edit} className="w-5 h-5" />

                            <span className="fontdesc !text-sm font-medium p-1 !m-0 text-white textsell1">
                              Edit Listing
                            </span>
                          </button>
                        </div>
                      )
                    );
                  case "NOT_ON_SALE":
                    return !check_past(item.toDate) ? (
                      <div className="btnchngflex w-full relative !mr-0 md:mr-6">
                        <button
                          className="btnchngflex1 mt-4 w-full btnhover   border-[#292931]  overflow-hidden hover: border-1 hover:text-gray-900 text-white font-bold py-2 px-4 rounded-full  bg-white"
                          height={60}
                          // style={{border:"2px solid"}}
                          onClick={handleNavigateCheckin}
                        >
                          <p className="txtchngflex1 fontdesc !text-sm font-medium p-1 !m-0 text-gray-900 textsell1">
                            Check-in
                          </p>
                        </button>

                        {new Date(item.fromDate) < new Date() ? (
                          <Tooltip title="The check-in date for this booking is in less than 48 hours so you can't list this item for sale anymore.">
                            <button
                              className={
                                "bg-[#444444] btnchngflex1 items-center justify-center flex mt-3 w-full overflow-hidden hover:border-1  text-white font-bold py-2 px-4 rounded-full mb-4"
                              }
                              height={60}
                              onClick={() =>
                                navigate(
                                  `/sales?sale-room=${item._id}&room=${item.roomId._id}`
                                )
                              }
                              disabled={true}
                            >
                              <span className="txtchngflex1 txtalign1 fontdesc !text-sm !m-0 font-medium p-2 textsell11">
                                List For Sale
                              </span>{" "}
                              <img
                                src={rightar}
                                width={20}
                                height={1}
                                className="ml-2"
                              />
                            </button>
                          </Tooltip>
                        ) : (
                          <button
                            className={
                              inLast24Hours(item.fromDate)
                                ? `bg-[#444444] btnchngflex1 items-center justify-center flex mt-3 w-full overflow-hidden hover:border-1  text-white font-bold py-2 px-4 rounded-full mb-4`
                                : `btnchngflex1 items-center justify-center flex mt-3 w-full overflow-hidden border-[#2F69FF]  hover:hover:bg-blue-700 hover:border-[#2F69FF95]  text-white font-bold py-2 px-4 rounded-full mb-4 bg-[#2f69ff]`
                            }
                            height={60}
                            onClick={() =>
                              navigate(
                                `/sales?sale-room=${item._id}&room=${item.roomId._id}`
                              )
                            }
                            disabled={inLast24Hours(item.fromDate)}
                          >
                            <span className="txtchngflex1 txtalign1 fontdesc !text-sm !m-0 font-medium p-2 textsell11">
                              List For Sale
                            </span>{" "}
                            <img
                              src={rightar}
                              width={20}
                              height={1}
                              className="ml-2"
                            />
                          </button>
                        )}
                      </div>
                    ) : (
                      <div className="btnchngflex w-full !mr-0 md:mr-6">
                        <button
                          className="flex justify-center mt-4 items-center w-full overflow-hidden hover:bg-blue-700 hover:border-1 text-white font-bold py-2 px-4 rounded-full mb-4 bg-[#2f69ff]"
                          height={60}
                          onClick={() =>
                            navigate(`/moreview?booking=${item?._id}`)
                          }
                        >
                          <span className="fontdesc !text-sm font-medium p-1 !m-0 textsell1 checkintext">
                            Check in at the hotel
                          </span>{" "}
                          <img
                            src={rightar}
                            width={20}
                            height={1}
                            className="ml-2"
                          />
                        </button>
                      </div>
                    );
                  case "SOLD":
                    return (
                      <div className="btnchngflex w-full !mr-0 md:mr-6">
                        <button
                          className="mt-4 mb-4 w-full flex justify-center items-center
                               border-2 border-[#292931] overflow-hidden text-[#292931] font-bold py-2 px-8 rounded-full  bg-white"
                          disabled
                        >
                          <span className="fontdesc !text-sm font-medium p-1 !m-0 textsell1">
                            Sold
                          </span>
                        </button>
                      </div>
                    );
                  default:
                    return (
                      !(new Date(item.fromDate) < new Date()) && (
                        <div className="btnchngflex w-full !mr-0 md:mr-6">
                          <button
                            className="flex justify-center mt-4 items-center w-full overflow-hidden hover:bg-blue-700 hover:border-1 hover:text-gray-900 text-white font-bold py-2 px-4 rounded-full mb-4 bg-[#2f69ff] border-2 border-[2px solid]"
                            height={60}
                            onClick={() =>
                              navigate(`/moreview?booking=${item?._id}`)
                            }
                          >
                            <span className="fontdesc !text-sm font-medium p-1 !m-0 textsell1 checkintext">
                              Check in at the hotel
                            </span>{" "}
                            <img
                              src={rightar}
                              width={20}
                              height={1}
                              className="ml-2"
                            />
                          </button>
                        </div>
                      )
                    );
                }
              })()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingCard;
