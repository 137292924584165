import "./ResultMap.css";
import React, { useState, useEffect } from "react";
import down from "../../images/down.svg";
import funnel from "../../images/funnel1.svg";
import CardMap from "../../components/CardMap/CardMap";
import { Swiper, SwiperSlide } from "swiper/react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PageLoader from "../../components/PageLoader/PageLoader";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import GMap from "../../services/GoogleMap";

const mapStyle = {
    minWidth: "400px",
    minHeight: "500px",
    height: "100%",
};

const ResultMap = ({ loading, hotelData, cordinates,search,sort,handleChange }) => {
    return (
        <>
            {loading ? (
                <PageLoader />
            ) : (
                <div className="flex mx-auto justify-center mt-2 max-w-[1360px]  min-h-[400px]">
                    <div className="cardwidth min-w-[461px] lg:block hidden">
                        <div className="flex w-full justify-between items-center h-[45px]">
                            <p className="fontdesc text-sm font-medium text-black">
                                {hotelData?.length} {hotelData?.length > 1 ? "Properties" : "Property"}{" "}
                                <span className="fontdesc text-gray-600">
                                    in {search}
                                </span>
                            </p>
                            <p className="flex items-center fontdesc text-sm font-medium text-gray-800">
                                Sort By :
                                <FormControl
                                    variant="standard"
                                    sx={{ m: 1, minWidth: 120 }}
                                >
                                    <Select
                                        value={sort}
                                        onChange={handleChange}
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "14px",
                                        }}
                                        IconComponent={
                                            KeyboardArrowDownRoundedIcon
                                        }
                                    >
                                        <MenuItem value={1}>
                                        Price: Low to High 
                                        </MenuItem>
                                        <MenuItem value={2}>
                                            Price: High to Low
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <img
                                    src={funnel}
                                    className="ml-2"
                                    width={14}
                                    height={14}
                                    alt=""
                                />
                            </p>
                        </div>
                        <div className="w-full !h-[450px] overflow-y-scroll overflow-x-hidden ">
                            {hotelData &&
                                hotelData.length > 0 &&
                                hotelData.map((item) => {
                                    return (
                                        <CardMap item={item} ratings={4.1} />
                                    );
                                })}
                        </div>
                    </div>

                    <div className="w-full h-full lg:ml-4 overflow-hidden relative">
                        <div className="w-full h-full">
                            <GMap
                                mapStyle={mapStyle}
                                coordinates={{
                                    lat: cordinates?.latitude,
                                    lng: cordinates?.longitude 
                                }}
                            />
                        </div>
                        <div className="lg:hidden absolute w-[350px] m-auto left-0 right-0 bottom-0">
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={1}
                                loop={true}
                                // freeMode
                                centeredSlides={true}
                                // onSwiper={(swiper) => console.log(swiper)}
                            >
                                {hotelData &&
                                    hotelData.length > 0 &&
                                    hotelData.map((item) => {
                                        return (
                                            <SwiperSlide>
                                                <CardMap
                                                    item={item}
                                                    ratings={4.1}
                                                />
                                            </SwiperSlide>
                                        );
                                    })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default ResultMap;
