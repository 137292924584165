import "./App.css";
import React, { useEffect, useState } from "react";
import Search from "./components/Search/Search";
import Navbar from "./components/Navbar/Navbar";
import MobileView from './components/Modals/MobileView/MobileView'
import Footer from "./components/Footer/Footer";
// import ResultMap from "./pages/ResultMap/ResultMap";
import "react-calendar/dist/Calendar.css";
import ManageBooking from "./pages/ManageBooking/ManageBooking";
import { BrowserRouter, Navigate, Routes, Route, useLocation } from "react-router-dom";
import Results from "./pages/Results/Results";
import HotelPage from "./pages/HotelPage/HotelPage";
import Sales from "./pages/Sales/Sales";
import Checkin from "./pages/Checkin/Checkin";
import Registration from "./pages/Registration/Registration";
import Moreview from "./pages/Moreview/Moreview";
// import Bookings from "./pages/Bookings/Bookings";
import Manage from "./pages/Manage/Manage";
import Details from "./pages/Details/Details";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Dashboard/Dashboard";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Transaction from "./pages/Transaction/Transaction";
import BuyFromOthers from "./pages/BuyFromOthers/BuyFromOthers";
import BuyNow from "./pages/BuyNow/BuyNow";
import AboutUs from "./pages/AboutUs/AboutUs";
import ContactUs from "./pages/ContactUs/ContactUs";
import FAQ from "./pages/FAQ/FAQ";
import TandC from "./pages/TandC/TandC";
import SaleRoom from "./pages/SaleRoom/saleRoom";
import HowItWorks from "./pages/HowItWorks/HowItWorks.jsx";
import UserCheckInInfo from "./pages/UserCheckInInfo/UserCheckInInfo.jsx";
import BookingInfo from "./pages/BookingInfo/BookingInfo.jsx";
import Support from "./pages/Support/Support.jsx";
import Help from "./pages/Help/Help.jsx";
import WhoWeAre from "./pages/WhoWeAre/WhoWeAre.jsx";

function App() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  const isMobile = width <= 768;
  console.log('🚀 ~ file: App.jsx ~ line 54 ~ App ~ isMobile', isMobile)
  return (
    <BrowserRouter>
      <ToastContainer limit={3} />
      <Navbar />
      <Routes>
        <Route path="/" element={<Search />} />
        <Route path="/bookings" element={isMobile ? <Navigate to="/" /> : <BuyFromOthers />} />
        <Route path="/manageBooking" element={isMobile ? <Navigate to="/" /> : <ManageBooking />} />


        <Route path="/results/:keyword" element={isMobile ? <Navigate to="/" /> : <Results />} />
        <Route path="/results" element={isMobile ? <Navigate to="/" /> : <Results />} />
        <Route path="/hotel/:id" element={isMobile ? <Navigate to="/" /> : <HotelPage />} />
        <Route path="/sales" element={isMobile ? <Navigate to="/" /> : <Sales />} />
        <Route path="/checkin" element={isMobile ? <Navigate to="/" /> : <Checkin />} />
        <Route path="/moreview" element={isMobile ? <Navigate to="/" /> : <Moreview />} />
        <Route path="/registration" element={isMobile ? <Navigate to="/" /> : <Registration />} />
        <Route path="/manage" element={isMobile ? <Navigate to="/" /> : <Manage />} />
        <Route path="/details" element={isMobile ? <Navigate to="/" /> : <Details />} />
        <Route path="/dashboard" element={isMobile ? <Navigate to="/" /> : <Dashboard />} />
        <Route path="/transaction" element={isMobile ? <Navigate to="/" /> : <ManageBooking />} />
        <Route path="/buynow/:roomId" element={isMobile ? <Navigate to="/" /> : <BuyNow />} />
        <Route path="/saleRoom/:roomId" element={isMobile ? <Navigate to="/" /> : <SaleRoom />} />
        <Route path="/booking-info" element={isMobile ? <Navigate to="/" /> : <BookingInfo />} />
        <Route path="/aboutus" element={isMobile ? <Navigate to="/" /> : <AboutUs />} />
        <Route path="/contactus" element={isMobile ? <Navigate to="/" /> : <ContactUs />} />
        <Route path="/faqs" element={isMobile ? <Navigate to="/" /> : <FAQ />} />
        <Route path="/terms" element={isMobile ? <Navigate to="/" /> : <TandC />} />
        <Route path="/how-it-works" element={isMobile ? <Navigate to="/" /> : <HowItWorks />} />
        <Route path="/user-check-in-info" element={isMobile ? <Navigate to="/" /> : <UserCheckInInfo />} />
        <Route path="/support" element={isMobile ? <Navigate to="/" /> : <Support />} />
        <Route path="/help" element={isMobile ? <Navigate to="/" /> : <Help />} />
        <Route path="/who-we-are" element={isMobile ? <Navigate to="/" /> : <WhoWeAre />} />
        {/* <Route path="/result-map" element={<ResultMap />} /> */}
        {/* { isMobile && <Route path="/manageBooking" element={<ManageBooking />} />} */}
      </Routes>
      <MobileView showModal={isMobile} />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
