import { Tooltip, Zoom } from "@mui/material";
import { useEffect, useState } from "react";
import "./heatmap.css";

const HeatMap = ({ data }) => {
  const colorBreakpoints = {
    lowValues: {
      valueRange: [1],
      color: "#E2F8E0",
    },
    midValues: {
      valueRange: [1, 20],
      color: "#C2DFC0",
    },
    highValues: {
      valueRange: [20],
      color: "#70DC66",
    },
  };

  const setColor = (value) => {
    if (value < colorBreakpoints.lowValues.valueRange[0]) return 1;
    if (
      value >= colorBreakpoints.midValues.valueRange[0] &&
      value < colorBreakpoints.midValues.valueRange[1]
    )
      return 2;
    if (value >= colorBreakpoints.highValues.valueRange[0]) return 3;
  };

  return (
    <div className="grid grid-cols-16 gap-[5px]">
      {data && data.map((activity, index) => {
        if (index >= 64) return;
        return (
          <Tooltip
            key={index}
            TransitionComponent={Zoom}
            title={`${activity.date}: ${activity.value}`}
          >
            <div
              className={`w-full h-[50px] color-${setColor(activity.value)}`}
            ></div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default HeatMap;
