import React from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import bookings from "../../images/bookings.svg";

function TandC() {
    const navigate = useNavigate();
    React.useEffect(() => {
        window.scrollTo(0, 0)
      },[])
    return (
        <div className="bg-[#F7F9FB] app">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Terms & Conditions</title>
            </Helmet>
       
            <p className="text-center sell py-4 ">Terms & Conditions</p>
            <p className=" p-2 mt-7 text-sm text-gray-500">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
            </p>
            <p className=" p-2 text-sm text-gray-500">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
            </p>
            <p className="text-zinc-900 font-bold mt-5">
                1. Scope of Service
            </p>
            <p className=" p-2 text-sm text-gray-500">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
            </p>
            <p className="text-zinc-900 font-bold mt-5">
                2. Our platforms
            </p>
            <p className=" p-2 text-sm text-gray-500">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
            </p>
            <p className="text-zinc-900 font-bold mt-5">3. Our values</p>
            <p className=" p-2 text-sm text-gray-500">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
            </p>
            <p className="text-zinc-900 font-bold mt-5">4. Prices</p>
            <p className=" p-2 text-sm text-gray-500">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
            </p>
            <p className="text-zinc-900 font-bold mt-5">5. Payment</p>
            <p className=" p-2 text-sm text-gray-500">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
            </p>
            <p className="text-zinc-900 font-bold mt-5">6. Policies</p>
            <p className=" p-2 text-sm text-gray-500">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
            </p>
        </div>
    );
}

export default TandC;
