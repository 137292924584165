import Loader from "../Loader/Loader";
import TransactionLoader from "../TransactionLoader/TransactionLoader";

const PageLoader = ({ showTransactionMessage, isTransaction }) => {
  return (
    <div className=" w-full h-full bg-white z-100000 flex justify-center items-center">
      {isTransaction ? <TransactionLoader /> : <Loader />}
      {/* <Loader showTransactionMessage={showTransactionMessage}/> */}
    </div>
  );
};

export default PageLoader;
