import { InputAdornment, TextField } from "@mui/material";
import userLogo from "../../images/userLogo.svg";
import hotelLogo from "../../images/hotelLogo.svg";
import mailLogo from "../../images/mailLogo.svg";
import addressLogo from "../../images/addressLogo.svg";
import axios from "axios";
import zipLogo from "../../images/zipLogo.svg";
import pinLogo from "../../images/pinLogo.svg";
import { useEffect, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import MapPicker from "react-google-map-picker";
import OutlinedInput from "@mui/material/OutlinedInput";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import FileUpload from "../../components/FileUpload/FileUpload";
import { toast } from "react-toastify";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import validator from 'validator';
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { validate_address } from "../../services/web3Services";
import CountrySelector from "../../components/CountrySelector/CountrySelector";
import TextEditor from "../../components/TextEditor/TextEditor";

const ITEM_HEIGHT = 48;
const DefaultLocation = { lat: 28.7041, lng: 77.1025 };
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const amenitiesList = [
    "Pool",
    "Air Conditioning",
    "Free Wifi",
    "Parking",
    "Restaurant",
    "Spa",
    "Wallet Friendly",
];

const stars = [
    { name: "2 Star", value: 2 },
    { name: "3 Star", value: 3 },
    { name: "4 Star", value: 4 },
    { name: "5 Star", value: 5 },
];

const RegistrationStepOne = ({
    stepData,
    setStepData,
    setStep,
    handleStepOneChange,
    stepOneData,
    setStepOneData,
    setTreasuryWallet,
    treasuryWallet,
    setPhone,
    phoneNo,
    countryHandler,
    country,
    setCountry,
    options,
    vatRate,
    handleVatRate,
    cleaningFee,
    handleCleaningFee,
    cityTax,
    handleCityTax,   
    setNewTags,
    noNext,
    updateImageHotelDB,
    hotelUpdate,
    addImageHotelDB,
    setDescription,
    description
}) => {
    const [tags, setTags] = useState(stepData.tags);
    const [files, setFiles] = useState({});
    const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
    const [location, setLocation] = useState(defaultLocation);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };
    const handleResetLocation = () => {
        setDefaultLocation({ ...DefaultLocation });
    };
    const handleFiles = (e) => {
        setFiles(e.target.files);
    };

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        let tempArr = typeof value === "string" ? value.split(",") : value
        setTags(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );

        sessionStorage.setItem('hotelTags',JSON.stringify(tempArr))
    };

    const handleDelete = (e, value) => {
        setTags(tags.filter((amenitiesList) => amenitiesList != value));
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            if (stepOneData.files.length < 3) {
                return toast.error("Please upload at least 3 photos.");
            }
            if(!validator.isURL(stepOneData.websiteUrl)) {
                return toast.error("Please enter a valid url!")
            }
            // const getApi = await axios.get(`https://api.postalpincode.in/pincode/${stepOneData.zip}`);

            // if(!validator.isPostalCode(stepOneData.zip, 'any') || getApi.data[0].Status == "Error") {
    
            //     return toast.error("Please use valid zip code!");
            // }
            if(!stepOneData.city) {
                return toast.error("Please enter Hotel City!");
            }
            if(!stepOneData.state) {
                return toast.error("Please enter Hotel State!");
            }
            if(!stepOneData.country) {
                return toast.error("Please enter Hotel Country!");
            }
            if(!stepOneData.latitude || !stepOneData.longitude) {
                return toast.error("Please enter valid Latitude and Longitude!")
            }
            setStepData({
                account: stepOneData.account,
                email: stepOneData.email,
                hotel: stepOneData.hotel,
                hotelAddress: stepOneData.hotelAddress,
                phone: stepOneData.phone,
                city: stepOneData.city,
                state: stepOneData.state,
                country: stepOneData.country,
                latitude: stepOneData.latitude,
                longitude: stepOneData.longitude,
                zip: stepOneData.zip,
                location: stepOneData.location,
                description: stepOneData.description,
                hotelStar: stepOneData.hotelStar,
                tags: tags,
                websiteUrl: stepOneData.websiteUrl,
                files: stepOneData.files,
            });
            setStep(1);
            window.scrollTo(0, 0)
        }
        catch(e) {
            console.log(e)
            toast.error("Please enter valid zip code!")
        }
    };

    const handleTreasury = (e) => {
        setTreasuryWallet(e.target.value);
        const re = validate_address(e.target.value);
        if (!re) {
            return toast.error("Please enter a valid address!");
        }
        sessionStorage.setItem("treasuryWallet",e.target.value);
    };
    const handlePhone = (e) => {
        setPhone(e)
        sessionStorage.setItem("phone",e)
    }
    useEffect(() => {
        if(hotelUpdate)
        setNewTags(tags);
        
    },[tags])
    useEffect(() => {
        if(sessionStorage.getItem("hotelTags")) {
            setTags(JSON.parse(sessionStorage.getItem("hotelTags")))
        }
    },[])
    return (
        <form
            className="flex flex-col gap-4 max-w-[1360px] w-full mt-8"
            onSubmit={handleSubmit}
        >
            <SimpleDialog
                open={open}
                onClose={handleClose}
                setLocation={setLocation}
                defaultLocation={defaultLocation}
            />
            <div className="flex flex-col items-center gap-8 p-8 py-12 bg-white shadow-xl shadow-[#d8e3f1a6] w-full">
                <span className="font-bold text-lg">Account Details</span>
                <div className="flex flex-col gap-4 w-full  mobile:flex-row justify-center">
                    <TextField
                        label="Account Name"
                        sx={{
                            width: "100%",
                        }}
                        value={stepOneData.account}
                        name={"account"}
                        onChange={handleStepOneChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src={userLogo} alt="account name" />
                                </InputAdornment>
                            ),
                        }}
                        required
                    />
                    <TextField
                        label="Email Address"
                        sx={{
                            width: "100%",
                        }}
                        value={stepOneData.email}
                        onChange={handleStepOneChange}
                        name="email"
                        type="email"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src={mailLogo} alt="email" />
                                </InputAdornment>
                            ),
                        }}
                        required
                    />
                </div>
                <div className="flex flex-col gap-4 w-full  mobile:flex-row justify-center">
                    <TextField
                        label="Treasury Wallet"
                        sx={{
                            width: "100%",
                        }}
                        value={treasuryWallet}
                        onChange={handleTreasury}
                        name="treasuryWallet"
                        type="text"
                        required
                    />
                </div>
            </div>
            <div className="flex flex-col items-center gap-8 p-8 py-12 bg-white shadow-xl shadow-[#d8e3f1a6] w-full">
                <span className="font-bold text-lg">Hotel Details</span>
                <div className="flex flex-col gap-4 w-full  mobile:flex-row justify-center">
                    <TextField
                        label="Hotel Name"
                        sx={{
                            width: "100%",
                        }}
                        value={stepOneData.hotel}
                        onChange={handleStepOneChange}
                        name={"hotel"}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src={hotelLogo} alt="account name" />
                                </InputAdornment>
                            ),
                        }}
                        required
                    />
                    <TextField
                        label="Address"
                        sx={{
                            width: "100%",
                        }}
                        value={stepOneData.hotelAddress}
                        name={"hotelAddress"}
                        onChange={handleStepOneChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src={addressLogo} alt="email" />
                                </InputAdornment>
                            ),
                        }}
                        required
                    />
                </div>

                <div className="flex flex-col gap-4 w-full  mobile:flex-row justify-center">
                    <TextField
                        label="City"
                        sx={{
                            width: "100%",
                        }}
                        value={stepOneData.city}
                        name={"city"}
                        onChange={handleStepOneChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src={addressLogo} alt="email" />
                                </InputAdornment>
                            ),
                        }}
                        required
                    />
                    <TextField
                        label="State"
                        sx={{
                            width: "100%",
                        }}
                        value={stepOneData.state}
                        name={"state"}
                        onChange={handleStepOneChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src={addressLogo} alt="email" />
                                </InputAdornment>
                            ),
                        }}
                        required
                    />
                    {/* <Select className="w-1/2" options={options} value={country} onChange={countryHandler} /> */}
                    <CountrySelector country={country} onChange={countryHandler}/>
                </div>
                <div className="flex flex-col gap-4 w-full  mobile:flex-row justify-center">
                    <PhoneInput
                        country={'us'}
                        value={phoneNo}
                        onChange={handlePhone}
                        placeholder="Phone Number"
                        inputProps={{
                            name: 'phoneNo',
                            required: true,
                          }}
                          isValid={(value, country) => {
                            if (value.match(/12345/)) {
                              return 'Invalid value: '+value+', '+country.name;
                            } else if (value.match(/1234/)) {
                              return false;
                            } 
                            else if(value.length <5){
                                return false
                            }
                            else {
                              return true;
                            }
                          }}
                          required
                    />
                    <div className="flex flex-col w-full mobile:flex-row justify-center gap-4">
                        <TextField
                            label="Zip Code"
                            sx={{
                                width: "100%",
                            }}
                            value={stepOneData.zip}
                            name={"zip"}
                            onChange={handleStepOneChange}
                            type="text"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={zipLogo} alt="zipcode" />
                                    </InputAdornment>
                                ),
                            }}
                            required
                        />
                        <TextField
                            label="Latitude"
                            sx={{
                                width: "100%",
                            }}
                            type={"number"}
                            value={stepOneData.latitude}
                            name={"latitude"}
                            // onClick={handleClickOpen}
                            onChange={handleStepOneChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={pinLogo} alt="email" />
                                    </InputAdornment>
                                ),
                            }}
                            required
                        />
                        <TextField
                            label="Longitude"
                            sx={{
                                width: "100%",
                            }}
                            type={"number"}
                            value={stepOneData.longitude}
                            name={"longitude"}
                            // onClick={handleClickOpen}
                            onChange={handleStepOneChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={pinLogo} alt="email" />
                                    </InputAdornment>
                                ),
                            }}
                            required
                        />
                    </div>
                </div>
                <TextEditor editorType={"hotelDescription"} description={description} setDescription={setDescription} />
                {/* <textarea
                    label="Hotel Description"
                    className='whitespace-pre-wrap'
                    placeholder="Hotel Description"
                    // multiline
                    // rows={4}
                    value={stepOneData.description}
                    name={"description"}
                    // sx={{ width: "100%" }}
                    onChange={handleStepOneChange}
                    required
                /> */}
            </div>
            <div className="flex flex-col items-center gap-8 p-8 py-12 bg-white shadow-xl shadow-[#d8e3f1a6] w-full">
                <div className="flex flex-col mobile:flex-row gap-8 justify-between w-full">
                    <div className="w-full">
                        <div className="font-bold text-lg">Star Rating</div>
                        <div className="flex gap-3 mt-4">
                            {stars.map((star) => (
                                <button
                                    key={star.name}
                                    type="button"
                                    onClick={() =>
                                        setStepOneData({
                                            ...stepOneData,
                                            hotelStar: star.value,
                                        })
                                    }
                                    className={`rounded-full border-2 border-[#7E7E7E] px-4 py-1 text-[#7E7E7E] ${
                                        stepOneData.hotelStar == star.value &&
                                        "bg-gray-300"
                                    }`}
                                >
                                    {star.name}
                                </button>
                            ))}
                        </div>
                    </div>
                    
                    <div className="w-full">
                        <div className="font-bold text-lg">Amenities<sup>*</sup></div>
                        <Select
                            multiple
                            required
                            value={tags}
                            onChange={handleChange}
                            input={<OutlinedInput sx={{ width: "100%" }} />}
                            IconComponent={AddCircleOutlineOutlinedIcon}
                            renderValue={(selected) => (
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 0.5,
                                    }}
                                >
                                    {selected.map((value) => (
                                        <Chip
                                            key={value}
                                            label={value}
                                            sx={{
                                                backgroundColor: "#2F69FF33",
                                                fontWeight: "500",
                                                borderRadius: "10px",
                                            }}
                                            onDelete={(e) =>
                                                handleDelete(e, value)
                                            }
                                            onMouseDown={(event) => {
                                                event.stopPropagation();
                                            }}
                                            deleteIcon={
                                                <CloseRoundedIcon className="!text-[#2F69FF]" />
                                            }
                                        />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {amenitiesList.map((name) => (
                                <MenuItem key={name} value={name}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    {/* <div className="w-full lg:w-1/2">
                    <div className="font-bold text-lg">VAT rate (%)<sup>*</sup></div>

                    <TextField
                            sx={{
                                width: "100%",
                            }}
                            type={"number"}
                            value={vatRate}
                            name={"vatRate"}
                            // onClick()={handleClickOpen}
                            onChange={handleVatRate}
                            InputProps={{
                                inputProps: { 
                                    max: 50, min: 1
                                }
                            }}
                            required
                        />
                    </div> */}
                    {/* <div className="w-full lg:w-1/2">
                    <div className="font-bold text-lg">Cleaning Fee (%)<sup>*</sup></div>

                    <TextField
                            sx={{
                                width: "100%",
                            }}
                            type={"number"}
                            value={cleaningFee}
                            name={"cleaningFee"}
                            // onClick()={handleClickOpen}
                            onChange={handleCleaningFee}
                            InputProps={{
                                inputProps: { 
                                    max: 50, min: 1
                                }
                            }}
                            required
                        />
                    </div>
                    <div className="w-full lg:w-1/2">
                    <div className="font-bold text-lg">City tax (%)<sup>*</sup></div>

                    <TextField
                            sx={{
                                width: "100%",
                            }}
                            type={"number"}
                            value={cityTax}
                            name={"cityTax"}
                            // onClick()={handleClickOpen}
                            onChange={handleCityTax}
                            InputProps={{
                                inputProps: { 
                                    max: 50, min: 1
                                }
                            }}
                            required
                        />
                    </div> */}
                </div>
                <TextField
                    label="Enter Website URL*"
                    sx={{ width: "100%" }}
                    value={stepOneData.websiteUrl}
                    name={"websiteUrl"}
                    onChange={handleStepOneChange}
                    type="text"
                />
                <div className="flex flex-col items-center max-w-[716px] w-full">
                    <div className="font-bold text-lg flex justify-center items-center">Photos & Videos<sup>*</sup> <span className="text-[10px] font-normal">(Min. 3)</span></div>
                    <FileUpload
                        files={files}
                        setFiles={setFiles}
                        stepData={stepOneData}
                        setStepData={setStepOneData}
                        index={"nothing"}
                        hotelUpdate={hotelUpdate}
                        updateImageHotelDB={updateImageHotelDB}
                        addImageHotelDB={addImageHotelDB}
                    />
                </div>
            </div>
            {!noNext && <div className="flex justify-center items-center my-8">
                <button
                    type="submit"
                    name="submit"
                    className="rounded-full bg-[#2F69FF] hover:hover:bg-blue-700 px-12 py-3 text-lg text-white"
                >
                    Next
                </button>
            </div>}
        </form>
    );
};

function SimpleDialog(props) {
    const { onClose, open, setLocation, defaultLocation } = props;

    function handleChangeLocation(lat, lng) {
        setLocation({ lat: lat, lng: lng });
    }

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <MapPicker
                defaultLocation={defaultLocation}
                style={{ height: "500px", width: "600px" }}
                onChangeLocation={handleChangeLocation}
                // onChangeZoom={handleChangeZoom}
                apiKey={process.env.REACT_APP_GOOGLE_MAP_API}
            />
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default RegistrationStepOne;
