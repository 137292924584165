import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'

function CountrySelector({country, onChange}) {
  const options = useMemo(() => countryList().getData(), [])

  return <Select class='w-full h-[54px]' options={options} value={country} onChange={onChange} />
}

export default CountrySelector