import { useEffect, useState } from "react";
import listSwitchImg from "../../images/listSwitch.svg";
import calanderSwitchImg from "../../images/calanderSwitch.svg";
import Switcher from "../Switcher/Switcher";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Calendar from "react-calendar";
import "./calander.css";
import Cancellation from "../../components/Modals/Cancellation/Cancellation";
import { addDaysTo } from "../../utils/date";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const cancellationList = ["Free Cancellation", "Late Cancellation"];

const switches = [
  {
    name: "List View",
    state: 0,
    img: listSwitchImg,
  },
  // {
  //     name: "Calendar view",
  //     state: 1,
  //     img: calanderSwitchImg,
  // },
];

const PricingComponent = ({
  data,
  setData,
  setStepData,
  index,
  stepData,
  editPricing,
}) => {
  let currDate = new Date();
  currDate.setHours(0, 0, 0, 0);
  const [switcher, setSwitcher] = useState(0);
  const [calanderDate, setCalanderDate] = useState([currDate, currDate]);
  const [pricingCancellation, setPricingCancellation] = useState(false);
  const [addPriceCancellation, setAddPriceCancellation] = useState(false);
  const [isClicked, setClicked] = useState(false);
  const[amount,setAmount]=useState();
  const updatePricingDate = (newVal, name, objIndex) => {
    if (name == "dateFrom") {
      setData((prevState) => {
        const newState = prevState.map((obj, index) => {
          if (index === objIndex) {
            return {
              ...obj,
              [name]: newVal,
              ["dateTo"]: addDaysTo(1, newVal),
              ["minDateTo"]: newVal,
            };
          }
          return obj;
        });
        return newState;
      });
    } else {
      setData((prevState) => {
        const newState = prevState.map((obj, index) => {
          if (index === objIndex) {
            return {
              ...obj,
              [name]: newVal,
              ["minDateTo"]:
                index === 0 ? currDate : addDaysTo(1, data[index - 1].dateTo),
            };
          }
          return obj;
        });
        return newState;
      });
    }
  };
  const updatePricingInfo = (e, objIndex) => {
    const { name, value } = e.target;
    // if(e.target.name=="percentage"){
      
    // }
    if (e.target.name == "price" && value < 0) {
      return;
    }
    if (e.target.name == "minResale" && value < 0) {
      return;
    }
    setData((prevState) => {
      const newState = prevState.map((obj, index) => {
        if (index === objIndex) {
          return { ...obj, [name]: value };
        }
        return obj;
      });
      return newState;
    });
  };

  const handleChange = (event, objIndex) => {
    const value = event.target;
    setData((prevState) => {
      const newState = prevState.map((obj, index) => {
        if (index === objIndex) {
          const newCancellation =
            typeof value === "string" ? value.split(",") : value;
          return {
            ...obj,
            cancellation: newCancellation.value,
          };
        }
        return obj;
      });
      return newState;
    });
  };

  const handleDelete = (event, value, objIndex) => {
    setData((prevState) => {
      const newState = prevState.map((obj, index) => {
        if (index === objIndex) {
          const newCancellation = obj.cancellation.filter(
            (cancellationList) => cancellationList != value
          );
          return {
            ...obj,
            cancellation: [...newCancellation],
          };
        }
        return obj;
      });
      return newState;
    });
  };

  const handleClone = (index) => {
    const tempArr = data;
    // tempArr.push(data[index]);
    tempArr.push({
      dateFrom: addDaysTo(2, data[index].dateTo),
      dateTo: addDaysTo(3, data[index].dateTo),
      price: data[index].price,
      minResale: data[index].minResale,
      cancellation: [],
      minDateTo: addDaysTo(3, data[index].dateTo),
      minDateFrom: addDaysTo(2, data[index].dateTo),
    });
    setData([...tempArr]);
  };

  const handleAddField = () => {
    const tempArr = data;
    const index = data.length - 1;
    tempArr.push({
      dateFrom: addDaysTo(1, data[index].dateTo),
      dateTo: addDaysTo(2, data[index].dateTo),
      price: data[index].price,
      minResale: data[index].minResale,
      cancellation: [],
      minDateTo: addDaysTo(1, data[index].dateTo),
      minDateFrom: addDaysTo(1, data[index].dateTo),
    });
    setData([...tempArr]);
  };
  const addCancellation = async () => {
    
    setAddPriceCancellation(true);
    setPricingCancellation(false);
  };
  const handleDeletePrice = (index) => {
    const tempArr = data;
    if (tempArr.length === 1) return;
    tempArr.splice(index, 1);
    setData([...tempArr]);
  };

  return (
    <div className="flex flex-col w-full">
      <div className="font-bold text-lg">Pricing</div>

      <div className="flex flex-col mobile:flex-row items-center gap-6 justify-between">
        <div className="text-[#7E7E7E] font-medium mobile:w-1/2">
          These details will be used to create the basic availability and
          pricing for all the rooms of this type. You will then be able to
          modify details for each room.
        </div>
        <Switcher
          state={switcher}
          setState={setSwitcher}
          switchData={switches}
        />
      </div>
      {switcher === 0 && (
        <div className="flex flex-col gap-4 mt-6">
          {data.map((pricing, index) => (
            <div
              key={index + " switcherdata"}
              className="flex justify-between flex-col mobile:flex-row gap-4 items-center"
            >
              {pricingCancellation && (
                <Cancellation
                  index={index}
                  updatePricingInfo={updatePricingInfo}
                  isClicked={isClicked}
                  pricing={pricing}
                  addCancellation={addCancellation}
                  open={pricingCancellation}
                  setOpen={setPricingCancellation}
                  setClicked={setClicked}
                />
              )}
              <MobileDatePicker
                disablePast={editPricing ? false : true}
                label="from"
                inputFormat="dd MMM yyyy"
                name="dateFrom"
                value={pricing.dateFrom}
                sx={{
                  width: "100%",
                  "@media(minWidth: 992px)": {
                    width: "auto",
                  },
                }}
                onChange={(newVal) =>
                  updatePricingDate(newVal, "dateFrom", index)
                }
                renderInput={(params) => <TextField {...params} />}
                minDate={pricing.minDateFrom}
                required
              />
              <MobileDatePicker
                disablePast={true}
                label="to"
                inputFormat="dd MMM yyyy"
                name="dateTo"
                value={pricing.dateTo}
                onChange={(newVal) =>
                  updatePricingDate(newVal, "dateTo", index)
                }
                renderInput={(params) => <TextField {...params} />}
                minDate={pricing.minDateTo}
                required
              />
              <TextField
                required
                label="Price"
                name="price"
                value={pricing.price}
                onChange={(e) => updatePricingInfo(e, index)}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
              <TextField
                required
                label="Minimum Resale"
                name="minResale"
                value={pricing.minResale}
                onChange={(e) => updatePricingInfo(e, index)}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel>Cancellation</InputLabel>
                <Select
                  // multiple
                  required
                  name="cancellation"
                  value={pricing.cancellation}
                  onChange={(e) => {
                    handleChange(e, index);
                    if (e.target.value == "Late Cancellation") {
                      setPricingCancellation(true);
                    }
                  }}
                  input={<OutlinedInput label="Cancellation" />}
                  IconComponent={AddCircleOutlineOutlinedIcon}
                  MenuProps={MenuProps}
                >
                  {cancellationList.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="flex gap-4 items-center">
                <button
                  type="button"
                  className="text-[#2F69FF] font-medium"
                  onClick={() => handleClone(index)}
                >
                  Clone
                </button>
                <button
                  type="button"
                  className="text-[#2F69FF] font-medium"
                  onClick={() => handleDeletePrice(index)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
          {data && data.length > 0 && (
            <div className="flex justify-center mobile:justify-start">
              <button
                onClick={handleAddField}
                type="button"
                className="text-[#2F69FF] font-medium flex items-center w-fit gap-1"
              >
                <AddCircleOutlineOutlinedIcon fontSize="small" />
                <span>Add new date range and price</span>
              </button>
            </div>
          )}
        </div>
      )}
      {switcher === 1 && (
        <>
          <div className="calendar-container w-full flex justify-center my-8">
            <Calendar
              onChange={setCalanderDate}
              value={calanderDate}
              selectRange={true}
              tileContent={({ activeStartDate, date, view }) => {
                return (
                  view === "month" &&
                  data.map((d) => {
                    return d.dateFrom <= date.getDate() ? (
                      <p>{d.price}</p>
                    ) : null;
                  })
                );
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PricingComponent;
