import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { InputAdornment, TextField } from "@mui/material";

const style = {
    position: "absolute",
    textAlign: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 580,
    bgcolor: "#fff",
    border: "0px solid #000",
    boxShadow: 24,
    p: 4,
};
const Cancellation = ({ open, setOpen,addCancellation, updatePricingInfo, pricing, index,isClicked  ,setClicked }) => {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Cancellation Policy
                </Typography>
                <Typography
                    // className="flex flex-row"
                    id="modal-modal-description"
                    sx={{ mt: 2 }}
                >
                    <TextField
                        required
                        label="Cancellation possible until (days)"
                        name="daysBefore"
                        value={pricing.daysBefore}
                        onChange={(e) => updatePricingInfo(e, index)}
                        type="number"
                        className="mr-2 w-1/2 "
                    />
                   
                </Typography>

                <Typography variant="body2" sx={{mt:2}}>
                Cancellation fees (% of original price or fixed USDC amount)
                </Typography>
                
                <Typography 
                                    className="flex flex-row"
                                    id="modal-modal-description"
                                    sx={{ mt: 3 }}
                >
                <TextField
                        label="% of original price"
                        name="percentage"
                        value={pricing.percentage}
                        onChange={(e) => updatePricingInfo(e, index)}
                        type="percentage"
                        placeholder="30%"
                        className="ml-4 "
                        disabled={pricing?.amount !== "" && pricing.amount !== undefined}
                        

                    />
                    <Typography 
                    sx={{ ml: 2 , mt:2}} >
                        OR
                    
                    </Typography>
                 <TextField
                        label="fixed USDC amount"
                        name="amount"
                        value={pricing.amount}
                        onChange={(e) => updatePricingInfo(e, index)}
                        type="number"
                        placeholder="$10" 
                        className="ml-4"
                        disabled={
                            pricing?.percentage !== "" && pricing?.percentage !== undefined
                          }
                    />


                </Typography>
                
                <div className="flex justify-center mt-4 items-center gap-4">
                    <button
                        type="button"
                        onClick={handleClose}
                        className="bg-white border-2 border-[#7E7E7E] text-[#7E7E7E] font-medium px-6 py-2 rounded-full"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={(e) => addCancellation(e)}
                        className="bg-[#292931] border-2 border-[#292931] text-[#fff] font-medium px-6 py-2 rounded-full"
                    >
                        Save
                    </button>
                </div>
            </Box>
        </Modal>
    );
};

export default Cancellation;
