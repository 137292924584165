import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
export const getBase64 = (file) => {
    return new Promise((resolve) => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
};

const FileUpload = ({
    files,
    setFiles,
    setStepData,
    index,
    stepData,
    roomData,
    type,
    updateImageDB,
    addImageDB,
    place,
    hotelUpdate,
    updateImageHotelDB,
    addImageHotelDB,
    ...props
}) => {
    const [mapFile, setMapFile] = useState([]);
    const [loading, setLoading] = useState("");
    const handleFiles = (e) => {
        let fileSize = e.target.files[0]?.size;
        if (fileSize > 2 * 1000000) {
            // fileSize > 10MB then show popup message
            toast.error(`File size is too large, please upload image of size less than 2MB.\nSelected File Size: ${(fileSize / 1000000).toFixed(2)}MB`);
            return;
        }
        setFiles(e.target.files);
    };

    const updateFiles = async () => {
        const tempArr = mapFile; //for state
        let count = 0;
        for (const [key, value] of Object.entries(files)) {
            let base64File;
            if (typeof value == "object") {
                base64File = await getBase64(value);
                count += 1
            } else {
                base64File = value;
            }
            tempArr.push(base64File);
        }
        if (place === "roomUpdate" && count !== 0) {
            try {
                setLoading(tempArr.length - 1)
                await addImageDB(tempArr[tempArr.length - 1])
                setLoading("")
            } catch (err) {
                console.log(err);
            }
        }
        setMapFile(tempArr);
        setStepData((prevState) => {
            const newState = prevState.map((obj, objIndex) => {
                if (objIndex == index) {
                    return {
                        ...obj,
                        files: tempArr,
                    };
                }
                return obj;
            });
            return newState;
        });
    };

    const mapFiles = async () => {
        let tempArr = mapFile;
        if (stepData.files?.length > 0) {
            tempArr = stepData.files;
        }
        let count = 0;
        for (const [key, value] of Object?.entries(files)) {
            if (key === "length") return;
            if (index == "nothing") {
                let base64File;

                if (typeof value == "object") {
                    base64File = await getBase64(value);
                    
                    count += 1
                } else {
                    base64File = value;
                }
                tempArr.push(base64File);
            }
        }
        if(hotelUpdate && count !== 0) {
            try {
                setLoading(tempArr.length - 1)
                await addImageHotelDB(tempArr[tempArr.length - 1])
                setLoading("")
                
            } catch (err) {
                console.log(err);
            }
        }
        setMapFile(tempArr);
        setStepData({
            ...stepData,
            files: tempArr,
        });
    };
    const uploadingImages = async () => {
        try {
            if (!roomData) return mapFiles();
            updateFiles();
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        uploadingImages();
    }, [files]);
    const handleImageDelete = async (idx) => {
        const toDelete = mapFile[idx];
        if(mapFile.length <=3 && place === "roomUpdate") {
            return toast.error("Atleast 3 images are required!")
        } 
        mapFile.splice(idx, 1);
        setMapFile(mapFile);

        if (type !== "room") {
            setStepData({
                ...stepData,
                mapFile: mapFile,
            });

            if(hotelUpdate) {
                try {
                    setLoading(idx);
                    const t = await updateImageHotelDB(toDelete);
                    setLoading("");
                } catch (err) {
                    console.log(err);
                    setLoading("");
                }
            }
        } else {
            if (place === "roomUpdate") {
                try {
                    setLoading(idx);
                    const t = await updateImageDB(toDelete);
                    setLoading("");
                } catch (err) {
                    console.log(err);
                    setLoading("");
                }
            }
            

            setStepData((prevState) => {
                const newState = prevState.map((obj, objIndex) => {
                    if (objIndex == index) {
                        return {
                            ...obj,
                            files: mapFile,
                        };
                    }
                    return obj;
                });
                return newState;
            });
        }
    };


    return (
        <div className="grid grid-cols-2 mobile:grid-cols-6 gap-4 mt-4">
            {mapFile.map((file, idx) =>
                loading === idx ? (
                    <div key={idx + "fileUpload" + index} className="flex justify-center items-center">
                        <CircularProgress />
                    </div>
                ) : (
                    <div
                        key={idx + "fileUpload" + index}
                        className="relative w-[106px] h-[106px] overflow-hidden rounded-xl imageHover cursor-pointer"
                        onClick={() => handleImageDelete(idx)}
                    >
                        <img
                            src={(file)}
                            alt="file"
                            className="w-full h-full absolute object-cover z-0"
                        />
                        <div className="h-full w-full absolute flex justify-center items-center bg-gray-900/50 z-10 text-white font-bold">
                            X
                        </div>
                    </div>
                )
            )}
            <label className="flex flex-col w-[106px] h-[106px] rounded-xl border-dashed border-2 border-[#2F69FF] gap-1 justify-center items-center">
                <div className="bg-[#2F69FF33] rounded-full w-fit p-1">
                    <AddRoundedIcon className="text-[#2F69FF]" />
                </div>
                <div className="font-medium text-[#2F69FF]">Add</div>
                <input
                    type="file"
                    accept=".jpg,.jpeg,.png,.mp4"
                    // value={files}
                    onChange={handleFiles}
                    className="opacity-0 absolute max-w-[1px] max-h-[1px]"
                    multiple
                />
            </label>
        </div>
    );
};

export default FileUpload;
